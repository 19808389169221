import { handleActions, createAction } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'dealerChange';


export const dealerChangeList = fasterActions(moduleName, 'dealerChangeList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);

const initialState = {
    dictPaging: {
        page: 0,
        size: 20,
        // totalElements: 0,
        // sort:'id,desc'
    },
    // dealerList: []
    dealerChangeData: [],
};

const reducer = handleActions({
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, dictPaging: { ...payload} }
    },

    [dealerChangeList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [dealerChangeList.SUCCESS]: (state, { payload }) => ({ ...state, dealerChangeData: payload, loading: false }),
    [dealerChangeList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
}, initialState);

export default reducer;