import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeScrap';
import { ajaxBaseConfig, actionApi ,apiBaseConfig} from '../../utils';

export const loadcodeScrapEpic = actions$ => actions$.pipe(
  ofType(actions.loadcodeScrap.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.data.scrap.codeScrapList}?search=${name}&page=${page}&size=${size}&tagFileTypeEnum=SCRAP_FILE`
    }).pipe(
      map(res => actions.loadcodeScrap.SUCCESS(res.response)),
      catchError(error => of(actions.loadcodeScrap.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// export const codeScrapDownloadEpic = actions$ => actions$.pipe(
//   ofType(actions.codeScrapDownload.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi()
//     // const {id,isErrorFile } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'GET',
//       url: `${urls.data.scrap.codeScrapDownload}`
//     }).pipe(
//       map(res => actions.codeScrapDownload.SUCCESS(res.response)),
//       catchError(error => of(actions.codeScrapDownload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );


// `,
export const loadcodeScrapSelectEpic = actions$ => actions$.pipe(
  ofType(actions.loadcodeScrapSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { idOrName = '', page = 0, size = 100, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.codedb.codedb}/selections?query=${idOrName}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadcodeScrapSelect.SUCCESS(res.response)),
      catchError(error => of(actions.loadcodeScrapSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const createcodeScrapEpic = actions$ => actions$.pipe(
  ofType(actions.createcodeScrap.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data,formData, callback } = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'POST',
      body: formData,
      url: `${urls.data.scrap.codeScrapUpload}?parentType=${data.parentType}&linkCode=${data.linkCode}&dataJson=${data.dataJson}&factoryId=${data.factoryId}`
   
    }).pipe(
      map(res => {
        callback && callback()
        return actions.createcodeScrap.SUCCESS(res)
      }),
      catchError(error => of(actions.createcodeScrap.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


  export const factoriesListEpic = actions$ => actions$.pipe(
    ofType(actions.factoriesList.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { pageable,word,factoryType,status,deptId,isPage=false } = action.payload;
      return ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.data.factory.factories}/allots?pageable=${pageable}&word=${word}&factoryType=${factoryType}&status=${status}&deptId=${deptId}&isPage=${isPage}`
      }).pipe(
        map(res => actions.factoriesList.SUCCESS(res.response)),
        catchError(error => of(actions.factoriesList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    }
    )
  );
