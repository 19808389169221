let baseUrl = '';
let baseApiUrl = '';
let baseCdnUrl = '';

let thirdUrl = '';
let thirdApiUrl = '';
let thirdCdnUrl = '';

let davinciUrl = '';//达芬奇服务域名，待优码更新后，换为优码达芬奇域名；

switch (process.env.env) {
  case 'local':
    //  baseUrl = 'https://ucode-test.aax6.cn/api-gateway';
    //  baseApiUrl = 'https://ucode-test-openapi.aax6.cn';
    //  baseUrl = 'http://192.168.31.14';
    //  baseApiUrl = 'http://192.168.31.14';
    baseUrl = 'https://ucode-test.aax6.cn/api-gateway';
    baseApiUrl = 'https://ucode-test-openapi.aax6.cn';
    break;
  case 'dev':
    baseUrl = 'https://ucode-dev.aax6.cn/api-gateway';
    baseApiUrl = 'https://ucode-test-openapi.aax6.cn';
    break;
  case 'test':
    baseUrl = 'https://ucode-test.aax6.cn/api-gateway';
    baseApiUrl = 'https://ucode-test-openapi.aax6.cn';
    break;
  case 'prod':
    baseUrl = 'https://ucode.aax6.cn/api-gateway';
    baseApiUrl = 'https://ucode-openapi.aax6.cn';
    break;
  default:
    baseUrl = 'https://ucode-test.aax6.cn/api-gateway';
    baseApiUrl = 'https://ucode-test-openapi.aax6.cn';
    break;
}
switch (process.env.env) {
  case 'local':
    thirdUrl = 'https://mztuat.jnc.cn/api-gateway'; // 测试环境
    thirdApiUrl = 'https://mztuat-openapi.jnc.cn';
    thirdCdnUrl = 'https://mztuat-cdn.jnc.cn';
    davinciUrl = 'https://mztuat.jnc.cn';
    break;
  case 'proxy':
    thirdUrl = 'api-gateway'; // 测试环境
    thirdApiUrl = 'https://mztuat-openapi.jnc.cn';
    thirdCdnUrl = 'https://mztuat-cdn.jnc.cn';
    davinciUrl = 'https://mztuat.jnc.cn';
    break;
  case 'dev':
    thirdUrl = 'https://mztuat.jnc.cn/api-gateway';
    thirdApiUrl = 'https://mztuat-openapi.jnc.cn';
    thirdCdnUrl = 'https://mztuat-cdn.jnc.cn';
    davinciUrl = 'https://mztuat.jnc.cn';
    break;
  case 'test':
    thirdUrl = 'https://mztuat.jnc.cn/api-gateway';
    thirdApiUrl = 'https://mztuat-openapi.jnc.cn';
    thirdCdnUrl = 'https://mztuat-cdn.jnc.cn';
    davinciUrl = 'https://mztuat.jnc.cn';
    break;
  case 'prod':
    thirdUrl = 'https://mzt.jncjt.cn/api-gateway';
    thirdApiUrl = 'https://mzt-openapi.jncjt.cn';
    thirdCdnUrl = 'https://mztuat-cdn.jnc.cn';
    davinciUrl = 'https://mzt.jncjt.cn';
    break;
  default:
    thirdUrl = 'https://mztuat.jnc.cn/api-gateway';
    thirdApiUrl = 'https://mztuat-openapi.jnc.cn';
    thirdCdnUrl = 'https://mztuat-cdn.jnc.cn';
    davinciUrl = 'https://mztuat.jnc.cn';
    break;
}

baseUrl = thirdUrl
baseApiUrl = thirdApiUrl
baseCdnUrl = thirdCdnUrl

const promotionServer = `${baseUrl}/promotion/portal`;
const dataServer = `${baseUrl}/data/portal`;
const codeServer = `${baseUrl}/code/portal`;
const wechatServer = `${baseUrl}/ws/portal`;
const couponServer = `${baseUrl}/card/portal`;
const queryToolsServer = `${baseUrl}/query-tools/portal`;
const memberServer = `${baseUrl}/member-center/portal`;
const codeToolServer = `${baseUrl}/code/tool`;
const reportServer = `${baseUrl}/report/portal`;
const warnServer = `${baseUrl}/warn/portal`;
const writeOffShop = `${baseUrl}/tm/portal`;

const dataManageServer = `${baseUrl}/dm/portal`;
const warnManageServer = `${baseUrl}/warn/portal`;
//  金额转账
const moneyServer = `${baseUrl}/report/portal/resources`;

//  码状态
const stateTableCodeUrl = `${dataManageServer}/tag-status/v1`;
const awManageServer = `${baseUrl}/aw/portal`;
//  商城
const pointMallServer = `${baseUrl}/points-mall`
//  返利
const rebateBaswServer = `${baseUrl}/tm/portal`
const rebateServer = `${rebateBaswServer}/rebate/v1/`
const rebateCaseServer = `${rebateBaswServer}/case/v1/`
const rebateDetailServer = `${rebateBaswServer}/rebatedetial/v1/`
// H5代码包
const h5CodePackageUrlBase = `${baseUrl}/promotion/portal`;
const h5CodePackageUrl = `${h5CodePackageUrlBase}/pack`;
//  const h5CodePackageUrladd = `${h5CodePackageUrlBase}/portal/pack`;

const prizesUrl = `${promotionServer}/prizes`;
const poolsUrl = `${promotionServer}/pools`;
const poolPrizesUrl = `${promotionServer}/pool-prizes`;
const poolPrizeGroupUrl = `${promotionServer}/pool-prize-group`;
const prizeCardTypesUrl = `${promotionServer}/prize-card-types`;
const prizePointTypesUrl = `${promotionServer}/prize-point-types`;
const promotionsUrl = `${promotionServer}/promotions`;
const tagConfigsUrl = `${promotionServer}/tag-configs`;
const userConfigsUrl = `${promotionServer}/user-configs`;
const planStrategiesUrl = `${promotionServer}/plan-strategies`;
const consumptionsUrl = `${promotionServer}/consumptions`;
const gpsPositionsUrl = `${promotionServer}/gps-positions`;
const checkInsUrl = `${promotionServer}/check-ins`;
const invitesUrl = `${promotionServer}/invites`;
const registersUrl = `${promotionServer}/registers`;
const promotionHistoriesUrl = `${promotionServer}/promotion-histories`;
const awardsUrl = `${promotionServer}/awards`;
const tencentBspUrl = `${promotionServer}/tencent-bsp`;
const plansUrl = `${promotionServer}/plans`;
const accessUrl = `${promotionServer}/access`;
const poolHistorysUrl = `${promotionServer}/pool-historys`;
const customTemplateUrl = `${promotionServer}/custom-template/v1`;


const permissionsUrl = `${dataServer}/permissions`;
const productsUrl = `${dataServer}/products`;
const zonesUrl = `${dataServer}/zones`

const memberTagUrl = `${memberServer}/tags`;
const memberTagAttrUrl = `${memberServer}/tag/attributes`;
const usersUrl = `${dataServer}/users`;
const addressBooksUrl = `${dataServer}/address-books`;
const UploadUrl = `${dataServer}/file-records`;
const UploadMasterUrl = `${dataServer}/master-query/v1/upLoad`;
const enterprisesUrl = `${dataServer}/enterprises`;
const rolesUrl = `${dataServer}/roles`;
const categoriesUrl = `${dataServer}/categories`;
const departments = `${dataServer}/departments`;
const factoryUrl = `${dataServer}/factories`;
const brandUrl = `${dataServer}/brands`;
const mpUsersUrl = `${dataServer}/mp-users/v1`;
const filedsUrl = `${dataServer}/fileds/v1`;
const isvUserUrl = `${dataServer}/isv-users/v1`;
const isvLinksUrl = `${dataManageServer}/isv/links/v1`;
const codedbUrl = `${dataServer}/codedb/v1`;
const codeUploadUrl = `${dataManageServer}/tag-package/v1/upload`;
const codeScrapUrl = `${dataManageServer}/tag-package/v1/scrap`;
const codeStatisticsUrl = `${dataManageServer}/scrap/report/v1`;
const dealerUrl = `${dataServer}/dealers/v1`;
const codefactoryUrl = `${dataManageServer}/dealers/v1`;
const outReportUrl = `${dataManageServer}/delivery/report/v1`;
const voucherUrl = `${promotionServer}/write-off-coupon-code/v1`;


const tagbatchesUrl = `${codeServer}/tagbatchs`;
const tagbatchConfigsUrl = `${codeServer}/tagbatch-configs`;
const domainConfigsByEseIdUrl = `${codeServer}/domain-configs/v1/all`;
const enterpriseinfo = `${codeServer}/enterpriseinfo/v1`;
const domainConfigsUrl = `${codeServer}/domain-configs/v1`;
const customFieldsUrl = `${codeServer}/custom-fields/v1`;
const customerUrl = `${codeServer}/customer/v1`;
const certificateUrl = `${codeServer}/certificate/v1`;
const certificateAll = `${codeServer}/ipc/v1/all`;
const loadIpcList = `${codeServer}/ipc/v1`;




const couponConfigsUrl = `${couponServer}/coupon-configs`;
const livesUrl = `${promotionServer}/pageManage/v1`
const templatesUrl = `${promotionServer}/templates/v1`;
const historiesUrl = `${promotionServer}/pageManage-histories/v1`
const couponConfigDetailsUrl = `${couponServer}/coupon-config-details`;
const couponCodePacketUrl = `${couponServer}/coupon-code-packet`;

const redPacketConfigsUrl = `${wechatServer}/red-packet-configs`;
const authorizerConfigsUrl = `${wechatServer}/authorizer-configs`;

const miniProgramConfig = `${wechatServer}/mini-program-config/v1`;
const qrcode = `${miniProgramConfig}/qrcode`;
const qrcodeJumpUrl = `${miniProgramConfig}/qrcode-jump`;
const qrcodeJumpReleaseUrl = `${miniProgramConfig}/qrcode-jump/release`;
const miniProgramDevelopersUrl = `${wechatServer}/mini-program-developers/v1/`;
const miniProgramDevelopersRelationUrl = `${wechatServer}/mini-program-developers-relation/v1/`;
const miniProgramDevelopersAppUrl = `${wechatServer}/mini-program-developers/v1/app`;
const wechatAuthUrl = `${wechatServer}/wechat-authorize/v1`;
const miniProgramRelease = `${wechatServer}/mini-program-release/v1`;
const miniProgramTesters = `${wechatServer}/mini-program-testers/v1/`;
const miniProgramMaterials = `${wechatServer}/mini-program-materials/v1/`;
const miniProgramEseRelationUrl = `${wechatServer}/mini-program-ese-relation/v1/`;
const experiencersUrl = `${wechatServer}/experiencers/v1`;


const batchApplysUrl = `${codeServer}/batch-applys`;
const batchScrapsUrl = `${codeServer}/tags/v1/scraps`;
const batchInformations = `${codeServer}/tags`;
const tagMaterialsUrl = `${codeServer}/tag-materials/v1`;
const templateConfigsUrl = `${codeServer}/template-configs`;
const thirdPartCodeHandleUrl = `${codeServer}/third-part-code-handle/package/v1`;


const queryToolsUrl = flag => `${queryToolsServer}/${flag}/v1`;
const tagInfoUrl = `${codeServer}/customer/v1/`;

const dataOverviewUrl = `${reportServer}/data/v1`;
const realTimeUrl = `${reportServer}/realtime/v1`;
const reportResourcesUrl = `${reportServer}/resources/v1`;
const marketingUrl = `${reportServer}/marketing/v1`;
const amountUrl = `${reportServer}/amount/v1`;
const reportLinkUrl = `${baseUrl}/report/portal/custom/reportv1`;
const rebateUrl = `${reportServer}/rebate/v1`;


const tagStatussUrl = `${dataManageServer}/tag-status/v1`;
const linksUrl = `${dataManageServer}/links/v1`;
const linksenterpriseUrl = `${dataManageServer}/link-enterprises/v1`;
const copyenterpriseUrl = `${dataManageServer}/link-enterprises/v1/copy`;
const codeSourceUrl = `${codeServer}/codesource/v1`;
const linkTagsUrl = `${dataManageServer}/link-tags/v1`
const linkUploadLogsUrl = `${dataManageServer}/link-upload-logs/v1`


const tagDatasUrl = `${awManageServer}/tag-datas/v1`
const tagDatasUrl2 = `${awManageServer}/tag-datas/v2`
const tagDataLogsUrl = `${awManageServer}/tag-data-logs/v1`
const tagDataLogsUrl2 = `${awManageServer}/tag-data-logs/v2`

// 数据大屏
const warnUrl = `${warnServer}/records/v1`;
const dataScreenUrl = `${reportServer}/data-screen/v1`;
//  预警事件
const warnEventsUrl = `${warnManageServer}/events/v1`;
const warnRecipientsUrl = `${warnManageServer}/recipients/v1`;
const warnRecordUrl = `${warnManageServer}/records/v1`;

//  小程序模版
const minTemplateUrl = `${wechatServer}/mp/template/v1`;
const minTemplateEseUrl = `${wechatServer}/mp/template/ese/v1`;


const codingTemUrl = `${promotionServer}/templates/v1`;



//  商户号
const merchantUrl = `${wechatServer}/merchants/v1`;
const merchantV2Url = `${wechatServer}/merchants/v2`;

// 品牌红包
const brandRedPackUrl = `${promotionServer}/brand-redpacket/v1`;

// 消息订阅
const messageCenterServer = `${baseUrl}/wechat-msg/portal`
const mallUrl = ``


//大屏抽奖
const screenLotteryUrl = `${baseUrl}/lucky-draw/portal`;

const characteristic = `${baseUrl}/special-activity/portal`; // 特色活动
const tmServer = `${baseUrl}/tm/portal`;

const urls = {
  promotion: {
    //  奖品
    prizes: {
      // 查询/删除/复制 奖品
      prizes: `${prizesUrl}/v1`,
      // 创建/修改 红包奖品
      redPacket: `${prizesUrl}/v1/red-packet`,
      // 创建/修改 实物奖品
      physical: `${prizesUrl}/v1/physical`,
      // 创建/修改 积分奖品
      pointPrizeType: `${prizesUrl}/v1/point-type`,
      // 创建/修改 优惠券优奖品
      coupon: `${prizesUrl}/v1/coupon`,
      // 创建/修改 虚拟卡奖品
      cardPrizeType: `${prizesUrl}/v1/card-type`,
      changeOfMoney: `${prizesUrl}/v1/changeOfMoney`,
      writeOff: `${prizesUrl}/v1/write_off`,
    },



    //  发放方式
    sendWay: {
      sendWay: `${promotionServer}/incentives/v1`
    },

    //  奖池
    pools: {
      //  查询/创建/修改/删除 奖池
      pools: `${poolsUrl}/v1`,
      delete: `${poolsUrl}/v1`,
      create: `${poolsUrl}/v1`,
      budget: `${poolsUrl}/v1/budget`,
      poolGroup: `${poolPrizeGroupUrl}/v1`,
      recharge: `${poolsUrl}/v1/recharge`
    },
    // 奖池V2
    poolsV2: {
      // 查询/创建/修改/删除 奖池
      poolsV2: `${poolsUrl}/v2`,
      nestPool: `${promotionServer}/nest-pool/v2`,

    },

    //  奖池奖品
    poolPrizes: {
      //  查询/创建/修改/删除 奖池奖品
      poolPrizes: `${poolPrizesUrl}/v1`,
      create: `${poolPrizesUrl}/v1/batch`,
      edit: `${poolPrizesUrl}/v1/`,
      check: `${poolPrizesUrl}/v1/check`
    },
    //  虚拟卡
    prizeCardTypes: {
      //  查询/创建/修改/删除 虚拟卡
      prizeCardTypes: `${prizeCardTypesUrl}/v1`,
    },
    // 积分类型
    prizePointTypes: {
      // 查询/创建/修改/删除 积分类型
      prizePointTypes: `${prizePointTypesUrl}/v1`,
    },
    // 防伪信息
    security: {
      securityDetail: `${reportServer}/security/v1/securityDetail`,
      security: `${promotionServer}/antiFake/v1`,
      secActivity: `${reportServer}/resources/v1/secActivity`,
      codeSingle: `${reportServer}/securityCode/v1`
    },
    //核销活动
    whiteOff: {
      // securityDetail: `${reportServer}/security/v1/securityDetail`,
      whiteOff: `${promotionServer}/verification/v1`,
      config: `${promotionServer}/Verify-configs/v1`,
      policy: `${promotionServer}/verify-policy`,
      // secActivity: `${reportServer}/resources/v1/secActivity`,
      // codeSingle: `${reportServer}/securityCode/v1`
    },
    // 活动信息
    promotions: {
      // 扫码活动 新增/修改
      tag: `${promotionsUrl}/v1/tag`,
      // 非扫码活动 新增/修改
      other: `${promotionsUrl}/v1/other`,
      // 活动发布
      publish: (id) => `${promotionsUrl}/v1/publish/post`,
      // 活动暂停
      pause: (id) => `${promotionsUrl}/v1/${id}/pause`,
      // 活动恢复
      resume: (id) => `${promotionsUrl}/v1/${id}/resume`,
      // 活动删除, 草稿分页查询
      promotions: `${promotionsUrl}/v1`,
      // 非草稿分页查询
      valid: `${promotionsUrl}/v1/valid`,
      //待发布分页查询
      toBeReleased: `${promotionsUrl}/v1/toBeReleased`,
      // 活动优先级调整
      priority: (publishId, promotionPriority) => `${promotionsUrl}/v1/${publishId}/priority/${promotionPriority}`,
      // 活动优先级列表
      priorities: (category) => `${promotionsUrl}/v1/priorities/${category}`,
      // 删除产品验证是否被方案适用条件使用
      productValid: (promotionId, productId) => `${promotionsUrl}/v1/${promotionId}/product-valid/${productId}`,
      // 统计草稿数量  
      draftCount: `${promotionsUrl}/v1/draft-count`,
      //统计待发布数量
      draftCountTime: `${promotionsUrl}/v1/toBeReleased-count`,
      bindCode: (publishId, promotionCode) => `${promotionsUrl}/v1/${publishId}/bind-code?promotionCode=${promotionCode}`,
      //列表
      selections: `${promotionsUrl}/v1/selections`,
      //列表for查询工具
      selectionsForQool: `${promotionServer}/match/v1`,
      selectionsForDealer: `${dataServer}/dealers/v1`,
      // 会员标签被活动使用列表
      tagCode: `${promotionsUrl}/v1/tag-code/usedPromotions`,

      moreActive: `${promotionServer}/more-promotion-limit/v1`,
      mateActive: `${promotionServer}/promotion-match-config/v1`,
    },

    //  扫码抽奖配置
    tagConfigs: {
      //  查询/创建/修改
      tagConfigs: `${tagConfigsUrl}/v1`,
    },
    //  用户限制配置
    userConfigs: {
      //  查询/创建/修改
      userConfigs: `${userConfigsUrl}/v1`,
    },
    //  抽奖策略
    planStrategies: {
      //  查询/创建/修改
      planStrategies: `${planStrategiesUrl}/v1`,
    },
    //  抽奖消耗配置
    consumptions: {
      //  查询/创建/修改/删除
      consumptions: `${consumptionsUrl}/v1`,
    },
    //  定点引爆
    gpsPositions: {
      //  查询/创建/修改/删除
      gpsPositions: `${gpsPositionsUrl}/v1`,
    },
    //  签到配置
    checkIns: {
      //  查询/创建/修改
      checkIns: `${checkInsUrl}/v1`,
    },
    //  邀请配置
    invites: {
      //  查询/创建/修改
      invites: `${invitesUrl}/v1`,
    },
    //  奖品登记发放
    registers: {
      //  查询/登记
      registers: `${registersUrl}/v1`,
      //  导出接口
      export: `${registersUrl}/v1/export`,
    },
    //  创建条件模版 TODO
    //  活动发布历史
    promotionHistories: {
      //  查询
      promotionHistories: `${promotionHistoriesUrl}/v1`,
      //  按步骤查询
      step: (id, promotionId, step) => `${promotionHistoriesUrl}/v1/${id}/step/${promotionId}?step=${step}`,
      //  指定奖项组合查询
      historyAward: (id, awardId) => `${promotionHistoriesUrl}/v1/${id}/history-award/${awardId}`,
      //  指定方案所属查询
      historyPlan: (id, planId) => `${promotionHistoriesUrl}/v1/${id}/history-plan/${planId}`
    },
    //  奖项
    awards: {
      //  创建/修改/删除/查询
      awards: `${awardsUrl}/v1`,
      //  设置
      setup: `${awardsUrl}/v1/setup`,
      //  组合奖 创建/修改
      combination: `${awardsUrl}/v1/combination`,
    },
    //  方案
    plans: {
      //  创建/修改/删除/查询
      plans: `${plansUrl}/v1`,
      //  方案排序
      sort: `${plansUrl}/v1/sort`,
      //  查询活动中方案概率 和 金额都是最大的奖品概率
      max: `${plansUrl}/v1/check/max`,
      //  查询活动中未设置限制的大奖
      limit: `${plansUrl}/v1/check/limit`,
      //  查询使用天御策略，未配置天御账号的方案
      tencentBsp: `${plansUrl}/v1/check/tencent-bsp`,
      //  查询使用userWeight
      tencentUserWeight: `${plansUrl}/v1/check/tencent-userWeight`,
      //  查询使用intelligent
      tencentIntelligent: `${plansUrl}/v1/check/tencent-intelligent`,
      //  检查方案中使用了红包，微信支付配置是否正常
      //  检查方案中使用了红包，微信支付配置是否正常
      payment: `${plansUrl}/v1/check/payment`,
      //  检查方案中使用了红包2
      switch: `${plansUrl}/v1/check/switch`,
      //  检查活动中红包奖品所用微信号与活动关联的微信号是否一致
      checkAppId: `${plansUrl}/v1/check/appId`,

      positionScene: `${plansUrl}/v1/positionScene`,

      //  创建签到活动
      checkIn: `${plansUrl}/v1/checkIn-batch`,
    },
    access: {
      access: `${accessUrl}/v1`,
    },

    //  奖池历史
    poolHistorys: {
      //  根据奖池id查询
      pool: `${poolHistorysUrl}/v1/pool`,
      //  根据奖池历史id查询
      poolHistorys: `${poolHistorysUrl}/v1`,
    },
    tencentBsp: {
      tencentBsp: `${tencentBspUrl}/v1`
    },
    customTemplate: {
      customTemplate: customTemplateUrl,
      user: `${promotionServer}/user/v1`,
    }
  },
  liveMange: {
    lives: {
      livesPub: `${livesUrl}/published`,
      lives: `${livesUrl}`,
      pageStyle: `${livesUrl}/pageStyle`,
      templates: `${templatesUrl}/selections`,
      templatesFind: `${templatesUrl}/find`,
      templatesApp: `${templatesUrl}/app`,
      histories: historiesUrl,
      upload:`${templatesUrl}/uploadFile`,
      export:`${templatesUrl}/exportExcel`,

    }
  },
  linkCirculate: {
    production: `${reportServer}/link/v1/production`,
    franchiser: `${reportServer}/link/v1/franchiser`,
    store: `${reportServer}/link/v1/store`,
    productionStatistics: `${reportServer}/link/v1/production-statistics`,
    franchiserStatistics: `${reportServer}/link/v1/franchiser-statistics`,
    storeStatistics: `${reportServer}/link/v1/store-statistics`,
    scanStatistics: `${reportServer}/link/v1/scan-statistics`,
    lotteryStatistics: `${reportServer}/link/v1/lottery-statistics`,
    cashStatistics: `${reportServer}/link/v1/cash-statistics`,
    prizeStatistics: `${reportServer}/link/v1/prize-statistics`,
    tendency: `${reportServer}/link/v1/tendency`,
    echartsUrl: `${reportServer}/link/v1`,
    consumer: `${reportServer}/link/v1`,
    enum: `${reportServer}/resources/v1/enum`,
    activity: `${reportServer}/resources/v1/activity`,
    childDept: `${reportServer}/resources/v1/child-dept`,
    citymanagerPost: `${reportServer}/resources/v1/cityManagerPost`,
    officePost: `${reportServer}/resources/v1/officePost`,
    plan: `${reportServer}/resources/v1/plan`,
  },
  order: {
    commonOrder: `${reportServer}/order/v1`,
    reportOrder: `${reportServer}/resources/v1`,
    soldIds: `${reportServer}/resources/v1/dealer/selections`,
    deliveryIds: `${reportServer}/resources/v1/receives/selections`,
    brands: `${baseUrl}/report/portal/resources/v1/brand`
  },
  scan: {
    banquet: `${reportServer}/scan/detail/v1/banquet`,
    consumer: `${reportServer}/scan/detail/v1/consumer`,
    statistics: `${reportServer}/scan/statistics/v1/statistics`,
    officePost: `${reportServer}/resources/v1/officePost`,
    officePostNew: `${reportServer}/resources/v1/office`,
    cityManagerPost: `${reportServer}/resources/v1/cityManagerPost`,
    tagType: `${reportServer}/resources/v1/tagType`,
    fixedDownload: `${reportServer}/resources/v1/fixedDownload`,
    downloadStatistics: `${reportServer}/scan/statistics/v1/download-statistics`,
    downloadBanquet: `${reportServer}/scan/detail/v1/download-banquet`,// 宴会扫码
    downloadConsumer: `${reportServer}/scan/detail/v1/download-consumer`,// 消费者扫码下载
    sumData: `${reportServer}/scan/statistics/v1/total`,
    activities: `${reportServer}/resources/v1/activities`,
    statisticSumDown: `${reportServer}/scan/statistics/v1/download-statistics`,
  },

  data: {
    products: {
      selections: `${productsUrl}/v1/selections`,
      products: `${productsUrl}/v1`,
    },

    users: {
      openIds: `${dataServer}/enterprise/login/v1`,
      checkAccount: `${dataServer}/enterprise/login/v1/jnc`,
      systemLogin: `${dataServer}/system/login/v1`,
      systemSignOut: `${dataServer}/system/logout/v1`,
      changePassword: `${dataServer}/system/changePassword/v1`,
      resetPassword: `${dataServer}/system/resetPassword/v1`,
      invitationCodes: (invitationCode) => `${usersUrl}/v1/invitation-codes/${invitationCode}`,
      registration: `${usersUrl}/v1/registration`,
      selections: `${usersUrl}/v1/selections`,
      users: `${usersUrl}/v1`,
      buildCode: `${usersUrl}/v1/invitation-codes`,
      sendOutCode: `${usersUrl}/v1/invitation-notice`,
      getChance: (id) => `${usersUrl}/v1/${id}/today-invitations`,
      self: `${usersUrl}/v1/self`,

      authorization: (id) => `${usersUrl}/v1/${id}/authorization`,
      refresh: (id) => `${usersUrl}/v1/${id}/invitation-codes`,

      blackUsers: `${promotionServer}/protect/v1`,

      files: `${dataServer}/async-tasks/v1`,
    },
    dealers: {
      dealers: `${dealerUrl}`,
    },
    permissions: {
      tree: `${permissionsUrl}/v1/tree`,
      selections: `${permissionsUrl}/v1/selections`
    },
    // 行政区划
    zones: {
      selections: `${zonesUrl}/v1/selections`
    },
    // 码库
    codedb: {
      codedb: `${codedbUrl}`,
    },
    // 码库
    codeUpload: {
      codeUpload: `${codeUploadUrl}`,
    },
    //  报废File
    scrap: {
      //  上传
      codeScrapUpload: `${codeScrapUrl}/upload/file`,
      //  分页
      codeScrapList: `${codeScrapUrl}/upload/logs`,
      //  下载
      codeScrapDownload: `${codeScrapUrl}/download/file`,
      //  上传
      codeSupplierScrapUpload: `${codeScrapUrl}/upload/file/supplier`,
      //  分页
      codeSupplierScrapList: `${codeScrapUrl}/upload/logs/supplier`,
      //  下载
      codeSupplierScrapDownload: `${codeScrapUrl}/download/file/supplier`,
    },
    //  报废统计
    statistics: {
      codeStatisticsList: `${codeStatisticsUrl}/pages`,
      codeStatisticsExcel: `${codeStatisticsUrl}/export/scrap/excel`
    },
    //会员中心标签
    memberTag: {
      selections: `${memberTagUrl}/v1`,
      selectionsNew: `${memberTagUrl}/userTagGroup/v1`,
      basicTag: `${memberTagUrl}/v1/basic`,
      userDefinedTagv2: `${memberTagUrl}/v1/user`,

      userDefineEnum: `${memberTagUrl}/userTagAttribute/v1`,
      userDefineGroup: `${memberTagUrl}/userTagGroup/v1`,
      userDefinedTag: `${memberTagUrl}/userTag/v1`,

      memberTagAttr: `${memberTagAttrUrl}/v1`,
      manualTag: `${memberTagUrl}/v1/manual`,
      operation: `${memberTagUrl}/v1/operation`,
      thirdTag: `${memberTagUrl}/v1/third`,
    },
    addressBooks: {
      addressBooks: `${addressBooksUrl}/v1`,
      addressBooksSelections: `${addressBooksUrl}/v1/selections`,
    },
    upload: {
      upload: (bucketType) => `${UploadUrl}/v1?bucketType=${bucketType}`,
      uploadMasterQuery: (bucketType) => `${UploadMasterUrl}?cate=${bucketType}`,
      uploadLibrary: `${dataServer}/material/v1`,
      uploadStore: `${writeOffShop}/write-off-terminal/v1/upload`,
      promotionUpload: `${baseApiUrl}/lz/excel`,
      promotionUploadList: `${baseApiUrl}/lz/batch/all`,
    },

    factory: {
      selections: `${factoryUrl}/v1/selections`,
      //  selections: (factoryType) => `${factoryUrl}/v1/selections?factoryType=${factoryType}`,
      factories: `${factoryUrl}/v1`,

    },
    dealerGroup: {
      list: `${baseUrl}/data/portal/dealer-group/v1`,
      uploadFile: `${baseUrl}/data/portal/dealer-group/v1/uploadFile`,
      query: `${baseUrl}/data/portal/dealers/v1/allots`,
      download: `${baseUrl}/data/portal/dealer-group/v1/downloadImportTemp`,
      download1: `${baseUrl}/data/portal/dealer-group/v1/download-export`,
      productUpload: `${baseUrl}/data/portal/dealer-group/v1/uploadDealerProductFile`,
      editDealerProduct: `${baseUrl}/data/portal/dealer-group/v1/editDealerProduct`,
      selectProduct: `${baseUrl}/data/portal/dealer-group/v1/selectProduct`,
      
      //  status:`${baseUrl}/data/portal/dealer-group/v1`,
    },
    enterprises: {
      enterprises: `${enterprisesUrl}/v1`,
      selections: `${enterprisesUrl}/v1/selections`
    },
    brand: {
      brands: `${brandUrl}/v1`
    },
    mpUsers: {
      mpUsers: mpUsersUrl
    },
    fileds: {
      fileds: filedsUrl
    },
    roles: {
      //  查询/创建/修改/禁用、启用 角色
      role: `${rolesUrl}/v1`,
      selections: `${rolesUrl}/v1/selections`,
    },
    categories: {
      categories: `${categoriesUrl}/v1`
    },
    departments: {
      departments: `${departments}/v1`,
      departmentsTree: `${departments}/v1/tree`,
      selections: `${departments}/v1/selections`,
      enableDepartment: (id, status) => `${departments}/v1/${id}/status?status=${status}`
    },
    isvUser: {
      isvUser: isvUserUrl
    },
    isvLink: {
      isvLink: isvLinksUrl
    },
    dictionary: {
      //  dict: `http:// 192.168.51.221:8082/data-zxr/portal/dict/v1`,
      dict: `${dataServer}/dicts/v1`,
      query: `${dataServer}/dicts/v1/query`,
    },
    bill: {
      billType: `${dataServer}/danju-types/v1`,
      billData: `${dataServer}/danju-records/v1`,
      billSelection: `${dataServer}/danju-types/v1/selections`,
      billRecord: `${dataServer}/danju-records/v1/code`,
    },
    contract: {
      contractType: `${dataServer}/contract-types/v1`,
      selection: `${dataServer}/contract-types/v1/selections`,
      contractData: `${dataServer}/contract-data/v1`,
    },
    activity: {
      activityType: `${dataServer}/activity-types/v1`,
      activityData: `${dataServer}/activity-data/v1`,
      status: `${dataServer}/activity-types/v1/status`,
    },
    terminal: {
      terminal: `${dataServer}/shops`,
      enableTerminal: (id, status) => `${dataServer}/shops/${id}/status?status=${status}`,
      createTerminal: `${dataServer}/shops`,
    },
    fieldAssign: {
      dict: `${baseUrl}/data/portal/dicts/ese/v1`,
      typelist: `${baseUrl}/data/portal/dicts/v1/query`,
      eseTypeList: `${baseUrl}/data/portal/dicts/ese/v1/query/map/ese`,
      eseQuery: `${baseUrl}/data/portal/dicts/ese/v1/query`,
      dictMap: `${baseUrl}/data/portal/dicts/ese/v1/query/map`
    },
    warehouse: {
      dict: `${baseUrl}/data/portal/warehouses`,
      add: `${baseUrl}/data/portal/warehouses`,
      warehousesQuery: `${baseUrl}/data/portal/warehouses/selections`,
      warehousesQueryNew: `${baseUrl}/data/portal/warehouses/list`,
      seletion: `${baseUrl}/data/portal/enums/v1/warehouse-types`
    },
    dealerChange: {
      dict: `${baseUrl}/dm/portal/dealer-change-record/v1`,
      export: `${baseUrl}/dm/portal/dealer-change-record/v1/exports`,
    },
    channel: {
      dict: `${baseUrl}/data/portal/sales-channels/v1`,
    },
    precinct: {
      dict: `${baseUrl}/data/portal/precincts/v1`,
      changeStatus: `${baseUrl}/data/portal/precincts/v1/status`
      //  add:`${baseUrl}/data/portal/precincts/v1/new`
    },
    //  宴会 ActivityInfomation
    banquet: {
      select: `${baseUrl}/data/portal/banquets/v1/selections`,
      //  select: `http:// 192168.6.16:8081/portal/`
    },

  },
  memberShip: {
    //  会员体系
    memberSystem: {
      memberSystem: `${memberServer}/systems/v1`,
      systemHistory: `${memberServer}/system-historys/v1`,
      memberLevel: `${memberServer}/levels/v1`,
      memberRights: `${memberServer}/rights/v1`,
      memberFields: `${memberServer}/fields/v1`
    },
    // 会员管理
    memberManage: {
      info: `${memberServer}/manage/v1`,
      ship: `${memberServer}/tags/v1/membership`,
      portrait: `${memberServer}/manage/v1/portrait`,
      trajectory: `${memberServer}/manage/v1/trajectory`,
      selection: `${memberServer}/tags/v1/selection`,
      tag: `${memberServer}/tag/configs/v1`,
      badgeList: `${memberServer}/badge/v1`,
      badgeGroup: `${memberServer}/badge-group/v1`,
    },
    // 会员导出
    memberExport: {
      exportAvailable: `${memberServer}/export/v1/available-count`,
    },
    memberTask: {
      memberTask: `${memberServer}/task/v1`,
      memberTaskHistory: `${memberServer}/task/history/v1`,
    }
  },
  code: {
    tagbatchConfigs: {
      tagbatchConfigs: `${tagbatchConfigsUrl}/v1`,
      submitCancelverifyCode: `${tagbatchConfigsUrl}/v2`,   // 创建编辑核销码规格
      tagbatchConfigsChoice: `${tagbatchConfigsUrl}/choice/v1`,
      tagbatchConfigsVerifyCodeChoice: `${tagbatchConfigsUrl}/choice/v2`,   // 核销码下拉框
      downLoadCode: `${tagbatchesUrl}/v1`,
      tagbatchConfigsDetail: (id) => `${tagbatchConfigsUrl}/v1/${id}`,
      tagbatchConfigsDetailAll: (id) => `${tagbatchConfigsUrl}/v1/${id}/all`,
      codeParamsDetail: (id) => `${tagbatchConfigsUrl}/v1/${id}/code-detail`,
      selector: `${tagbatchConfigsUrl}/v1/all`,
    },
    domainConfigs: {
      domainConfigsByEseId: domainConfigsByEseIdUrl,
      domainConfigsByCodeSource: `${domainConfigsUrl}/codesource-type`,
      domainConfigsByCodeBank: `${domainConfigsUrl}/code-bank-name`,
      domainConfigsUrl,
    },
    customFields: {
      customFields: customFieldsUrl
    },
    certificate: {
      certificate: certificateUrl,
      certificateUrlAll: `${certificateUrl}/all`,
      certificateAll,

    },
    loadIpc: {
      loadIpcList
    },
    codingTems: {
      codingTemUrl,
      codingTemsLoad: `${codingTemUrl}/loading`,
      codingTemsdelete: `${codingTemUrl}`,
      codingTemsId: `${codingTemUrl}`,
      elementAll: `${codingTemUrl}/getAllElements`,
      elementAdd: `${codingTemUrl}/add`,
      elementEdit: `${codingTemUrl}/edit`,
      elements: `${codingTemUrl}/elements`,
      elementStatus: `${codingTemUrl}/forbidden`,

    },

    customer: {
      customer: `${codeToolServer}/customer/v1/aixi/tags/info`,
    },
    batchApplys: {
      batchApplys: `${batchApplysUrl}/v1`,
      applyUnauditeds: `${batchApplysUrl}/v1/apply-unauditeds`, //  码申请待审核列表
      applyPasses: `${batchApplysUrl}/v1/apply-passes`, //  码申请已通过列表
      applyReturns: `${batchApplysUrl}/v1/apply-returns`, //  码申请未通过列表
      sendBatchs: (id) => `${batchApplysUrl}/v1/${id}/send-batchs`, // 再次发送码包
      auditUnauditeds: `${batchApplysUrl}/v1/audit-unauditeds`, //  待审核列表(审核)
      auditPasses: `${batchApplysUrl}/v1/audit-passes`, //  已审核列表(审核)
      audit: `${batchApplysUrl}/v1/audit`, //  审批
      viewAudit: (id) => `${batchApplysUrl}/v1/${id}`,
      viewAuditLog: (id) => `${batchApplysUrl}/detail/v1/${id}`,
      submitVoucherCode: `${batchApplysUrl}/v2`, // 创建核销码
    },
    h5CodePackage: {
      h5CodePackageTable: `${h5CodePackageUrl}/v1`,// 代码包查询（分页查询）
      h5CodePackageaddPackage: `${h5CodePackageUrl}/v1`,// 新增/修改代码包
      h5CodePackageDel: `${h5CodePackageUrl}/v1`,// 删除代码包
      h5PackageAll: `${h5CodePackageUrl}/v1/selections`,// 代码包查询所有（不分页）
      h5linkTable: `${h5CodePackageUrl}/v1/getAllLinks`,// 链接查询（分页查询）
      h5Linkadd: `${h5CodePackageUrl}/v1/add`,// 链接新增
      h5ruleTable: `${h5CodePackageUrlBase}/rule/v1`,// 规则Table
      h5ruleAdd: `${h5CodePackageUrlBase}/rule/v1`,// 规则新增，编辑
      h5ruleDel: `${h5CodePackageUrlBase}/rule/v1`,// 规则删除
      domainAll: `${domainConfigsUrl}/allots`,// 查询域名（不分页）
      h5PathAdd: `${h5CodePackageUrlBase}/rule/v1/add`,//  add path
      h5PathEdit: `${h5CodePackageUrlBase}/rule/v1/edit`,// 规则 edit
      h5PathDel: `${h5CodePackageUrlBase}/rule/v1/details`,// 规则删除
    },
    codeTempConfig: {
      templateConfigs: `${codeServer}/template-configs/v1`,
      fields: `${codeServer}/template-configs/v1/fields`,
    },
    batchInformations: {
      applyUnauditeds: `${batchInformations}/v1/registration/batch`, //  码申请待审核列表
      info: (data) => `${batchInformations}/v1/registration/batch/${data.id}/info?page=${data.page}&size=${data.size}&sort=${data.sort}`, //  码申请已通过列表
      history: (data) => `${batchInformations}/v1/registration/batch/${data.id}/history?page=${data.page}&size=${data.size}&sort=${data.sort}`, //  码申请已通过列表
      active: `${batchInformations}/v1/activation/batch`,
      revocation: `${batchInformations}/v1/revocation/activation/batch`,
      check: (data) => `${batchInformations}/v1/activation/check?ids=${data}`,
      applet_settings: `${batchInformations}/v1/activation/applet-settings`,
      query_settings: `${batchInformations}/v1/activation/query-settings`,

      seqnumEnter: `${batchInformations}/v1/registration/seqnum`, //  码申请待审核列表
      seqnumActive: `${batchInformations}/v1/activation/seqnum`,
      seqnumRevocation: `${batchInformations}/v1/revocation/activation/seqnum`,
      seqnumCheck: (data) => `${batchInformations}/v1/registration/seqnum/check?eseSeqnumStart=${data.eseSeqnumStart}&eseSeqnumEnd=${data.eseSeqnumEnd}`,
      delete: `${batchInformations}/v1/registration/delete`,
      //  sendBatchs: (id) => `${batchInformations}/v1/${id}/send-batchs`, // 再次发送码包
      //  auditUnauditeds: `${batchInformations}/v1/audit-unauditeds`, //  待审核列表(审核)
      //  auditPasses: `${batchInformations}/v1/audit-passes`, //  已审核列表(审核)
      //  audit: `${batchInformations}/v1/audit`, //  已审核列表(审核)
    },
    batchScraps: {
      batchScraps: batchScrapsUrl,
      viewScraps: `${batchScrapsUrl}/info`
    },
    enterpriseinfo: {
      enterpriseinfo,

    },
    tagMaterials: {
      tagMaterials: tagMaterialsUrl
    },
    templateConfigs: {
      selectTemplate: `${templateConfigsUrl}/select-template/v1`,
      selectCancelverifyCodeTemplate: `${templateConfigsUrl}/select-template/v2`   // 企业配置选择核销码配置模板列表
    },
    extractionCode: (url) => `${baseUrl + url}`, //  码包提取
    tool: {
      tagbatch: (key) => `${codeToolServer}/tagbatch/v1/${key}`,
      downloadLog: (key) => `${codeToolServer}/tagbatch/v1/download-log/${key}`,
      packageDownloadLog: (key) => `${codeToolServer}/tagbatch/v1/package/download-log/${key}`,
      downloadPackage: (key) => `${codeToolServer}/tagbatch/v1/download-package`,
    },
    codeSource: {
      selectList: `${codeSourceUrl}/select-list`,
      codeSource: codeSourceUrl,
      distribution: `${codeSourceUrl}/distribution`,
      getDistribution: (id) => `${codeSourceUrl}/${id}/distribution-list`,
    },
    thirdPartCodeHandle: {
      thirdPartCodeHandle: thirdPartCodeHandleUrl
    },
    // 辅料
    accessories: {
      accessories: `${dataManageServer}/accessories/v1/`,
    },
    //  码状态
    stateTable: {
      //  码状态列表 //  根据企业 分页模糊查询字段code/name
      codeTable: (data) => `${stateTableCodeUrl}?name=${data.name}&code=${data.code}&page=${data.page}&size=${data.size}`,
      stateIncreaseCode: `${stateTableCodeUrl}`,
    },
    // 码标签
    codeLabel: {
      userDefinedTag: `${codeServer}/label/v1`,
      menuBatch: `${codeServer}/label/v1/menu/batch`,
      menuTree: `${codeServer}/label/v1/menu/tree`,
      menuEdit: `${codeServer}/label/v1/menu`,
      labelData: `${codeServer}/label/field/v1`,
      labelVerify_DraftList: `${codeServer}/label/field/v1/page`,
      deleteLabelField: `${codeServer}/label/field/v1`,
      submitSingleLabel: `${codeServer}/label/field/v1/commit`,
      submitBatchLabels: `${codeServer}/label/field/v1/commit/batch`,
      releaseSingleLabel: `${codeServer}/label/field/v1/publish`,
      releaseBatchLabels: `${codeServer}/label/field/v1/publish/batch`,
      labelHistory: `${codeServer}/label/v1/history`,
      customLabel: `${codeServer}/label/v1/custom-label`,
      labelSelection: `${codeServer}/label/v1/selection`,
      getLabelSearchList: `${codeServer}/label/field/v1/query`,
      getGroupId: `${codeServer}/label/field/v1/group`,
      pushPlatformList: `${codeServer}/push-platform/config/v1`,
      updateBindPushLabels: `${codeServer}/label/push/v2/bind`,
      getPushLabelBindList: `${codeServer}/label/push/v2/bind`,
      pushLabels: `${codeServer}/label/push/v2`,
      getPushLabelPageList: `${codeServer}/label/push/v2/page`,
      getLinkPageLabelDropdownList: `${codeServer}/label/field/v1/link`,
    },

    // 核销码/券码
    voucherCode: {
      voucherCode: voucherUrl
    }
  },
  wechat: {
    authorizerConfigs: {
      //  获取（小程序-公众号）列表
      authorizerConfigs: (wechatAccountType, eseId) => `${authorizerConfigsUrl}/v1/${wechatAccountType && eseId ? `?wechatAccountType=${wechatAccountType}&eseId=${eseId}` : wechatAccountType ? `?wechatAccountType=${wechatAccountType}` : eseId ? `?eseId=${eseId}` : ''}`
    },
    authorization: {
      //  授权（小程序-公众号）
      authorization: (eseId, deptId, userId, redirectUri) => `${baseUrl}/ws/?eseId=${eseId}&deptId=${deptId}&userId=${userId}&redirectUri=${redirectUri}`,
      wechatAuthorize: redirectUri => `${wechatAuthUrl}?_utoken=${window.__INITIAL_STATE__.backend['X-TC-TOKEN'] || ''}&_csrf=${(window as any).__INITIAL_STATE__.backend['X-CSRF-TOKEN'] || ''}&redirectUri=${redirectUri}`,
    },
    pay: {
      //  祝福语
      blessing: `${redPacketConfigsUrl}/v1/`,
    },
    card: {
      cardInfo: (appId, cardId) => `${wechatServer}/v1/app/${appId}/card/${cardId}`
    },
    redpacketQuery: (appId, merchantBillNo, openId, merchantId) => `${wechatServer}/redPacket/v1/query?appId=${appId}&merchantBillNo=${merchantBillNo}&openId=${openId}&merchantId=${merchantId}`,
    redpacketQueryPayer: (appId, merchantBillNo, openId, merchantId) => `${wechatServer}/redPacket/v1/queryPayer?appId=${appId}&partnerTradeNo=${merchantBillNo}&openId=${openId}&merchantId=${merchantId}`,
    //  miniProgramDevelopers: `${miniProgramDevelopersUrl}`,
    //  qrcodeJump: qrcodeJumpUrl,
    miniProgramConfig: {
      //  qrcode: (appId, path) => `${qrcode}?appId=${appId}&path=${path}`,
      qrcode,
      qrcodeJump: appId => `${qrcodeJumpUrl}?appId=${appId}`,
      qrcodeJumpRelease: qrcodeJumpReleaseUrl,
      categoryList: `${miniProgramConfig}/category-list`,
      page: `${miniProgramConfig}/page`,
      qrcodeJumpDelete: `${qrcodeJumpUrl}/delete`
    },
    miniProgramDevelopers: {
      developers: miniProgramDevelopersUrl,
      developersApp: appId => `${miniProgramDevelopersAppUrl}?appId=${appId}`,
      developersRelation: miniProgramDevelopersRelationUrl,

    },
    miniProgramTesters: {
      testers: miniProgramTesters
    },
    miniProgramEseRelation: {
      miniProgramEseRelation: miniProgramEseRelationUrl
    },

    experiencers: {
      // 体验者
      experiencers: `${experiencersUrl}`,
    },
    minTemplate: {
      minTemplate: `${minTemplateUrl}`,
      minTemplateEse: `${minTemplateEseUrl}`,
    },
    merchant: {
      merchant: merchantUrl,
      v3Merchant: merchantV2Url,
    },
    brandRedPack: {
      brandRedPack: brandRedPackUrl,
    },
    secretKey: {
      secretKey: `${wechatServer}/auth/v1`,
    },
    phoneSetting: {
      phoneSetting: `${authorizerConfigsUrl}/v1`,
    },
    menu: {
      menu: `${wechatServer}/menu/v1`,
      menuFrom: `${wechatServer}/v1`,
    }
  },

  coupon: {
    //  优惠券增删改查
    couponConfigs: `${couponConfigsUrl}/v1`,
    couponConfigDetails: `${couponConfigDetailsUrl}/v1`,
    couponCodePacketExport: (couponId, num, remark) => `${couponCodePacketUrl}/v1/export?couponId=${couponId}&num=${num}&exportRemark=${remark}`,

  },

  //  预警服务
  warn: {
    events: {
      events: `${warnEventsUrl}`,
      eventsStatus: `${warnEventsUrl}/status`,
    },
    recipients: {
      recipients: `${warnRecipientsUrl}`,
      recipientsStatus: `${warnRecipientsUrl}/status`,
      recipientsClass: `${warnRecipientsUrl}/count-warningClass`,
      recipientsEvent: `${reportServer}/warning-event/v1/get-event`,
      recipientsParameter: `${reportServer}/warning-event/v1/parameter-config`,
      recipientsMdm: `${reportServer}/warning-event/v1/warning-mdm-config`,
      recipientsField: `${reportServer}/warning-event/v1/field`,
      recipientsIndexGet: `${reportServer}/warning-event/v1/get-warningIndex`,
      recipientsIndexCreate: `${reportServer}/warning-event/v1/save-warningIndex`,
      recipientsIndexEdit: `${reportServer}/warning-event/v1`,

    },
    ai: {
      ai: `${warnManageServer}/robot/v1`,
    },
    records: {
      records: warnRecordUrl,
      statistics: `${warnRecordUrl}/statistics`,
      info: `${warnRecordUrl}/info`,
    },
    receivers: {
      query: `${baseUrl}/data/portal/warn/v1/queryWarning`,
      save: `${baseUrl}/warn/portal/recipients/v1/saveRecipient`,
      deleteOne: `${baseUrl}/warn/portal/recipients/v1/deleteOneRecipient`,
      deleteAll: `${baseUrl}/warn/portal/recipients/v1/deleteRecipient`,
      pitchOn: `${baseUrl}/warn/portal/recipients/v1/updateSendMode`
    }
  },
  codeBatchQuery: {
    log: `${awManageServer}/tag-batch/download/v1/tag/log`,
    query: `${awManageServer}/tag-batch/download/v1/import/tag/query`,
    download: `${awManageServer}/tag-batch/download/v1/tag/file`,
    orderQuery: `${awManageServer}/tag-batch/download/v1/tag/query`,
  },
  labelBatchQuery: {
    createLabelBatchQuery: `${awManageServer}/tag-batch/download/label/v1`,
    getLabelBatchQueryList: `${awManageServer}/tag-batch/download/label/v1/log`,
    handleTagBatchDownload: `${awManageServer}/tag-batch/download/label/v1/log/file`,
  },
  queryTools: {
    tags: {
      info: `${tagInfoUrl}tags/info`,
      promotion: `${queryToolsUrl('tags')}/promotion`,
      queryRecords: `${queryToolsUrl('tags')}/queryRecords`,
      lotteryRecords: `${queryToolsUrl('tags')}/lotteryRecords`,
      incentiveRecords: `${queryToolsUrl('tags')}/incentiveRecords`,
      export: `${queryToolsUrl('tags')}`,
    },
    users: {
      matedata: `${queryToolsUrl('users')}/metadata`,
      lotteryRecords: `${queryToolsUrl('users')}/lotteryRecords`,
      scanRecords: `${queryToolsUrl('users')}/queryRecords`,
      pointRecords: `${queryToolsUrl('users')}/pointDetails`,
      pointDetails: `${queryToolsUrl('users')}/pointRecords`,
      cardRecords: `${queryToolsUrl('users')}/cardDetails`,
      cardDetails: `${queryToolsUrl('users')}/cardRecords`,
      export: `${queryToolsUrl('users')}`,
    },
    miniProgramRelease: {
      develop: `${miniProgramRelease}/develop`,
      experience: `${miniProgramRelease}/experience`,
      release: `${miniProgramRelease}/release`,
      experienceCount: (appId) => `${miniProgramRelease}/experience/count?appId=${appId}`,
      undocodeAudit: `${miniProgramRelease}/undocode-audit`,
      releaseLog: `${miniProgramRelease}/release-log`,
    },
    miniProgramMaterials: {
      miniProgramMaterials,
    },
    parameter: {
      parameter: `${wechatServer}/v1/sceneqrcode`,
      wechatMedia: `${wechatServer}/v1/wechatMedia`
    },
    leaveInfo: {
      leaveInfo: `${promotionServer}/leave-info/v1`,
      leaveType: `${promotionServer}/form-template/v1`,
      leaveField:`${promotionServer}/field/v1`,
    },
    promotionCode: {
      promotionCode: `${promotionServer}/promotion_tag/v1`
    },
    productManual: {
      productManual: `${promotionServer}/product/v1`
    },
  },

  statistics: {
    dataOverview: {
      statistics: `${dataOverviewUrl}/statistics`, // 面板部分
      activeCity: `${dataOverviewUrl}/active-city`, // 活跃城市
      activityCr: `${dataOverviewUrl}/activity-cr`, // 活动转化率
      amountTrend: `${dataOverviewUrl}/amount-trend`, // 微信红包金额趋势
      bonusTrend: `${dataOverviewUrl}/bonus-trend`, // 兑换次数/金额趋势
      participant: `${dataOverviewUrl}/participant`, // 参与用户
      payingTrend: `${dataOverviewUrl}/paying-trend`, // 微信红包发放趋势
      scanWinTrend: `${dataOverviewUrl}/scan-winTrend`, // 扫码/中奖趋势
      winRatio: `${dataOverviewUrl}/win-ratio`, // 奖品中奖占比
    },
    resources: {
      activity: `${reportResourcesUrl}/activity`,// 活动
      deptactivity: `${reportResourcesUrl}/dept-activity`,// 看板活动
      prize: `${reportResourcesUrl}/prize`,// 奖品
      pool: `${reportResourcesUrl}/pool`,// 奖池
      product: `${reportResourcesUrl}/product`,// 产品
      area: `${reportResourcesUrl}/area`,// 获取省、市、区
      redPacketsType: `${reportResourcesUrl}/redPackets`,// 红包类型
      redPacketsSource: `${reportResourcesUrl}/redPackets`,// 红包来源
      wechatSubscription: `${reportResourcesUrl}/wechatSubscription`,// 获取公众号列表
      commercialption: `${moneyServer}/v1/wechat-merchant/selections`,// 商户号
      jackpotption: `${moneyServer}/v1/pool/selections`,// 奖池下拉
    },
    realTime: {
      statistics: `${realTimeUrl}/overview/statistics`, // 面板部分
      activityDistribution: `${realTimeUrl}/overview/activity-distribution`,// 活动参与人数热区分布图
      prizeTopTen: `${realTimeUrl}/overview/prize-top-ten`,// Top10奖品
      productTopTen: `${realTimeUrl}/overview/product-top-ten`,// Top10产品
      realTimeTrend: `${realTimeUrl}/monitor/realtime-trend`,// 实时趋势监控
      userGrowthTrend: `${realTimeUrl}/monitor/userGrowth-trend`,// 用户增长趋势

      scanNum: `${realTimeUrl}/trendCompare/scan-num`,// 扫码个数
      scanCount: `${realTimeUrl}/trendCompare/scan-count`,// 扫码次数
      prizeCount: `${realTimeUrl}/trendCompare/prize-count`,// 兑奖次数
      winCount: `${realTimeUrl}/trendCompare/win-count`,// 中奖次数
      poollistNew: `${reportServer}/resources/v1/poolList`,// 奖池列表
      prizeListNew: `${reportServer}/resources/v1/prizeList`,// 奖池列表
      middlePrize: `${realTimeUrl}/trendCompare/middlePrize-amount`,// 中奖金额
      redeemPrize: `${realTimeUrl}/trendCompare/redeemPrize-amount`,// 兑奖金额
    },
    marketing: {
      list: `${marketingUrl}`,// 统计列表
      download: `${marketingUrl}/export`,// 统计列表
    },
    amountBill: {
      list: `${amountUrl}`,// 金额账单
      download: `${amountUrl}/export`,// 下载金额账单
    },

    customizingReports: {
      url: reportLinkUrl,// 定制报表外部地址跳转
    },
    dataScreen: {
      warningStatistics: `${warnUrl}/statisticsHis`,
      warningEvents: `${warnUrl}`,
      linkData: `${dataScreenUrl}/link-data`,
      outControlRates: `${dataScreenUrl}/link-statistics`,
      //  inspControlRates: `${dataScreenUrl}/insp-control-rates`,


      scanNum: `${dataScreenUrl}/scan-num`,
      userCount: `${dataScreenUrl}/user-count`,
      marketingReport: `${dataScreenUrl}/marketing-report`,
      linkTendency: `${dataScreenUrl}/link-tendency`,
      areaHeatMaps: `${dataScreenUrl}/area-heat-maps`,
    }
  },

  dataManage: {
    linkDataCheck: {
      summaryList: `${dataManageServer}/link-handling-check/v1/count/page`,
      summaryUpdate: `${dataManageServer}/link-handling-check/v1/count/update`,
      detailList: `${dataManageServer}/link-handling-check/v1/detail/page`,
      detailUpdate: `${dataManageServer}/link-handling-check/v1/detail/update`,
    },
    masterDataCheck: {
      dict: `${dataServer}/master-query/v1/page`,
      remark: `${dataServer}/master-query/v1`,
    },
    links: {
      links: `${linksUrl}`,
      linksenterprise: `${linksenterpriseUrl}`,
      copyenterprise: `${copyenterpriseUrl}`,
      selections: `${linksUrl}/selections`, // 面板部分
      diEnterpirsesLinks: `${linksUrl}/di/enterprises`,

      linkAll: `${codeServer}/label/field/v1/link/all`,
      linkplan: `${dataManageServer}/plans/v1`,
      linkRule: `${awManageServer}/check-rule/convert/v1`,
      linkDraft: `${dataManageServer}/link-publish/v1/unCommit`,
      linkVerify: `${dataManageServer}/link-publish/v1/unPublished`,
      linkHistory: `${dataManageServer}/link-publish/v1/record`,
      linkHistoryDetail: `${dataManageServer}/link-publish/v1/record/detail`,
      linkPublish: `${dataManageServer}/link-publish/v1/publish`,
      linkDraftAndVerifyForCommitAndSubmit: `${dataManageServer}/link-publish/v1`,
      deleteLink: `${dataManageServer}/link-publish/v1/delete`,
      linkPublished: `${dataManageServer}/link-publish/v1`,
      linkEditPublished: `${dataManageServer}/link-publish/v1/editPublished`,
    },
    tagStatus: {
      selections: `${tagStatussUrl}/selections`,
    },
    //  码数据管理
    linkTags: {
      linkTags: `${linkTagsUrl}`,
      linkTagsUpload: `${linkTagsUrl}/upload`,
      linkUploadLogs: `${linkUploadLogsUrl}`,
    },
    tagDatas: {
      tagDatas: `${tagDatasUrl}`,
      tagDatas2: `${tagDatasUrl2}`,
      tagDataLogs: `${tagDataLogsUrl}`,
      tagDataLogs2: `${tagDataLogsUrl2}`,
    },
    deptdAuth: {
      list: `${dataServer}/data-allots/v1/selections`,
      userProduct: `${dataServer}/data-allots/v1/user-product/selection`,
      userProductWrite: `${dataServer}/data-allots/v1/user-product/over-write`,
    }

  },
  outReport: {
    flow: `${outReportUrl}/flow`,
    flowExport: `${outReportUrl}/flow/export`,
    outStatistic: `${outReportUrl}/outStatistic`,
    outStatisticExport: `${outReportUrl}/outStatistic/export`,
  },
  pointMall: {
    pointMall: {
      pointMall: `${pointMallServer}/portal/point-malls`,
      check: merchantUrl
    },

    rule: {
      rule: `${pointMallServer}/portal/present-rules`,
    },
    present: {
      present: `${pointMallServer}/portal`,
      presentPools: `${pointMallServer}/portal/present-pools`,
      presentPoolsPage: `${pointMallServer}/portal/present-pools/page-present`,// 礼品库信息接口（礼品分页）
      presentDraft: `${pointMallServer}/portal/present-pools/present/draft`,// 礼品库 礼品数据保存至草稿
      presentPoolsGroups: `${pointMallServer}/portal/present-groups`,
      presentList: `${pointMallServer}/portal/present/v1/page`,
    },
    order: {
      order: `${pointMallServer}/portal/orders`,
    },
    scan: {
      banquet: `${reportServer}/scan/detail/v1/banquet`,
      consumer: `${reportServer}/scan/detail/v1/consumer`,
      statistics: `${reportServer}/scan/statistics/v1/statistics`,
      officePost: `${reportServer}/resources/v1/officePost`,
      cityManagerPost: `${reportServer}/resources/v1/cityManagerPost`,
      tagType: `${reportServer}/resources/v1/tagType`,
      fixedDownload: `${reportServer}/resources/v1/fixedDownload`,
      downloadStatistics: `${reportServer}/scan/statistics/v1/download-statistics`,
      downloadBanquet: `${reportServer}/scan/detail/v1/download-banquet`,
      downloadConsumer: `${reportServer}/scan/detail/v1/download-consumer`,
    },


  },

  rebate: {
    getDealerPage: `${rebateUrl}/dealerPage`, // 分页查询经销商返利统计报表
    getShopPage: `${rebateUrl}/shopPage`, // 分页查询终端店返利统计报表
    getMechanicPage: `${rebateUrl}/mechanicPage`, // 分页查询机修工返利统计报表
    getAllRebateActive: `${reportServer}/resources/v1/rebatePromotion`,// 返利活动
    getAllCase: `${reportServer}/resources/v1/rebatePlan`,// 返利方案
    getAllRebateAccount: `${reportServer}/resources/v1/rebateAccount`,// 业务员

    rebateList: `${rebateServer}findAll`,
    rebateStarus: `${rebateServer}status`,
    hasDraft: `${rebateServer}judge`,
    rebatePriority: `${rebateServer}change`,
    rebateBase: `${rebateServer}edit`,
    rebateBaseInquire: `${rebateServer}get`,
    rebatePublish: `${rebateServer}push/`,
    rebateDel: `${rebateServer}del/`,
    rebateCaseList: `${rebateCaseServer}caselist`,
    rebateCaseAdd: `${rebateCaseServer}add`,
    rebateCaseSort: `${rebateCaseServer}sort`,
    rebateCaseInquire: `${rebateCaseServer}get`,
    rebateCaseGetRule: `${rebateCaseServer}getrole`,
    rebateCaseSaveCase: `${rebateCaseServer}edit`,
    rebateAddpoint: `${rebateCaseServer}addpoint`,
    rebateGetPoint: `${rebateCaseServer}pointlist`,
    rebateDetailList: `${rebateDetailServer}findAll`,
    exportRebateDetail: `${rebateDetailServer}export`,
    rebateHistory: `${rebateServer}page`,
    rebateHistoryId: `${rebateServer}history/get/`,
  },
  masterData: {
    masterDataAllots: `${dataManageServer}/links/master-data/v1/allots`
  },
  flowDate: {
    flowDateList: `${reportServer}/flow/v1/flowDetail`,
    maStatus: `${dataManageServer}/tag-status/v1/selections`,
    maSpecs: `${dataServer}/precincts/v1/selections`
  },
  codeDetail: {
    orderType: `${reportServer}/resources/v1/orderType`,
    codeDetailList: `${reportServer}/code/v1/codeDetail`
  },
  security: {
    secActivity: `${reportServer}/resources/v1/secActivity`,
    securityDetail: `${reportServer}/security/v1/securityDetail`,
  },
  anticounterfeiting: {
    monitor: `${reportServer}/monitor`
  },
  targetManage: {// 指标说明
    target: `${reportServer}/resources/v1/function-description`
  },
  // 消息模板
  subscribeMsg: {
    template: `${messageCenterServer}/subscribe-msg-template`,
    templateListByAppId: `${messageCenterServer}/subscribe-msg-template/list/findByAppId`,
    templateInfoById: `${messageCenterServer}/subscribe-msg-template/list/findById`,
    findByMiniAppSubscribeId: `${messageCenterServer}/subscribe-msg-template/list/findByMiniAppSubscribeId`,
    selectTemplateById: `${messageCenterServer}/subscribe-msg-template/selectTemplateById`,
    getTemplateContent: `${messageCenterServer}/subscribe-msg-template/getTemplateContent`,
    templateListNoPageable: `${messageCenterServer}/subscribe-msg-template/list/findMsgListByAppId`,
    wechatTemplateList: `${messageCenterServer}/portal/subscribe-msg-template/findWechatTemplateById`,
    subscribeCountById: `${messageCenterServer}/subscribe-msg-template/selectSubscribeData`,
    sendMsg: `${messageCenterServer}/subscribe-msg-send-manage`,
    error: `${messageCenterServer}/subscribe-msg-send-manage/error`,
    stopSendMsg: `${messageCenterServer}/subscribe-msg-send-manage/stop`,
    arrivalRateById: `${messageCenterServer}/subscribe-msg-send-manage/arrival-rate`,
    cancelSendMsg: `${messageCenterServer}/subscribe-msg-send-manage/cancel`,
    cancelSend: `${messageCenterServer}/subscribe-msg-send-manage/cancel/cycle`,
    config: `${messageCenterServer}/subscribe-config`,
    detail: `${messageCenterServer}/subscribe-config/detail`,
    upLoadTemplate: `${messageCenterServer}/subscribe-msg-send-manage/export/example`,
    uploadFile: `${messageCenterServer}/subscribe-msg-send-manage/downloadFile`
  },

  writeOffShop: {
    writeOffStore: `${writeOffShop}/write-off-terminal/v1`,
    writeOffEmptyAll: `${writeOffShop}/write-off-terminal/v1/clear`,
    writeOffEdit: `${writeOffShop}/write-off-terminal/v1/edit`,
    writeOffDelete: `${writeOffShop}/write-off-terminal/v1`,
    writeOffPolicy: `${writeOffShop}/write-off-terminal/v1/batchPolicy`,
    writeOffStoreAll: `${writeOffShop}/write-off-terminal/v1/selections`,
    writeOffPolicyFind: `${baseUrl}/promotion/portal/verify-policy/find`,
    writeOffDetail: `${writeOffShop}/write-off-detail/v1`,
    writeOffDetailExport: `${writeOffShop}/write-off-detail/v1/export`,
  },

  screenLottery: {
    data: {
      prize: `${screenLotteryUrl}/prize/v1`,
      bg: `${screenLotteryUrl}/bg-config/v1`,
      award: `${screenLotteryUrl}/award/v1`,
      order: `${screenLotteryUrl}/order/v1`,
    },
    active: {
      active: `${screenLotteryUrl}/activity/v1`,
      participant: `${screenLotteryUrl}/activity-participant/v1`,
      inAward: `${screenLotteryUrl}/activity-award/v1`,
      lottery: `${screenLotteryUrl}/lottery/v1`,
    }
  },

  characteristic: {
    /***
     * 宴会类型
     */
    banquetTypeList: `${characteristic}/banquet/v1/type`, // 宴会类型
    createBanquetType: `${characteristic}/banquet/v1/type`, // 创建宴会类型
    editBanquetType: `${characteristic}/banquet/v1/type`, // 编辑宴会类型
    deleteBanquetType: `${characteristic}/banquet/v1/type`, // 删除宴会类型
    disableBanquetType: `${characteristic}/banquet/v1/type/status`, // 启用禁用宴会类型

    /**
     * 宴会政策
     */
    banquetPolicyOnlineList: `${characteristic}/banquet/v1/policy/publish`, // 正式列表
    banquetPolicyTestList: `${characteristic}/banquet/v1/policy`, // 草稿列表
    createBanquetPolicyTest: `${characteristic}/banquet/v1/policy`, // 创建草稿
    editBanquetPolicyTest: `${characteristic}/banquet/v1/policy`, // 编辑草稿
    editViewBanquetPolicyTest: `${characteristic}/banquet/v1/policy`, // 查看详情
    editViewBanquetPolicyHistory: `${characteristic}/banquet/v1/policy/publish/history/detail`, // 宴会政策历史详情
    deleteBanquetPolicyTest: `${characteristic}/banquet/v1/policy`, // 删除草稿
    releaseBanquetPolicyTest: `${characteristic}/banquet/v1/policy/publish`, // 发布草稿 
    disableBanquetPolicyOnline: `${characteristic}/banquet/v1/policy/publish/status`, // 正式 --启用暂停
    banquetPolicyHistory: `${characteristic}/banquet/v1/policy/publish/history`, // 历史发布版本

    /**
     * 宴会执行奖励
     */
    banquetRewardOnlineList: `${characteristic}/special-activity/v1`, // 正式列表
    banquetRewardTestList: `${characteristic}/special-activity-draft/v1`, // 草稿列表
    banquetRewardWaitReleaseList: `${characteristic}/special-activity-un-publish/v1`, // 待发布列表
    disableBanquetRewardOnline: `${characteristic}/special-activity/v1/execute`, // 正式 --启用暂停
    banquetRewardHistory: `${characteristic}/special-activity/v1/history`, // 历史发布版本
    banquetRewardHistoryView: `${characteristic}/special-activity/v1/history`, // 历史发布版本
    banquetRewardCreate: `${characteristic}/special-activity-draft/v1`, // 创建
    banquetRewardEdit: `${characteristic}/special-activity-draft/v1`, // 修改
    banquetRewardOnlineEdit: `${characteristic}/special-activity/v1`, // 修改
    banquetRewardAddProgramme: `${characteristic}/special-activity-plan-draft/v1`, // 添加方案
    banquetRewardEditProgramme: `${characteristic}/special-activity-plan-draft/v1`, // 修改方案
    banquetRewardListProgramme: `${characteristic}/special-activity-plan-draft/v1`, // 方案列表
    banquetRewardListRule: `${characteristic}/plan-entry-conditions/v1/draft`, // 方案准则数据列表
    banquetRewardOnlineListRule: `${characteristic}/plan-entry-conditions/v1/published`, //方案准则数据列表正式
    banquetRewardSortRule: `${characteristic}/special-activity-plan-draft/v1/sort`, // 方案顺序调整
    banquetRewardActivityHistory: `${characteristic}/special-activity-draft/v1`, // 活动详情
    banquetRewardThrow: `${characteristic}/special-activity-draft/v1/reward-rule`, // 投放奖品
    banquetLevel: `${characteristic}/special-activity/v1/level`, // 优先级
    banquetRewardOnlineView: `${characteristic}/special-activity/v1`, // 正式版详情
    banquetRewardStatus: `${characteristic}/special-activity/v1/execute`, // 启用暂停
    banquetRewardListOnlineRule: `${characteristic}/plan-entry-conditions/v1/published`, // 方案准则数据正式列表
    releaseBanquetReward: `${characteristic}/special-activity-draft/v1/publish`, // 发布
    banquetRewardDelete: `${characteristic}/special-activity-draft/v1`, // 删除草稿
    banquetRewardPrizeList: `${characteristic}/special-activity-draft/v1/`, // 奖品列表
    banquetRewardFactory: `${baseUrl}/data/portal/factories/v1/selections`, // 工厂列表

    banquetOrderTypeList: `${characteristic}/banquet/v1/order/type`,
    banquetOrderTypeStatus: `${characteristic}/banquet/v1/order/type/status`,
    banquetOrderDataList: `${characteristic}/banquet/v1/order`,
    banquetTypeSelection: `${characteristic}/banquet/v1/type/selection`,
    banquetPolicySelection: `${characteristic}/banquet/v1/policy/publish/selection`,

    /**
     * 核销门店
     */
    writeOffStore: `${tmServer}/write-off-terminal/v1`, // 终端列表
    writeOffStoreAll: `${tmServer}/write-off-terminal/v1/selections`, // 终端全量的列表
    writeOffEmptyAll: `${tmServer}/write-off-terminal/v1/clear`, // 清空终端列表
    writeOffUpload: `${characteristic}/write-off-terminal/v1/upload`, // 上传终端
    writeOffEdit: `${tmServer}/write-off-terminal/v1/edit`, // 选择终端 -- 终端编辑确认
    writeOffDelete: `${tmServer}/write-off-terminal/v1`, // 删除终端
    writeOffPolicy: `${tmServer}/write-off-terminal/v1/batchPolicy`, // 政策/批量政策
    writeOffPolicyFind: `${baseUrl}/promotion/portal/verify-policy/find`, // 核销政策
    WriteOffWords: `${baseUrl}/code/portal/verify-code/v1`, //核销字样
    /**
     * 终端核销记录
     */
    writeOffDetail: `${tmServer}/write-off-detail/v1`, // 核销明细分页列表
    writeOffDetailExport: `${tmServer}/write-off-detail/v1/export`, // 核销明细导出
    writeOffLog: `${characteristic}/write-off-log/v1`, // 核销执行奖励明细分页列表
    writeOffLogExport: `${characteristic}/write-off-log/v1/export`, // 核销执行奖励明细导出
    /**
     * 订单活动记录
     */
    orderActivityRecordList: `${characteristic}/termianl-detail/v1`, // 订单活动列表
    orderActivityRecordExport: `${characteristic}/termianl-detail/v1/export`, // 订单活动导出

    /**
     * 剑南春核销明细
     */
    jncWriteOffDetail: `${baseUrl}/jnc-shop-miniapp/portal/shop-write-off/v1`,
    jncWriteOffDetailExport: `${baseUrl}/jnc-shop-miniapp/portal/shop-write-off/exportExcel/v1`
  },

  baseUrl,
  baseCdnUrl,
  davinciUrl,

}

export default urls;
