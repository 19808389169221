import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './coupon';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 优惠券管理
 */
// 优惠券树
export const loadCouponConfigsTreeEpic = actions$ => actions$.pipe(
    ofType(actions.loadCouponConfigsTree.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, sort = '', config = '', detail = '' } = action.payload;

        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.coupon.couponConfigs}/configs?page=${page}&size=${size}&sort=${sort}&config=${config}&detail=${detail}`,
            }).pipe(
                map(res => actions.loadCouponConfigsTree.SUCCESS(res.response)),
                catchError(error => of(actions.loadCouponConfigsTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
// 优惠券品牌下拉列表
export const loadCouponConfigsAllEpic = actions$ => actions$.pipe(
    ofType(actions.loadCouponConfigsAll.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.coupon.couponConfigs}`,
            }).pipe(
                map(res => actions.loadCouponConfigsAll.SUCCESS(res.response.content)),
                catchError(error => of(actions.loadCouponConfigsAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


export const createCouponConfigEpic = actions$ => actions$.pipe(
    ofType(actions.createCouponConfig.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.coupon.couponConfigs}`,
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.createCouponConfig.SUCCESS(res.response)
            }),
            catchError(error => of(actions.createCouponConfig.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const putCouponConfigEpic = actions$ => actions$.pipe(
    ofType(actions.putCouponConfig.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: data,
            url: `${urls.coupon.couponConfigs}`,
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.putCouponConfig.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putCouponConfig.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const disabledOrEnableCouponConfigEpic = actions$ => actions$.pipe(
    ofType(actions.disabledOrEnableCouponConfig.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            url: `${urls.coupon.couponConfigs}/status`,
            body: data
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.disabledOrEnableCouponConfig.SUCCESS(res.response)
            }),
            catchError(error => of(actions.disabledOrEnableCouponConfig.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 优惠券种类下拉列表
export const loadCouponConfigDetailsAllEpic = actions$ => actions$.pipe(
    ofType(actions.loadCouponConfigDetailsAll.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.coupon.couponConfigDetails}/config/${action.payload}`,
            }).pipe(
                map(res => actions.loadCouponConfigDetailsAll.SUCCESS(res.response.content)),
                catchError(error => of(actions.loadCouponConfigDetailsAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


export const createCouponConfigDetailEpic = actions$ => actions$.pipe(
    ofType(actions.createCouponConfigDetail.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.coupon.couponConfigDetails}`,
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.createCouponConfigDetail.SUCCESS(res.response)
            }),
            catchError(error => of(actions.createCouponConfigDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const putCouponConfigDetailEpic = actions$ => actions$.pipe(
    ofType(actions.putCouponConfigDetail.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: data,
            url: `${urls.coupon.couponConfigDetails}`,
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.putCouponConfigDetail.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putCouponConfigDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const disabledOrEnableCouponConfigDetailEpic = actions$ => actions$.pipe(
    ofType(actions.disabledOrEnableCouponConfigDetail.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            url: `${urls.coupon.couponConfigDetails}/status`,
            body: data
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.disabledOrEnableCouponConfigDetail.SUCCESS(res.response)
            }),
            catchError(error => of(actions.disabledOrEnableCouponConfigDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const delCouponConfigDetailEpic = actions$ => actions$.pipe(
    ofType(actions.delCouponConfigDetail.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.coupon.couponConfigDetails}/${id}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.delCouponConfigDetail.SUCCESS(res.response)
            }),
            catchError(error => of(actions.delCouponConfigDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 优惠券导出
export const couponCodePacketExportEpic = actions$ => actions$.pipe(
    ofType(actions.couponCodePacketExport.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { couponId = '', num = 0, remark = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.coupon.couponCodePacketExport(couponId, num, remark)}`,
            }).pipe(
                map(res => {
                    callback && callback();
                    return actions.couponCodePacketExport.SUCCESS()
                }),
                catchError(error => of(actions.couponCodePacketExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
