import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'reportResource';

export const loadProductList = fasterActions(moduleName, 'loadProductList');//获取产品列表
export const loadActivityList = fasterActions(moduleName, 'loadActivityList');//获取活动列表
export const loaddeptactivityList = fasterActions(moduleName, 'loaddeptactivityList');//看板活动列表
export const loadPrizeList = fasterActions(moduleName, 'loadPrizeList');//获取奖品列表
export const loadPrizeListAllots = fasterActions(moduleName, 'loadPrizeListAllots');//获取奖品列表 allots
export const loadPoolList = fasterActions(moduleName, 'loadPoolList');//获取奖池列表
export const loadPoolListAllots = fasterActions(moduleName, 'loadPoolListAllots');//获取奖池列表 allots
export const loadProvinceList = fasterActions(moduleName, 'loadProvinceList');//获取奖品列表
export const loadCityList = fasterActions(moduleName, 'loadCityList');//获取奖品列表
export const loadRedPacketsTypeList = fasterActions(moduleName, 'loadRedPacketsTypeList');//获取红包类型
export const loadRedPacketsSourceList = fasterActions(moduleName, 'loadRedPacketsSourceList');//获取红包来源
export const loadAppletsList = fasterActions(moduleName, 'loadAppletsList');//小程序
export const jackpotList = fasterActions(moduleName, 'jackpotList');//奖池名称
export const commercialList = fasterActions(moduleName, 'commercialList');//商户号

const initialState = {
    loading: false,

    activityArray: [],//活动列表
    loaddeptactivityArray:[],//看板活动列表
    productArray: [], //产品列表
    prizeArray: [],//奖品列表
    prizeArrayAllots: [],//奖品列表
    poolArray: [],//奖池列表
    poolArrayAllots: [],//奖池列表 分部门
    provinceArray: [],//省列表
    cityArray: [],//市列表
    redPacketsTypeArray: [],//红包类型
    redPacketsSourceArray: [],//红包来源
    appletsArray: [],//小程序
    jackpotArray:[],//奖池名称
    commercialArray:[],//商户号
};

const parseSelect = ( content = [] ) => {
    content = content.map(item => ({
        text: item.name,
        value: item.id,
    }));
    return content;
};

const parseSelectPrize = ( content = [] ) => {
    content = content.map(item => ({
        text: item.name+`(${item.id})`,
        value: item.id,
    }));
    return content;
};

const parseAreaSelect = ( content = [] ) => {
  
    content = content.map(item => ({
        text: item.value,
        value: item.id,
    }));
    return content;
};

const parseAppletSelect = ( content = [] ) => {
    content = content.map(item => ({
        text: item,
        value: item,
    }));
    return content;
};
const parseRedPacketSelect = ( content = [] ) => {
  
    content = content.map(item => ({
        text: item.name,
        value: item.flag_code,
    }));
    return content;
};
const merchantSelect = ( content = [] ) => {
    content = content.map(item => ({
        text: item.merchant_id,
        value: item.merchant_id,
    }));
    return content;
};


const reducer = handleActions({
    [loadProductList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadProductList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, productArray: parseSelect(payload.content) }),
    [loadProductList.FAIL]: (state, { payload }) => ({ ...state, loading: false, productArray: [], error: payload }),
    [loadActivityList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadActivityList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, activityArray: parseSelect(payload.content) }),
    [loadActivityList.FAIL]: (state, { payload }) => ({ ...state, loading: false, activityArray: [], error: payload }),
    [loaddeptactivityList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loaddeptactivityList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, loaddeptactivityArray: parseSelect(payload.content) }),
    [loaddeptactivityList.FAIL]: (state, { payload }) => ({ ...state, loading: false, loaddeptactivityArray: [], error: payload }),
    
    [loadPrizeList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadPrizeList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, prizeArray: parseSelect(payload.content) }),
    [loadPrizeList.FAIL]: (state, { payload }) => ({ ...state, loading: false, prizeArray: [], error: payload }),

    [loadPrizeListAllots.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadPrizeListAllots.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, prizeArrayAllots: parseSelectPrize(payload.content) }),
    [loadPrizeListAllots.FAIL]: (state, { payload }) => ({ ...state, loading: false, prizeArrayAllots: [], error: payload }),

    [loadPoolList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadPoolList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, poolArray: parseSelect(payload.content) }),
    [loadPoolList.FAIL]: (state, { payload }) => ({ ...state, loading: false, poolArray: [], error: payload }),

    [loadPoolListAllots.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadPoolListAllots.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, poolArrayAllots: parseSelect(payload.content) }),
    [loadPoolListAllots.FAIL]: (state, { payload }) => ({ ...state, loading: false, poolArrayAllots: [], error: payload }),

    [loadProvinceList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadProvinceList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, provinceArray: parseAreaSelect(payload.content) }),
    [loadProvinceList.FAIL]: (state, { payload }) => ({ ...state, loading: false, provinceArray: [], error: payload }),
    [loadCityList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadCityList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, cityArray: parseAreaSelect(payload.content) }),
    [loadCityList.FAIL]: (state, { payload }) => ({ ...state, loading: false, cityArray: [], error: payload }),
    [loadRedPacketsTypeList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadRedPacketsTypeList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, redPacketsTypeArray: parseRedPacketSelect(payload.content) }),
    [loadRedPacketsTypeList.FAIL]: (state, { payload }) => ({ ...state, loading: false, redPacketsTypeArray: [], error: payload }),
    [loadRedPacketsSourceList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadRedPacketsSourceList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, redPacketsSourceArray: parseRedPacketSelect(payload.content) }),
    [loadRedPacketsSourceList.FAIL]: (state, { payload }) => ({ ...state, loading: false, redPacketsSourceArray: [], error: payload }),
    [loadAppletsList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadAppletsList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, appletsArray: parseAppletSelect(payload.content) }),
    [loadAppletsList.FAIL]: (state, { payload }) => ({ ...state, loading: false, appletsArray: [], error: payload }),
    [commercialList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [commercialList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, commercialArray: merchantSelect(payload.content) }),
    [commercialList.FAIL]: (state, { payload }) => ({ ...state, loading: false, commercialArray: [], error: payload }),
    [jackpotList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [jackpotList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, jackpotArray: parseSelect(payload.content) }),
    [jackpotList.FAIL]: (state, { payload }) => ({ ...state, loading: false, jackpotArray: [], error: payload }),
}, initialState);

export default reducer;


