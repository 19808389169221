import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
//不要encode
import { ajax } from 'rxjs/ajax';

import urls from '@/api/urls';
import * as uiActions from '../ui';
import * as actions from './batchScrap';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadScrapsEpic = actions$ => actions$.pipe(
  ofType(actions.loadScraps.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { query = '', registerType = '', startTime = '', endTime = '', eseSeqnumStart = '', eseSeqnumEnd = '', page = 0, size = 20, sort = 'createdTime,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchScraps.batchScraps}?query=${query}&registerType=${registerType}&startTime=${startTime}&endTime=${endTime}&eseSeqnumStart=${eseSeqnumStart}&eseSeqnumEnd=${eseSeqnumEnd}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadScraps.SUCCESS(res.response)),
      catchError(error => of(actions.loadScraps.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const viewScrapsEpic = actions$ => actions$.pipe(
  ofType(actions.viewScraps.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { registerType = '', eseSeqnumEnd = '', eseSeqnumStart = '', query = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchScraps.viewScraps}?query=${query}&registerType=${registerType}&eseSeqnumStart=${eseSeqnumStart}&eseSeqnumEnd=${eseSeqnumEnd}` //
    }).pipe(
      map(res => actions.viewScraps.SUCCESS(res.response)),
      catchError(error => of(actions.viewScraps.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const batchScrapsEpic = (actions$, store) => actions$.pipe(
  ofType(actions.batchScraps.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: urls.code.batchScraps.batchScraps,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.batchScraps.SUCCESS(res.response),uiActions.notification('报废成功', 'success'), uiActions.updateScrapDialogStatus(false), actions.loadScraps.REQUEST(store.value.batchScrap.loadScrapsParams)]),
      catchError(error => of(actions.batchScraps.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);
