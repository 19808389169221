import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './whiteOff';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { trims } from '@/utils/commonUtils.js';





// Epics
export const loadByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.whiteOff.whiteOff}/${action.payload.callback ? action.payload.id : action.payload}`
    }).pipe(
      map(res => {
        action.payload.callback && action.payload.callback(res.response)
        return actions.loadById.SUCCESS(res.response)
      }),

      // catchError(error => of(actions.loadById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      // )
    )
  }
  )
);


export const loadListEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { isVaild = true, promotionCategory, keywords = '', productKeywords = '', promotionType = '', promotionPublishStatus = '', page = 0, size = 20, sort = '' } = action.payload;
    const url = isVaild ? urls.promotion.whiteOff.whiteOff + '/normal' : urls.promotion.whiteOff.whiteOff + '/page-draft'
    return ajax({
      ...ajaxBaseConfig,
      url: `${url}?promotionCategory=${promotionCategory}&promotionStatus=DRAFT&keywords=${keywords}&productKeywords=${productKeywords}&promotionType=${promotionType}&promotionPublishStatus=${promotionPublishStatus}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const editWhiteOffEpic = actions$ => actions$.pipe(
  ofType(actions.editWhiteOff.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { sendData, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.whiteOff.whiteOff}`,
      method: sendData.id ? 'PUT' : 'POST',
      body: trims(sendData)
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.editWhiteOff.SUCCESS(res.response)
      }),
      catchError(error => of(actions.editWhiteOff.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const deleteWhiteOff = (actions$, store) => actions$.pipe(
  ofType(actions.deleteWhiteOff.REQUEST.toString()),
  mergeMap(action => {
    const { id, callback } = action.payload;
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.promotion.whiteOff.whiteOff}/${id}`
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.deleteWhiteOff.SUCCESS(res.response)
      }),
      catchError(error => of(actions.deleteWhiteOff.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const whiteOffPauseEpic = (actions$, store) => actions$.pipe(
  ofType(actions.whiteOffPause.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.whiteOff.whiteOff}/${id}/pause`
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.whiteOffPause.SUCCESS(res.response)
      }),
      catchError(error => of(actions.whiteOffPause.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const whiteOffResumeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.whiteOffResume.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.whiteOff.whiteOff}/${id}/resume`
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.whiteOffResume.SUCCESS(res.response)
      }),
      catchError(error => of(actions.whiteOffResume.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  })
);

export const publishWhiteOffEpic = (actions$, store) => actions$.pipe(
  ofType(actions.publishWhiteOff.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.whiteOff.whiteOff}/${data.id}/publish`,
      // url: `${urls.promotion.promotions.publish(action.payload.id)}?&authType=${action.payload.authType}`,
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.publishWhiteOff.SUCCESS(res.response)
      }),
      catchError(error => of(actions.publishWhiteOff.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const loadPromotionHistoriesByIdEpic = (actions$, store) => actions$.pipe(
  ofType(actions.loadPromotionHistoriesById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { keywords, promotionId, page = 0, size = 10, sort } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotionHistories.promotionHistories}?keywords=${keywords}&promotionId=${promotionId}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadPromotionHistoriesById.SUCCESS(res.response)),
      catchError(error => of(actions.loadPromotionHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);





//保存环节方案
export const addLinkPlanEpic = actions$ => actions$.pipe(
  ofType(actions.addLinkPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: data.id ? 'PUT' : 'POST',
      body: data,
      url: `${urls.promotion.plans.plans}`
    }).pipe(
      map(res => {

        callback && callback()
        return actions.addLinkPlan.SUCCESS(res.response);

      }),
      catchError(error => of(actions.addLinkPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//环节方案不分页
export const linkPlanSelectEpic = actions$ => actions$.pipe(
  ofType(actions.linkPlanSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { promotionId, callback } = action.payload;
    let url = `${urls.promotion.plans.plans}?promotionId=${promotionId}`

    return ajax({
      ...ajaxBaseConfig,
      url,
    }).pipe(
      map(res => {
        // res.response.reverse()
        callback && callback(res.response.content);

        return actions.linkPlanSelect.SUCCESS(res.response.content);
      }),
      catchError(error => of(actions.linkPlanSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
//获取step2方案详情
export const getPlanDetailEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getPlanDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { planId, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.promotion.plans.plans}?planId=${planId}`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getPlanDetail.SUCCESS(res.response);
      }),
       catchError(error => of(actions.getPlanDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))

    )
  }
  )
);





//排序方案
export const sortLinkPlanEpic = actions$ => actions$.pipe(
  ofType(actions.sortLinkPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { callback, data } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.sort}`,
      body: data,
      method: 'PUT'
    }).pipe(
      map(res => {
        callback && callback();

        return actions.sortLinkPlan.SUCCESS(res.response);
      }),
      catchError(error => of(actions.sortLinkPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

//copystep2方案
export const copyPlanEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.copyPlan.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.promotion.plans.plans}/copy`
    }).pipe(
      map(res => {
        callback && callback();

        return actions.copyPlan.SUCCESS(res.response);
      }),
      catchError(error => of(actions.copyPlan.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);



export const loadPrioritiesEpic = actions$ => actions$.pipe(
  ofType(actions.loadPriorities.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionCategory, keywords, statues, page = 0, size = 20, sort = '', promotionType = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.whiteOff.whiteOff}/priorities/${promotionCategory}?keywords=${keywords}&statues=${statues}&page=${page}&size=${size}&sort=${sort}&promotionType=${promotionType}`,
    }).pipe(
      map(res => actions.loadPriorities.SUCCESS(res.response)),
      catchError(error => of(actions.loadPriorities.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const updatePrioritiesEpic = (actions$, store) => actions$.pipe(
  ofType(actions.updatePriorities.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, publishId, promotionPriority } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion.whiteOff.whiteOff}/${publishId}/priority/${promotionPriority}`,
    }).pipe(
      map(res => {

        callback && callback()
        return actions.updatePriorities.SUCCESS(res.response);

      }),
      catchError(error => of(actions.updatePriorities.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);





export const loadwhiteOffConfigsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadwhiteOffConfigsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, data } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.whiteOff.config}/${data}`
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.loadwhiteOffConfigsById.SUCCESS(res.response);
      }),
      catchError(error => of(actions.loadwhiteOffConfigsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const addwhiteOffConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.addwhiteOffConfigs.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, data } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.promotion.whiteOff.config}`,
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.addwhiteOffConfigs.SUCCESS(res.response);
      }),
      catchError(error => of(actions.addwhiteOffConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const editwhiteOffConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.editwhiteOffConfigs.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, data } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.promotion.whiteOff.config}`,
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.editwhiteOffConfigs.SUCCESS(res.response);
      }),
      catchError(error => of(actions.editwhiteOffConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


//草稿数量
export const draftCountEpic = actions$ => actions$.pipe(
  ofType(actions.draftCount.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.whiteOff.whiteOff}/draft-count`,
    }).pipe(
      map(res => actions.draftCount.SUCCESS(res.response)),
      catchError(error => of(actions.draftCount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);




//核销政策页面
//list
export const loadWriteOffPolicyListEpic = actions$ => actions$.pipe(
  ofType(actions.loadWriteOffPolicyList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.whiteOff.policy}/findAll?word=${keywords}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.loadWriteOffPolicyList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadWriteOffPolicyList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createWriteOffPolicyEpic = actions$ => actions$.pipe(
  ofType(actions.createWriteOffPolicy.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.promotion.whiteOff.policy}/v1`,
        body: trims(action.payload.data),
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createWriteOffPolicy.SUCCESS(res)
        }),
        catchError(error => of(actions.createWriteOffPolicy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editWriteOffPolicyEpic = actions$ => actions$.pipe(
  ofType(actions.editWriteOffPolicy.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.promotion.whiteOff.policy}/v1`,
        body: trims(action.payload.data),
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editWriteOffPolicy.SUCCESS(res)
        }),
        catchError(error => of(actions.editWriteOffPolicy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//able

export const ableWriteOffPolicyEpic = actions$ => actions$.pipe(
  ofType(actions.ableWriteOffPolicy.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.promotion.whiteOff.policy}/v1/${data.id}/status?status=${data.status}`,
        //
        // body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableWriteOffPolicy.SUCCESS(res)
        }),
        catchError(error => of(actions.ableWriteOffPolicy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通信录查询by id
export const loadWriteOffPolicyIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadWriteOffPolicyId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.whiteOff.policy}/v1/${data.id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.loadWriteOffPolicyId.SUCCESS(res.response)

        }),
        catchError(error => of(actions.loadWriteOffPolicyId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


export const deleteWriteOffPolicyEpic = actions$ => actions$.pipe(
  ofType(actions.deleteWriteOffPolicy.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.promotion.whiteOff.policy}/v1/${id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.deleteWriteOffPolicy.SUCCESS(res)
        }),
        catchError(error => of(actions.deleteWriteOffPolicy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


export const getWriteOffPolicySelectionEpic = actions$ => actions$.pipe(
  ofType(actions.getWriteOffPolicySelection.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { status = '' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.whiteOff.policy}/find?status=${status}`,
      }).pipe(
        map(res => actions.getWriteOffPolicySelection.SUCCESS(res.response || [])),
        catchError(error => of(actions.getWriteOffPolicySelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
