import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './miniProgramRelease';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 微信管理
 */

// Epics
// 获取该企业的开发版小程序
export const developEpic = actions$ => actions$.pipe(
  ofType(actions.develop.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.miniProgramRelease.develop}`
    }).pipe(
      map(res => actions.develop.SUCCESS(res.response)),
      catchError(error => of(actions.develop.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 获取/发布 该企业的体验版小程序
export const experienceEpic = actions$ => actions$.pipe(
  ofType(actions.experience.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.miniProgramRelease.experience}`,
      method: action.payload && action.payload.appId ? 'POST' : 'GET',
      body: action.payload,
    }).pipe(
      mergeMap(res => [actions.experience.SUCCESS(res.response), uiActions.notification('已发布到体验版', action.payload && action.payload.appId ? 'success' : '')]),
      catchError(error => of(actions.experience.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 获取该企业的正式版小程序  
export const releaseEpic = actions$ => actions$.pipe(
  ofType(actions.release.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page, size } = action.payload;
    let url = `${urls.queryTools.miniProgramRelease.release}?page=${page}&size=${size}&sort=id,desc`;
    return ajax({
      ...ajaxBaseConfig,
      url,
    }).pipe(
      map(res => actions.release.SUCCESS(res.response)),
      catchError(error => of(actions.release.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 提交小程序审核 
export const submitReleaseEpic = actions$ => actions$.pipe(
  ofType(actions.submitRelease.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.miniProgramRelease.release}`,
      method: 'post',
      body: action.payload,
    }).pipe(
      mergeMap(res => [actions.submitRelease.SUCCESS(res.status), uiActions.notification('已提交正式版', 'success')]),
      catchError(error => of(actions.submitRelease.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 获取小程序今天发布体验版的剩余次数
export const experienceCountEpic = actions$ => actions$.pipe(
  ofType(actions.experienceCount.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.miniProgramRelease.experienceCount(action.payload)}`
    }).pipe(
      map(res => actions.experienceCount.SUCCESS(res.response)),
      catchError(error => of(actions.experienceCount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 发布已通过审核的小程序
export const submitAuditReleaseEpic = actions$ => actions$.pipe(
  ofType(actions.submitAuditRelease.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.miniProgramRelease.release}`,
      method: 'PATCH',
      body: action.payload,
    }).pipe(
      map(res => actions.submitAuditRelease.SUCCESS(res.status)),
      catchError(error => of(actions.submitAuditRelease.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 撤销小程序审核
export const undocodeAuditEpic = actions$ => actions$.pipe(
  ofType(actions.undocodeAudit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.miniProgramRelease.undocodeAudit}`,
      method: 'PATCH',
      body: action.payload,
    }).pipe(
      map(res => actions.undocodeAudit.SUCCESS(res.status)),
      catchError(error => of(actions.undocodeAudit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 检查该小程序发布情况
export const releaseLogEpic = actions$ => actions$.pipe(
  ofType(actions.releaseLog.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.miniProgramRelease.releaseLog}?appId=${action.payload}`,
    }).pipe(
      map(res => actions.releaseLog.SUCCESS(res.response)),
      catchError(error => of(actions.releaseLog.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);