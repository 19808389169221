import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { notification } from '$bere';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeLabel';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { uuid } from '@utils/commonUtils.js'

// 获取列表数据
export const getUserDefinedLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedLabelData.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}?sort=id,desc&size=100`
        }).pipe(
            map(res => actions.getUserDefinedLabelData.SUCCESS(res.response)),
            catchError(error => of(actions.getUserDefinedLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 禁用
export const enableUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableUserDefinedLabel.REQUEST),

    mergeMap(action => {
        const { id, status } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/${id}/status?status=${status}`,
            method: 'PATCH',
            // body: action.payload
        }).pipe(
            concatMap(res => [actions.enableUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.enableUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 删除
export const deleteGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { groupId, status } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/group/${groupId}`,
            method: 'DELETE',
        }).pipe(
            concatMap(res => [actions.deleteGroupUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.deleteGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 推送设置部分数据
export const getPlatformsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPlatforms.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/platforms`,
        }).pipe(
            concatMap(res => [actions.getPlatforms.SUCCESS(res.response)]),
            catchError(error => of(actions.getPlatforms.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


//推送设置
export const bindGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.bindGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/bind`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                return [actions.bindGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.bindGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
//查看推送设置
export const getbindGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getbindGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, id } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/bind/${id}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.content || []);
                return [actions.getbindGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getbindGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)





// 推送标签
export const pushGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.pushGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/push`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                return [actions.pushGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.pushGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
//查看推送历史
export const pushrecordGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.pushrecordGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { groupId = '', page = 0, size = 20, sort = '' } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/push?groupId=${groupId}&page=${page}&size=${size}&=${sort}`,
        }).pipe(
            concatMap(res => {
                // callback && callback(res.response.content || []);
                return [actions.pushrecordGroupUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.pushrecordGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




// (详情页面接口)
// 获取数据详情
export const getUserDefinedDetailEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedDetail.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/${action.payload.id}`
        }).pipe(

            map(res => {
                action.payload.callback && action.payload.callback(res.response);
                return actions.getUserDefinedDetail.SUCCESS(res.response)
            }),
            catchError(error => {
                return of(actions.getUserDefinedDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)
// 创建自定义标签
export const postUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '', res.response);
                // return [actions.postUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedDetail.REQUEST({ id: res.response.id || '' })]
                return [actions.postUserDefinedLabel.SUCCESS(res.response)]

            }),
            catchError(error => {
                return of(actions.postUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

// 修改自定义标签
export const patchUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchUserDefinedLabel.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '', res.response);
                return [actions.patchUserDefinedLabel.SUCCESS(res.response)]
                // return [actions.patchUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedDetail.REQUEST({ id: res.response.id || '' })]
            }),
            catchError(error => {
                return of(actions.patchUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)


export const patchUserDefinedLabelStep2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchUserDefinedLabelStep2.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/upsert-rules`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                // return [actions.patchUserDefinedLabelStep2.SUCCESS(res.response)]
                return [actions.patchUserDefinedLabelStep2.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.patchUserDefinedLabelStep2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const patchUserDefinedLabelStep3Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchUserDefinedLabelStep3.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/update-group`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.id || '');
                // return [actions.patchUserDefinedLabel.SUCCESS(res.response)]
                return [actions.patchUserDefinedLabelStep3.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.patchUserDefinedLabelStep3.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 标签组菜单创建(多条同级)
export const menuBatchEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.menuBatch.REQUEST),
    mergeMap(action => {
        const { callback, data } = action.payload;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.menuBatch}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.menuBatch.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.menuBatch.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 标签组菜单查询
export const menuTreeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.menuTree.REQUEST),
    mergeMap(action => {
        const { word = '' } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.menuTree}?word=${word}`,
            method: 'GET',
        }).pipe(
            concatMap(res => {
                return [actions.menuTree.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.menuTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const menuEditEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.menuEdit.REQUEST),
    mergeMap(action => {
        const { data, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.menuEdit}`,
            method: 'PATCH',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.menuEdit.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.menuEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const menuDelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.menuDel.REQUEST),
    mergeMap(action => {
        const { id, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.menuEdit}/${id}`,
            method: 'DELETE',
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.menuDel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.menuDel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getLabelData.REQUEST),
    mergeMap(action => {
        const { labelMenuId, callback, labelTypeEnum } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.labelData}/label-type?labelMenuId=${labelMenuId}&labelPublishStatusEnums=PUBLISHED`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.getLabelData.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const labelSelectionEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.labelSelection.REQUEST),
    mergeMap(action => {
        const { labelMenuId, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.labelSelection}?labelMenuId=${labelMenuId}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.labelSelection.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.labelSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const editLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editLabelData.REQUEST),
    mergeMap(action => {
        const { id, name, labelFieldWriteModelEnum, callback, version } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.labelData}`,
            method: 'PATCH',
            body: { id, name, labelFieldWriteModelEnum, version, labelTypeEnum: 'CUSTOM_LABEL' }
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.editLabelData.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.editLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

export const delLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delLabelData.REQUEST),
    mergeMap(action => {
        const { id, name, labelFieldWriteModelEnum, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.labelData}/${id}`,
            method: 'DELETE',
            body: { id, name, labelFieldWriteModelEnum }
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.delLabelData.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.delLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 标签分组-标签审核及草稿-获取列表
export const getLabelVerifyOrDraftListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getLabelVerifyOrDraftList.REQUEST),
    mergeMap(action => {
        let { page, size, labelPublishStatusEnums, labelTypeEnum = '', labelMenuName = '', labelName = '', labelFieldName = '', queryName = '', callback } = action.payload;
        let paramStr = `?page=${page}&size=${size}&labelPublishStatusEnums=${labelPublishStatusEnums}`;
        labelMenuName = labelMenuName.trim();
        labelName = labelName.trim();
        labelFieldName = labelFieldName.trim();

        if (labelTypeEnum.trim()) {
            paramStr += `&labelTypeEnum=${labelTypeEnum}`
        }
        if (labelName.length != 0) {
            paramStr += `&labelName=${labelName}`;
        }
        if (labelFieldName.length != 0) {
            paramStr += `&labelFieldName=${labelFieldName}`
        }
        if (labelMenuName.length != 0) {
            paramStr += `&labelMenuName=${labelMenuName}`
        }
        if (queryName.trim() && !labelTypeEnum) {
            paramStr += `&queryName=${queryName}`
        }
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.labelVerify_DraftList}${paramStr}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res);
                return [actions.getLabelVerifyOrDraftList.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.getLabelVerifyOrDraftList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

// 标签分组-标签历史-获取列表
export const getLabelHistoryListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getLabelHistoryList.REQUEST),
    mergeMap(action => {
        let { labelMenuId, dataId, labelTypeEnum } = action.payload;
        let paramStr = `?labelMenuId=${labelMenuId}&dataId=${dataId}&labelTypeEnum=${labelTypeEnum}`;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.labelHistory}${paramStr}`,
        }).pipe(
            concatMap(res => {
                return [actions.getLabelHistoryList.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.getLabelHistoryList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

export const delLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delLabel.REQUEST),
    mergeMap(action => {
        const { labelId, labelMenuId, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}?labelId=${labelId}&labelMenuId=${labelMenuId}`,
            method: 'DELETE',
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.delLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.delLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 标签分组-标签审核-删除
export const deleteLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteLabel.REQUEST),
    mergeMap(action => {
        const { id, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.deleteLabelField}/${id}`,
            method: 'DELETE'
        }).pipe(
            concatMap(res => {
                callback && callback(res);
                return [actions.deleteLabel.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.deleteLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)


export const disabledLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.disabledLabelData.REQUEST),
    mergeMap(action => {
        const { status, id, callback, } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.labelData}/${id}?status=${status}`,
            method: 'PATCH',
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.disabledLabelData.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.disabledLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 标签分组-标签审核-发布单条标签
export const releaseSingleLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.releaseSingleLabel.REQUEST),
    mergeMap(action => {
        const { id, remark, callback } = action.payload;
        const paramsObj = {};
        if (remark.trim()) {
            paramsObj.remark = remark;
        }
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.releaseSingleLabel}/${id}`,
            body: paramsObj,
            method: 'POST'
        }).pipe(
            concatMap(res => {
                callback && callback(res);
                return [actions.releaseSingleLabel.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.releaseSingleLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)


// 标签分组-标签审核-发布批量标签
export const releaseBatchLabelsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.releaseBatchLabels.REQUEST),
    mergeMap(action => {
        const { ids, remark, callback } = action.payload;
        const paramsObj = {};
        if (remark.trim()) {
            paramsObj.remark = remark;
        }
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.releaseBatchLabels}?ids=${ids}`,
            body: paramsObj,
            method: 'POST'
        }).pipe(
            concatMap(res => {
                callback && callback(res);
                return [actions.releaseBatchLabels.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.releaseBatchLabels.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)


// 标签分组-标签草稿-提交单条标签
export const submitSingleLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.submitSingleLabel.REQUEST),
    mergeMap(action => {
        const { id, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.submitSingleLabel}/${id}`,
            method: 'POST'
        }).pipe(
            concatMap(res => {
                callback && callback(res);
                return [actions.submitSingleLabel.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.submitSingleLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

// 标签分组-标签草稿-提交批量标签
export const submitBatchLabelsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.submitBatchLabels.REQUEST),
    mergeMap(action => {
        const { ids, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.submitBatchLabels}?ids=${ids}`,
            method: 'POST'
        }).pipe(
            concatMap(res => {
                callback && callback(res);
                return [actions.submitBatchLabels.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.submitBatchLabels.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)


// createLabelData
export const createLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createLabelData.REQUEST),
    mergeMap(action => {
        const { id, code, name, labelTypeEnum, callback, labelAddEnum, labelFields, labelMenuId } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.customLabel}`,
            method: 'POST',
            body: { name, labelTypeEnum, labelAddEnum, labelFields, labelMenuId, code, labelTypeEnum: 'CUSTOM_LABEL' }
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.createLabelData.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.createLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const editLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editLabel.REQUEST),
    mergeMap(action => {
        const { id, name, labelMenuId, callback, } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.customLabel}`,
            method: 'PATCH',
            body: { name, id, labelMenuId, }
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.editLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.editLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getLabelSearchListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getLabelSearchList.REQUEST),
    mergeMap(action => {
        const { labelFieldName, labelName, labelMenuName, labelFieldNameQuery, labelNameQuery, labelMenuNameQuery, labelMenuCode, labelCode, labelFieldCode, labelTypeEnum, callback, status } = action.payload;
        let paramsStr = `labelPublishStatusEnums=PUBLISHED&labelTypeEnum=${labelTypeEnum}`;
        if (labelFieldName) {
            paramsStr += `&labelFieldName=${labelFieldName}`
        }
        if (labelName) {
            paramsStr += `&labelName=${labelName}`
        }
        if (labelMenuName) {
            paramsStr += `&labelMenuName=${labelMenuName}`
        }
        if (labelFieldNameQuery) {
            paramsStr += `&labelFieldNameQuery=${labelFieldNameQuery}`
        }
        if (labelNameQuery) {
            paramsStr += `&labelNameQuery=${labelNameQuery}`
        }
        if (labelMenuNameQuery) {
            paramsStr += `&labelMenuNameQuery=${labelMenuNameQuery}`
        }
        if (labelMenuCode) {
            paramsStr += `&labelMenuCode=${labelMenuCode}`
        }
        if (labelCode) {
            paramsStr += `&labelCode=${labelCode}`
        }
        if (labelFieldCode) {
            paramsStr += `&labelFieldCode=${labelFieldCode}`
        }
        if (status) {
            paramsStr += `&status=ENABLE`
        }
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.getLabelSearchList}?${paramsStr}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.getLabelSearchList.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.getLabelSearchList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

export const getGroupIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getGroupId.REQUEST),
    mergeMap(action => {
        const { labelMenuId = '', callback, } = action.payload;

        if (!labelMenuId) {
            notification.error('没有labelMenuId')
            return;
        }
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.getGroupId}/${labelMenuId}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.getGroupId.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.getGroupId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

export const getLinkPageLabelDropdownListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getLinkPageLabelDropdownList.REQUEST),
    mergeMap(action => {
        const { body = {}, callback, } = action.payload;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.getLinkPageLabelDropdownList}`,
            method: 'POST',
            body
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.getLinkPageLabelDropdownList.SUCCESS(res.response)]
            }),
            catchError(error => {
                console.log(error)
                return of(actions.getLinkPageLabelDropdownList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)


// 同步标签-获取推送平台
export const getPushPlatformListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPushPlatformList.REQUEST),
    mergeMap(action => {
        const { callback, } = action.payload;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.pushPlatformList}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.content);
                return [actions.getPushPlatformList.SUCCESS(res.response.content)]
            }),
            catchError(error => {
                return of(actions.getPushPlatformList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)
// 同步标签-绑定推送的标签信息(更新删除二合一)
export const updateBindPushLabelsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.updateBindPushLabels.REQUEST),
    mergeMap(action => {
        const { body, callback, } = action.payload;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.updateBindPushLabels}`,
            method: 'PUT',
            body
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.content);
                return [actions.updateBindPushLabels.SUCCESS(res.response.content)]
            }),
            catchError(error => {
                return of(actions.updateBindPushLabels.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)
// 同步标签-获取绑定推送的标签信息
export const getPushLabelBindListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPushLabelBindList.REQUEST),
    mergeMap(action => {
        const { callback, } = action.payload;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.getPushLabelBindList}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response.content);
                return [actions.getPushLabelBindList.SUCCESS(res.response.content)]
            }),
            catchError(error => {
                return of(actions.getPushLabelBindList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)
// 同步标签-推送数据
export const pushLabelsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.pushLabels.REQUEST),
    mergeMap(action => {
        const { body, callback, } = action.payload;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.pushLabels}`,
            method: 'POST',
            body
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.pushLabels.SUCCESS(res.response.content)]
            }),
            catchError(error => {
                return of(actions.pushLabels.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)
// 同步标签-推送列表-分页查询
export const getPushLabelPageListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPushLabelPageList.REQUEST),
    mergeMap(action => {
        const { page, size } = action.payload;

        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.getPushLabelPageList}?page=${page}&size=${size}`,
        }).pipe(
            concatMap(res => {
                return [actions.getPushLabelPageList.SUCCESS(res.response)]
            }),
            catchError(error => {
                return of(actions.getPushLabelPageList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            })
        )
    })
)

// 新下拉框(树) 营销
export const loadCodeLabelSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadCodeLabelSelect.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.code.codeLabel.userDefinedTag}/menu/tile?labelPublishStatusEnum=PUBLISHED&status=ENABLE`
        }).pipe(
            // map(res => actions.loadCodeLabelSelect.SUCCESS(res.response)),
            concatMap(res => {
                let newarray = JSON.parse(JSON.stringify(res.response));

                let newarray2 = []
                newarray.map((item, index) => {

                    item.id = item.code;
                    item.value = item.code;
                    item.text = item.name + (item.code ? '_' + item.code : '');
                    item.children = [...item.customLabels || [], ...item.calculateLabels || []];
                    newarray2.push(JSON.parse(JSON.stringify(item)))

                    item.children.map((item2, index2) => {
                        item2.id = item.code + '|||' + item2.code;
                        item2.value = item.code + '|||' + item2.code;
                        item2.text = item2.labelMenuPathName + (item2.code ? '_' + item2.code : '');
                        item2.title = item2.text
                        item2.children = item2.labelFields;

                        newarray2[index].children[index2] = {
                            id: item.code + '|||' + item2.code,
                            value: item.code + '|||' + item2.code,
                            text: item2.labelMenuPathName + (item2.code ? '_' + item2.code : ''),
                            title: item2.text,
                            code: item2.code,
                            labelTypeEnum: item2.labelTypeEnum,
                        }

                        item2.children.map((item3) => {
                            item3.id = item.code + '|||' + item2.code + '|||' + item3.code;
                            item3.value = item.code + '|||' + item2.code + '|||' + item3.code;
                            item3.text = item2.labelMenuPathName + '/' + item3.name + (item3.code ? '_' + item3.code : '');
                            item3.title = item3.text;
                        })

                    })


                })


                return [actions.loadCodeLabelSelect.SUCCESS({ list: newarray, list2: newarray2, list3: newarray })]
            }),
            // catchError(error => of(actions.loadCodeLabelSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
