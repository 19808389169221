import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'customFields';

/**
 * TODO: 
 */

// 自定义字段
export const customFields = fasterActions(moduleName, 'customFields');
export const customFieldsById = fasterActions(moduleName, 'customFieldsById');
export const customFieldsPreview = fasterActions(moduleName, 'customFieldsPreview');
export const customFieldsStatus = fasterActions(moduleName, 'customFieldsStatus');
export const customFieldsModify = fasterActions(moduleName, 'customFieldsModify');


const initialState = {

}

const reducer = handleActions(
  {
    [customFields.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [customFields.SUCCESS]: (state, { payload }) => ({ ...state, customFields: payload, loading: false }),
    [customFields.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [customFieldsById.SUCCESS]: (state, { payload }) => ({ ...state, customFieldsById: payload }),
    [customFieldsById.FAIL]: (state, { payload }) => ({ ...state }),

    [customFieldsPreview.REQUEST]: (state, { payload }) => ({ ...state, payload, previewLoading: true }),
    [customFieldsPreview.SUCCESS]: (state, { payload }) => ({ ...state, customFieldsPreview: payload, previewLoading: false }),
    [customFieldsPreview.FAIL]: (state, { payload }) => ({ ...state, previewLoading: false }),

    [customFieldsStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, customFieldsStatusLoaded: false }),
    [customFieldsStatus.SUCCESS]: (state, { payload }) => ({ ...state, customFieldsStatusLoaded: payload, }),
    [customFieldsStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [customFieldsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, customFieldsModifyLoaded: false, loading: true }),
    [customFieldsModify.SUCCESS]: (state, { payload }) => ({ ...state, customFieldsModifyLoaded: payload, loading: false }),
    [customFieldsModify.FAIL]: (state, { payload }) => ({ ...state, loading: false }),



  },
  initialState
);

export default reducer;
