import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import { fasterActions } from '../../utils';
import { createAction, createActions, handleActions, combineActions } from 'redux-actions';

const moduleName = 'tagbatch';

const initialState = {

}

// Actions
export const tagbatch = fasterActions(moduleName, 'tagbatch');
export const downloadLog = fasterActions(moduleName, 'downloadLog');
export const packageDownloadLog = fasterActions(moduleName, 'packageDownloadLog');
export const downloadPackage = fasterActions(moduleName, 'downloadPackage');




const reducer = handleActions(
  {



    [tagbatch.REQUEST]: (state, { payload }) => ({ ...state, payload, tagbatch: '' }),
    [tagbatch.SUCCESS]: (state, { payload }) => ({ ...state, tagbatch: payload }),
    [tagbatch.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [downloadLog.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [downloadLog.SUCCESS]: (state, { payload }) => ({ ...state, downloadLogList: payload }),
    [downloadLog.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [packageDownloadLog.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [packageDownloadLog.SUCCESS]: (state, { payload }) => ({ ...state, downloadLogList: payload, downloadError: false }),
    [packageDownloadLog.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [downloadPackage.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [downloadPackage.SUCCESS]: (state, { payload }) => ({ ...state, downloadPackageUrl: payload, downloadError: false }),
    [downloadPackage.FAIL]: (state, { payload }) => {
      let downloadError;
      const { ecode, emsg } = payload;
      if (ecode == 21016) {
        downloadError = emsg;
      }
      return ({ ...state, error: payload, downloadError });
    },
  },


  initialState
);

export default reducer;
