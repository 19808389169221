import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import ui, { loadPermissionsEpic, loadPermissionsSelectionEpic } from './ui';
import { reducer as formReducer } from 'redux-form';
import promotions from './promotions/promotions';
import * as promotionsEpic from './promotions/promotionsEpic';
import pools from './pools/pools';
import * as poolsEpic from './pools/poolsEpic';
import prizeRegister from './prizeRegister/prizeRegister';
import * as prizeRegisterEpic from './prizeRegister/prizeRegisterEpic';
import prizeManager from './prizeManager/prizeManager';
import * as prizeManagerEpic from './prizeManager/prizeManagerEpic';

// 页面管理
import liveManageMent from './liveManageMent/liveManageMent';
import * as liveManageMentEpic from './liveManageMent/liveManageMentEpic';

// import wechat, { authorizerConfigsEpic } from './wechat';

import dataOverview from './dataOverview/dataOverview';
import * as dataOverviewEpic from './dataOverview/dataOverviewEpic';
import realTime from './realTime/realTime';
import * as realTimeEpic from './realTime/realTimeEpic';
import amountBill from './amountBill/amountBill';
import * as amountBillEpic from './amountBill/amountBillEpic';
import marketing from './marketing/marketing';
import * as marketingEpic from './marketing/marketingEpic';
import reportResource from './reportResource/reportResource';
import * as reportResourceEpic from './reportResource/reportResourceEpic';


import products from './products/products';
import * as productsEpic from './products/productsEpic';
import enterprises from './enterprises/enterprises';
import * as enterprisesEpic from './enterprises/enterprisesEpic';
//图片库
import uploadLibrary from './uploadLibrary/uploadLibrary';
import * as uploadLibraryEpic from './uploadLibrary/uploadLibraryEpic';


import tagbatchConfigs from './tagbatchConfigs/tagbatchConfigs';
import * as tagbatchConfigsEpic from './tagbatchConfigs/tagbatchConfigsEpic';
import prizePointTypes from './prizePointTypes/prizePointTypes';
import * as prizePointTypesEpic from './prizePointTypes/prizePointTypesEpic';
import prizeCardTypes from './prizeCardTypes/prizeCardTypes';
import * as prizeCardTypesEpic from './prizeCardTypes/prizeCardTypesEpic';
import coupon from './coupon/coupon';
import * as couponEpic from './coupon/couponEpic';
import wechatPay from './wechatPay/wechatPay';
import * as wechatPayEpic from './wechatPay/wechatPayEpic';
import wechat from './wechat/wechat';
import * as wechatEpic from './wechat/wechatEpic';
import backend from './backend/backend';
import consumptions from './consumptions/consumptions';
import * as consumptionsEpic from './consumptions/consumptionsEpic';
import plan from './plan/plan';
import * as planEpic from './plan/planEpic';
import award from './award/award';
import * as awardEpic from './award/awardEpic';
import award1 from './award1/award'; // 特色活动使用
import * as awardEpic1 from './award1/awardEpic'; // 特色活动使用
import zone from './zone/zone';
import * as zoneEpic from './zone/zoneEpic';
import dataScreen from './dataScreen/dataScreen';
import * as dataScreenEpic from './dataScreen/dataScreenEpic';
import customWineTemplate from './customWineTemplate/customWineTemplate';
import * as customWineTemplateEpic from './customWineTemplate/customWineTemplateEpic';
import whiteOff from './whiteOff/whiteOff';
import * as whiteOffEpic from './whiteOff/whiteOffEpic';





import member from './member/member';
import * as memberEpic from './member/memberEpic';
import membershipSystem from './membershipSystem/membershipSystem';
import * as membershipSystemEpic from './membershipSystem/membershipSystemEpic';
import memberManage from './memberManage/memberManage';
import * as memberManageEpic from './memberManage/memberManageEpic';
import memberTask from './memberTask/memberTask';
import * as memberTaskEpic from './memberTask/memberTaskEpic';

import batchInformation from './batchInformation/batchInformation';
import * as batchInformationEpic from './batchInformation/batchInformationEpic';
import seqnum from './seqnum/seqnum';
import * as seqnumEpic from './seqnum/seqnumEpic';

import accessories from './accessories/accessories';
import * as accessoriesEpic from './accessories/accessoriesEpic';

import links from './links/links';
import * as linksEpic from './links/linksEpic';
import fileds from './fileds/fileds';
import * as filedsEpic from './fileds/filedsEpic';

// 接收人
import receivers from './receivers/receivers'
import * as receiversEpic from './receivers/receiversEpic';

import addressBooks from './addressBooks/addressBooks';
import * as addressBooksEpic from './addressBooks/addressBooksEpic';

import domainConfigs from './domainConfigs/domainConfigs';
import * as domainConfigsEpic from './domainConfigs/domainConfigsEpic';
import miniProgramConfig from './miniProgramConfig/miniProgramConfig';
import * as miniProgramConfigEpic from './miniProgramConfig/miniProgramConfigEpic';
import miniProgramDevelopers from './miniProgramDevelopers/miniProgramDevelopers';
import * as miniProgramDevelopersEpic from './miniProgramDevelopers/miniProgramDevelopersEpic';
import miniProgramMaterials from './miniProgramMaterials/miniProgramMaterials';
import * as miniProgramMaterialsEpic from './miniProgramMaterials/miniProgramMaterialsEpic';
import minProgramTemplate from './minProgramTemplate/minProgramTemplate';
import * as minProgramTemplateEpic from './minProgramTemplate/minProgramTemplateEpic';
import merchant from './merchant/merchant';
import * as merchantEpic from './merchant/merchantEpic';

import miniProgramParameter from './miniProgramParameter/miniProgramParameter';
import * as miniProgramParameterEpic from './miniProgramParameter/miniProgramParameterEpic';



import users from './users/users';
import * as usersEpic from './users/usersEpic'
import factory from './factory/factory';
import * as factoryEpic from './factory/factoryEpic';
import brand from './brand/brand';
import * as brandEpic from './brand/brandEpic';
import batchApply from './batchApply/batchApply';
import * as batchApplyEpic from './batchApply/batchApplyEpic';
import h5CodePackage from './h5CodePackage/h5CodePackage';
import * as h5CodePackageEpic from './h5CodePackage/h5CodePackageEpic';
import reportRebate from './reportRebate/reportRebate';
import * as reportRebateEpic from './reportRebate/reportRebateEpic';
import outReport from './outReport/outReport';
import * as outReportEpic from './outReport/outReportEpic';
import enterpriseinfo from './enterpriseinfo/enterpriseinfo';
import * as enterpriseinfoEpic from './enterpriseinfo/enterpriseinfoEpic';
import datadealers from './datadealers/datadealers';
import * as datadealersEpic from './datadealers/datadealersEpic';

import billInfomation from './billInfomation/billInfomation';
import * as billInfomationEpic from './billInfomation/billInfomationEpic';

import contractInfomation from './contractInfomation/contractInfomation';
import * as contractInfomationEpic from './contractInfomation/contractInfomationEpic';

import activityInfomation from './activityInfomation/activityInfomation';
import * as activityInfomationEpic from './activityInfomation/activityInfomationEpic';

import terminalManagement from './terminalManagement/terminalManagement';
import * as terminalManagementEpic from './terminalManagement/terminalManagementEpic';

// 预警服务
import warnEvents from './warnEvents/warnEvents';
import * as warnEventsEpic from './warnEvents/warnEventsEpic';
import warnRecipients from './warnRecipients/warnRecipients';
import * as warnRecipientsEpic from './warnRecipients/warnRecipientsEpic';
import warnRecords from './warnRecords/warnRecords';
import * as warnRecordsEpic from './warnRecords/warnRecordsEpic';



// 工具箱
import queryTools from './queryTools/queryTools';
import * as queryToolsEpic from './queryTools/queryToolsEpic';
import miniProgramRelease from './miniProgramRelease/miniProgramRelease';
import * as miniProgramReleaseEpic from './miniProgramRelease/miniProgramReleaseEpic';
import miniProgramTesters from './miniProgramTesters/miniProgramTesters';
import * as miniProgramTestersEpic from './miniProgramTesters/miniProgramTestersEpic';

import operationTools from './operationTools/operationTools';
import * as operationToolsEpic from './operationTools/operationToolsEpic';


import codePrintingConfig from './codePrintingConfig/codePrintingConfig';
import * as codePrintingConfigEpic from './codePrintingConfig/codePrintingConfigEpic';

import codeSource from './codeSource/codeSource';
import * as codeSourceEpic from './codeSource/codeSourceEpic';
import thirdPartCodeHandle from './thirdPartCodeHandle/thirdPartCodeHandle';
import * as thirdPartCodeHandleEpic from './thirdPartCodeHandle/thirdPartCodeHandleEpic';

import batchScrap from './batchScrap/batchScrap';
import * as batchScrapEpic from './batchScrap/batchScrapEpic';

import customFields from './customFields/customFields';
import * as customFieldsEpic from './customFields/customFieldsEpic';
import codingRegister from './codingRegister/codingRegister';
import * as codingRegisterEpic from './codingRegister/codingRegisterEpic';
// 组织架构  角色
import role from './role/role';
import * as roleEpic from './role/roleEpic';
import categories from './categories/categories';
import * as categoriesEpic from './categories/categoriesEpic';
import tagbatch from './tagbatch/tagbatch';
import * as tagbatchEpic from './tagbatch/tagbatchEpic';
import departments from './departments/departments';
import * as departmentsEpic from './departments/departmentsEpic';
import dataAllots from './dataAllots/dataAllots';
import * as dataAllotsEpic from './dataAllots/dataAllotsEpic';

// 内部
import codeTempConfig from './codeTempConfig/codeTempConfig';
import * as codeTempConfigEpic from './codeTempConfig/codeTempConfigEpic';
import codedb from './codedb/codedb';
import * as codedbEpic from './codedb/codedbEpic';
//码包上传
import codeUpload from './codeUpload/codeUpload';
import * as codeUploadEpic from './codeUpload/codeUploadEpic';
// 批量报废
import codeScrap from './codeScrap/codeScrap';
import * as codeScrapEpic from './codeScrap/codeScrapEpic';
// 供应商批量报废
import codeSupplierScrap from './codeSupplierScrap/codeSupplierScrap';
import * as codeSupplierScrapEpic from './codeSupplierScrap/codeSupplierScrapEpic';

import codeStatistics from './codeStatistics/codeStatistics';
import * as codeStatisticsEpic from './codeStatistics/codeStatisticsEpic';

// 码标签
import codeLabel from './codeLabel/codeLabel';
import * as codeLabelEpic from './codeLabel/codeLabelEpic';

// 小程序开通工具
import miniProgramEseRelations from './miniProgramEseRelations/miniProgramEseRelations';
import * as miniProgramEseRelationsEpic from './miniProgramEseRelations/miniProgramEseRelationsEpic';
import mpUsers from './mpUsers/mpUsers';
import * as mpUsersEpic from './mpUsers/mpUsersEpic';

import isvUser from './isvUser/isvUser';
import * as isvUserEpic from './isvUser/isvUserEpic';

import sendWay from './sendWay/sendWay';
import * as sendWayEpic from './sendWay/sendWayEpic';

// 企业管理平台-码数据管理
import codeDataManagement from './codeDataManagement/codeDataManagement';
import * as codeDataManagementEpic from './codeDataManagement/codeDataManagementEpic';

// 操作环节
import operationLink from './operationLink/operationLink';
import * as operationLinkEpic from './operationLink/operationLinkEpic';

// 标签草稿
import labelDraft from './labelDraft/labelDraft';
import * as labelDraftEpic from './labelDraft/labelDraftEpic';

// 标签批量查询
import labelBatchQuery from './labelBatchQuery/labelBatchQuery';
import * as labelBatchQueryEpic from './labelBatchQuery/labelBatchQueryEpic';

// 标签审核
import labelVerify from './labelVerify/labelVerify';
import * as labelVerifyEpic from './labelVerify/labelVerifyEpic';

// 标签历史
import labelHistory from './labelHistory/labelHistory';
import * as labelHistoryEpic from './labelHistory/labelHistoryEpic';

// 环节草稿
import linkDraft from './linkDraft/linkDraft';
import * as linkDraftEpic from './linkDraft/linkDraftEpic';

// 码标签-同步标签-推送记录
import pushRecord from './pushRecord/pushRecord';
import * as pushRecordEpic from './pushRecord/pushRecordEpic';

// 环节审核
import linkVerify from './linkVerify/linkVerify';
import * as linkVerifyEpic from './linkVerify/linkVerifyEpic';

// 主数据核对
import masterDataCheck from './masterDataCheck/masterDataCheck';
import * as masterDataCheckEpic from './masterDataCheck/masterDataCheckEpic';

// 环节数据核对-环节汇总
import linkSummary from './linkSummary/linkSummary';
import * as linkSummaryEpic from './linkSummary/linkSummaryEpic';

// 环节数据核对-环节明细
import linkDetails from './linkDetails/linkDetails';
import * as linkDetailsEpic from './linkDetails/linkDetailsEpic';
// 数据字典
import dictionary from './dictionary/dictionary';
import * as dictionaryEpic from './dictionary/dictionaryEpic';

//字段分配
import fieldAssign from './fieldassign/fieldassign';
import * as fieldAssignEpic from './fieldassign/fieldassignEpic';

//经销商分组管理
import dealerGroup from './dealerGroup/dealerGroup';
import * as dealerGroupEpic from './dealerGroup/dealerGroupEpic';

//环节流转
import linkCirculate from './linkCirculate/linkCirculate';
import * as linkCirculateEpic from './linkCirculate/linkCirculateEpic';

//防伪明细
import security from './security/security';
import * as securityEpic from './security/securityEpic';

//异常监控 
import anticounterfeiting from './anticounterfeiting/anticounterfeiting';
import * as anticounterfeitingEpic from './anticounterfeiting/anticounterfeitingEpic';

//订单统计
import order from './order/order';
import * as orderEpic from './order/orderEpic';

//扫码明细
import scancodeDetail from './scancodeDetail/scancodeDetail';
import * as scancodeDetailEpic from './scancodeDetail/scancodeDetailEpic';

//扫码统计
import scancodeStatistics from './scancodeStatistics/scancodeStatistics';
import * as scancodeStatisticsEpic from './scancodeStatistics/scancodeStatisticsEpic';

//窜货明细
import flowDetail from './flowDetail/flowDetail';
import * as flowDetailEpic from './flowDetail/flowDetailEpic';

//码明细
import codeDetail from './codeDetail/codeDetail';
import * as codeDetailEpic from './codeDetail/codeDetailEpic';

//码批量查询
import codeBatchQuery from './codeBatchQuery/codeBatchQuery';
import * as codeBatchQueryEpic from './codeBatchQuery/codeBatchQueryEpic';

//仓库
import warehouse from './warehouse/warehouse';
import * as warehouseEpic from './warehouse/warehouseEpic';
//经销商变更
import dealerChange from './dealerChange/dealerChange';
import * as dealerChangeEpic from './dealerChange/dealerChangeEpic';

//渠道
import channel from './channel/channel';
import * as channelEpic from './channel/channelEpic';

//渠道
import precinct from './precinct/precinct';
import * as precinctEpic from './precinct/precinctEpic';

// 积分商城
import pointMall from './pointMall/pointMall';
import * as pointMallEpic from './pointMall/pointMallEpic'
import pointMallOrder from './pointMallOrder/pointMallOrder';
import * as pointMallOrderEpic from './pointMallOrder/pointMallOrderEpic'
import pointMallPresents from './pointMallPresents/pointMallPresents';
import * as pointMallPresentsEpic from './pointMallPresents/pointMallPresentsEpic';

// 返利
import rebate from './rebate/rebate';
import * as rebateEpic from './rebate/rebateEpic'
// 单据
import certificate from './certificate/certificate';
import * as certificateEpic from './certificate/certificateEpic'
//指标说明
import targetAll from './targetAll/targetAll';
import * as targetAllEpic from './targetAll/targetAllEpic';

// 防黑管理
import antiblack from './antiblack/antiblack';
import * as antiblackEpic from './antiblack/antiblackEpic';
// 消息模板
import messageTemplate from './messageTemplate/messageTemplate'
import * as messageTemplateEpic from './messageTemplate/messageTemplateEpic';

// 消息管理
import newsManage from './newsManage/newsManage'
import * as newsManageEpic from './newsManage/newsManageEpic';

// 订阅消息
import subscriber from './subscriber/subscriber'
import * as subscriberEpic from './subscriber/subscriberEpic';

import writeOffShop from './writeOffShop/writeOffShop'
import * as writeOffShopEpic from './writeOffShop/writeOffShopEpic';

//大屏抽奖
import screenData from './screenData/screenData';
import * as screenDataEpic from './screenData/screenDataEpic';

// 品牌红包配置
import brandRedpackConfig from './brandRedpackConfig/brandRedpackConfig';
import * as brandRedpackConfigEpic from './brandRedpackConfig/brandRedpackConfigEpic';

// 特色活动
import characteristic from './characteristic/characteristic'
import * as characteristicEpic from './characteristic/characteristicEpic';

const myPoolsEpic = { ...poolsEpic }
const myPrizeRegisterEpic = { ...prizeRegisterEpic }
const myPromotionsEpic = { ...promotionsEpic };
const myprizeManagerEpic = { ...prizeManagerEpic }
const myliveManageMentEpic = { ...liveManageMentEpic }
const myProductsEpic = { ...productsEpic };
const myEnterprisesEpic = { ...enterprisesEpic };
const myUploadLibraryEpic = { ...uploadLibraryEpic };

const myTagbatchConfigsEpic = { ...tagbatchConfigsEpic };
const myPrizePointTypesEpic = { ...prizePointTypesEpic };
const myPrizeCardTypesEpic = { ...prizeCardTypesEpic };
const myCouponEpic = { ...couponEpic };
const myConsumptionsEpic = { ...consumptionsEpic };
const myPlanEpic = { ...planEpic };
const myAwardEpic = { ...awardEpic };
const myAwardEpic1 = { ...awardEpic1 };
const myZoneEpic = { ...zoneEpic };
const myDataScreenEpic = { ...dataScreenEpic };
const myCustomWineTemplateEpic = { ...customWineTemplateEpic };


const myWhiteOffEpic = { ...whiteOffEpic };

const myMemberEpic = { ...memberEpic };
const myMembershipSystemEpic = { ...membershipSystemEpic };
const myMemberManageEpic = { ...memberManageEpic };
const myMemberTaskEpic = { ...memberTaskEpic };


const myAddressBooksEpic = { ...addressBooksEpic };
const myWechatEpic = { ...wechatEpic };
const myWechatPayEpic = { ...wechatPayEpic };
const myDomainConfigsEpic = { ...domainConfigsEpic };
const myMiniProgramConfigEpic = { ...miniProgramConfigEpic };
const myMiniProgramDevelopersEpic = { ...miniProgramDevelopersEpic };
const myMiniProgramMaterialsEpic = { ...miniProgramMaterialsEpic };
const myMinProgramTemplateEpic = { ...minProgramTemplateEpic };
const myMiniProgramParameterEpic = { ...miniProgramParameterEpic };

const myMerchantEpic = { ...merchantEpic };

const myUsersEpic = { ...usersEpic };
const myQueryToolsEpic = { ...queryToolsEpic };
const myFactoryEpic = { ...factoryEpic };

const myOperationToolsEpic = { ...operationToolsEpic };
const myBrandEpic = { ...brandEpic };
const myBatchApplyEpic = { ...batchApplyEpic };
const myH5CodePackageEpic = { ...h5CodePackageEpic };
const myReportRebateEpic = { ...reportRebateEpic };
const myOutReportEpic = { ...outReportEpic };
const myBatchInformationEpic = { ...batchInformationEpic };
const mySeqnumEpic = { ...seqnumEpic };
const myAccessoriesEpic = { ...accessoriesEpic };


const myLinksEpic = { ...linksEpic };
const myFiledsEpic = { ...filedsEpic };
const myReceiversEpic = { ...receiversEpic };

const myWarnEventsEpic = { ...warnEventsEpic };
const myWarnRecipientsEpic = { ...warnRecipientsEpic };
const myWarnRecordsEpic = { ...warnRecordsEpic };


const myenterpriseinfoEpic = { ...enterpriseinfoEpic };
const mydatadealersEpic = { ...datadealersEpic };
const myCodePrintingConfigEpic = { ...codePrintingConfigEpic };
const myMiniProgramReleaseEpic = { ...miniProgramReleaseEpic };
const myBatchScrapEpic = { ...batchScrapEpic };
const myCustomFields = { ...customFieldsEpic };
const mycodingRegister = { ...codingRegisterEpic };
const myRoleEpic = { ...roleEpic };
const myCategoriesEpic = { ...categoriesEpic };
const myDataOverviewEpic = { ...dataOverviewEpic };
const myRealTimeEpic = { ...realTimeEpic };
const myminiProgramTestersEpic = { ...miniProgramTestersEpic };
const mytagbatchEpicEpic = { ...tagbatchEpic };
const mydepartmentsEpic = { ...departmentsEpic };
const mydataAllotsEpic = { ...dataAllotsEpic };
const myAmountBillEpic = { ...amountBillEpic };
const myMarketEpic = { ...marketingEpic };
const myReportResourceEpic = { ...reportResourceEpic };
const mycodeTempConfigEpic = { ...codeTempConfigEpic };
const mycodedbEpic = { ...codedbEpic };
const mycodeUploadEpic = { ...codeUploadEpic };
const myCodeScrapEpic = { ...codeScrapEpic };
const myCodeSupplierScrapEpic = { ...codeSupplierScrapEpic };
const myCodeStatisticsEpic = { ...codeStatisticsEpic };
const mycodeLabelEpic = { ...codeLabelEpic };


const myminiProgramEseRelationsEpic = { ...miniProgramEseRelationsEpic };
const mympUsersEpic = { ...mpUsersEpic };
const myCodeSourceEpic = { ...codeSourceEpic };
const myThirdPartCodeHandleEpic = { ...thirdPartCodeHandleEpic };
const myIsvUserEpic = { ...isvUserEpic };
const mySendWayEpic = { ...sendWayEpic };

const mycodeDataManagementEpic = { ...codeDataManagementEpic }; // 企业管理平台-码数据管理
const mylinkSummaryEpic = { ...linkSummaryEpic };
const mymasterDataCheckEpic = { ...masterDataCheckEpic };
const mylinkVerifyEpic = { ...linkVerifyEpic };
const mylinkDraftEpic = { ...linkDraftEpic };
const mylabelHistoryEpic = { ...labelHistoryEpic };
const mylabelVerifyEpic = { ...labelVerifyEpic };
const mylabelDraftEpic = { ...labelDraftEpic };
const mylabelBatchQueryEpic = { ...labelBatchQueryEpic };
const myoperationLinkEpic = { ...operationLinkEpic };
const mylinkDetailsEpic = { ...linkDetailsEpic };
const mypushRecordEpic = { ...pushRecordEpic }; 
const myDictionaryEpic = { ...dictionaryEpic };
const myFieldAssignEpic = { ...fieldAssignEpic };
const myDealerGroupEpic = { ...dealerGroupEpic };
const myLinkCirculate = { ...linkCirculateEpic };
const mySecurity = { ...securityEpic };
const myAnticounterfeiting = { ...anticounterfeitingEpic };
const myOrder = { ...orderEpic };
const myScancodeDetail = { ...scancodeDetailEpic };
const myScancodeStatistics = { ...scancodeStatisticsEpic };
const myFlowDetail = { ...flowDetailEpic };
const myCodeBatchQuery = { ...codeBatchQueryEpic };
const myCodeDetail = { ...codeDetailEpic };
const myWarehouseEpic = { ...warehouseEpic };
const myDealerChangeEpic = { ...dealerChangeEpic };
const myChannelEpic = { ...channelEpic };
const myPrecinctEpic = { ...precinctEpic };
const myBillInfomationEpic = { ...billInfomationEpic };
const myContractInfomationEpic = { ...contractInfomationEpic };
const myActivityInfomationEpic = { ...activityInfomationEpic };

const myTerminalManagementEpic = { ...terminalManagementEpic };

const myPointMall = { ...pointMallEpic };
const myPointMallOrder = { ...pointMallOrderEpic };
const myPointMallPresents = { ...pointMallPresentsEpic };
const myRebate = { ...rebateEpic };
const myCertificate = { ...certificateEpic };
//指标说明
const myTargetAll = { ...targetAllEpic };
// 防黑管理
const myAntiblack = { ...antiblackEpic };
const mySubscriberEpic = { ...subscriberEpic }
const myNewsManageEpic = { ...newsManageEpic }
const myMessageTemplateEpic = { ...messageTemplateEpic }

const myWriteOffShopEpic = { ...writeOffShopEpic }

//大屏抽奖
const myScreenDataEpic = { ...screenDataEpic }

// 品牌红包配置
const mybrandRedpackConfigEpic = { ...brandRedpackConfigEpic };
const myCharacteristicEpic = { ...characteristicEpic };

export const rootEpic = combineEpics(
  ...Object.values(myPromotionsEpic),
  ...Object.values(myPoolsEpic),
  ...Object.values(myPrizeRegisterEpic),
  ...Object.values(myprizeManagerEpic),
  ...Object.values(myliveManageMentEpic),
  ...Object.values(myProductsEpic),
  ...Object.values(myEnterprisesEpic),
  ...Object.values(myUploadLibraryEpic),

  ...Object.values(myTagbatchConfigsEpic),
  ...Object.values(myPrizePointTypesEpic),
  ...Object.values(myPrizeCardTypesEpic),
  ...Object.values(myConsumptionsEpic),
  ...Object.values(myPlanEpic),
  ...Object.values(myAwardEpic),
  ...Object.values(myAwardEpic1),
  ...Object.values(myZoneEpic),
  ...Object.values(myWhiteOffEpic),

  ...Object.values(myDataScreenEpic),
  ...Object.values(myCustomWineTemplateEpic),


  ...Object.values(myMemberEpic),
  ...Object.values(myMembershipSystemEpic),
  ...Object.values(myMemberManageEpic),
  ...Object.values(myMemberTaskEpic),

  ...Object.values(myAddressBooksEpic),
  ...Object.values(myCouponEpic),
  ...Object.values(myWechatPayEpic),
  ...Object.values(myWechatEpic),
  ...Object.values(myDomainConfigsEpic),
  ...Object.values(myMiniProgramConfigEpic),
  ...Object.values(myMiniProgramDevelopersEpic),
  ...Object.values(myMiniProgramMaterialsEpic),
  ...Object.values(myMiniProgramParameterEpic),

  ...Object.values(myUsersEpic),
  ...Object.values(myQueryToolsEpic),
  ...Object.values(myOperationToolsEpic),
  
  ...Object.values(myFactoryEpic),
  ...Object.values(myBrandEpic),
  ...Object.values(myBatchApplyEpic),
  ...Object.values(myH5CodePackageEpic),
  ...Object.values(myReportRebateEpic),
  ...Object.values(myOutReportEpic),
  ...Object.values(myBatchInformationEpic),
  ...Object.values(mySeqnumEpic),
  ...Object.values(myAccessoriesEpic),

  ...Object.values(myenterpriseinfoEpic),
  ...Object.values(mydatadealersEpic),
  ...Object.values(myCodePrintingConfigEpic),
  ...Object.values(myMiniProgramReleaseEpic),
  ...Object.values(myBatchScrapEpic),
  ...Object.values(myCustomFields),
  ...Object.values(mycodingRegister),
  ...Object.values(myRoleEpic),
  ...Object.values(myCategoriesEpic),
  ...Object.values(myDataOverviewEpic),
  ...Object.values(myRealTimeEpic),
  ...Object.values(myminiProgramTestersEpic),
  ...Object.values(mytagbatchEpicEpic),
  ...Object.values(mydepartmentsEpic),
  ...Object.values(mydataAllotsEpic),
  ...Object.values(myAmountBillEpic),
  ...Object.values(myMarketEpic),
  ...Object.values(myReportResourceEpic),
  ...Object.values(mycodeTempConfigEpic),
  ...Object.values(mycodedbEpic),
  ...Object.values(mycodeUploadEpic),
  ...Object.values(myCodeScrapEpic),
  ...Object.values(myCodeSupplierScrapEpic),
  ...Object.values(myCodeStatisticsEpic),
  ...Object.values(mycodeLabelEpic),


  ...Object.values(myminiProgramEseRelationsEpic),
  ...Object.values(myMinProgramTemplateEpic),
  ...Object.values(myMerchantEpic),
  ...Object.values(mympUsersEpic),
  ...Object.values(myLinksEpic),
  ...Object.values(myFiledsEpic),
  ...Object.values(myReceiversEpic),
  ...Object.values(myCodeSourceEpic),
  ...Object.values(myWarnEventsEpic),
  ...Object.values(myWarnRecipientsEpic),
  ...Object.values(myWarnRecordsEpic),
  ...Object.values(myThirdPartCodeHandleEpic),
  ...Object.values(myIsvUserEpic),
  ...Object.values(mySendWayEpic),
  ...Object.values(mycodeDataManagementEpic),
  ...Object.values(mymasterDataCheckEpic),
  ...Object.values(mylinkVerifyEpic),
  ...Object.values(mylinkDraftEpic),
  ...Object.values(mylabelHistoryEpic),
  ...Object.values(mypushRecordEpic),
  ...Object.values(mylabelVerifyEpic),
  ...Object.values(mylabelDraftEpic),
  ...Object.values(mylabelBatchQueryEpic),
  ...Object.values(myoperationLinkEpic),
  ...Object.values(mylinkSummaryEpic),
  ...Object.values(mylinkDetailsEpic),

  ...Object.values(myDictionaryEpic),
  ...Object.values(myFieldAssignEpic),
  ...Object.values(myDealerGroupEpic),
  ...Object.values(myLinkCirculate),
  ...Object.values(mySecurity),
  ...Object.values(myAnticounterfeiting),
  ...Object.values(myOrder),
  ...Object.values(myScancodeDetail),
  ...Object.values(myScancodeStatistics),
  ...Object.values(myFlowDetail),
  ...Object.values(myCodeBatchQuery),
  ...Object.values(myCodeDetail),
  ...Object.values(myWarehouseEpic),
  ...Object.values(myDealerChangeEpic),
  ...Object.values(myChannelEpic),
  ...Object.values(myPrecinctEpic),
  ...Object.values(myBillInfomationEpic),
  ...Object.values(myContractInfomationEpic),
  ...Object.values(myTerminalManagementEpic),
  ...Object.values(myActivityInfomationEpic),
  ...Object.values(myCertificate),

  ...Object.values(myPointMall),
  ...Object.values(myPointMallOrder),
  ...Object.values(myPointMallPresents),
  ...Object.values(myRebate),
  ...Object.values(myTargetAll),




  ...Object.values(myAntiblack),
  ...Object.values(mySubscriberEpic),
  ...Object.values(myMessageTemplateEpic),
  ...Object.values(myNewsManageEpic),

  ...Object.values(myWriteOffShopEpic),

  ...Object.values(myScreenDataEpic),

  ...Object.values(mybrandRedpackConfigEpic),
  ...Object.values(myCharacteristicEpic),

  loadPermissionsEpic,
  loadPermissionsSelectionEpic
);


export const rootReducer = combineReducers({
  form: formReducer,
  promotions,
  products,
  enterprises,  
  uploadLibrary,

  tagbatchConfigs,
  ui,
  pools,
  prizeRegister,
  prizeManager,
  liveManageMent,
  wechat,
  backend,
  prizePointTypes,
  prizeCardTypes,
  coupon,
  wechatPay,
  consumptions,
  plan,
  award,
  award1,
  zone,

  whiteOff,
  dataScreen,
  customWineTemplate,
  member,
  membershipSystem,
  memberManage,
  memberTask,
  addressBooks,
  domainConfigs,
  miniProgramConfig,
  miniProgramDevelopers,
  miniProgramMaterials,
  minProgramTemplate,
  miniProgramParameter,
  merchant,
  users,
  queryTools,
  operationTools,
  factory,
  brand,
  batchApply,
  h5CodePackage,
  reportRebate,
  outReport,
  batchInformation,
  seqnum,
  accessories,
  enterpriseinfo,
  datadealers,
  codePrintingConfig,
  miniProgramRelease,
  batchScrap,
  customFields,
  codingRegister,
  role,
  categories,
  dataOverview,
  realTime,
  miniProgramTesters,
  tagbatch,
  departments,
  dataAllots,
  amountBill,
  marketing,
  reportResource,
  codeTempConfig,
  codedb,
  codeLabel,
  codeUpload,
  codeScrap,
  codeSupplierScrap,
  codeStatistics,
  miniProgramEseRelations,
  mpUsers,
  links,
  fileds,
  receivers,
  codeSource,
  warnEvents,
  warnRecipients,
  warnRecords,
  thirdPartCodeHandle,
  isvUser,
  sendWay,
  codeDataManagement,
  masterDataCheck,
  linkVerify,
  linkDraft,
  pushRecord,
  labelHistory,
  labelVerify,
  labelDraft,
  labelBatchQuery,
  operationLink,
  linkSummary,
  linkDetails,
  dictionary,
  fieldAssign,
  dealerGroup,
  linkCirculate,
  security,
  anticounterfeiting,
  order,
  scancodeDetail,
  scancodeStatistics,
  flowDetail,
  codeBatchQuery,
  codeDetail,
  warehouse,
  dealerChange,
  channel,
  precinct,
  billInfomation,
  contractInfomation,
  activityInfomation,
  terminalManagement,
  pointMall,
  pointMallOrder,
  pointMallPresents,
  rebate,
  certificate,
  targetAll,
  antiblack,
  subscriber,
  newsManage,
  messageTemplate,
  writeOffShop,

  screenData,
  brandRedpackConfig,
  characteristic
});
