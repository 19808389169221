import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './customWineTemplate';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * 
 */
//list
export const loadCustomWinesListEpic = actions$ => actions$.pipe(
	ofType(actions.loadCustomWinesList.REQUEST.toString()),

	mergeMap(action => {


		actionApi()
		const { customWineRoute = '', name = '', productList = '', positions = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
		let url = urls.promotion.customTemplate.customTemplate + (customWineRoute ? ('/' + customWineRoute) : '')
		url = `${url}?keyWords=${name}&positions=${positions}&productIds=${productList}&page=${page}&size=${size}&sort=${sort}`;
		url = url + (customWineRoute == 'loading' ? "&jncCustomTemplatePublishStatus=LOADING" : customWineRoute == 'unLoading' ? "&jncCustomTemplatePublishStatus=UNLOADING" : '')
		return (
			ajax({
				...ajaxBaseConfig,
				url: url,
			}).pipe(
				map(res => actions.loadCustomWinesList.SUCCESS(res.response || [])),
				catchError(error => of(actions.loadCustomWinesList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
			)
		)
	})
);
//create
export const createCustomWineEpic = actions$ => actions$.pipe(
	ofType(actions.createCustomWine.REQUEST.toString()),

	mergeMap(action => {
		actionApi()
		const { data, callback } = action.payload;
		return (

			ajax({
				...ajaxBaseConfig,
				method: 'POST',
				url: `${urls.promotion.customTemplate.customTemplate}`,
				body: action.payload.data,
			}).pipe(
				map(res => {
					callback && callback()
					return actions.createCustomWine.SUCCESS(res)
				}),
				catchError(error => of(actions.createCustomWine.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
			)
		)
	})
);
//edit
export const editCustomWineEpic = actions$ => actions$.pipe(
	ofType(actions.editCustomWine.REQUEST.toString()),

	mergeMap(action => {
		actionApi()
		const { data, callback } = action.payload;
		return (

			ajax({
				...ajaxBaseConfig,
				method: 'PUT',
				url: `${urls.promotion.customTemplate.customTemplate}/update`,
				body: action.payload.data,
			}).pipe(
				map(res => {
					callback && callback()
					return actions.editCustomWine.SUCCESS(res)
				}),
				catchError(error => of(actions.editCustomWine.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
			)
		)
	})
);
//able
export const ableCustomWineEpic = actions$ => actions$.pipe(
	ofType(actions.ableCustomWine.REQUEST.toString()),

	mergeMap(action => {
		actionApi()
		const { data, callback } = action.payload;
		return (

			ajax({
				...ajaxBaseConfig,
				method: 'PATCH',
				url: `${urls.promotion.customTemplate.customTemplate}/${data.id}/${data.status}`,
				//  body: action.payload.data,
			}).pipe(
				map(res => {
					callback && callback()
					return actions.ableCustomWine.SUCCESS(res)
				}),
				catchError(error => of(actions.ableCustomWine.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
			)
		)
	})
);
//del
export const delCustomWineEpic = actions$ => actions$.pipe(
	ofType(actions.delCustomWine.REQUEST.toString()),

	mergeMap(action => {
		actionApi()
		const { data, callback } = action.payload;
		return (

			ajax({
				...ajaxBaseConfig,
				method: 'DELETE',
				url: `${urls.promotion.customTemplate.customTemplate}/${data.id}`,
				//  body: action.payload.data,
			}).pipe(
				map(res => {
					callback && callback()
					return actions.delCustomWine.SUCCESS(res)
				}),
				catchError(error => of(actions.delCustomWine.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
			)
		)
	})
);
//publish
export const publishCustomWineEpic = actions$ => actions$.pipe(
	ofType(actions.publishCustomWine.REQUEST.toString()),

	mergeMap(action => {
		actionApi()
		const { data, callback } = action.payload;
		return (

			ajax({
				...ajaxBaseConfig,
				method: 'PATCH',
				url: `${urls.promotion.customTemplate.customTemplate}/${data.id}/publish`,
				body: action.payload.data,
			}).pipe(
				map(res => {
					callback && callback()
					return actions.publishCustomWine.SUCCESS(res)
				}),
				catchError(error => of(actions.publishCustomWine.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
			)
		)
	})
);

//历史
export const loadCustomWineHistoriesByIdEpic = (actions$, store) => actions$.pipe(
	ofType(actions.loadCustomWineHistoriesById.REQUEST.toString()),
	mergeMap(action => {
		actionApi()
		const { keywords, customTemplateId, page = 0, size = 10, sort } = action.payload;
		return ajax({
			...ajaxBaseConfig,
			url: `${urls.promotion.customTemplate.customTemplate}/getHistory?keywords=${keywords}&customTemplateId=${customTemplateId}&page=${page}&size=${size}&sort=${sort}`
		}).pipe(
			map(res => actions.loadCustomWineHistoriesById.SUCCESS(res.response)),
			catchError(error => of(actions.loadCustomWineHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	})
);


//下拉列表
export const loadCustomWinesSelectionsEpic = actions$ => actions$.pipe(
	ofType(actions.loadCustomWinesSelections.REQUEST.toString()),
	mergeMap(action => {
		actionApi()
		return (
			ajax({
				...ajaxBaseConfig,
				url: action.payload && action.payload.status ? `${urls.promotion.customTemplate.selections}?status=${action.payload.status}` : `${urls.promotion.customTemplate.selections}`,
			}).pipe(
				map(res => actions.loadCustomWinesSelections.SUCCESS(res.response.content || [])),
				catchError(error => of(actions.loadCustomWinesSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
			)
		)
	})
);












// 根据id查询企业的模板
export const customWineByIdEpic = actions$ => actions$.pipe(
	ofType(actions.customWineById.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { id, callback } = action.payload;
		return ajax({
			...apiBaseConfig,
			url: `${urls.promotion.customTemplate.customTemplate}/findOne/${id}`,
		}).pipe(
			map(res => {
				callback && callback(res.response)
				return actions.customWineById.SUCCESS(res.response)
			}),
			catchError(error => of(actions.customWineById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);

// 根据id查询企业的模板历史
export const customWineHistoryByIdEpic = actions$ => actions$.pipe(
	ofType(actions.customWineHistoryById.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { id } = action.payload;
		return ajax({
			...apiBaseConfig,
			url: `${urls.promotion.customTemplate.customTemplate}/findHistoryOne/${id}`,
		}).pipe(
			map(res => {
				return actions.customWineHistoryById.SUCCESS(res.response)
			}),
			catchError(error => of(actions.customWineHistoryById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);

// 查字体包
export const fontFamilyEpic = actions$ => actions$.pipe(
	ofType(actions.fontFamily.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { url } = action.payload || {};
		return ajax({
			// ...apiBaseConfig,
			url: `${url}`,
		}).pipe(
			map(res => actions.fontFamily.SUCCESS(res.response)),
			catchError(error => of(actions.fontFamily.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);



// 更新体包
export const fontFamilyModifyEpic = actions$ => actions$.pipe(
	ofType(actions.fontFamilyModify.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { data } = action.payload;
		var blob = new Blob([JSON.stringify(data)], { type: "application/json;charset=utf-8" }); // 把数据转化成blob对象
		let file = new File([blob], "fontFamily.json", { type: 'application/json', lastModified: Date.now() }); // 
		// let file = new File([JSON.stringify(data)], 'filename.json', { type: 'application/json', lastModified: Date.now() })
		console.log(file)
		let formData = new FormData();
		formData.append('file', file)
		formData.append('uuid', 'fontFamily')
		return ajax({
			...apiBaseConfig,
			url: urls.data.upload.upload('PUBLIC'),
			body: formData,
			method: 'POST'
		}).pipe(
			map(res => actions.fontFamilyModify.SUCCESS(data)),
			catchError(error => of(actions.fontFamilyModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);


// 本地上传文件（字体包/json文件）
export const templateJsonModifyEpic = actions$ => actions$.pipe(
	ofType(actions.templateJsonModify.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { data, type } = action.payload;
		var blob = new Blob([JSON.stringify(data)], { type: "application/json;charset=utf-8" }); // 把数据转化成blob对象
		let file = new File([blob], "wineData.json", { type: 'application/json', lastModified: Date.now() }); // 
		// let file = new File([JSON.stringify(data)], 'filename.json', { type: 'application/json', lastModified: Date.now() })
		console.log(file)
		let formData = new FormData();
		formData.append('file', file)

		return ajax({
			...apiBaseConfig,
			url: urls.data.upload.upload('PUBLIC'),
			body: formData,
			method: 'POST'
		}).pipe(
			map(res => actions.templateJsonModify.SUCCESS({ ...res.response, type })),
			catchError(error => of(actions.templateJsonModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);






// 添加/修改 企业模板json文件
export const customWineModifyEpic = actions$ => actions$.pipe(
	ofType(actions.customWineModify.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { id, type } = action.payload;
		let url = `${urls.promotion.customTemplate.customTemplate}`;
		if (id) {
			url += '/update'
		}

		return ajax({
			...ajaxBaseConfig,
			url,
			body: action.payload,
			method: id ? 'PUT' : 'POST'
		}).pipe(
			map(res => actions.customWineModify.SUCCESS({ ...res.response, type })),
			catchError(error => of(actions.customWineModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);


// 设置最新版本
export const customWineSetLatestEpic = actions$ => actions$.pipe(
	ofType(actions.customWineSetLatest.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { id } = action.payload;
		return ajax({
			...ajaxBaseConfig,
			url: `${urls.promotion.customTemplate.customTemplate}/setLatest/${id}`,
			body: action.payload,
			method: 'PUT'
		}).pipe(
			map(res => actions.customWineSetLatest.SUCCESS(res.status)),
			catchError(error => of(actions.customWineSetLatest.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);



// 获取模板json文件
export const getWineDataEpic = actions$ => actions$.pipe(
	ofType(actions.getWineData.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		return ajax({
			// ...apiBaseConfig,
			url: action.payload + `?v=${+new Date()}`,
			// body: action.payload,
			// method: 'PUT'
		}).pipe(
			map(res => actions.getWineData.SUCCESS(res.response)),
			catchError(error => of(actions.getWineData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);





export const getRetentionEpic = actions$ => actions$.pipe(
	ofType(actions.getRetention.REQUEST.toString()),
	mergeMap(action => {
		actionApi();

		const { page = 0, size = 20, sort = 'id,desc', jncType = 'CUSTOMIZED_WINE_RETENTION', status = '', customProduct = '', applyStart = '', applyEnd = '',provinceCode='',cityCode='' ,areaCode=''} = action.payload;
		return ajax({
			...ajaxBaseConfig,
			url: `${urls.promotion.customTemplate.user}/query?status=${status}&customProduct=${customProduct}&createdTime=${applyStart && applyEnd ? applyStart + ',' + applyEnd : ''}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaCode=${areaCode}&jncType=${jncType}&page=${page}&size=${size}&sort=${sort}`,
			// body: action.payload,
			// method: 'PUT'
		}).pipe(
			map(res => actions.getRetention.SUCCESS(res.response)),
			catchError(error => of(actions.getRetention.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);

export const patchRetentionEpic = actions$ => actions$.pipe(
	ofType(actions.patchRetention.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { data, callback } = action.payload;
		console.log(data)
		return ajax({
			...ajaxBaseConfig,
			method: 'PATCH',
			url: `${urls.promotion.customTemplate.user}/update/status?status=${data.status}&opinions=${data.opinions}`,
			body: data.ids,
		}).pipe(
			map(res => {
				callback && callback(res.response)
				return actions.patchRetention.SUCCESS(res.response)
			}),
			catchError(error => of(actions.patchRetention.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);

export const exportRetentionEpic = actions$ => actions$.pipe(
	ofType(actions.exportRetention.REQUEST.toString()),
	mergeMap(action => {
		actionApi();
		const { page = 0, size = 20, sort = 'id,desc', jncType = 'CUSTOMIZED_WINE_RETENTION', status = '', customProduct = '', applyStart = '', applyEnd = '',provinceCode='',cityCode='' ,areaCode='',callback } = action.payload;
		return ajax({
			...ajaxBaseConfig,
			url: `${urls.promotion.customTemplate.user}/export?status=${status}&customProduct=${customProduct}&createdTime=${applyStart && applyEnd ? applyStart + ',' + applyEnd : ''}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaCode=${areaCode}&jncType=${jncType}&sort=${sort}`,
			// method: 'POST',
		}).pipe(
			map(res => {
				callback && callback(res.response)
				return actions.exportRetention.SUCCESS(res.response)
			}),
			catchError(error => of(actions.exportRetention.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
		)
	}
	)
);


