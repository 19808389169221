import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'zone';

export const getZone = fasterActions(moduleName, 'getZone');


const initialPoolState = {
    zoneList: [],
    zoneListTwoTree:[],
    zoneListThreeTree:[],
}

// Reducer
const reducer = handleActions(
    {
        [getZone.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
        [getZone.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, zoneList: payload.list, zoneListTwoTree: payload.list2,zoneListThreeTree: payload.list3}),
        [getZone.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
    },
    initialPoolState
)
export default reducer;