import React from 'react';
// import { hot } from 'react-hot-loader/root'
import { hot,setConfig  } from 'react-hot-loader';
import MainLayout from './Layout';
import { Provider } from 'react-redux';

setConfig({
  showReactDomPatchNotification: false
  })
  
// @inject('dataStore')
// @observer
class App extends React.Component {

  render() {
    const { store } = this.props;
    return (
      <Provider store={store}>
        <div className="App">
            <MainLayout></MainLayout>
        </div>
      </Provider>
    )
  }
}

export default hot(module)(App);
// {process.env.NODE_ENV === 'development'
// ? <DevTools store={store} />
// : null}