import React from "react";

const SvgArrowLeft = props => (
  <svg
    className="arrow_left_svg__icon"
    viewBox="0 0 1024 1024"
    width={200}
    height={200}
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path
      d="M635.733 490.667L810.667 665.6l-64 59.733L512 490.667 746.667 256l64 59.733-174.934 174.934zm-260.266 0L550.4 665.6l-59.733 59.733L256 490.667 490.667 256l59.733 59.733-174.933 174.934z"
      fill="#BBB"
    />
  </svg>
);

export default SvgArrowLeft;
