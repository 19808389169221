import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'enterprises';

/**
 * TODO: 企业管理
 */
export const loadEnterprisesList = fasterActions(moduleName, 'loadEnterprisesList');
export const loadEnterprisesSelections = fasterActions(moduleName, 'loadEnterprisesSelections');
export const createEnterprise = fasterActions(moduleName, 'createEnterprise');
export const editEnterprise = fasterActions(moduleName, 'editEnterprise');
export const ableEnterprise = fasterActions(moduleName, 'ableEnterprise');

const initialState = {

  enterprisesList: [],
  loadEnterprisesListLoading: false,
  loadEnterprisesListLoaded: false,

  enterprisesSelectionsList: [],

}

const reducer = handleActions(
  {

    //企业列表
    [loadEnterprisesList.REQUEST]: (state, { payload }) => ({ ...state, loadEnterprisesListLoading: true }),
    [loadEnterprisesList.SUCCESS]: (state, { payload }) => ({ ...state, loadEnterprisesListLoading: false, loadEnterprisesListLoaded: true, enterprisesList: payload }),
    [loadEnterprisesList.FAIL]: (state, { payload }) => ({ ...state, loadEnterprisesListLoading: false, loadEnterprisesListLoaded: false, error: payload }),

    // 企业select
    [loadEnterprisesSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadEnterprisesSelections.SUCCESS]: (state, { payload }) => {
      const selector = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ value: elem.id, text: elem.name, id: elem.id });
        }
      }
      return ({ ...state, enterprisesSelectionsList: selector });
    },
    [loadEnterprisesSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createEnterprise.REQUEST]: (state, { payload }) => ({ ...state }),
    [createEnterprise.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createEnterprise.FAIL]: (state, { payload }) => ({ ...state }),
    [editEnterprise.REQUEST]: (state, { payload }) => ({ ...state }),
    [editEnterprise.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editEnterprise.FAIL]: (state, { payload }) => ({ ...state }),
    [ableEnterprise.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableEnterprise.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableEnterprise.FAIL]: (state, { payload }) => ({ ...state }),
  },
  initialState
);

export default reducer;
