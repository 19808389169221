import { of } from "rxjs"
import { ofType } from "redux-observable"
import { mergeMap, map, catchError } from "rxjs/operators"
import { ajax } from "rxjs/ajax"
import urls from "../../../api/urls"
import * as uiActions from '../ui';
import queryString from 'query-string';
import { trims } from "@/utils/commonUtils.js"
import * as actions from "./pointMall"
import { ajaxBaseConfig, apiBaseConfig, actionApi } from "../../utils";


//新增商城
export const createMallEpic = actions$ => actions$.pipe(
  ofType(actions.createMall.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { history, sendData, location, isGoNext } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}`,
      method: 'POST',
      body: trims(sendData)
    }).pipe(
      mergeMap(res => [() => {
        const search = location.search;
        const s = search.substring(1, search.length);
        const parsed = queryString.parse(s);
        parsed.step = 1;
        parsed.mallId = res.response.id;
        parsed.mallType = '';//修改过就为空
        parsed.action = 'edit';

        const path = location.pathname;
        let pushUrl;
        if (isGoNext) {
          pushUrl = `${path}?${queryString.stringify(parsed)}`;
          history.replace(pushUrl);
        } else {
          // 第一步保存商城
          pushUrl = '/pointsmalldraft';
          history.replace(pushUrl);
        }

      }
        , actions.createMall.SUCCESS(res.response)
        , actions.loadById.REQUEST(res.response.id)
      ]),
      catchError(error => of(actions.createMall.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
//修改商城
export const editMallEpic = actions$ => actions$.pipe(
  ofType(actions.editMall.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const { history, sendData, location, isGoNext } = data;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}/${sendData.id}`,
      method: 'PUT',
      body: trims(sendData)
    }).pipe(
      mergeMap(res => [() => {
        const search = location.search;
        const s = search.substring(1, search.length);
        const parsed = queryString.parse(s);
        parsed.step = 1;
        parsed.mallType = '';//修改过就为空
        parsed.action = 'edit';
        parsed.mallId = res.response.id;
        const path = location.pathname;
        let pushUrl;
        console.log('isGoNext>>>', isGoNext)
        if (isGoNext === 'return') { 
          callback && callback()
        } else if (isGoNext) {
          pushUrl = `${path}?${queryString.stringify(parsed)}`;
          history.replace(pushUrl);
        } else {
          // 第一步保存商城
          pushUrl = '/pointsmalldraft';
          history.replace(pushUrl);
        }

      }, actions.editMall.SUCCESS(res.response), actions.loadById.REQUEST(res.response.id)]),
      catchError(error => of(actions.editMall.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//按id查询
export const loadByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}/${action.payload.id || action.payload} ${action.payload.type ? '' : '/unpublished'}`

    }).pipe(
      map(res => {
        callback && callback(res.response);

        return actions.loadById.SUCCESS(res.response);
      }),

      catchError(error => of(actions.loadById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//分页
export const loadListByTypeEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { isVaild = true, mallType, keywords = '', pointType = '', mallStatus = '', page = 0, size = 20, sort = '' } = action.payload;
    const url = isVaild ? urls.pointMall.pointMall.pointMall : urls.pointMall.pointMall.pointMall + '/unpublished'
    return ajax({
      ...ajaxBaseConfig,
      url: `${url}?mallType=${mallType}&idOrName=${keywords}&pointType=${pointType}&mallStatus=${mallStatus}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// 删除商城
export const deleteMallByIdEpic = (actions$, store) => actions$.pipe(
  ofType(actions.deleteMallById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.pointMall.pointMall.pointMall}/${action.payload.id}`
    }).pipe(
      mergeMap(res => {
        callback && callback(res.response);
        if (callback) {
          return [actions.deleteMallById.SUCCESS(res.response)];
        }
        else {
          return [actions.deleteMallById.SUCCESS(res.response), actions.loadList.REQUEST(store.value.pointMall.loadMallParams), actions.draftCount.REQUEST()];
        }

      }),
      catchError(error => of(actions.deleteMallById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//

export const checknextEpic = (actions$, store) => actions$.pipe(
  ofType(actions.checknext.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { appId, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.check}?appId=${appId}`
    }).pipe(
      mergeMap(res => {
        callback && callback(res.response);
        return [actions.checknext.SUCCESS(res.response)];
      }),
      catchError(error => of(actions.checknext.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//草稿数量
export const draftCountEpic = actions$ => actions$.pipe(
  ofType(actions.draftCount.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}/count`,
    }).pipe(
      map(res => actions.draftCount.SUCCESS(res.response)),
      catchError(error => of(actions.draftCount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
//第三步更新
export const editMallSettingsEpic = actions$ => actions$.pipe(
  ofType(actions.editMallSettings.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}/${data.id}/security-settings`,
      method: 'put',
      body: data,
    }).pipe(
      map(res => {
        callback && callback(res.response.content || []);

        return actions.editMallSettings.SUCCESS(res.response.content);
      }),
      catchError(error => of(actions.editMallSettings.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


//发布
export const publishPointMallEpic = (actions$, store) => actions$.pipe(
  ofType(actions.publishPointMall.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { remark, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.pointMall.pointMall.pointMall}/${action.payload.id || action.payload}/publish?remark=${remark}`,
    }).pipe(
      mergeMap(res => {
        callback && callback(res.response);
        return [actions.publishPointMall.SUCCESS(res.response), uiActions.notification('操作成功', 'success'),
        uiActions.updatePublishDialogStatus(false), actions.draftCount.REQUEST()]
      }),
      catchError(error => of(actions.publishPointMall.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

//是否有草稿


export const hasDraftEpic = actions$ => actions$.pipe(
  ofType(actions.hasDraft.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}/${action.payload.id || action.payload}/hasDraft`

    }).pipe(
      map(res => {
        callback && callback(res.response);

        return actions.hasDraft.SUCCESS(res.response);
      }),

      catchError(error => of(actions.hasDraft.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


//启用禁用
export const mallResumeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.mallResume.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id, type } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.pointMall.pointMall.pointMall}/${id}/${type}`
    }).pipe(
      map(res => {
        callback && callback(res.response);

        return actions.mallResume.SUCCESS(res.response);
      }),
      catchError(error => of(actions.mallResume.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
// 商城列表(不分页)
export const loadPointMallSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadPointMallSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return (
      ajax({
        ...apiBaseConfig,
        url: `${urls.pointMall.pointMall.pointMall}/all`,
      }).pipe(
        map(res => actions.loadPointMallSelections.SUCCESS(res.response)),
        catchError(error => of(actions.loadPointMallSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//历史


export const loadPMallHistoriesByIdEpic = (actions$, store) => actions$.pipe(
  ofType(actions.loadMallHistoriesById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { keywords, mallId, page = 0, size = 10, sort } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}/${mallId}/versions?publisher=${keywords}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadMallHistoriesById.SUCCESS(res.response)),
      catchError(error => of(actions.loadMallHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

//获取策略
export const getPresentRulesEpic = (actions$, store) => actions$.pipe(
  ofType(actions.getPresentRules.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'GET',
      url: `${urls.pointMall.rule.rule}/${id}`,
    }).pipe(
      mergeMap(res => {
        callback && callback(res.response);
        return [actions.getPresentRules.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getPresentRules.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



//新建编辑策略
export const createOrEditPresentRulesEpic = (actions$, store) => actions$.pipe(
  ofType(actions.createOrEditPresentRules.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { type, data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: type,
      body: data,
      url: `${urls.pointMall.rule.rule}`,
    }).pipe(
      mergeMap(res => {
        callback && callback(res.response);
        return [actions.createOrEditPresentRules.SUCCESS(res.response)]
      }),
       catchError(error => of(actions.createOrEditPresentRules.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


//历史
export const getHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.getHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { mallId, historyId, step } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.pointMall.pointMall.pointMall}/${mallId}/version/${historyId}`
    }).pipe(
      map(res => {
        return actions.getHistory.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);