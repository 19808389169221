import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'prizePointTypes';

/**
 * TODO: 积分类型
 */
export const loadPrizePointTypes = fasterActions(moduleName, 'loadPrizePointTypes');
export const loadPointTypeDetail = fasterActions(moduleName, 'loadPointTypeDetail');
export const pointTypeCreate = fasterActions(moduleName, 'pointTypeCreate');
export const pointTypeEdit = fasterActions(moduleName, 'pointTypeEdit');
export const pointTypeDialogVisibale = createAction(`${moduleName}/pointTypeDialogVisibale`);
export const resetSelectorConversionRatio = createAction(`${moduleName}/resetSelectorConversionRatio`);


const initialState = {
  prizePointTypesLoaded: false,
  pointTypeDetailObj: {},
  pointTypeId: '',
  isPointTypeDialogVisibale: false,
  selectorConversionRatio: 0
}

const reducer = handleActions(
  {
    /**
     * TODO: 积分类型 
     */
    // 下拉列表
    [loadPrizePointTypes.SUCCESS]: (state, { payload }) => {
      const selector = [{ value: '', text: '请选择积分类型' }];
      const selector2 = [];
      for (let elem of payload.values()) {
        selector.push({ value: elem.id, text: elem.name, conversionRatio: elem.conversionRatio || 0 })
        selector2.push({ value: elem.id, text: elem.name, conversionRatio: elem.conversionRatio || 0 })
      }
      return {
        ...state, selector, selector2, prizePointTypesLoaded: true, prizePointTypes: payload
      }
    },
    [loadPrizePointTypes.FAIL]: (state, { payload }) => ({
      ...state, prizePointTypesLoaded: false, prizePointTypes: []
    }),
    // 详情
    [loadPointTypeDetail.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadPointTypeDetail.SUCCESS]: (state, { payload }) => ({ ...state, pointTypeDetailObj: payload }),
    [loadPointTypeDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 创建
    [pointTypeCreate.REQUEST]: (state, { payload }) => ({ ...state }),
    [pointTypeCreate.SUCCESS]: (state, { payload }) => ({ ...state, isPointTypeDialogVisibale: false, pointTypeDetailObj: {} }),
    [pointTypeCreate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 编辑
    [pointTypeEdit.REQUEST]: (state, { payload }) => ({ ...state }),
    [pointTypeEdit.SUCCESS]: (state, { payload }) => ({ ...state, isPointTypeDialogVisibale: false, selectorConversionRatio: payload.conversionRatio, pointTypeDetailObj: {} }),
    [pointTypeEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 弹窗
    [pointTypeDialogVisibale]: (state, { payload }) => ({ ...state, ...payload }),
    [resetSelectorConversionRatio]: (state, { payload }) => ({ ...state, selectorConversionRatio: 0 }),
  },
  initialState
);

export default reducer;
