import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'dataOverview';

export const loadStatistics = fasterActions(moduleName, 'statistics');//
export const loadParticipant = fasterActions(moduleName, 'loadParticipant');   //参与用户
export const loadBonusTrend = fasterActions(moduleName, 'loadBonusTrend');   //兑换次数/金额趋势
export const loadScanWinTrend = fasterActions(moduleName, 'loadScanWinTrend');   //扫码/中奖趋势
export const loadActiveCity = fasterActions(moduleName, 'loadActiveCity');   //活跃城市
export const loadActiveCR = fasterActions(moduleName, 'loadActiveCR');   //CR转换
export const loadAmountTrend = fasterActions(moduleName, 'loadAmountTrend');   //微信红包金额趋势
export const loadPayingTrend = fasterActions(moduleName, 'loadPayingTrend');   //微信红包发放趋势
export const loadWinRatio = fasterActions(moduleName, 'loadWinRatio');   //奖品中奖占比

const initialState = {
    loading: false,
    loaded: false,

    sLoading: false,
    statistics: [],

    pLoading: false,
    participant: [],//参与用户

    bonusTrend: [],
    bLoading: false,//兑奖次数/金额趋势

    scanWinTrend: [],
    swtLoading: false,//扫码/中奖趋势

    activeCity: [],
    aLoading: false,//活跃城市

    activeCR: {
        cashNum: 0,
        firstRate: 0,
        lotteryNum: 0,
        scanNum: 0,
        secondRate: 0,
    },
    acLoading: false,//活动转化率

    amountTrend: [],
    atLoading: false,//微信红包金额趋势

    payingTrend: [],//微信红包发放趋势
    ptLoading: false,

    winRatio: [],//奖品中奖
    allCount: 0,//总数
    wrLoading: false,
};

const keyMap = {
    participate_user: {
        "codeType": "participate_user",
        "codeName": "参与用户",
        "details": []
    },
    scan_activity: {
        "codeType": "scan_activity",
        "codeName": "扫码活动",
        "details": []
    },
    barcode: {
        "codeType": "barcode",
        "codeName": "二维码",
        "details": []
    },
    payment_amount: {
        "codeType": "payment_amount",
        "codeName": "发放金额",
        "details": []
    }
};

const reducer = handleActions({
    [loadStatistics.REQUEST]: (state, { payload }) => ({ ...state,  sLoading: true }),
    [loadStatistics.SUCCESS]: (state, { payload }) => {
        let  { content = [] } = payload;
        let tmpKey = keyMap;
        // 处理原因：后端可能少数组，不确定是某那个内容
        content.map(_ => {
            if (_.codeType === 'barcode') {
                delete tmpKey.barcode;
            }
            if (_.codeType === 'scan_activity') {
                delete tmpKey.scan_activity;
            }
            if (_.codeType === 'participate_user') {
                delete tmpKey.participate_user;
            }
            if (_.codeType === 'payment_amount') {
                delete tmpKey.payment_amount;
            }
        });
        for (let key in tmpKey) {
            content.push(tmpKey[key]);
        }
        let code = 0;
        let activity = 1;
        let user = 2;
        let amount = 3;
        //排序
        content.map((_, k) => {
            if (_.codeType === 'barcode') {
                code = k;
            }
            if (_.codeType === 'scan_activity') {
                activity = k;
            }
            if (_.codeType === 'participate_user') {
                user = k;
            }
            if (_.codeType === 'payment_amount') {
                amount = k;
            }
        });
        const sort = [content[code], content[activity], content[user], content[amount]];
        //处理字段
        let statistics = sort.map(item => {
            let { details = [], codeName = '', codeType = '' } = item;
            if (Object.prototype.toString.call(details) === "[object Null]")  {
                details = [];
            }
            let totalName = '';
            let total = 0;
            let totalType = '';
            details = details.filter(child => {
               let { item_Type_Code = '', count = 0, item_type_Name = '' } = child;
               if (item_Type_Code === 'st_barcode_sent_count' || item_Type_Code === 'st_all_activity_num' || item_Type_Code === 'all_user_count' || item_Type_Code === 'st_all_amount') {
                   totalName = item_type_Name;
                   totalType = item_Type_Code;
                   total = count;
                   return false;
               } else {
                   return true;
               }

            });
            let obj = {
                codeName,
                codeType,
                totalName,
                totalType,
                total,
                details,
            };
            return obj;
        });
        return {...state, sLoading: false, statistics};
    },
    [loadStatistics.FAIL]: (state, { payload }) => ({ ...state, sLoading: false, statistics: [], error: payload }),
    [loadParticipant.REQUEST]: (state, { payload }) => ({ ...state,  pLoading: true }),
    [loadParticipant.SUCCESS]: (state, { payload }) => ({ ...state, pLoading: false, participant: payload.content }),
    [loadParticipant.FAIL]: (state, { payload }) => ({ ...state, pLoading: false, participant: [], error: payload }),
    [loadBonusTrend.REQUEST]: (state, { payload }) => ({ ...state,  bLoading: true }),
    [loadBonusTrend.SUCCESS]: (state, { payload }) => ({ ...state, bLoading: false, bonusTrend: payload.content }),
    [loadBonusTrend.FAIL]: (state, { payload }) => ({ ...state, bLoading: false, bonusTrend: [], error: payload }),
    [loadScanWinTrend.REQUEST]: (state, { payload }) => ({ ...state,  swtLoading: true }),
    [loadScanWinTrend.SUCCESS]: (state, { payload }) => ({ ...state, swtLoading: false, scanWinTrend: payload.content }),
    [loadScanWinTrend.FAIL]: (state, { payload }) => ({ ...state, swtLoading: false, scanWinTrend: [], error: payload }),
    [loadActiveCity.REQUEST]: (state, { payload }) => ({ ...state,  aLoading: true }),
    [loadActiveCity.SUCCESS]: (state, { payload }) => ({ ...state, aLoading: false, activeCity: payload.content }),
    [loadActiveCity.FAIL]: (state, { payload }) => ({ ...state, aLoading: false, activeCity: [], error: payload }),
    [loadActiveCR.REQUEST]: (state, { payload }) => ({ ...state,  acLoading: true }),
    [loadActiveCR.SUCCESS]: (state, { payload }) => {
        const { content } = payload;
        let res = {
            cashNum: 0,
            firstRate: 0,
            lotteryNum: 0,
            scanNum: 0,
            secondRate: 0,
        };
        if ("[object Array]" === Object.prototype.toString.call(content)) {
            if ( 0 !== content.length) {
                let { cashNum = 0, firstRate, lotteryNum, scanNum, secondRate } = content[0];
                res = {
                    cashNum: cashNum == null?0:cashNum,
                    firstRate: firstRate == null?0:firstRate,
                    lotteryNum: lotteryNum == null?0:lotteryNum,
                    scanNum: scanNum == null?0:scanNum,
                    secondRate: secondRate == null?0:secondRate,
                }
            }
        }
        return { ...state, acLoading: false, activeCR: res };
    },
    [loadActiveCR.FAIL]: (state, { payload }) => ({ ...state, acLoading: false, error: payload }),
    [loadAmountTrend.REQUEST]: (state, { payload }) => ({ ...state,  atLoading: true }),
    [loadAmountTrend.SUCCESS]: (state, { payload }) => ({ ...state, atLoading: false, amountTrend: payload.content }),
    [loadAmountTrend.FAIL]: (state, { payload }) => ({ ...state, atLoading: false, amountTrend: [], error: payload }),
    [loadPayingTrend.REQUEST]: (state, { payload }) => ({ ...state,  ptLoading: true }),
    [loadPayingTrend.SUCCESS]: (state, { payload }) => ({ ...state, ptLoading: false, payingTrend: payload.content }),
    [loadPayingTrend.FAIL]: (state, { payload }) => ({ ...state, ptLoading: false, payingTrend: [], error: payload }),
    [loadWinRatio.REQUEST]: (state, { payload }) => ({ ...state,  wrLoading: true }),
    [loadWinRatio.SUCCESS]: (state, { payload }) => {
        let { allCount = 0, data = [] } = payload;
        if (Object.prototype.toString.call(allCount) === "[object Null]")  {
            allCount = 0;
        }
        const winRatio = data.map(item => ({
            name: item.name,
            value: item.prizeNum,
            ratio: item.ratio,
        }));
        return { ...state, wrLoading: false, allCount: allCount , winRatio: winRatio }
    },
    [loadWinRatio.FAIL]: (state, { payload }) => ({ ...state, wrLoading: false, allCount: 0, winRatio: [], error: payload }),
}, initialState);

export default reducer;


