import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import { createLogger } from 'redux-logger';
// import rootReducer from '../reducers';
import { createEpicMiddleware } from 'redux-observable';
// import { rootEpic } from './epics';
import { rootEpic, rootReducer } from '../modules/root';

const epicMiddleware = createEpicMiddleware();

const devCompose = window.__REDUX_DEVTOOLS_EXTENSION__ ? compose(
  // applyMiddleware(thunk, createLogger(), epicMiddleware),
  applyMiddleware(thunk, epicMiddleware),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
) : compose(
  applyMiddleware(thunk, epicMiddleware),
);

const prodCompose = compose(
  // applyMiddleware(thunk, createLogger(), epicMiddleware),
  applyMiddleware(thunk, epicMiddleware)
);

const myCompose = process.env.NODE_ENV === 'development' ? devCompose : prodCompose;

const configureStore = initialState => {
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(applyMiddleware(thunk, createLogger(), epicMiddleware)));
  const store = createStore(
    rootReducer,
    initialState,
    myCompose,
  )
  epicMiddleware.run(rootEpic);

  if (module.hot) {
    module.hot.accept('../modules/root.js', () => {
      store.replaceReducer(rootReducer);
    });
  }

  return store;
}

export default configureStore;
