import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Button } from '$bere'

function inject_unount(target) {
  // 改装componentWillUnmount，销毁的时候记录一下
  let next = target.prototype.componentWillUnmount
  target.prototype.componentWillUnmount = function () {
    if (next) next.call(this, ...arguments);
    this.unmount = true
  }
  // 对setState的改装，setState查看目前是否已经销毁
  let setState = target.prototype.setState
  target.prototype.setState = function () {
    if (this.unmount) return;
    setState.call(this, ...arguments)
  }
}



const asyncComponent = (load) => {
  @inject_unount
  class AsyncComponent extends React.Component {
    state = { Component: null }

    componentDidMount() {
      if (this.state.Component) return;
      // 在高阶组件 DidMount 时才去执行网络加载步骤
      load().then(({ default: Component }) => {
        // 代码加载成功，获取到了代码导出的值，调用 setState 通知高阶组件重新渲染子组件
        this.setState({
          Component,
        })
      }).catch((err) => {
        throw err;
      });
    }

    render() {
      const { Component } = this.state;
      // component 是 React.Component 类型，需要通过 React.createElement 生产一个组件实例
      // return Component ? React.createElement(component) : null;
      return Component ? <Component {...this.props} /> : null;
    }
  }

  return AsyncComponent
}


const Home = () => <Button className="hello">Home</Button>;


const routes = [
  {
    path: '/',
    component: asyncComponent(() => import(/* webpackChunkName: "Root" */'../pages/Root')),
    exact: true
  },
  {
    path: '/home',
    component: Home
  },
  /* 数据字典  Start*/
  {
    path: '/dmfield',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectField" */'../pages/DataDictionary/CollectInformationField/CollectField')),
    exact: true

  },
  {
    path: '/dmlink',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/DataDictionary/CollectInformationField/CollectLink')),
    exact: true
  },

  //操作环节

  {
    path: '/dmpublish',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/OperationLink')),
    exact: true
  },
  {
    path: '/dmunCommit',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/LinkDraft')),
    exact: true
  },
  {
    path: '/dmunPublished',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/LinkVerify')),
    exact: true
  },
  {
    // path: '/linkhistory',
    path: '/dmlinkEnerprise/dmpublish/linkhistory',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/LinkHistory')),
    exact: true
  },
  {
    path: '/dmlinkEnerprise',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink')),
    exact: true
  },
  {
    path: '/dmlinkEnerprise/view',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/Components/ModifyLink.js')),
    exact: true
  },
  {
    path: '/dmlinkEnerprise/dmpublish/viewnew',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/ModifyLinkNew/Edit')),
    exact: true
  },
  {
    path: '/dmlinkEnerprise/viewnew',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/ModifyLinkNew/Edit')),
    exact: true
  },
  {
    path: '/dmlinkPublish/dmpublish/viewnew',
    component: asyncComponent(() => import(/* webpackChunkName: "CollectLink" */'../pages/OperationLink/ModifyLinkNew/Edit')),
    exact: true
  },



  {
    path: '/datacategory',
    component: asyncComponent(() => import(/* webpackChunkName: "CategoryName" */'../pages/DataDictionary/ProductCategory/CategoryName')),
    exact: true

  },
  {
    path: '/datafiled',
    component: asyncComponent(() => import(/* webpackChunkName: "CategoryField" */'../pages/DataDictionary/ProductCategory/CategoryField')),
    exact: true
  },
  {
    path: '/codecustomFields',
    component: asyncComponent(() => import(/* webpackChunkName: "CustomFormat" */'../pages/DataDictionary/CustomFormat')),
    exact: true
  },
  {
    path: '/codecustomFields/view',
    component: asyncComponent(() => import(/* webpackChunkName: "CustomFormatView" */'../pages/DataDictionary/CustomFormat/CustomFormatView')),
    exact: true
  },
  {
    path: '/datadict',
    component: asyncComponent(() => import(/* webpackChunkName: "MasterDictionary" */'../pages/DataDictionary/MasterDictionary')),
    exact: true
  },
  /* 数据字典  End*/

  /** 剑南春定制酒 Start */
  {
    path: '/promotionloading',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/CustomWineTemplate/index.tsx')),
    exact: true
  },
  {
    path: '/promotionunLoading', //测试版
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/CustomWineTemplate/index.tsx')),

    exact: true
  },
  {
    path: '/promotioncustomTemplateDraft', //未发布活动
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/CustomWineTemplate/index.tsx')),
    exact: true
  },
  {
    path: '/promotioncustomTemplate/history', //活动历史
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityHistory" */'../pages/ActivityManagement/CustomWineTemplate/History/index.tsx')),
    exact: true
  },
  {
    path: '/promotioncustomTemplate/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/CustomWineTemplate/Template/index')),

  },
  /* 剑南春定制酒  End*/

  /** 剑南春留资 Start */
  {
    path: '/promotionuser',
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/Retention/index.tsx')),
    exact: true
  },
  /* 剑南春留资  End*/

   /** 活动管理 Start */
   {
    path: '/promotionnormal',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),
    exact: true
  },
  {
    path: '/promotiondevelop', //测试版
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),

    exact: true
  },
  {
    path: '/promotiondraft', //未发布活动
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),
    exact: true
  },
  {
    path: '/promotiontoBeReleased', //待发布活动
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),
    exact: true
  },
  {
    path: '/promotionactive/priority', //活动优先级
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityPriority" */'../pages/ActivityManagement/ActivityManagement/Priority')),
    exact: true
  },
  {
    path: '/promotionactive/history', //活动历史
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityHistory" */'../pages/ActivityManagement/ActivityManagement/History')),
    exact: true
  },
  {
    path: '/promotionactive/more', //多活动限制
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityPriority" */'../pages/ActivityManagement/ActivityManagement/More/index.tsx')),
    exact: true
  },
  {
    path: '/promotionactive/mate', //活动匹配配置
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityPriority" */'../pages/ActivityManagement/ActivityManagement/Mate/index.tsx')),
    exact: true
  },

  {
    path: '/promotionactive/activity/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityEdit" */'../pages/ActivityManagement/ActivityManagement/Activity/ActivityEdit')),
    exact: true
  },
  {
    path: '/promotionactive/activity/edit/conditions',
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityEdit" */'../pages/ActivityManagement/ActivityManagement/Activity/ActivityEdit/Step0/Conditions.js')),
    exact: true
  },
  // {
  //   path: '/promotionactive/activity/edit/sort',
  //   component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/ActivityManagement/ActivityManagement/Activity/ActivityEdit/Step2/SortPage')),
  //   exact: true
  // },

  //b端活动
  // {
  //   path: '/promotionterminalNormal',  //正式版
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminalDevelop', //测试版
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),

  //   exact: true
  // },
  // {
  //   path: '/promotionterminalDraft', //未发布活动
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminalToBeReleased', //待发布活动
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminal/priority', //活动优先级
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityPriority" */'../pages/ActivityManagement/ActivityManagement/Priority')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminal/history', //活动历史
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityHistory" */'../pages/ActivityManagement/ActivityManagement/History')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminal/activity/edit',
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityEdit" */'../pages/ActivityManagement/ActivityManagement/Activity/ActivityEdit')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminal/more', //多活动限制
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityPriority" */'../pages/ActivityManagement/ActivityManagement/More/index.tsx')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminal/activity/edit/conditions',
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityEdit" */'../pages/ActivityManagement/ActivityManagement/Activity/ActivityEdit/Step0/Conditions.js')),
  //   exact: true
  // },
  // {
  //   path: '/promotionterminal/activity/edit/sort',
  //   component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/ActivityManagement/ActivityManagement/Activity/ActivityEdit/Step2/SortPage')),
  //   exact: true
  // },

  {
    path: '/promotionpool',
    component: asyncComponent(() => import(/* webpackChunkName: "PoolManage" */'../pages/ActivityManagement/PrizePoolManagement/PoolManage')),
    exact: true
  },
  {
    path: '/promotionprizes/pooledit',
    component: asyncComponent(() => import(/* webpackChunkName: "PoolEdit" */'../pages/ActivityManagement/PrizePoolManagement/PoolManage/PoolEdit.js')),
  },
  {
    path: '/promotionprizes/poolgroup',
    component: asyncComponent(() => import(/* webpackChunkName: "PoolEdit" */'../pages/ActivityManagement/PrizePoolManagement/PoolManage/PoolGroup.js')),
  },
  {
    path: '/promotionprizes/poolhistory',
    component: asyncComponent(() => import(/* webpackChunkName: "PoolHistory" */'../pages/ActivityManagement/PrizePoolManagement/PoolManage/PoolHistory.js')),
  },
  {
    path: '/promotionprizes/poolhistoryinfo',
    component: asyncComponent(() => import(/* webpackChunkName: "PoolHistoryInfo" */'../pages/ActivityManagement/PrizePoolManagement/PoolManage/PoolHistoryInfo.js')),
  },
  {
    path: '/promotionregister',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeRegistration" */'../pages/ActivityManagement/PrizePoolManagement/PrizeRegistration')),
    exact: true
  },
  {
    path: '/promotionprizes/createprize',
    component: asyncComponent(() => import(/* webpackChunkName: "CreatePrize" */'../pages/ActivityManagement/PrizePoolManagement/PrizeManagement/Detail/CreatePrize')),
  },
  {
    path: '/promotionprize',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/PrizePoolManagement/PrizeManagement/index')),
  },
  {
    path: '/promotionprizes/createvouchercode',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/PrizePoolManagement/VoucherCode/index')),
  },

  //核销政策
   {
    path: '/promotionpolicy',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePackageApplication" */'../pages/WriteOffManagement/WriteOffPolicy/index.tsx')),
    exact: true,
  },

  {
    path: '/tmverifyTerminal',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePackageApplication" */'../pages/WriteOffManagement/WriteOffShop')),
    exact: true
  },

  {
    path: '/tmwriteOffDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePackageApplication" */'../pages/WriteOffManagement/WriteOffDetail')),
    exact: true
  },

  {
    path: '/wswe',
    component: asyncComponent(() => import(/* webpackChunkName: "WechatManagement" */'../pages/BaseManagement/WechatManagement')),
    exact: true
  },
  {
    path: '/wswe/auth',
    component: asyncComponent(() => import(/* webpackChunkName: "WechatManagementAuth" */'../pages/BaseManagement/WechatManagement/Auth')),
    exact: true,
  },
  {
    path: '/wswe/merchants',
    component: asyncComponent(() => import(/* webpackChunkName: "MerchantInfo" */'../pages/BaseManagement/WechatManagement/MerchantInfo')),
    exact: true,
  },
  {
    path: '/wswe/urlConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramScanConfig" */'../pages/BaseManagement/WechatManagement/MinProgramScanConfig')),
    exact: true,
  },
  {
    path: '/wsmptemplate',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramTemplate" */'../pages/BaseManagement/MinProgramTemplate')),
  },
  {
    path: '/wswe/parameter',
    component: asyncComponent(() => import(/* webpackChunkName: "MinTemplate" */'../pages/BaseManagement/WechatManagement/ParameterCode/index.tsx')),
    exact: true,
  },
  {
    path: '/wswe/parameter/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "MinTemplate" */'../pages/BaseManagement/WechatManagement/ParameterCode/parameter.tsx')),
    exact: true,
  },
  {
    path: '/wswe/menu',
    component: asyncComponent(() => import(/* webpackChunkName: "MinTemplate" */'../pages/BaseManagement/WechatManagement/Menu/index.tsx')),
    exact: true,
  },

  {
    path: '/wswe/wsdecorate',
    component: asyncComponent(() => import(/* webpackChunkName: "MinTemplate" */'../pages/BaseManagement/WechatManagement/MinTemplate')),
    exact: true,
  },
  {
    path: '/wswe/wsdecorate/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "Template" */'../pages/BaseManagement/WechatManagement/MinTemplate/Template')),
    exact: true,
  },
  {
    path: '/wswe/experience',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramScanConfig" */'../pages/BaseManagement/WechatManagement/MinProgramExperience')),
    exact: true,
  },
  {
    path: '/datadealer', // 经销商
    component: asyncComponent(() => import(/* webpackChunkName: "Datadealer" */'../pages/BaseManagement/Datadealer/index')),
    exact: true,
  },
  {
    path: '/databasicData/datadealer/detail', // 经销商
    component: asyncComponent(() => import(/* webpackChunkName: "DatadealerDetail" */'../pages/BaseManagement/Datadealer/detail')),
    exact: true,
  },
  {
    path: '/datacodedb',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeBank" */'../pages/BaseManagement/CodeBank')),
    exact: true
  },
  {
    path: '/dmtagStatus',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeBank" */'../pages/BaseManagement/BasicInformationManagement/StateCode')),
    exact: true
  },
  {
    path: '/datacodedb/ip',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeBankIp" */'../pages/BaseManagement/CodeBank/Ip')),
    exact: true
  },


  {
    path: '/dataaddressBook',
    component: asyncComponent(() => import(/* webpackChunkName: "AddressBook" */'../pages/BaseManagement/OrganizeManagement/AddressBook')),
    exact: true,
  },
  {
    path: '/datarole',
    component: asyncComponent(() => import(/* webpackChunkName: "RolePermission" */'../pages/BaseManagement/OrganizeManagement/RolePermission')),
    exact: true,
  },
  {
    path: '/dataorganization/dataroleedit',
    component: asyncComponent(() => import(/* webpackChunkName: "RolePermissionCreate" */'../pages/BaseManagement/OrganizeManagement/RolePermission/Create')),
    exact: true
  },
  {
    path: '/datauser',
    component: asyncComponent(() => import(/* webpackChunkName: "SubAccount" */'../pages/BaseManagement/OrganizeManagement/SubAccount')),
    exact: true
  },
  {
    path: '/dataorganization/datauseredit',
    component: asyncComponent(() => import(/* webpackChunkName: "SubAccountForm" */'../pages/BaseManagement/OrganizeManagement/SubAccount/Create/SubAccountForm')),
    exact: true
  },
  {
    path: '/datafactory',
    component: asyncComponent(() => import(/* webpackChunkName: "FactoryManagement" */'../pages/BaseManagement/BasicInformationManagement/FactoryManagement')),
    exact: true
  },
  //经销商分组管理
  {
    path: '/datadealerGroup',
    component: asyncComponent(() => import(/* webpackChunkName: "DealerGroupManagement" */'../pages/BaseManagement/BasicInformationManagement/DealerGroupManagement')),
    exact: true
  },
  //防伪管理
  {
    path: '/promotionantiFake_published',  //已发布
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/SecurityManagement')),
    exact: true
  },
  {
    path: '/promotionantiFake_unpublished', //未发布
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/SecurityManagement')),

    exact: true
  },
  // {
  //   path: '/promotiondraft', //未发布活动
  //   component: asyncComponent(() => import(/* webpackChunkName: "ActivityManagement" */'../pages/ActivityManagement/ActivityManagement')),
  //   exact: true
  // },
  {
    path: '/promotionantiFake/priority', //活动优先级
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityPriority" */'../pages/ActivityManagement/SecurityManagement/Priority')),
    exact: true
  },
  {
    path: '/promotionantiFake/history', //活动历史
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityHistory" */'../pages/ActivityManagement/SecurityManagement/History')),
    exact: true
  },
  {
    path: '/promotionantiFake/activity/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityEdit" */'../pages/ActivityManagement/SecurityManagement/Activity/ActivityEdit')),
    exact: true
  },
  {
    path: '/promotionantiFake/activity/edit/conditions',
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityEdit" */'../pages/ActivityManagement/SecurityManagement/Activity/ActivityEdit/Step0/Conditions.js')),
    exact: true
  },

  // 证书
  {
    path: '/databasicData/datafactory/Certificate',
    component: asyncComponent(() => import(/* webpackChunkName: "CertificateManagement" */'../pages/BaseManagement/BasicInformationManagement/CertificateManagement/index.js')),
    exact: true
  },
  {
    path: '/dataproduct',
    component: asyncComponent(() => import(/* webpackChunkName: "ProductManagement" */'../pages/BaseManagement/BasicInformationManagement/ProductManagement/index')),
    exact: true
  },
  {
    path: '/datawarehouse',
    component: asyncComponent(() => import(/* webpackChunkName: "WarehouseManagement" */'../pages/BaseManagement/BasicInformationManagement/WarehouseManagement/index')),
    exact: true
  },
  {
    path: '/datasalesChannel',
    component: asyncComponent(() => import(/* webpackChunkName: "ChannelManagement" */'../pages/BaseManagement/BasicInformationManagement/ChannelManagement/index')),
    exact: true
  },
  {
    path: '/dataprecinct',
    component: asyncComponent(() => import(/* webpackChunkName: "PrecinctManagement" */'../pages/BaseManagement/BasicInformationManagement/PrecinctManagement/index')),
    exact: true
  },
  {
    path: '/datafieldAssign',
    component: asyncComponent(() => import(/* webpackChunkName: "FieldAssign" */'../pages/BaseManagement/FieldAssign')),
    exact: true
  },
  {
    path: '/databasicData/dataproduct/create',
    component: asyncComponent(() => import(/* webpackChunkName: "ProductDetail" */'../pages/BaseManagement/BasicInformationManagement/ProductManagement/productDetail')),
    exact: true
  },
  {
    path: '/databasicData/dataproduct/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "ProductDetail" */'../pages/BaseManagement/BasicInformationManagement/ProductManagement/productDetail')),
    exact: true
  },
  {
    path: '/datashop',
    component: asyncComponent(() => import(/* webpackChunkName: "TerminalManagement" */'../pages/BaseManagement/BasicInformationManagement/TerminalManagement')),
    exact: true
  },
  {
    path: '/databasicData/datashop/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "TerminalEditForm" */'../pages/BaseManagement/BasicInformationManagement/TerminalManagement/TerminalEditForm')),
    exact: true
  },
  {
    path: '/datadepartment',
    component: asyncComponent(() => import(/* webpackChunkName: "DepartmentManage" */'../pages/BaseManagement/OrganizeManagement/DepartmentManage')),
    exact: true
  },
  {
    path: '/dataorganization/allots',
    component: asyncComponent(() => import(/* webpackChunkName: "DataDistribution" */'../pages/BaseManagement/OrganizeManagement/DepartmentManage/DataDistribution')),
    exact: true
  },
  {
    path: '/datampuser',
    component: asyncComponent(() => import(/* webpackChunkName: "AuthMiniprogramTools" */'../pages/BaseManagement/OrganizeManagement/AuthMiniprogramTools/index')),
    exact: true
  },
  {
    path: '/dataorganization/mpuser',
    component: asyncComponent(() => import(/* webpackChunkName: "MpUser" */'../pages/BaseManagement/OrganizeManagement/AuthMiniprogramTools/mpUser')),
    exact: true
  },
  {
    path: '/datadanjutype',
    component: asyncComponent(() => import(/* webpackChunkName: "BillInfomation" */'../pages/BaseManagement/InfomationManagement/BillInfomation')),
    exact: true
  },
  {
    path: '/datarecords',
    component: asyncComponent(() => import(/* webpackChunkName: "BillData" */'../pages/BaseManagement/InfomationManagement/BillInfomation/Bill')),
    exact: true
  },
  {
    path: '/dataactivitytype',
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityInfomation" */'../pages/BaseManagement/InfomationManagement/ActivityInfomation')),
    exact: true
  },
  {
    path: '/dataactivitydata',
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityData" */'../pages/BaseManagement/InfomationManagement/ActivityInfomation/Activity')),
    exact: true
  },
  {
    path: '/datatype',
    component: asyncComponent(() => import(/* webpackChunkName: "ContractInfomation" */'../pages/BaseManagement/InfomationManagement/ContractInfomation')),
    exact: true
  },
  {
    path: '/datadata',
    component: asyncComponent(() => import(/* webpackChunkName: "ContractData" */'../pages/BaseManagement/InfomationManagement/ContractInfomation/Contract')),
    exact: true
  },
  {
    path: '/datadanjutype/infomationedit',
    component: asyncComponent(() => import(/* webpackChunkName: "Infomationedit" */'../pages/BaseManagement/InfomationManagement/Components/InfomationForm')),
    exact: true
  },
  {
    path: '/dataactivitytype/infomationedit',
    component: asyncComponent(() => import(/* webpackChunkName: "Infomationedit" */'../pages/BaseManagement/InfomationManagement/Components/InfomationForm')),
    exact: true
  },
  {
    path: '/datatype/infomationedit',
    component: asyncComponent(() => import(/* webpackChunkName: "Infomationedit" */'../pages/BaseManagement/InfomationManagement/Components/InfomationForm')),
    exact: true
  },
  /** 活动管理 End */

  /**
   *  码管理 start
   */
  {
    path: '/codeapply',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePackageApplication" */'../pages/CodeManagement/CodeSendingMangement/CodePackageApplication/index')),
    exact: true,
  },
  {
    path: '/codeauditor',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePackageAudit" */'../pages/CodeManagement/CodeSendingMangement/CodePackageAudit/index')),
    exact: true,
  },


  {
    path: '/codebatch',
    component: asyncComponent(() => import(/* webpackChunkName: "BatchEntry" */'../pages/CodeManagement/CodeEntryMangement/BatchEntry/index')),
    exact: true,
  },
  {
    path: '/coderegbatch/codebatch/more',
    component: asyncComponent(() => import(/* webpackChunkName: "BatchEntryMore" */'../pages/CodeManagement/CodeEntryMangement/BatchEntry/components/More')),
    exact: true
  },

  {
    path: '/codeseqnum',
    component: asyncComponent(() => import(/* webpackChunkName: "NumberEntry" */'../pages/CodeManagement/CodeEntryMangement/NumberEntry/index')),
    exact: true,
  },


  /**
  *  码管理 end
  */
  {
    path: '/codeapplyandauditor/codeApplication',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeApplicationMangement" */'../pages/CodeManagement/CodeApplicationMangement')),
    exact: true,
    name: '申请码'
  },
  {
    path: '/codetagConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "CodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig')),
    exact: true,
  },
  {
    path: '/codetagConfig/create',
    component: asyncComponent(() => import(/* webpackChunkName: "CreateCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/create')),
    exact: true,
  },
  {
    path: '/codetagConfig/createCompose',
    component: asyncComponent(() => import(/* webpackChunkName: "CreateCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/create/indexCompose')),
    exact: true,
  },

  {
    path: '/codetagConfig/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "EditCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/edit')),
    exact: true,
  },
  {
    path: '/codetagConfig/editCompose',
    component: asyncComponent(() => import(/* webpackChunkName: "EditCodePrintingConfig" */'../pages/CodeManagement/CodePrintingConfig/edit/indexCompose')),
    exact: true,
  },
  {
    path: '/codescrap',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeScrap" */'../pages/CodeManagement/CodeEntryMangement/CodeScrap')),
    exact: true,
  },


  {
    path: '/codethirdPartCode',
    component: asyncComponent(() => import(/* webpackChunkName: "ImportThirdPartyCode" */'../pages/CodeManagement/ImportThirdPartyCode')),
    exact: true,
  },
  {
    path: '/codethirdPartCode/import',
    component: asyncComponent(() => import(/* webpackChunkName: "ImportCode" */'../pages/CodeManagement/ImportThirdPartyCode/Components/ImportCode')),
    exact: true,
  },

  {
    path: '/promotiontemplate',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramServiceMedia" */'../pages/ToolsetManagement/DeveloperTools/CodingRegister')),
    exact: true,
  },
  // 模板元素
  {
    path: '/wsws/elementTemplate',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramServiceMedia" */'../pages/ToolsetManagement/DeveloperTools/ElementTemplate')),
    exact: true,
  },

  //页面模板
  {
    path: '/promotionpublished',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/pageManagement/index.tsx')),
  },
  // 未发布
  {
    path: '/promotionunpublish',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/pageManagement/indexUnpulish.tsx')),
  },
  {
    path: '/promotiontoBeAnnounced',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/pageManagement/indexUnpulish.tsx')),
  },
  {
    path: '/promotionpage/createprize',
    component: asyncComponent(() => import(/* webpackChunkName: "CreatePrize" */'../pages/ActivityManagement/pageManagement/Live/LiveEdit/index.tsx')),
  },
  {
    path: '/promotionpage/DetailList',
    component: asyncComponent(() => import(/* webpackChunkName: "CreatePrize" */'../pages/ActivityManagement/pageManagement/Live/DetailList/DetailList.tsx')),
  },
  {
    path: '/promotionpage/history',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/pageManagement/History/History.tsx')),

  },
  {
    path: '/promotionpage/service',
    component: asyncComponent(() => import(/* webpackChunkName: "PrizeList" */'../pages/ActivityManagement/pageManagement/Service/Service.tsx')),

  },

  {
    path: '/dmaccessories',
    component: asyncComponent(() => import(/* webpackChunkName: "ImportThirdPartyCode" */'../pages/CodeManagement/AccessoriesMangement/AccessoriesBox')),
    exact: true,
  },


  /**
  *  码管理 end
  */


  /**
    * 工具箱 start
    */

  {
    path: '/querytoolsqtuser',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/QueryTools/UserQuery/UserQuery')),
    exact: true,
  },
  {
    path: '/querytoolsqt/cardDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "CardDetailRecord" */'../pages/ToolsetManagement/QueryTools/UserQuery/cardDetail')),
    exact: true,
  },
  {
    path: '/querytoolsqt/pointDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "PointDetailRecord" */'../pages/ToolsetManagement/QueryTools/UserQuery/pointDetail')),
    exact: true,
  },
  {
    path: '/querytoolsqttag',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeQuery" */'../pages/ToolsetManagement/QueryTools/CodeQuery/CodeQuery')),
    exact: true,
  },
  {
    path: '/wsrelease',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramDevelopment" */'../pages/ToolsetManagement/DeveloperTools/MinProgramDevelopment')),
    exact: true,
  },
  {
    path: '/wsmaterial',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramServiceMedia" */'../pages/ToolsetManagement/DeveloperTools/MinProgramServiceMedia')),
    exact: true,
  },
  {
    path: '/promotiontemplate',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramServiceMedia" */'../pages/ToolsetManagement/DeveloperTools/CodingRegister')),
    exact: true,
  },
  {
    path: '/wechatmsgsubscribeconfig',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/ToolsetManagement/DeveloperTools/Subscriber/index')),
    exact: true,
  },
  // 模板元素
  {
    path: '/wsws/elementTemplate',
    component: asyncComponent(() => import(/* webpackChunkName: "MinProgramServiceMedia" */'../pages/ToolsetManagement/DeveloperTools/ElementTemplate')),
    exact: true,
  },

  {
    path: '/promotionh5pack',
    component: asyncComponent(() => import(/* webpackChunkName: "H5CodePackage" */'../pages/ToolsetManagement/DeveloperTools/H5CodePackage')),
    exact: true,
  },
  {
    path: '/wsws/h5link',
    component: asyncComponent(() => import(/* webpackChunkName: "h5link" */'../pages/ToolsetManagement/DeveloperTools/H5Link')),
    exact: true,
  },
  {
    path: '/promotionrule',
    component: asyncComponent(() => import(/* webpackChunkName: "h5link" */'../pages/ToolsetManagement/DeveloperTools/H5CodeRule')),
    exact: true,
  },
  {
    path: '/promotionwspromotion',
    component: asyncComponent(() => import(/* webpackChunkName: "InteractiveActivityCode" */'../pages/ToolsetManagement/DeveloperTools/InteractiveActivityCode')),
    exact: true,
  },
  {
    path: '/membercenterwsmember',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberShipSystemCode" */'../pages/ToolsetManagement/DeveloperTools/MemberShipSystemCode')),
    exact: true,
  },
  {
    path: '/cardcouponConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/DeveloperTools/CodeConfig/index.tsx')),
    exact: true,
  },

  {
    path: '/querytoolsqtpromotion',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/ActiveMatch')),
    exact: true,
  },

  {
    path: '/promotionaccRule',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/PromotionRule/index.tsx')),
    exact: true,
  },

  {
    path: '/promotionenterprisebuy',
    component: asyncComponent(() => import('../pages/ToolsetManagement/DeveloperTools/CorporateGroupPurchaseImport')),
    exact: true
  },
  {
    path: '/wsws/promotionenterprisebuy-details',
    component: asyncComponent(() => import('../pages/ToolsetManagement/DeveloperTools/CorporateGroupPurchaseImport/details.js')),
    exact: true
  },

  {
    path: '/promotionlotteryNumLimitPrize',
    component: asyncComponent(() => import('../pages/ToolsetManagement/DeveloperTools/BatchUserLotteryImport')),
    exact: true
  },
  {
    path: '/wsws/promotionlotteryNumLimitPrize-details',
    component: asyncComponent(() => import('../pages/ToolsetManagement/DeveloperTools/BatchUserLotteryImport/details.js')),
    exact: true
  },

  {
    path: '/promotionleave_info',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/OperationalTools/LeaveInfo/index.tsx')),
    exact: true,
  },
  {
    path: '/promotionot/type',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/OperationalTools/LeaveInfo/type.tsx')),
    exact: true,
  },
  {
    path: '/promotionot/field',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/OperationalTools/LeaveInfo/field.tsx')),
    exact: true,
  },
  {
    path: '/promotionpromotion_code',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/OperationalTools/PromotionCode/index.tsx')),
    exact: true,
  },

  {
    path: '/promotionproduct_manual',
    component: asyncComponent(() => import(/* webpackChunkName: "UserQuery" */'../pages/ToolsetManagement/OperationalTools/ProductManual/index.tsx')),
    exact: true,
  },
  /* 
  * 工具箱 end
  */
    /**
   * DAVICI
   */
    {
    // path: '/datadavinci',  //正式版
    path: '/dataBIProtal',
    component: asyncComponent(() => import(/* webpackChunkName: "davinci" */'../pages/Davinci/index.tsx')),
    exact: true
    },
  /*
    * 数据管理 start
   */
  {
    path: '/reportbill',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "AmountBillManagement" */'../pages/StatisticsManagement/AmountBillManagement/index')),
    exact: true
  },
  {
    path: '/reportstatistics',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "MarketingManagement" */'../pages/StatisticsManagement/MarketingManagement/index')),
    exact: true
  },
  {
    path: '/reportoverview',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "OverviewManagement" */'../pages/StatisticsManagement/OverviewManagement')),
    exact: true
  },
  //数字大屏
  {
    path: '/reportscreen',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "OverviewManagement" */'../pages/CodeCenter/DigitalScreen')),
    exact: true
  },
  {
    path: '/reportrealtimeoverview',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "RealTimeManagement" */'../pages/StatisticsManagement/RealTimeManagement')),
    exact: true
  },
  {
    path: '/reportcustomReport',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "CustomizingReports" */'../pages/StatisticsManagement/CustomizingReports')),
    exact: true
  },
  /*
    * 数据管理 end
   */


  /** 内部 */
  {
    path: '/SystemLogin',
    component: asyncComponent(() => import(/* webpackChunkName: "SystemLogin" */'../pages/SystemLogin/SystemLogin')),
    exact: true,
  },
  {
    path: '/codetempConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeTempConfig" */'../pages/CodeTempConfig/index')),
    exact: true,
  },
  {
    path: '/codetempConfig/create',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeTempConfigCreateOrEdit" */'../pages/CodeTempConfig/CreateOrEdit')),
    exact: true,
  },
  {
    path: '/codetempConfig/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeTempConfigCreateOrEdit" */'../pages/CodeTempConfig/CreateOrEdit')),
    exact: true,
  },

  {
    path: '/dataenterprise',
    component: asyncComponent(() => import(/* webpackChunkName: "DataEnterprise" */'../pages/DataEnterprise/index')),
    exact: true,
  },
  {
    path: '/dataenterprise/allot',
    component: asyncComponent(() => import(/* webpackChunkName: "DataEnterpriseAllot" */'../pages/DataEnterprise/AllotIndex')),
    exact: true,
  },

  {
    path: '/codecodesourceConfig',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeSourceManagement" */'../pages/ResourceDistribution/CodeSourceManagement/index')),
    exact: true,
  },

  {
    path: '/codecodesourceConfig/create',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeSourceManagementCreateOrEdit" */'../pages/ResourceDistribution/CodeSourceManagement/createOrEdit')),
    exact: true,
  },

  {
    path: '/codecodesourceConfig/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "CodeSourceManagementCreateOrEdit" */'../pages/ResourceDistribution/CodeSourceManagement/createOrEdit')),
    exact: true,
  },

  {
    path: '/warnevent',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningEventTemplates" */'../pages/ResourceDistribution/WarningEventTemplates')),
    exact: true,
  },
  {
    path: '/dataisvUser',
    component: asyncComponent(() => import(/* webpackChunkName: "ISVAccount" */'../pages/ResourceDistribution/ISVAccount/ISVAccount')),
    exact: true,
  },
  {
    path: '/dataisvUser/allocation',
    component: asyncComponent(() => import(/* webpackChunkName: "EnterpriseAllocation" */'../pages/ResourceDistribution/ISVAccount/EnterpriseAllocation')),
    exact: true,
    name: '企业分配'
  },
  {
    path: '/dataisvUser/link',
    component: asyncComponent(() => import(/* webpackChunkName: "EnterpriseAllocation" */'../pages/ResourceDistribution/ISVAccount/Link')),
    exact: true,
    name: '环节分配'
  },
  {
    path: '/dataisvUser/whitelist',
    component: asyncComponent(() => import(/* webpackChunkName: "WhitelistSetting" */'../pages/ResourceDistribution/ISVAccount/WhitelistSetting')),
    exact: true,
    name: '白名单设置'
  },


  // 会员中心
  {
    path: '/membercenterbasic',
    component: asyncComponent(() => import(/* webpackChunkName: "BasicTag" */'../pages/MemberManagement/LabelManagement/basicTag/index')),
    exact: true,
  },
  {
    path: '/membercenteruser',
    component: asyncComponent(() => import(/* webpackChunkName: "UserDefinedTag" */'../pages/MemberManagement/LabelManagement/userDefinedTag/index')),
    exact: true,
  },
  {
    path: '/membercentertag/membercenteruser/detail',
    component: asyncComponent(() => import(/* webpackChunkName: "UserDefinedTagDetail" */'../pages/MemberManagement/LabelManagement/userDefinedTag/components/index')),
    exact: true,
  },
  {
    path: '/membercentertag/membercenteruser/history', //活动历史
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityHistory" */'../pages/MemberManagement/LabelManagement/userDefinedTag/History/index')),
    exact: true
  },
  {
    path: '/membercentermanual',
    component: asyncComponent(() => import(/* webpackChunkName: "ManualTag" */'../pages/MemberManagement/LabelManagement/ManualTag/index')),
    exact: true,
  },
  {
    path: '/membercentertag/membercentermanual/setting',
    component: asyncComponent(() => import(/* webpackChunkName: "ManualTagImportSet" */'../pages/MemberManagement/LabelManagement/ManualTag/ImportSetting')),
    exact: true,
  },
  // 第三方标签
  {
    path: '/membercenterthird',
    component: asyncComponent(() => import(/* webpackChunkName: "ManualTag" */'../pages/MemberManagement/LabelManagement/ThirdTag/index')),
    exact: true,
  },
  {
    path: '/membercentertag/membercenterthird/setting',
    component: asyncComponent(() => import(/* webpackChunkName: "ManualTagImportSet" */'../pages/MemberManagement/LabelManagement/ThirdTag/ImportSetting')),
    exact: true,
  },

  {
    path: '/membercenteroperation',
    component: asyncComponent(() => import(/* webpackChunkName: "UserDefinedTag" */'../pages/MemberManagement/LabelManagement/OperationTag/index')),
    exact: true,
  },
  {
    path: '/membercentertag/membercenteroperation/detail',
    component: asyncComponent(() => import(/* webpackChunkName: "ManualTagImportSet" */'../pages/MemberManagement/LabelManagement/OperationTag/components/index')),
    exact: true,
  },
  {
    path: '/membercentertag/membercenteroperation/history', //活动历史
    component: asyncComponent(() => import(/* webpackChunkName: "ActivityHistory" */'../pages/MemberManagement/LabelManagement/OperationTag/History/index')),
    exact: true
  },
  // 会员体系
  {
    path: '/membercentersystemPublish',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MemberSystem/index')),
    exact: true,
  },
  {
    path: '/membercentersystemDratf',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MemberSystem/index')),
    exact: true,
  },
  
  {
    path: '/membercentersystem/history',
    component: asyncComponent(() => import(/* webpackChunkName: "ReleaseHistory" */'../pages/MemberManagement/MemberSystem/History')),
    exact: true
  },
  {
    path: '/membercentersystem/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystemEdit" */'../pages/MemberManagement/MemberSystem/Member/ActivityEdit/index.tsx')),
    exact: true
  },
  {
    path: '/membercentersystem/rights',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystemEdit" */'../pages/MemberManagement/MemberSystem/Member/ActivityEdit/Step0/SystemRights')),
    exact: true
  },
  {
    path: '/membercentersystem/info',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberBenefitInfo" */'../pages/MemberManagement/MemberSystem/info')),
    exact: true
  },

  //会员任务中心
  {
    path: '/membercentertaskDratf',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MemberTask/index')),
    exact: true,
  },
  {
    path: '/membercentertaskPublish',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MemberTask/index')),
    exact: true,
  },
  {
    path: '/membercentertaskToBeReleased',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MemberTask/index')),
    exact: true,
  },
  {
    path: '/membercentertask/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MemberTask/edit')),
    exact: true,
  },
  {
    path: '/membercentertask/history',
    component: asyncComponent(() => import(/* webpackChunkName: "PoolHistory" */'../pages/MemberManagement/MemberTask/TaskHistory')),
  },
  {
    path: '/membercentertask/historyinfo',
    component: asyncComponent(() => import(/* webpackChunkName: "PoolHistoryInfo" */'../pages/MemberManagement/MemberTask/TaskHistoryInfo.js')),
  },
  //会员管理
  {
    path: '/membercentermemberinfo',
    component: asyncComponent(() => import(/* webpackChunkName: "memberManageInfo" */'../pages/MemberManagement/MemberManage/info')),
    exact: true,
  },
  {
    path: '/membercentermembership',
    component: asyncComponent(() => import(/* webpackChunkName: "memberManageShip" */'../pages/MemberManagement/MemberManage/ship')),
    exact: true,
  },
  {
    path: '/membercentermemberbadge',
    component: asyncComponent(() => import(/* webpackChunkName: "memberManageShip" */'../pages/MemberManagement/MemberManage/Badge')),
    exact: true,
  },
  {
    path: '/membercentermanage/allot',
    component: asyncComponent(() => import(/* webpackChunkName: "memberManageAllot" */'../pages/MemberManagement/MemberManage/AllotIndex')),
    exact: true,
  },
  {
    path: '/membercentermanage/create',
    component: asyncComponent(() => import(/* webpackChunkName: "memberManageCreateShip" */'../pages/MemberManagement/MemberManage/createShip')),
    exact: true,
  },
  {
    path: '/membercentermanage/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "memberManageCreateShip" */'../pages/MemberManagement/MemberManage/createShip')),
    exact: true,
  },
  {
    path: '/wechatmsgsubscribemsgtemplate',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MessageCenter/MiniprogramMessage/index')),
    exact: true,
  },
  {
    path: '/wechatmsgsubscribemsgsendmanage',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MessageCenter/SendMessage/index')),
    exact: true,
  },
  {
    path: '/wechatmsgwechatmsg/create',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberSystem" */'../pages/MemberManagement/MessageCenter/SendMessage/addSubscribe/index')),
    exact: true,
  },
  /* 配置管理 */
  //预警通知
  {
    path: '/warnrecord',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningRecord" */'../pages/BaseManagement/WarningNotice/WarningRecord')),
    exact: true
  },
  {
    path: '/warnrecipientsSec/detail',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningRecordDetail" */'../pages/BaseManagement/WarningNotice/WarningRecord/Components/WarningRecordDetail')),
    exact: true,
  },
  {
    path: '/warnrecipients',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningConfig" */'../pages/BaseManagement/WarningNotice/WarningConfig')),
    exact: true
  },
  {
    path: '/warnrecipientsSec/receivers',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningConfigReceivers" */'../pages/BaseManagement/WarningNotice/WarningConfig/Components/Receivers.tsx')),
    exact: true
  },
  {
    path: '/warnrecipientsSec/config',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningRecordDetail" */'../pages/BaseManagement/WarningNotice/WarningConfig/Components/config')),
  },
  {
    path: '/warnrecipientsSec/ai',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningRecordDetail" */'../pages/BaseManagement/WarningNotice/WarningConfig/Components/WarnAi')),
  },
  {
    path: '/warnrecipientsSec/tip',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningRecordDetail" */'../pages/BaseManagement/WarningNotice/WarningConfig/Components/AiTip')),
  },
  //wolf环节流转
  {
    path: '/reportproduction',
    component: asyncComponent(() => import(/* webpackChunkName: "LinkCirculation" */'../pages/BaseManagement/LinkCirculation/Production')),
    exact: true
  },
  //窜货预警
  {
    path: '/reportflowWarning',
    component: asyncComponent(() => import(/* webpackChunkName: "AntiCounterfeiting" */'../pages/BaseManagement/AntiCounterfeiting/index.js')),
    exact: true
  },
  //防伪预警
  {
    path: '/reportsecurityWarning',
    component: asyncComponent(() => import(/* webpackChunkName: "AntiCounterfeiting" */'../pages/BaseManagement/AntiCounterfeiting/index.js')),
    exact: true
  },
  //窜货明细
  {
    path: '/reportflowDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "FlowDetail" */'../pages/BaseManagement/FlowDetail/index.js')),
    exact: true
  },
  //码明细 
  {
    path: '/reportcodeDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "LinkCirculation" */'../pages/BaseManagement/CodeDetail/index.js')),
    exact: true
  },
  //防伪明细
  {
    path: '/reportsecurityDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "Security" */'../pages/BaseManagement/Security/index.js')),
    exact: true
  },
  {
    path: '/reportchannel',
    component: asyncComponent(() => import(/* webpackChunkName: "LinkCirculation" */'../pages/BaseManagement/LinkCirculation/Production')),
    exact: true
  },
  {
    path: '/reportLinkConsumer',
    component: asyncComponent(() => import(/* webpackChunkName: "LinkCirculation" */'../pages/BaseManagement/LinkCirculation/Production')),
    exact: true
  },
  {
    path: '/reportproductionOrder',
    component: asyncComponent(() => import(/* webpackChunkName: "OrderStatistics" */'../pages/BaseManagement/OrderStatistics/index')),
    exact: true
  },
  {
    path: '/reportsaleOrder',
    component: asyncComponent(() => import(/* webpackChunkName: "OrderStatistics" */'../pages/BaseManagement/OrderStatistics/index')),
    exact: true
  },
  //窜货明细
  {
    path: '/reportflowDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "FlowDetail" */'../pages/BaseManagement/FlowDetail/index.js')),
    exact: true
  },
  {
    path: '/reportdistributionOrder',
    component: asyncComponent(() => import(/* webpackChunkName: "OrderStatistics" */'../pages/BaseManagement/OrderStatistics/index')),
    exact: true
  },
  {
    path: '/reportdistributor',
    component: asyncComponent(() => import(/* webpackChunkName: "OrderStatistics" */'../pages/BaseManagement/OrderStatistics/index')),
    exact: true
  },
  {
    path: '/reportbanquetOrder',
    component: asyncComponent(() => import(/* webpackChunkName: "OrderStatistics" */'../pages/BaseManagement/OrderStatistics/index')),
    exact: true
  },
  {
    path: '/reportotherOrder',
    component: asyncComponent(() => import(/* webpackChunkName: "OrderStatistics" */'../pages/BaseManagement/OrderStatistics/index')),
    exact: true
  },
  //宴会扫码
  {
    path: '/reportbanquet',
    component: asyncComponent(() => import(/* webpackChunkName: "OrderStatistics" */'../pages/BaseManagement/ScanCodeDetail/index')),
    exact: true
  },
  //消费者扫码
  {
    path: '/reportconsumer',
    component: asyncComponent(() => import(/* webpackChunkName: "ScanCodeDetail" */'../pages/BaseManagement/ScanCodeDetail/index')),
    exact: true
  },
  //扫码统计
  {
    path: '/reportscanStatistics',
    component: asyncComponent(() => import(/* webpackChunkName: "ScanStatistics" */'../pages/BaseManagement/ScanCodeStatistics/index.js')),
    exact: true
  },
  {
    path: '/warnrecipients3',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningConfig" */'../pages/BaseManagement/LinkCirculation/Production')),
    exact: true
  },
  {
    path: '/warnrecipients4',
    component: asyncComponent(() => import(/* webpackChunkName: "WarningConfig" */'../pages/BaseManagement/LinkCirculation/Production')),
    exact: true
  },
  //返利统计
  {
    path: '/reportdealer',
    component: asyncComponent(() => import(/* webpackChunkName: "ScanStatistics" */'../pages/BaseManagement/RebateStatistics/index')),
    exact: true
  },
  {
    path: '/reportshop',
    component: asyncComponent(() => import(/* webpackChunkName: "ScanStatistics" */'../pages/BaseManagement/RebateStatistics/index')),
    exact: true
  },
  {
    path: '/reportmechanic',
    component: asyncComponent(() => import(/* webpackChunkName: "ScanStatistics" */'../pages/BaseManagement/RebateStatistics/index')),
    exact: true
  },
  //出库统计报表
  {
    path: '/dmoutflow',
    component: asyncComponent(() => import(/* webpackChunkName: "ScanStatistics" */'../pages/BaseManagement/Out/index')),
    exact: true
  },
  //出库流水报表
  {
    path: '/dmoutStatistic',
    component: asyncComponent(() => import(/* webpackChunkName: "ScanStatistics" */'../pages/BaseManagement/Out/index')),
    exact: true
  },
  /**
   * 企业管理平台-码数据管理
   */
  // 码数据变更

  {
    path: '/dataquantity',
    component: asyncComponent(() => import(/* webpackChunkName: "codeDataChange" */'../pages/CodeDataManagement/MasterDataCheck')),
    exact: true
  },
  {
    path: '/dmlinkcountcheck',
    component: asyncComponent(() => import(/* webpackChunkName: "codeDataChange" */'../pages/CodeDataManagement/LinkDataCheck/LinkSummary')),
    exact: true
  },
  {
    path: '/dmlinkdetailcheck',
    component: asyncComponent(() => import(/* webpackChunkName: "codeDataChange" */'../pages/CodeDataManagement/LinkDataCheck/LinkDetails')),
    exact: true
  },
  {
    path: '/dmlinkTag',
    component: asyncComponent(() => import(/* webpackChunkName: "codeDataChange" */'../pages/CodeDataManagement/CodeDataChange/codeDataChange')),
    exact: true
  },
  {
    path: '/dmlinkTag/detail',
    component: asyncComponent(() => import(/* webpackChunkName: "codeDataChangeDetail" */'../pages/CodeDataManagement/CodeDataChange/codeDataChangeDetail')),
    exact: true
  },
  // 码数据查询
  {
    path: '/awtagView',
    component: asyncComponent(() => import(/* webpackChunkName: "awtagView" */'../pages/CodeDataManagement/awtagView/awtagView')),
    exact: true
  },
  // 码数据下载
  {
    path: '/awtagExports',
    component: asyncComponent(() => import(/* webpackChunkName: "awtagExports" */'../pages/CodeDataManagement/awtagExports/awtagExports')),
    exact: true
  },
  // 经销商变更
  {
    path: '/dmdealerChangeRecord',
    component: asyncComponent(() => import(/* webpackChunkName: "dealerChange" */'../pages/CodeDataManagement/DealerChange/dealerChange.tsx')),
    exact: true
  },
  // 环节数据查看
  {
    path: '/dmlinkUpload',
    component: asyncComponent(() => import(/* webpackChunkName: "linkDataList" */'../pages/CodeDataManagement/LinkDataList/linkDataList')),
    exact: true
  },
  // 码数据批量查询
  {
    path: '/awtagBathDownload',
    component: asyncComponent(() => import(/* webpackChunkName: "codeBatchQuery" */ /* webpackCPrefetch: true */'../pages/CodeDataManagement/CodeBatchQuery/codeBatchQuery')),
    exact: true
  },
  // 标签批量查询
  {
    path: '/awlabelTagBathDownload',
    component: asyncComponent(() => import(/* webpackChunkName: "codeBatchQuery" */ /* webpackCPrefetch: true */'../pages/CodeDataManagement/LabelBatchQuery/index')),
    exact: true
  },
  // 标签批量查询-创建批量查询
  {
    path: '/awlabelTagBathDownload/create',
    component: asyncComponent(() => import(/* webpackChunkName: "codeBatchQuery" */ /* webpackCPrefetch: true */'../pages/CodeDataManagement/LabelBatchQuery/CreateLabelBatchQuery')),
    exact: true
  },
  /**
  *  积分商城 start
  */
  // {
  //   path: '/pointsmallgrouprules',
  //   component: asyncComponent(() => import(/* webpackChunkName: "Release" */'../pages/IntegalMall/MallManagement/Release/index')),
  // },

  {
    path: '/pointsmallformal',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement')),
    exact: true
  },
  {
    path: '/pointsmalltest', //测试版
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement')),

    exact: true
  },
  {
    path: '/pointsmalldraft', //未发布
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement')),
    exact: true
  },
  {
    path: '/pointsmallmall/history', //历史
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement/History')),
    exact: true
  },
  {
    path: '/pointsmallmall/pointmall/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement/Pointmall/ActivityEdit')),
    exact: true
  },
  {
    path: '/pointsmallmall/pointmall/group',
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement/Pointmall/ActivityEdit/Step2/MallGroup')),
    exact: true
  },

  {
    path: '/pointsmallmall/pointmall/sort',
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement/Pointmall/ActivityEdit/Step2/SortPage')),
    exact: true
  },
  {
    path: '/pointsmallmall/pointmall/groupsort',
    component: asyncComponent(() => import(/* webpackChunkName: "MallManagement" */'../pages/IntegalMall/MallManagement/Pointmall/ActivityEdit/Step2/GroupSortPage')),
    exact: true
  },
  {
    path: '/pointsmallpresents',
    component: asyncComponent(() => import(/* webpackChunkName: "Beta" */'../pages/IntegalMall/PresentsManagement')),
    exact: true
  },
  {
    path: '/pointsmallpresents/logs',
    component: asyncComponent(() => import(/* webpackChunkName: "Beta" */'../pages/IntegalMall/PresentsManagement/Components/PresentsHistory')),
  },
  {
    path: '/pointsmallpresents/modify',
    component: asyncComponent(() => import(/* webpackChunkName: "Beta" */'../pages/IntegalMall/PresentsManagement/Components/PresentsModify')),
  },
  {
    path: '/pointsmallorders',
    component: asyncComponent(() => import(/* webpackChunkName: "Unrelease" */'../pages/IntegalMall/OrderManagement/index.tsx')),
  },
    // 防黑管理
    {
      path: '/promotionblack',
      component: asyncComponent(() => import(/* webpackChunkName: "blacklist"*/'../pages/AntiBlackManagement/BlacklistManagement')),
      exact: true,
    },
    {
      path: '/promotionwhite',
      component: asyncComponent(() => import(/* webpackChunkName: "whitelist"*/'../pages/AntiBlackManagement/WhitelistManagement')),
      exact: true,
    },
  
  /**
  *  积分商城 end
  */
  // 码包上传
  {
    path: '/dmtagPackage',
    component: asyncComponent(() => import(/* webpackChunkName: "Release" */'../pages/CodeUpload/index')),
  },

  /**
    *  返利活动 start
    */

  {
    path: '/tmrebateformal',  //正式版
    component: asyncComponent(() => import(/* webpackChunkName: "RebateActivities" */'../pages/RebateManagement/RebateActivities')),
    exact: true
  },
  {
    path: '/tmrebatedraft', //草稿版
    component: asyncComponent(() => import(/* webpackChunkName: "RebateActivities" */'../pages/RebateManagement/RebateActivities')),

    exact: true
  },
  {
    path: '/tmactivity/history', //历史 tmactivity
    component: asyncComponent(() => import(/* webpackChunkName: "RebateActivities" */'../pages/RebateManagement/RebateActivities/History')),
    exact: true
  },
  {
    path: '/tmactivity/rebateActive/priority', //活动优先级
    component: asyncComponent(() => import(/* webpackChunkName: "RebateActivities" */'../pages/RebateManagement/RebateActivities/active/Priority')),
    exact: true
  },
  {
    path: '/tmactivity/rebateactive/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "RebateActivities" */'../pages/RebateManagement/RebateActivities/active/ActivityEdit')),
    exact: true
  },
  {
    path: '/tmdetail',
    component: asyncComponent(() => import(/* webpackChunkName: "Beta" */'../pages/RebateManagement/RebateDetail')),
    exact: true
  },
  // 码报废管理
  {
    path: '/dmscrapReport',
    component: asyncComponent(() => import(/* webpackChunkName: "Beta" */'../pages/CodeStatistics')),
    exact: true
  },

  {
    path: '/dmtagScrap',
    component: asyncComponent(() => import(/* webpackChunkName: "Release" */'../pages/CodeScrap/index')),
  },
  {
    path: '/dmsupplierScrap',
    component: asyncComponent(() => import(/* webpackChunkName: "Release" */'../pages/CodeSupplierScrap/index')),
  },
  /**
  *  返利活动 end
  */

  // 码标签
  // {
  //   path: '/codelabel',
  //   component: asyncComponent(() => import(/* webpackChunkName: "BasicTag" */'../pages/CodeLabel/userDefinedTag/index')),
  //   exact: true,
  // },
  {
    path: '/codeconfig',
    component: asyncComponent(() => import(/* webpackChunkName: "NewEntry" */'../pages/CodeLabel/NewEntry/index')),
    exact: true,
  },
  {
    path: '/codelabel/labelsearch',
    component: asyncComponent(() => import(/* webpackChunkName: "NewEntry" */'../pages/CodeLabel/NewEntry/LabelSearch')),
    exact: true,
  },
  {
    path: '/codelabel/labelhistory',
    component: asyncComponent(() => import(/* webpackChunkName: "NewEntry" */'../pages/CodeLabel/NewEntry/LabelHistory/index')),
    exact: true,
  },
  {
    path: '/codeaudit',
    component: asyncComponent(() => import(/* webpackChunkName: "NewEntry" */'../pages/CodeLabel/NewEntry/LabelVerify/index')),
    exact: true,
  },
  {
    path: '/codedraft',
    component: asyncComponent(() => import(/* webpackChunkName: "NewEntry" */'../pages/CodeLabel/NewEntry/LabelDraft/index')),
    exact: true,
  },
  {
    path: '/codelabel/detail',
    component: asyncComponent(() => import(/* webpackChunkName: "UserDefinedTagDetail" */'../pages/CodeLabel/userDefinedTag/userDefinedTagDetail')),
    exact: true,
  },

  /** 大屏抽奖 Start */
  {
    path: '/luckydrawprize',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/DataManagement/PoolManage/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawaward',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/DataManagement/AwardManage/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawbgconfig',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/DataManagement/BgManage/index.tsx')),
    exact: true
  },
  {
    path: '/luckydraworder',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/DataManagement/OrderManage/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawconfig',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/ActivityManagement/ActivityManagement/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawdrawactivity/luckydrawconfig/detail',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/ActivityManagement/ActivityManagement/components/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawdrawactivity/luckydrawconfig/history',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/ActivityManagement/ActivityManagement/History/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawdrawactivity/luckydrawconfig/simulateLottery',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/ActivityManagement/ActivityManagement/Lottery/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawdrawactivity/luckydrawconfig/lottery',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/ActivityManagement/ActivityManagement/Lottery/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawhighConfig',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/ActivityManagement/ActivityManagement/index.tsx')),
    exact: true
  },
  {
    path: '/luckydrawdrawactivity/luckydrawhighConfig/detail',  
    component: asyncComponent(() => import(/* webpackChunkName: "ScreenManagement" */'../pages/ScreenManagement/ActivityManagement/ActivityManagement/components/index.tsx')),
    exact: true
  },
  
  /** 大屏抽奖 End */

  /**
   * 特色活动
   */
  // 宴会类型
  {
    path: '/specialactivitytype',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetType')),
    exact: true
  },
  {
    path: '/specialactivitypolicy',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetPolicy')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/specialactivitypolicy/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetPolicy/BanquetPolicyEdit')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/specialactivitypolicy/history',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetPolicy/history')),
    exact: true
  },
  // 宴会执行奖励
  {
    path: '/specialactivitybanquetActivity',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetReward')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/specialactivitybanquetActivity/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetReward/NucleusRewardEdit')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/specialactivitybanquetActivity/history',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetReward/history')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/specialactivitybanquetActivity/priority',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetReward/Priority')),
    exact: true
  },

  // 宴会订单管理
  {
    path: '/specialactivityorder',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetOrder')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/specialactivityorder/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetOrder/edit')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/specialactivityorder/edit/:id',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetOrder/edit')),
    exact: true
  },

  // 宴会记录
  {
    path: '/specialactivityexecuteRecord',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Banquet/BanquetRecord')),
    exact: true
  },

  // 核销执行奖励
  {
    path: '/specialactivitywriteOffActivity',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusReward')),
    exact: true
  },
  {
    path: '/specialactivitywriteOff/specialactivitywriteOffActivity/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusReward/NucleusRewardEdit')),
    exact: true
  },
  {
    path: '/specialactivitywriteOff/specialactivitywriteOffActivity/history',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusReward/history')),
    exact: true
  },
  {
    path: '/specialactivitywriteOff/specialactivitywriteOffActivity/priority',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusReward/Priority')),
    exact: true
  },
  // 核销门店
  {
    path: '/tmwriteOffTerminal',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusManage')),
    exact: true
  },
  // 核销明细
  {
    path: '/tmwriteOffDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusRecord/recordDefinite')),
    exact: true
  },
  // 核销执行奖励明细
  {
    path: '/tmrewardLog',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusRecord/recordPrize')),
    exact: true
  },
  {
    path: '/tmsummaryTotal',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/NucleusRecord/summary/index.js')),
    exact: true
  },
  // 终端 订单活动
  {
    path: '/specialactivityorderActivity',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OrderActivity')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/specialactivityorderActivity/edit',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OrderActivity/NucleusRewardEdit')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/specialactivityorderActivity/history',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OrderActivity/history')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/specialactivityorderActivity/priority',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OrderActivity/Priority')),
    exact: true
  },
  {
    path: '/tmorderDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OrderActivityRecord')),
    exact: true
  },


  // 终端激励 开瓶返利
  {
    path: '/specialactivitymarketingActivity',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/tmmarketingActivityEdit',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/edit')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/tmmarketingActivityEdit/:id',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/edit')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/tmmarketingActivityEditConditions/:id',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/edit-conditions')),
    exact: true
  },
  {
    path: '/specialactivitywriteOff/more-conditions/:id',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/edit-conditions')),
    exact: true
  },
  {
    path: '/specialactivitybanquet/more-conditions/:id',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/edit-conditions')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/tmmarketingActivityPriority',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/priority')),
    exact: true
  },
  {
    path: '/specialactivityterminalExcitation/tmmarketingActivityHistory/:id',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/history')),
    exact: true
  },
  {
    path: '/tmmarketingDetail',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebateRecord')),
    exact: true
  },
  {
    path: '/specialactivitylotteryActivity',
    component: asyncComponent(() => import('../pages/Characteristic/lottery-activity')),
    exact: true
  },
  {
    path: '/specialactivitylotteryActivityMenu/edit',
    component: asyncComponent(() => import('../pages/Characteristic/lottery-activity/edit')),
    exact: true
  },
  {
    path: '/specialactivitylotteryActivityMenu/priority',
    component: asyncComponent(() => import('../pages/Characteristic/lottery-activity/priority')),
    exact: true
  },
  {
    path: '/specialactivitylotteryActivityMenu/history/:id',
    component: asyncComponent(() => import('../pages/Characteristic/lottery-activity/history')),
    exact: true
  },
  {
    path: '/specialactivitylotteryActivityMenu/more-conditions/:id',
    component: asyncComponent(() => import('../pages/Characteristic/Nucleus/OpenBottleRebate/edit-conditions')),
    exact: true
  },
  {
    path: '/specialactivityruleConfiguration',
    component: asyncComponent(() => import('../pages/Characteristic/input-activity/rules')),
    exact: true
  },
  {
    path: '/specialactivityinputRule/edit',
    component: asyncComponent(() => import('../pages/Characteristic/input-activity/rules/edit')),
    exact: true
  },
  {
    path: '/specialactivityinputRule/priority',
    component: asyncComponent(() => import('../pages/Characteristic/input-activity/rules/priority')),
    exact: true
  },
  {
    path: '/specialactivityinputRule/history/:id',
    component: asyncComponent(() => import('../pages/Characteristic/input-activity/rules/history')),
    exact: true
  },
  {
    path: '/specialactivityinputRule/more-conditions/:id',
    component: asyncComponent(() => import(/* webpackChunkName: "AccountPermissionCreate" */'../pages/Characteristic/Nucleus/OpenBottleRebate/edit-conditions')),
    exact: true
  },
  {
    path: '/jncshopminiappbillFailRecord',
    component: asyncComponent(() => import('../pages/Characteristic/input-activity/exception-rule-log')),
    exact: true
  },
  {
    path: '/jncshopminiappbillFailRecord/history',
    component: asyncComponent(() => import('../pages/Characteristic/input-activity/exception-rule-log/history')),
    exact: true
  },

  /** 内部 */
  {
    component: asyncComponent(() => import(/* webpackChunkName: "NoMatch" */'../pages/NoMatch')),
  },
];

const codeRoutes = [
  {
    path: '/',
    component: asyncComponent(() => import(/* webpackChunkName: "ExtractionCodePackage" */'../pages/Ucode/ExtractionCode/ExtractionCodePackage')),
    exact: true,
  },
  {
    path: '/extractionCodeFile',
    component: asyncComponent(() => import(/* webpackChunkName: "ExtractionCodeFile" */'../pages/Ucode/ExtractionCode/ExtractionCodeFile')),
    exact: true,
  },
  {
    path: '/extractionCodeHistory',
    component: asyncComponent(() => import(/* webpackChunkName: "ExtractionCodeHistory" */'../pages/Ucode/ExtractionCode/ExtractionCodeHistory')),

    exact: true,
  },
  {
    path: '/memberFile',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberFile" */'../pages/Ucode/ExtractionCode/MemberFile')),
    exact: true,
  },
  {
    path: '/promotionUpload',
    component: asyncComponent(() => import(/* webpackChunkName: "MemberFile" */'../pages/Ucode/ExtractionCode/PromotionUpload')),
    exact: true,
  },

  {
    component: asyncComponent(() => import(/* webpackChunkName: "NoMatch" */'../pages/NoMatch')),
  },
]



// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
function RouteWithSubRoutes({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => (
        // pass the sub-routes down to keep nesting
        <Component {...props} />
        // <Component {...props} name={name}></Component>
      )}
    />
  );
}

const AppRouter = () => (
  <div style={{ width: '100%', height: '100%' }}>
    {/* <HashRouter> */}
    <Switch>
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
    {/* </HashRouter> */}
  </div>
);

const CodeRouter = () => (
  <div style={{ width: '100%', height: '100%' }}>
    <Switch>
      {codeRoutes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </Switch>
  </div>
);




export { AppRouter, routes, CodeRouter };
