import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';
const moduleName = 'realTime';

export const loadList = fasterActions(moduleName, 'loadList');
export const codeNumCompare = fasterActions(moduleName, 'codeNumCompare');//扫码个数趋势比较
export const codeCountCompare = fasterActions(moduleName, 'codeCountCompare');//扫码次数趋势比较
export const winnerCountCompare = fasterActions(moduleName, 'winnerCountCompare');//中奖次数趋势比较
export const exchangeCountCompare = fasterActions(moduleName, 'exchangeCountCompare');//兑奖次数趋势比较
export const loadRealTimeEcharts = fasterActions(moduleName, 'loadRealTimeEcharts');//实时监控趋势
export const loadMemberIncreaseEcharts = fasterActions(moduleName, 'loadMemberIncreaseEcharts');//参与用户增长趋势
export const loadOverview = fasterActions(moduleName, 'loadOverview');//获取概览视图
export const loadActivityDistribution = fasterActions(moduleName, 'loadActivityDistribution');//活动参与人数热区分布图
export const loadPrizeTopTen = fasterActions(moduleName, 'loadPrizeTopTen');//Top10奖品
export const loadProductTopTen = fasterActions(moduleName, 'loadProductTopTen');//Top10产品
export const poolListData = fasterActions(moduleName, 'poolListData');//奖池list
export const prizeListData = fasterActions(moduleName, 'prizeListData');//奖品list
export const middlePrizeAmount = fasterActions(moduleName, 'middlePrizeAmount');
export const redeemPrizeAmount = fasterActions(moduleName, 'redeemPrizeAmount');


const initMap = {
    listKey: [],
    listValue: [],
};

const initialState = {
    loading: false,
    loaded: false,
    cnLoading: false,//扫码个数趋势比较
    codeNumArray: Object.assign({}, initMap),
    ccLoading: false,//扫码次数趋势比较
    codeCountArray: Object.assign({}, initMap),
    wcLoading: false,//中奖次数趋势比较
    winnerCountArray: Object.assign({}, initMap),
    eLoading: false,//兑奖次数趋势比较
    exchangeCountArray: Object.assign({}, initMap),
    middlePrizeArray:Object.assign({}, initMap),//中奖金额
    redeemPrizeArray:Object.assign({}, initMap),//兑奖金额

    rLoading: false, //实时监控趋势
    realTimeTrend: {},
    mLoading: false, //参与用户增长趋势
    userGrowthTrend: [[], []],

    aLoading: false, //活动参与人数热区分布图
    activityDistribution: [],//活动热力图数据
    topCity: [],//前十全国城市
    statistics: {}, //数据统计部分
    sLoading: false,
    productTop: [],//top10产品
    productTopProductNumber: 0 ,

    pdLoading: false,//
    prizeTop: [],//TOp 10 奖品
    prizeCategoryNum: 0,
    pzLoading: false,
    prizeListData:[],
    poolListData:[]

};

const reducer = handleActions({
    [loadList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, list: payload }),
    [loadList.FAIL]: (state, { payload }) => ({ ...state, loading: false,  error: payload }),

    [poolListData.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [poolListData.SUCCESS]: (state, { payload }) =>  {
        let poolList= [];
        console.log(payload.content,'payload',payload)
        payload.content.map((item,index)=>{
            item.text= item.name;
            item.value =  item.poolId+"["+index+"]";
            poolList.push(item)
        })
        return ({ ...state, poolList})
    },
    [poolListData.FAIL]: (state, { payload }) => ({ ...state, loading: false,  error: payload }),

    [prizeListData.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [prizeListData.SUCCESS]:  (state, { payload }) =>  {
        let prizeList = [];
        console.log(payload.content,'payload',payload)
        payload.content.map((item,index)=>{
            item.text= item.name;
            item.value = item.id;
            prizeList.push(item)
        })
        return ({ ...state, prizeList})
    },
    [prizeListData.FAIL]: (state, { payload }) => ({ ...state, loading: false,  error: payload }),


    [codeNumCompare.REQUEST]: (state, { payload }) => ({ ...state,  cnLoading: true }),
    [codeNumCompare.SUCCESS]: (state, { payload }) => ({ ...state, cnLoading: false, codeNumArray: payload }),
    [codeNumCompare.FAIL]: (state, { payload }) => ({ ...state, cnLoading: false, codeNumArray: Object.assign({}, initMap),  error: payload }),
    [codeCountCompare.REQUEST]: (state, { payload }) => ({ ...state,  ccLoading: true }),
    [codeCountCompare.SUCCESS]: (state, { payload }) => ({ ...state, ccLoading: false, codeCountArray: payload }),
    [codeCountCompare.FAIL]: (state, { payload }) => ({ ...state, ccLoading: false, codeCountArray: Object.assign({}, initMap), error: payload }),
    [winnerCountCompare.REQUEST]: (state, { payload }) => ({ ...state,  wcLoading: true }),
    [winnerCountCompare.SUCCESS]: (state, { payload }) => ({ ...state, wcLoading: false, winnerCountArray: payload }),
    [winnerCountCompare.FAIL]: (state, { payload }) => ({ ...state, wcLoading: false, winnerCountArray: Object.assign({}, initMap),  error: payload }),
    [exchangeCountCompare.REQUEST]: (state, { payload }) => ({ ...state,  eLoading: true }),
    [exchangeCountCompare.SUCCESS]: (state, { payload }) => ({ ...state, eLoading: false, exchangeCountArray: payload }),
    [exchangeCountCompare.FAIL]: (state, { payload }) => ({ ...state, eLoading: false, exchangeCountArray: Object.assign({}, initMap), error: payload }),
    [middlePrizeAmount.REQUEST]: (state, { payload }) => ({ ...state,  eLoading: true }),
    [middlePrizeAmount.SUCCESS]: (state, { payload }) => ({ ...state, eLoading: false, middlePrizeArray: payload }),
    [middlePrizeAmount.FAIL]: (state, { payload }) => ({ ...state, eLoading: false, middlePrizeArray: Object.assign({}, initMap), error: payload }),
    [redeemPrizeAmount.REQUEST]: (state, { payload }) => ({ ...state,  eLoading: true }),
    [redeemPrizeAmount.SUCCESS]: (state, { payload }) => ({ ...state, eLoading: false, redeemPrizeArray: payload }),
    [redeemPrizeAmount.FAIL]: (state, { payload }) => ({ ...state, eLoading: false, redeemPrizeArray: Object.assign({}, initMap), error: payload }),
    [loadRealTimeEcharts.REQUEST]: (state, { payload }) => ({ ...state,  rLoading: true }),
    [loadRealTimeEcharts.SUCCESS]: (state, { payload }) => ({ ...state, rLoading: false, realTimeTrend: payload,  error: payload }),
    [loadRealTimeEcharts.FAIL]: (state, { payload }) => ({ ...state, rLoading: false, realTimeTrend: {},  error: payload }),
    [loadMemberIncreaseEcharts.REQUEST]: (state, { payload }) => ({ ...state,  mLoading: true }),
    [loadMemberIncreaseEcharts.SUCCESS]: (state, { payload }) => {
        let { newCustomers = [], oldCustomers = [] } = payload;
        newCustomers = newCustomers.map(item => ({
            name: item.time,
            value: item.value,
        }));
        oldCustomers = oldCustomers.map(item => ({
            name: item.time,
            value: item.value,
        }));
        return { ...state, mLoading: false, userGrowthTrend: [newCustomers, oldCustomers] };
    },
    [loadMemberIncreaseEcharts.FAIL]: (state, { payload }) => ({ ...state, mLoading: false, userGrowthTrend: [[],[]], error: payload }),

    [loadOverview.REQUEST]: (state, { payload }) => ({ ...state,  sLoading: true }),
    [loadOverview.SUCCESS]: (state, { payload }) => ({ ...state, sLoading: false, statistics: payload }),
    [loadOverview.FAIL]: (state, { payload }) => ({ ...state, sLoading: false,  error: payload }),
    [loadActivityDistribution.REQUEST]: (state, { payload }) => ({ ...state,  aLoading: true }),
    [loadActivityDistribution.SUCCESS]: (state, { payload }) => {
        let { distributionValue = [] } = payload;
        distributionValue = distributionValue.map(item => ({
            ...item,
            label: {
                show: true,
            },
            itemStyle: {
                borderColor: 'transparent',
            },
        }));
        const topCity = distributionValue.slice(0, 10);
        return { ...state, aLoading: false, list: payload, topCity, activityDistribution: distributionValue };
    },
    [loadActivityDistribution.FAIL]: (state, { payload }) => ({ ...state, aLoading: false,  error: payload }),
    [loadPrizeTopTen.REQUEST]: (state, { payload }) => ({ ...state,  pzLoading: true }),
    [loadPrizeTopTen.SUCCESS]: (state, { payload: {
        prizeList = [],
        prizeCategoryNum = 0,//奖品总数量
    } }) => {
        let list = prizeList.map(item => ({
            name: item.prizeName,
            value: item.value,
            pool: item.poolName,
            ratio: item.ratio,
        }));
        return { ...state, pzLoading: false, prizeTop: list, prizeCategoryNum: prizeCategoryNum }
    },
    [loadPrizeTopTen.FAIL]: (state, { payload }) => ({ ...state, pzLoading: false, prizeTop: [], prizeCategoryNum: 0,  error: payload }),
    [loadProductTopTen.REQUEST]: (state, { payload }) => ({ ...state,  pdLoading: true }),
    [loadProductTopTen.SUCCESS]: (state, { payload: { productList = [], productNum = 0 } }) => ({ ...state, pdLoading: false, productTop: productList, productTopProductNumber: productNum }),//prizeList
    [loadProductTopTen.FAIL]: (state, { payload }) => ({ ...state, pdLoading: false, productTop: [],  error: payload }),
}, initialState);

export default reducer;


