import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap, debounce } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './rebate';
import { ajaxBaseConfig, actionApi } from '../../utils';
// import { createHashHistory } from 'history';
import { trims } from '@/utils/commonUtils.js';

const parseParam = function params(obj) {
  let result = '';
  let item;
  for (item in obj) {
    if (obj[item] && String(obj[item])) {
      result += `&${item}=${obj[item]}`;
    }
  }
  if (result) {
    result = '?' + result.slice(1);
  }
  return result;
}

// 返利列表
export const loadRebateEpic = actions$ => actions$.pipe(
  ofType(actions.loadRebate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let data = { ...action.payload }
    let types = '', status = ''
    if (data.activityStatus) { let a = data.activityStatus.split(','); a.forEach(it => { status = status + ('&status=' + it) }); delete data.activityStatus; }
    if (data.rebateType) { let a = data.rebateType.split(','); a.forEach(it => { if (it) { types = types + ('&types=' + it) } }); delete data.rebateType; }
    if (!data.sort) { data.sort = 'createTime,desc' }
    
    let newData = parseParam(data)
    newData = newData + types + status 
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateList}${newData}`,
      method: 'GET',
    }).pipe(
      map(res => actions.loadRebate.SUCCESS(res.response)),
      catchError(error => of(actions.loadRebate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 返利历史列表
export const rebateHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.rebateHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    // let data = { ...action.payload }${parseParam(data)
    let id = action.payload.id
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateHistory}/${id}`,
      method: 'GET',
    }).pipe(
      map(res => actions.rebateHistory.SUCCESS(res.response)),
      catchError(error => of(actions.rebateHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
//根据ID获取历史记录详情
export const rebateHistoryIdEpic = actions$ => actions$.pipe(
  ofType(actions.rebateHistoryId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id,  } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateHistoryId}/${id}`,
      method: 'GET',
    }).pipe(
      map(res => {
        console.warn(res.response,'res.response');
        return actions.rebateHistoryId.SUCCESS(res.response)
      }),
      catchError(error => of(actions.rebateHistoryId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 返利活动启用/禁用
export const rebateStarusEpic = actions$ => actions$.pipe(
  ofType(actions.rebateStarus.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id, status } = action.payload;
    const data = { id, status }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateStarus}${parseParam(data)}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateStarus.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateStarus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 返利活动调整优先级
export const rebatePriorityEpic = actions$ => actions$.pipe(
  ofType(actions.rebatePriority.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id, priorityLevel } = action.payload;
    const data = { id, priorityLevel }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebatePriority}${parseParam(data)}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebatePriority.SUCCESS(res.response);
      }),
      catchError(error => {
        return of(actions.rebatePriority.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);
//是否有草稿
export const hasDraftEpic = actions$ => actions$.pipe(
  ofType(actions.hasDraft.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id, } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.hasDraft}/${id}`,
      method: 'GET',
      body: { ...action.payload }
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.hasDraft.SUCCESS(res.response);
      }),
      catchError(error => of(actions.hasDraft.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

//保存/编辑返利活动基础信息
export const rebateBaseEpic = actions$ => actions$.pipe(
  ofType(actions.rebateBase.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id, rebateType, name, startTime, endTime, priorityLevel, touchType, productIds,select } = action.payload;
    const data = { id: id || null, rebateType, name, startTime, endTime, priorityLevel, touchType, productIds }
    data.select = select
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateBase}`,
      method: 'POST',
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateBase.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateBase.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
//返利活动（id查询）
export const rebateBaseInquireEpic = actions$ => actions$.pipe(
  ofType(actions.rebateBaseInquire.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id, select  } = action.payload;
    const data = { id }
    let select0=select==1?false:true
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateBaseInquire}/${id}${parseParam(data)}&select=${select0}`,
      method: 'GET',
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateBaseInquire.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateBaseInquire.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 查询返利方案列表
export const rebateCaseListEpic = actions$ => actions$.pipe(
  ofType(actions.rebateCaseList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id ,select} = action.payload;
    const data = { id }
    select?(data.select = true):''
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateCaseList}${parseParam(data)}`,
      method: 'GET',
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateCaseList.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateCaseList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//新增方案
export const rebateCaseAddEpic = actions$ => actions$.pipe(
  ofType(actions.rebateCaseAdd.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, cases } = action.payload;
    const data = { cases }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateCaseAdd}`,
      method: 'POST',
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateCaseAdd.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateCaseAdd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//排序
export const rebateCaseSortEpic = actions$ => actions$.pipe(
  ofType(actions.rebateCaseSort.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, newlist } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateCaseSort}`,
      method: 'POST',
      body: newlist
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateCaseSort.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateCaseSort.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//获取返利方案
export const rebateCaseInquireEpic = actions$ => actions$.pipe(
  ofType(actions.rebateCaseInquire.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id,select } = action.payload;
    let data = {id}
    select?(data.select = true):""
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateCaseInquire}/${parseInt(id)}${parseParam(data)}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateCaseInquire.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateCaseInquire.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//获取返利方案规则
export const rebateCaseGetRuleEpic = actions$ => actions$.pipe(
  ofType(actions.rebateCaseGetRule.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateCaseGetRule}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateCaseGetRule.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateCaseGetRule.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//新增奖励
export const rebateAddpointEpic = actions$ => actions$.pipe(
  ofType(actions.rebateAddpoint.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id, name } = action.payload;
    let newData = { id, name }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateAddpoint}`,
      method: 'POST',
      body: newData
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateAddpoint.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateAddpoint.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//获取奖励列表
export const rebateGetPointEpic = actions$ => actions$.pipe(
  ofType(actions.rebateGetPoint.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateGetPoint}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateGetPoint.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateGetPoint.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//
export const rebateCaseSaveCaseEpic = actions$ => actions$.pipe(
  ofType(actions.rebateCaseSaveCase.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, params } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateCaseSaveCase}`,
      method: 'POST',
      body: { ...params }
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateCaseSaveCase.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateCaseSaveCase.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//publish 发布 
export const rebatePublishEpic = actions$ => actions$.pipe(
  ofType(actions.rebatePublish.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebatePublish}${id}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebatePublish.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebatePublish.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//删除草稿活动 
export const rebateDelEpic = actions$ => actions$.pipe(
  ofType(actions.rebateDel.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, id } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateDel}${id}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateDel.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateDel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

//返利明细（分页查询）
export const rebateDetailListEpic = actions$ => actions$.pipe(
  ofType(actions.rebateDetailList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback, word, touchType, pointIds, page = 0, size = 20 } = action.payload;
    let data = { word, touchType, pointIds, page, size }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.rebate.rebateDetailList}${parseParam(data)}`,
      method: 'GET',
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.rebateDetailList.SUCCESS(res.response);
      }),
      catchError(error => of(actions.rebateDetailList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 导出返利明细
export const exportRebateDetailEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.exportRebateDetail.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { word, touchType, pointIds } = action.payload;
    let data = { word, touchType, pointIds }
    // const { productPageParam = {}, productSearchKey = '' } = state$.value.products;
    return ajax({
      ...ajaxBaseConfig,
      // url: `${urls.data.products.products}/excel?sort=${productPageParam.sort}&brandIds=${productPageParam.brandId}&word=${productSearchKey}`,
      url: `${urls.rebate.exportRebateDetail}${parseParam(data)}`,
    }).pipe(
      concatMap(res => [actions.exportRebateDetail.SUCCESS(res.response), uiActions.notification('文件导出成功，请注意查收', 'success')]),
      catchError(error => of(actions.exportRebateDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);