import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'miniProgramMaterials';

/**
 * TODO: 微信管理
 */

export const miniProgramMaterials = fasterActions(moduleName, 'miniProgramMaterials');
export const miniProgramMaterialsById = fasterActions(moduleName, 'miniProgramMaterialsById');
export const modifyMiniProgramMaterials = fasterActions(moduleName, 'modifyMiniProgramMaterials');



const initialState = {

}

const reducer = handleActions(
  {
    [miniProgramMaterials.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [miniProgramMaterials.SUCCESS]: (state, { payload }) => ({ ...state, miniProgramMaterialsList: payload, loading: false }),
    [miniProgramMaterials.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [miniProgramMaterialsById.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [miniProgramMaterialsById.SUCCESS]: (state, { payload }) => ({ ...state, miniProgramMaterialsById: payload }),
    [miniProgramMaterialsById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [modifyMiniProgramMaterials.REQUEST]: (state, { payload }) => ({ ...state, payload, miniProgramMaterialsLoaded: '', miniProgramMaterialsModifyLoaded: '' }),
    [modifyMiniProgramMaterials.SUCCESS]: (state, { payload }) => {
      let miniProgramMaterialsLoaded = '', miniProgramMaterialsModifyLoaded = '';
      const { method, status } = payload;
      switch (method) {
        case 'PATCH': case 'DELETE':
          miniProgramMaterialsLoaded = status;
          break;
        case 'POST': case 'PUT':
          miniProgramMaterialsModifyLoaded = status;
          break;
      }
      return ({ ...state, miniProgramMaterialsLoaded, miniProgramMaterialsModifyLoaded })
    },
    [modifyMiniProgramMaterials.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



  },

  initialState
);

export default reducer;
