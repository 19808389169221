import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'thirdPartCodeHandle';

/**
 * TODO: 微信管理
 */

export const thirdPartCodeHandle = fasterActions(moduleName, 'thirdPartCodeHandle');
export const thirdPartCodeHandleModify = fasterActions(moduleName, 'thirdPartCodeHandleModify');






const initialState = {

}

const reducer = handleActions(
  {

    [thirdPartCodeHandle.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [thirdPartCodeHandle.SUCCESS]: (state, { payload }) => ({ ...state, thirdPartCodeHandleList: payload, loading: false }),
    [thirdPartCodeHandle.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [thirdPartCodeHandleModify.REQUEST]: (state, { payload }) => ({ ...state, payload, thirdPartCodeHandleModifyLoaded: false }),
    [thirdPartCodeHandleModify.SUCCESS]: (state, { payload }) => ({ ...state, thirdPartCodeHandleModifyLoaded: payload }),
    [thirdPartCodeHandleModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },

  initialState
);

export default reducer;
