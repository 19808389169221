import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'masterDataCheck';

// 主数据核对分页列表
export const getMasterDataList = fasterActions(moduleName, 'getMasterDataList');
export const handleMasterDataRemark = fasterActions(moduleName, 'handleMasterDataRemark');


const initialState = {

    // 码数据变更
    codeDatachangeList: [],
    paging: {
        page: 0,
        size: 20,
    },
    masterDataList: {}, // 主数据核对分页列表
}

const reducer = handleActions(
    {
        // 主数据核对分页列表
        [getMasterDataList.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, }),
        [getMasterDataList.SUCCESS]: (state, { payload }) => ({ ...state, masterDataList: payload }),
        [getMasterDataList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // 主数据核对 备注修改
        [handleMasterDataRemark.REQUEST]: (state, { payload }) => ({ ...state, }),
        [handleMasterDataRemark.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [handleMasterDataRemark.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    },
    initialState
)

export default reducer;