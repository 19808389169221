import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'linkDetails';

export const getLinkDetailList = fasterActions(moduleName, 'getLinkDetailList');
export const handleLinkDetailRemark = fasterActions(moduleName, 'handleLinkDetailRemark');

const initialState = {
    paging: {
        page: 0,
        size: 20,
    },
    linkDetailList: {}, // 环节数据核对分页列表
}

const reducer = handleActions(
    {
        [getLinkDetailList.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload } }),
        [getLinkDetailList.SUCCESS]: (state, { payload }) => ({ ...state, linkDetailList: payload }),
        [getLinkDetailList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [handleLinkDetailRemark.REQUEST]: (state, { payload }) => ({ ...state }),
        [handleLinkDetailRemark.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [handleLinkDetailRemark.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },
    initialState
)

export default reducer;