import React from "react";

const SvgBackArrow = props => (
  <svg
    className="back_arrow_svg__icon"
    viewBox="0 0 1024 1024"
    width={200}
    height={200}
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path
      d="M290.133 448H896v85.333H290.133L422.4 665.6l-59.733 59.733L128 490.667 362.667 256l59.733 59.733L290.133 448z"
      fill="#1469FB"
    />
  </svg>
);

export default SvgBackArrow;
