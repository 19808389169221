import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './accessories';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadSeqnumUnauditedsEpic = actions$ => actions$.pipe(
  ofType(actions.loadSeqnumUnauditeds.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '',idOrName2='', factoryIds = '', page = 0, size = 20, sort = 'id,desc', } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.accessories.accessories}?packgeCode=${idOrName}&fileName=${idOrName2}&accessoriesStatus=${factoryIds?factoryIds.length!=0?factoryIds:'RELEVANCING,REGISTERED,REGISTING,REGIST_FAIL,RELEVANCE_SUCCESS,RELEVANCE_FAILD,REVOCATION_ACTIVATION':'RELEVANCING,REGISTERED,REGISTING,REGIST_FAIL,RELEVANCE_SUCCESS,RELEVANCE_FAILD,REVOCATION_ACTIVATION'}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadSeqnumUnauditeds.SUCCESS(res.response)),
      catchError(error => of(actions.loadSeqnumUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const loadSeqnumPassesEpic = actions$ => actions$.pipe(
  ofType(actions.loadSeqnumPasses.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', idOrName2 = '',  factoryIds = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.accessories.accessories}?packgeCode=${idOrName}&fileName=${idOrName2}&accessoriesStatus=${factoryIds?factoryIds.length!=0?factoryIds:'TO_BE_ACTIVATED,ACTIVATED,ACTIVATING,REVOCATIONING,REVOCATION_FAIL':'TO_BE_ACTIVATED,ACTIVATED,ACTIVATING,REVOCATIONING,REVOCATION_FAIL'}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadSeqnumPasses.SUCCESS(res.response)),
      catchError(error => of(actions.loadSeqnumPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const accessoriesChangeEpic = actions$ => actions$.pipe(
  ofType(actions.accessoriesChange.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.code.accessories.accessories}registration`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.accessoriesChange.SUCCESS(res)
      }),
      catchError(error => of(actions.accessoriesChange.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);




export const accessoriesActiveEpic = actions$ => actions$.pipe(
  ofType(actions.accessoriesActive.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.accessories.accessories}activation`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.accessoriesActive.SUCCESS(res)
      }),
      catchError(error => of(actions.accessoriesActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const revocationEpic = actions$ => actions$.pipe(
  ofType(actions.revocation.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.accessories.accessories}revocation/activation`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.revocation.SUCCESS(res)
      }),
      catchError(error => of(actions.revocation.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
  );

  export const checkEpic = actions$ => actions$.pipe(
    ofType(actions.check.REQUEST.toString()),
    mergeMap(action => { actionApi()
      const { data, callback } = action.payload;
      return ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        body: data,
        url: urls.code.batchInformations.check(data)
      }).pipe(
        map(res => {
          // let array = [
          //   res.response,
          //   ...state$.value.plan.planAllList,
  
          // ];
          callback && callback()
          return actions.check.SUCCESS(res)
        }),
        catchError(error => of(actions.check.FAIL(error.xhr.response)) //, uiActions.notification(error.xhr.response)
        )
      )
    }
    )
  );


  
  export const accessoriesDeleteEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.accessoriesDelete.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.code.accessories.accessories}delete/${data.id}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.accessoriesDelete.SUCCESS(res.response)
            }),
            catchError(error => of(actions.accessoriesDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
