import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'dataAllots';

export const initDataAllots = createAction(`${moduleName}/initDataAllots`);

export const tagbatchAllots = fasterActions(moduleName, 'tagbatchAllots');
export const tagbatchAllotsModify = fasterActions(moduleName, 'tagbatchAllotsModify');

export const domainAllots = fasterActions(moduleName, 'domainAllots');
export const domainAllotsModify = fasterActions(moduleName, 'domainAllotsModify');

export const productAllots = fasterActions(moduleName, 'productAllots');
export const productAllotsModify = fasterActions(moduleName, 'productAllotsModify');

export const factoryAllots = fasterActions(moduleName, 'factoryAllots');
export const factoryAllotsModify = fasterActions(moduleName, 'factoryAllotsModify');

export const fieldsAllots = fasterActions(moduleName, 'fieldsAllots');
export const fieldsAllotsModify = fasterActions(moduleName, 'fieldsAllotsModify');

export const dealerAllots = fasterActions(moduleName, 'dealerAllots');
export const dealerAllotsModify = fasterActions(moduleName, 'dealerAllotsModify');

export const shopAllots = fasterActions(moduleName, 'shopAllots');
export const shopAllotsModify = fasterActions(moduleName, 'shopAllotsModify');

export const linksAllots = fasterActions(moduleName, 'linksAllots');
export const linksAllotsModify = fasterActions(moduleName, 'linksAllotsModify');

export const wechatAllots = fasterActions(moduleName, 'wechatAllots');
export const wechatAllotsModify = fasterActions(moduleName, 'wechatAllotsModify');

export const prizeAllots = fasterActions(moduleName, 'prizeAllots');
export const prizeAllotsModify = fasterActions(moduleName, 'prizeAllotsModify');

export const prizePointAllots = fasterActions(moduleName, 'prizePointAllots');
export const prizePointAllotsModify = fasterActions(moduleName, 'prizePointAllotsModify');

export const prizeCardAllots = fasterActions(moduleName, 'prizeCardAllots');
export const prizeCardAllotsModify = fasterActions(moduleName, 'prizeCardAllotsModify');

export const prizePoolAllots = fasterActions(moduleName, 'prizePoolAllots');
export const prizePoolAllotsModify = fasterActions(moduleName, 'prizePoolAllotsModify');

export const datasAllots = fasterActions(moduleName, 'datasAllots');
export const datasAllotsModify = fasterActions(moduleName, 'datasAllotsModify');


export const leaveInfoAllots = fasterActions(moduleName, 'leaveInfoAllots');
export const leaveInfoAllotsModify = fasterActions(moduleName, 'leaveInfoAllotsModify');


export const deptdAllots = fasterActions(moduleName, 'deptdAllots');


export const userProductAllots = fasterActions(moduleName, 'userProductAllots');
export const userProductAllotsModify = fasterActions(moduleName, 'userProductAllotsModify');

const initialState = {

}

const reducer = handleActions(
  {
    [initDataAllots]: (state, { payload }) => ({}),

    // 码印刷规格
    [tagbatchAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [tagbatchAllots.SUCCESS]: (state, { payload }) => {
      let { tagbatchAllots, tagbatchAllotsSelector, tagbatchAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          tagbatchAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          tagbatchAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        tagbatchAllots = payload;
      }

      return ({ ...state, tagbatchAllots, tagbatchAllotsSelector, tagbatchAllotsById, loading: false })
    },
    [tagbatchAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [tagbatchAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [tagbatchAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [tagbatchAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 码域名
    [domainAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [domainAllots.SUCCESS]: (state, { payload }) => {
      let { domainAllots, domainAllotsSelector, domainAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          domainAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          domainAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        domainAllots = payload;
      }

      return ({ ...state, domainAllots, domainAllotsSelector, domainAllotsById, loading: false })
    },
    [domainAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [domainAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [domainAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [domainAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 产品信息
    [productAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [productAllots.SUCCESS]: (state, { payload }) => {
      let { productAllots, productAllotsSelector, productAllotsById, productAllotsByList } = state;
      const { content = [], deptId, isPage, callback } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          productAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          productAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        productAllots = payload;
      }

      return ({ ...state, productAllots, productAllotsSelector, productAllotsById, productAllotsByList, loading: false })
    },
    [productAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [productAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [productAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [productAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 工厂信息
    [factoryAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [factoryAllots.SUCCESS]: (state, { payload }) => {
      let { factoryAllots, factoryAllotsSelector, factoryAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          factoryAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          factoryAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        factoryAllots = payload;
      }

      return ({ ...state, factoryAllots, factoryAllotsSelector, factoryAllotsById, loading: false })
    },
    [factoryAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [factoryAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [factoryAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [factoryAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 数据字典
    [fieldsAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [fieldsAllots.SUCCESS]: (state, { payload }) => {
      let { fieldsAllots, fieldsAllotsSelector, fieldsAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          fieldsAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          fieldsAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        fieldsAllots = payload;
      }

      return ({ ...state, fieldsAllots, fieldsAllotsSelector, fieldsAllotsById, loading: false })
    },
    [fieldsAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [fieldsAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [fieldsAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [fieldsAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 经销商信息
    [dealerAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [dealerAllots.SUCCESS]: (state, { payload }) => {
      let { dealerAllots, dealerAllotsSelector, dealerAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          dealerAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          dealerAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        dealerAllots = payload;
      }

      return ({ ...state, dealerAllots, dealerAllotsSelector, dealerAllotsById, loading: false })
    },
    [dealerAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [dealerAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [dealerAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [dealerAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    // 门店信息
    [shopAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [shopAllots.SUCCESS]: (state, { payload }) => {
      let { shopAllots, shopAllotsSelector, shopAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          shopAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          shopAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        shopAllots = payload;
      }

      return ({ ...state, shopAllots, shopAllotsSelector, shopAllotsById, loading: false })
    },
    [shopAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [shopAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [shopAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [shopAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 环节字段
    [linksAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [linksAllots.SUCCESS]: (state, { payload }) => {
      let { linksAllots, linksAllotsSelector, linksAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          linksAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          linksAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        linksAllots = payload;
      }

      return ({ ...state, linksAllots, linksAllotsSelector, linksAllotsById, loading: false })
    },
    [linksAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [linksAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [linksAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [linksAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 微信商户号
    [wechatAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [wechatAllots.SUCCESS]: (state, { payload }) => {
      let { wechatAllots, wechatAllotsSelector, wechatAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          wechatAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          wechatAllotsSelector = content.map(val => ({ value: val.id, text: `${val.merchantId || ''}/${val.appId || ''}`, id: val.id }));
        }
      } else {
        // 当前微信商户号数据
        wechatAllots = payload;
      }

      return ({ ...state, wechatAllots, wechatAllotsSelector, wechatAllotsById, loading: false })
    },
    [wechatAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [wechatAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [wechatAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [wechatAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),



    // 奖品信息
    [prizeAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [prizeAllots.SUCCESS]: (state, { payload }) => {
      let { prizeAllots, prizeAllotsSelector, prizeAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          prizeAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          prizeAllotsSelector = content.map(val => ({ value: val.id, text: (val.name || '') + (val.remark ? ('(' + val.remark) + ')' : ''), id: val.id }));
        }
      } else {
        // 当前部门数据
        prizeAllots = payload;
      }

      return ({ ...state, prizeAllots, prizeAllotsSelector, prizeAllotsById, loading: false })
    },
    [prizeAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [prizeAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [prizeAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [prizeAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    // 奖品信息2
    [prizePointAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [prizePointAllots.SUCCESS]: (state, { payload }) => {
      let { prizePointAllots, prizePointAllotsSelector, prizePointAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          prizePointAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          prizePointAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        prizePointAllots = payload;
      }

      return ({ ...state, prizePointAllots, prizePointAllotsSelector, prizePointAllotsById, loading: false })
    },
    [prizePointAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [prizePointAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [prizePointAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [prizePointAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // 奖品信息3
    [prizeCardAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [prizeCardAllots.SUCCESS]: (state, { payload }) => {
      let { prizeCardAllots, prizeCardAllotsSelector, prizeCardAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          prizeCardAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          prizeCardAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        prizeCardAllots = payload;
      }

      return ({ ...state, prizeCardAllots, prizeCardAllotsSelector, prizeCardAllotsById, loading: false })
    },
    [prizeCardAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [prizeCardAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [prizeCardAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [prizeCardAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),



    // 奖池信息
    [prizePoolAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [prizePoolAllots.SUCCESS]: (state, { payload }) => {
      let { prizePoolAllots, prizePoolAllotsSelector, prizePoolAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          prizePoolAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          prizePoolAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        prizePoolAllots = payload;
      }

      return ({ ...state, prizePoolAllots, prizePoolAllotsSelector, prizePoolAllotsById, loading: false })
    },
    [prizePoolAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [prizePoolAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [prizePoolAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [prizePoolAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    // 主数据信息
    [datasAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [datasAllots.SUCCESS]: (state, { payload }) => {
      let { datasAllots, datasAllotsSelector, datasAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        if (deptId) {
          datasAllotsById = content.map(val => val.id);
        } else {
          datasAllotsSelector = content.map(val => ({ ...val, text: val.name, value: val.code }));
        }
      } else {
        datasAllots = payload;
      }

      return ({ ...state, datasAllots, datasAllotsSelector, datasAllotsById, loading: false })
    },
    [datasAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [datasAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [datasAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [datasAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    // 留资类型
    [leaveInfoAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [leaveInfoAllots.SUCCESS]: (state, { payload }) => {
      let { leaveInfoAllots, leaveInfoAllotsSelector, leaveInfoAllotsById } = state;
      const { content = [], deptId, isPage } = payload;
      if (isPage === false) {
        // 不分页数据
        if (deptId) {
          leaveInfoAllotsById = content.map(val => val.id);
        } else {
          // 账号所有数据
          leaveInfoAllotsSelector = content.map(val => ({ value: val.id, text: val.name || '', id: val.id }));
        }
      } else {
        // 当前部门数据
        leaveInfoAllots = payload;
      }

      return ({ ...state, leaveInfoAllots, leaveInfoAllotsSelector, leaveInfoAllotsById, loading: false })
    },
    [leaveInfoAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [leaveInfoAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, allotsModifyLoaded: '' }),
    [leaveInfoAllotsModify.SUCCESS]: (state, { payload }) => ({ ...state, allotsModifyLoaded: payload, loading: false }),
    [leaveInfoAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    // 部门权限
    [deptdAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [deptdAllots.SUCCESS]: (state, { payload }) => {
      // const { callback} = payload;
      // console.log('payload:',payload)
      // callback && callback(payload.content)
      return { ...state, deptedAuthList: payload }
    },
    [deptdAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    // 用户关系绑定接口下拉框接口 查询产品
    [userProductAllots.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [userProductAllots.SUCCESS]: (state, { payload }) => {
      // console.log('payload:',payload)
      return { ...state, userProductList: payload }
    },
    [userProductAllots.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    // 用户绑定产品
    [userProductAllotsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [userProductAllotsModify.SUCCESS]: (state, { payload }) => {
      return { ...state }
    },
    [userProductAllotsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
  },
  initialState
)

export default reducer;