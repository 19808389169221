import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './merchant';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 商户号
 */

// Epics
// 分页查询商户号接口
export const merchantEpic = actions$ => actions$.pipe(
    ofType(actions.merchant.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { appId = '', page, size } = action.payload;
        // let url = `${urls.wechat.merchant.merchant}?page=${page}&size=${size}&sort=id,desc`;
        // if (appId) {
        //     url += `&appId=${appId}`;
        // }
        return ajax({
            ...apiBaseConfig,
            url: `${urls.wechat.merchant.merchant}?appId=${appId}`,
        }).pipe(
            map(res => actions.merchant.SUCCESS(res.response)),
            catchError(error => of(actions.merchant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 根据id查询商户号
export const merchantByIdEpic = actions$ => actions$.pipe(
    ofType(actions.merchantById.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.wechat.merchant.merchant}?id=${id}`,
        }).pipe(
            map(res => actions.merchantById.SUCCESS(res.response)),
            catchError(error => of(actions.merchantById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 添加/修改 商户号接口
export const merchantModifyEpic = actions$ => actions$.pipe(
    ofType(actions.merchantModify.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: urls.wechat.merchant.merchant,
            body: action.payload,
            method: id ? 'PUT' : 'POST'
        }).pipe(
            mergeMap(res => [actions.merchantModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]),
            catchError(error => of(actions.merchantModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 添加/修改 v3弹窗商户号接口
export const v3MerchantModifyEpic = actions$ => actions$.pipe(
    ofType(actions.v3MerchantModify.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        // debugger
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: urls.wechat.merchant.v3Merchant,
            body: action.payload,
            method: id ? 'PUT' : 'POST'
        }).pipe(
            // concatMap(res => [actions.v3MerchantModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success'), actions.merchant.SUCCESS(res.response)]),

            // 刷新
            mergeMap(res => [actions.v3MerchantModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success'), actions.merchant.SUCCESS(res.response)]),

            // mergeMap(res => {
            //     callback && callback();
            //     return [actions.v3MerchantModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]
            // }),
            catchError(error => of(actions.v3MerchantModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 商户号选框
export const loadMerchantSelectioinsEpic = actions$ => actions$.pipe(
    ofType(actions.loadMerchantSelectioins.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { appId } = action.payload;
        let url = `${urls.wechat.merchant.merchant}/allots?isPage=false`;
        if (appId) {
            url += `&appId=${appId}`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url,
            //  url: `${urls.wechat.merchant.merchant}/selections?appId=${appId}`,
            // body: action.payload,
            method: 'GET'
        }).pipe(
            map(res => actions.loadMerchantSelectioins.SUCCESS(res.response)),
            catchError(error => of(actions.loadMerchantSelectioins.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
