import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'labelHistory';

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);

const initialState = {
    dictPaging: {
        page: 0,
        size: 20,
    },
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, dictPaging: { ...payload } }
        },
    },
    initialState
)

export default reducer;