import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './member';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// Epics
export const getMemberTagEpic = actions$ => actions$.pipe(
    ofType(actions.getMemberTag.REQUEST),
    mergeMap(action => {
        actionApi()
        const url = urls.data.memberTag.selectionsNew + '/all/selection?size=10000';
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.data.memberTag.selections}`
            url
        }).pipe(
            concatMap(res => {
                let newarray = res.response.content.map((item) => { return { ...item, id: item.id + item.groupSpecies, value: item.code, text: item.name, } })

                for (let i = 0; i < newarray.length; i++) {
                    newarray[i].tags = newarray[i].tags.map((item) => { return { ...item, value: item.code, text: item.name } })
                }



                let newarray2 = JSON.parse(JSON.stringify(newarray));

                newarray2.map((item) => {

                    item.children = item.tags;

                    item.children.map((item2) => {
                        item2.id = item.code + '|||' + item2.code + '|||' + item.groupSpecies + '|||' + (item2.memberTagUserType || '');
                        item2.value = item.code + '|||' + item2.code + '|||' + item.groupSpecies + '|||' + (item2.memberTagUserType || '');
                        item2.text = item2.name;


                    })


                })

                return [actions.getMemberTag.SUCCESS({ list: newarray, list2: newarray2 })]
            }),
            catchError(error => of(actions.getMemberTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getGroupSpeciesLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getGroupSpeciesLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        const { groupSpecies = '' } = state$.value.member;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.basicTag}?groupSpecies=${groupSpecies}`
        }).pipe(
            map(res => actions.getGroupSpeciesLabel.SUCCESS(res.response)),
            catchError(error => of(actions.getGroupSpeciesLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 基础标签
export const getBasicLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getBasicLabelData.REQUEST),
    mergeMap(action => {
        actionApi()
        const { groupSpecies = '' } = state$.value.member;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.basicTag}?groupSpecies=${groupSpecies}`
        }).pipe(
            map(res => actions.getBasicLabelData.SUCCESS(res.response)),
            catchError(error => of(actions.getBasicLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const enableBasicLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableBasicLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.basicTag}/enable`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => [actions.enableBasicLabel.SUCCESS(res.response), actions.getBasicLabelData.REQUEST()]),
            catchError(error => of(actions.enableBasicLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const exportBasicLabelEpic = (actions$, store) => actions$.pipe(
    ofType(actions.exportBasicLabel.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.basicTag}/export?id=${data.id}`,

        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.exportBasicLabel.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportBasicLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

/**
 * 自定义标签
 */
// 获取列表数据
export const getUserDefinedLabelDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedLabelData.REQUEST),
    mergeMap(action => {
        actionApi()
        const { groupSpecies = '' } = state$.value.member;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}?groupSpecies=${groupSpecies}&sort=createdTime,desc&size=100`
        }).pipe(
            map(res => actions.getUserDefinedLabelData.SUCCESS(res.response)),
            catchError(error => of(actions.getUserDefinedLabelData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 禁用
export const enableUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableUserDefinedLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}/enable`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => [actions.enableUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.enableUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 删除
export const deleteGroupUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteGroupUserDefinedLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}/${action.payload.groupId}`,
            method: 'DELETE',
        }).pipe(
            concatMap(res => [actions.deleteGroupUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.deleteGroupUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 更新标签组
export const groupTaskUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.groupTaskUserDefinedLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}/groupTask`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => [actions.groupTaskUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            // concatMap(res => [actions.groupTaskUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.groupTaskUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 撤回
export const cancelGroupTaskUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.cancelGroupTaskUserDefinedLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}/cancel`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => [actions.cancelGroupTaskUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.cancelGroupTaskUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


// (详情页面接口)
// 获取数据详情
export const getUserDefinedDetailEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedDetail.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}/${action.payload.id}`
        }).pipe(
            map(res => actions.getUserDefinedDetail.SUCCESS(res.response)),
            catchError(error => of(actions.getUserDefinedDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 更新单个标签
export const tagTaskUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.tagTaskUserDefinedLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        const { id = '', version = '' } = action.payload;
        const queryString = id ? `?id=${id}&version=${version}` : '';
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}/tagTask${queryString}`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => [actions.tagTaskUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedLabelData.REQUEST()]),
            catchError(error => of(actions.tagTaskUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 创建自定义标签
export const postUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postUserDefinedLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}`,
            method: 'POST',
            body: action.payload.data
        }).pipe(
            concatMap(res => {
                action.payload.callback && action.payload.callback(res.response.id || '');
                return [actions.postUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedDetail.REQUEST({ id: res.response.id || '' })]
            }),
            catchError(error => of(actions.postUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改自定义标签
export const patchUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchUserDefinedLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTagv2}`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => {
                // return [actions.patchUserDefinedLabel.SUCCESS(res.response)]
                return [actions.patchUserDefinedLabel.SUCCESS(res.response), actions.getUserDefinedDetail.REQUEST({ id: action.payload.id || '' })]
            }),
            catchError(error => of(actions.patchUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 属性标签
export const getMemberTagAttrEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberTagAttr.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.memberTagAttr}`
        }).pipe(
            map(res => actions.getMemberTagAttr.SUCCESS(res.response)),
            catchError(error => of(actions.getMemberTagAttr.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 属性标签组
export const getMemberTagAttrGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getMemberTagAttrGroup.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            // url: `${ urls.data.memberTag.memberTagAttr }`
            url: `${urls.data.memberTag.memberTagAttr}/group?dimension=${action.payload.dimension}`,
        }).pipe(
            map(res => actions.getMemberTagAttrGroup.SUCCESS(res.response.content || [])),
            catchError(error => of(actions.getMemberTagAttrGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


// 获取列表数据
export const getUserDefinedLabelDatav2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedLabelDatav2.REQUEST),
    mergeMap(action => {
        actionApi()
        const { groupSpecies = '', tagGroupStatus = '', type = '' } = action.payload;
        let url = `${urls.data.memberTag.userDefineGroup}/page?groupSpecies=${groupSpecies}&tagGroupStatus=${tagGroupStatus}&sort=createdTime,desc&size=100`
        if (type == 'code') {
            url = `${urls.data.memberTag.userDefineGroup}/successList`
        }
        else if (type == 'codeing') {

            url = `${urls.data.memberTag.userDefineGroup}/inProcessList`
        }
        return ajax({
            ...ajaxBaseConfig,
            url
        }).pipe(
            map(res => actions.getUserDefinedLabelDatav2.SUCCESS(res.response)),
            catchError(error => of(actions.getUserDefinedLabelDatav2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 创建自定义标签组v2
export const postUserDefinedLabelv2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.postUserDefinedLabelv2.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefineGroup}`,
            method: data.id ? 'PUT' : 'POST',
            body: data,
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.postUserDefinedLabelv2.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.postUserDefinedLabelv2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const delUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delUserDefinedLabel.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefineGroup}/${data}`,
            method: 'delete',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.delUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.delUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const enableUserDefinedLabelv2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableUserDefinedLabelv2.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, enable, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.data.memberTag.userDefineGroup}/enable`,
            body: {
                status: enable,
                id
            }
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.enableUserDefinedLabelv2.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.enableUserDefinedLabelv2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const cancelUserDefinedLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.cancelUserDefinedLabel.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefineGroup}/cancel`,
            method: 'POST',
            body: { ids: data }
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.cancelUserDefinedLabel.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.cancelUserDefinedLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


// 获取数据详情v2
export const getUserDefinedDetailv2Epic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedDetailv2.REQUEST),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefineGroup}/${id}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.getUserDefinedDetailv2.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getUserDefinedDetailv2.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const loadUserDefinedHistoriesByIdEpic = (actions$, store) => actions$.pipe(
    ofType(actions.loadUserDefinedHistoriesById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keywords, groupId, page = 0, size = 10, sort } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefineGroup}/pagePublishHistory?keywords=${keywords}&tagGroupId=${groupId}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadUserDefinedHistoriesById.SUCCESS(res.response)),
            catchError(error => of(actions.loadUserDefinedHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadUserTagHistoriesEpic = (actions$, store) => actions$.pipe(
    ofType(actions.loadUserTagHistories.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { historyId = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefineGroup}/publishHistoryDetail?historyId=${historyId}`
        }).pipe(
            map(res => actions.loadUserTagHistories.SUCCESS(res.response)),
            catchError(error => of(actions.loadUserTagHistories.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);


export const getUserDefinedEnumEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getUserDefinedEnum.REQUEST),
    mergeMap(action => {
        actionApi()
        const { callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefineEnum}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.getUserDefinedEnum.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.getUserDefinedEnum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



export const getUserDefinedTagByIdEpic = actions$ => actions$.pipe(
    ofType(actions.getUserDefinedTagById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTag}/${id}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.getUserDefinedTagById.SUCCESS(res.response);

            }),
            catchError(error => of(actions.getUserDefinedTagById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
)
//保存环节方案(自定义标签)
export const addUserDefinedTagEpic = actions$ => actions$.pipe(
    ofType(actions.addUserDefinedTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: data.id ? 'PUT' : 'POST',
            body: data,
            url: `${urls.data.memberTag.userDefinedTag}`,
        }).pipe(
            map(res => {

                callback && callback()
                return actions.addUserDefinedTag.SUCCESS(res.response);

            }),
            catchError(error => of(actions.addUserDefinedTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
)



export const delUserDefinedTagEpic = actions$ => actions$.pipe(
    ofType(actions.delUserDefinedTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.data.memberTag.userDefinedTag}/${id}`
        }).pipe(
            map(res => {

                callback && callback()
                return actions.delUserDefinedTag.SUCCESS(res.response);

            }),
            catchError(error => of(actions.delUserDefinedTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
)
export const enabelUserDefinedTagEpic = actions$ => actions$.pipe(
    ofType(actions.enabelUserDefinedTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, enable, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.data.memberTag.userDefinedTag}/enable`,
            body: {
                status: enable,
                id
            }
        }).pipe(
            map(res => {

                callback && callback()
                return actions.enabelUserDefinedTag.SUCCESS(res.response);

            }),
            catchError(error => of(actions.enabelUserDefinedTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
)



export const publishUserDefinedTagEpic = (actions$, store) => actions$.pipe(
    ofType(actions.publishUserDefinedTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            url: `${urls.data.memberTag.userDefineGroup}/publish`,
            // url: `${urls.promotion.promotions.publish(action.payload.id)}?&authType=${action.payload.authType}`,
            body: data
        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.publishUserDefinedTag.SUCCESS(res.response)
            }),
            catchError(error => of(actions.publishUserDefinedTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const exportUserDefinedTagEpic = (actions$, store) => actions$.pipe(
    ofType(actions.exportUserDefinedTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.userDefinedTag}/export?id=${data.tagId}`,

        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.exportUserDefinedTag.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportUserDefinedTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);




//手动标签
export const getManualTagTableEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getManualTagTable.REQUEST),
    mergeMap(action => {
        actionApi()
        const { groupSpecies = 'MANUAL' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}?groupSpecies=${groupSpecies}&sort=created_time,desc`,
        }).pipe(
            map(res => actions.getManualTagTable.SUCCESS(res.response)),
            catchError(error => of(actions.getManualTagTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 禁用/启用手动标签
export const enableOrDisabledManualTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledManualTag.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}/enable`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => [actions.enableOrDisabledManualTag.SUCCESS(res.response), actions.getManualTagTable.REQUEST()]),
            catchError(error => of(actions.enableOrDisabledManualTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 创建标签组
export const postManualTagGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postManualTagGroup.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.postManualTagGroup.SUCCESS(res.response), actions.getManualTagTable.REQUEST()]
            }),
            catchError(error => of(actions.postManualTagGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 修改标签
export const editManualTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editManualTag.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}`,
            method: 'PATCH',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback();
                return [actions.editManualTag.SUCCESS(res.response), actions.getManualTagTable.REQUEST()]
            }),
            catchError(error => of(actions.editManualTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 更新标签组
export const patchManualTagGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchManualTagGroup.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}/groupTask`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => {
                // callback && callback(res.response);
                return [actions.patchManualTagGroup.SUCCESS(res.response), actions.getManualTagTable.REQUEST()]
            }),
            catchError(error => of(actions.patchManualTagGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 获取标签详情
export const getManualTagInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getManualTagInfo.REQUEST),
    mergeMap(action => {
        actionApi()
        const { manualId, callback } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}/${manualId}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.getManualTagInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getManualTagInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 导入
export const uploadManualEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.uploadManual.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, formData, callback } = action.payload || {};
        return ajax({
            // ...ajaxBaseConfig,
            ...apiBaseConfig,
            url: `${urls.data.memberTag.manualTag}/upload?tagId=${data.tagId}`,
            method: 'POST',
            body: formData
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.uploadManual.SUCCESS(res.response), actions.importHistory.REQUEST(state$.value.member.importPaging)]
            }),
            catchError(error => of(actions.uploadManual.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 导入记录
export const importHistoryEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importHistory.REQUEST),
    mergeMap(action => {
        const { page = 0, size = 20, query = '' } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}/uploadLog?page=${page}&size=${size}&query=${query}`,
        }).pipe(
            map(res => {
                return actions.importHistory.SUCCESS({ ...res.response, query })
            }),
            catchError(error => of(actions.importHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 撤回
export const cancelTagLabelEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.cancelTagLabel.REQUEST),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.manualTag}/cancel`,
            method: 'PATCH',
            body: action.payload
        }).pipe(
            concatMap(res => [actions.cancelTagLabel.SUCCESS(res.response), actions.getManualTagTable.REQUEST()]),
            catchError(error => of(actions.cancelTagLabel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



//第三方标签

export const getThirdTagTableEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getThirdTagTable.REQUEST),
    mergeMap(action => {
        actionApi()
        const { groupSpecies = 'THIRD' } = action.payload || {};
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.thirdTag}?groupSpecies=${groupSpecies}&sort=created_time,desc&size=1000`,
        }).pipe(
            map(res => actions.getThirdTagTable.SUCCESS(res.response)),
            catchError(error => of(actions.getThirdTagTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const postThirdTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postThirdTag.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.thirdTag}`,
            method: data.method,
            body: data
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.postThirdTag.SUCCESS(res.response)
            }),
            catchError(error => of(actions.postThirdTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

// 导入
export const uploadThirdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.uploadThird.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, formData, callback } = action.payload || {};
        return ajax({
            // ...ajaxBaseConfig,
            ...apiBaseConfig,
            url: `${urls.data.memberTag.thirdTag}/upload?tagCode=${data.tagCode}&groupCode=${data.groupCode}&actionType=${data.actionType}`,
            method: 'POST',
            body: formData
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                // , actions.importHistory.REQUEST(state$.value.member.importPaging)
                return [actions.uploadThird.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.uploadThird.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// 导入记录
export const importThirdHistoryEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importThirdHistory.REQUEST),
    mergeMap(action => {
        const { page = 0, size = 20, sort = '', query = '' } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.thirdTag}/taskList?page=${page}&size=${size}&sort=${sort}&query=${query}`,
        }).pipe(
            map(res => {
                return actions.importThirdHistory.SUCCESS(res.response)
            }),
            catchError(error => of(actions.importThirdHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const downLoadThirdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.downLoadThird.REQUEST),
    mergeMap(action => {
        const { id, callback } = action.payload;
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.thirdTag}/download?taskId=${id}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.downLoadThird.SUCCESS(res.response)
            }),
            catchError(error => of(actions.downLoadThird.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



// 运算标签


// 获取运算标签
export const getOperationTagDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getOperationTagData.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data } = action.payload;
        let url = `${urls.data.memberTag.operation}?sort=createdTime,desc&size=100`;
        if (data == 'publishHistory') {
            url = `${urls.data.memberTag.operation}/publishHistory?sort=createdTime,desc&size=100`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url: url,
        }).pipe(
            map(res => actions.getOperationTagData.SUCCESS(res.response)),
            catchError(error => of(actions.getOperationTagData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getOperationTagByIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getOperationTagById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { groupId, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}/${groupId}`,
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.getOperationTagById.SUCCESS(res.response)]
            }),
            // catchError(error => of(actions.getOperationTagById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)





// 创建运算标签
export const postOperationTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postOperationTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}`,
            method: 'POST',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.postOperationTag.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.postOperationTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
//编辑运算标签
export const delOperationTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delOperationTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}/${data}`,
            method: 'delete',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.delOperationTag.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.delOperationTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const cancelOperationTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.cancelOperationTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}/cancel/${data}`,
            method: 'GET',
            // body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.cancelOperationTag.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.cancelOperationTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


//编辑运算标签
export const enableOperationTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOperationTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, enable, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.data.memberTag.operation}/group/${enable}/${id}`,
            data: {
                id
            }
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.enableOperationTag.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.enableOperationTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const putOperationTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putOperationTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}`,
            method: 'PUT',
            body: data
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.putOperationTag.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.putOperationTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




export const loadOperationTagHistoriesByIdEpic = (actions$, store) => actions$.pipe(
    ofType(actions.loadOperationTagHistoriesById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keywords, groupId, page = 0, size = 10, sort } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}/publishHistoryById?keywords=${keywords}&groupId=${groupId}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadOperationTagHistoriesById.SUCCESS(res.response)),
            catchError(error => of(actions.loadOperationTagHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loaTagHistoriesEpic = (actions$, store) => actions$.pipe(
    ofType(actions.loaTagHistories.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { historyId = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}/details?historyId=${historyId}`
        }).pipe(
            map(res => actions.loaTagHistories.SUCCESS(res.response)),
            catchError(error => of(actions.loaTagHistories.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);





//保存环节方案
export const addTagEpic = actions$ => actions$.pipe(
    ofType(actions.addTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: data.id ? 'PUT' : 'POST',
            body: data,
            url: `${urls.data.memberTag.operation}/tag`,
        }).pipe(
            map(res => {

                callback && callback()
                return actions.addTag.SUCCESS(res.response);

            }),
            catchError(error => of(actions.addTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
)

export const delTagEpic = actions$ => actions$.pipe(
    ofType(actions.delTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.data.memberTag.operation}/tag/${id}`,
        }).pipe(
            map(res => {

                callback && callback()
                return actions.delTag.SUCCESS(res.response);

            }),
            catchError(error => of(actions.delTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
)

export const sortTagEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.sortTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: data,
            url: `${urls.data.memberTag.operation}/sort`
        }).pipe(
            concatMap(res => {
                callback && callback()
                return [actions.sortTag.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.sortTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
);

export const enabelTagEpic = actions$ => actions$.pipe(
    ofType(actions.enabelTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, enable, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.data.memberTag.operation}/${enable}/${id}`,
            data: {
                id
            }
        }).pipe(
            map(res => {

                callback && callback()
                return actions.enabelTag.SUCCESS(res.response);

            }),
            catchError(error => of(actions.enabelTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    }
    )
)

export const publishTagEpic = (actions$, store) => actions$.pipe(
    ofType(actions.publishTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.data.memberTag.operation}/publish`,
            // url: `${urls.promotion.promotions.publish(action.payload.id)}?&authType=${action.payload.authType}`,
            body: data
        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.publishTag.SUCCESS(res.response)
            }),
            catchError(error => of(actions.publishTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);


export const exportOperationTagEpic = (actions$, store) => actions$.pipe(
    ofType(actions.exportOperationTag.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.memberTag.operation}/export?id=${data.id}`,

        }).pipe(
            map(res => {
                callback && callback(res.response)
                return actions.exportOperationTag.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportOperationTag.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
