import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeLabel';


// 自定义标签
export const getUserDefinedLabelData = fasterActions(moduleName, 'getUserDefinedLabelData');
export const enableUserDefinedLabel = fasterActions(moduleName, 'enableUserDefinedLabel');
export const deleteGroupUserDefinedLabel = fasterActions(moduleName, 'deleteGroupUserDefinedLabel');
export const getbindGroupUserDefinedLabel = fasterActions(moduleName, 'getbindGroupUserDefinedLabel');
export const bindGroupUserDefinedLabel = fasterActions(moduleName, 'bindGroupUserDefinedLabel');
export const pushGroupUserDefinedLabel = fasterActions(moduleName, 'pushGroupUserDefinedLabel');
export const pushrecordGroupUserDefinedLabel = fasterActions(moduleName, 'pushrecordGroupUserDefinedLabel');

//推送设置的部分数据
export const getPlatforms = fasterActions(moduleName, 'getPlatforms');

// upstate
export const updateUserDefinedStep = createAction(`${moduleName}/updateUserDefinedStep`);


export const postUserDefinedLabel = fasterActions(moduleName, 'postUserDefinedLabel');
export const getUserDefinedDetail = fasterActions(moduleName, 'getUserDefinedDetail');


export const patchUserDefinedLabel = fasterActions(moduleName, 'patchUserDefinedLabel');
export const patchUserDefinedLabelStep2 = fasterActions(moduleName, 'patchUserDefinedLabelStep2');
export const patchUserDefinedLabelStep3 = fasterActions(moduleName, 'patchUserDefinedLabelStep3');

// 标签分组
// 标签组菜单创建(多条同级)
export const menuBatch = fasterActions(moduleName, 'menuBatch');
export const menuTree = fasterActions(moduleName, 'menuTree');
export const menuEdit = fasterActions(moduleName, 'menuEdit');
export const menuDel = fasterActions(moduleName, 'menuDel');
export const getLabelData = fasterActions(moduleName, 'getLabelData');
export const editLabelData = fasterActions(moduleName, 'editLabelData');
export const editLabel = fasterActions(moduleName, 'editLabel');
export const delLabelData = fasterActions(moduleName, 'delLabelData');
export const disabledLabelData = fasterActions(moduleName, 'disabledLabelData');
export const delLabel = fasterActions(moduleName, 'delLabel');
export const createLabelData = fasterActions(moduleName, 'createLabelData');
export const labelSelection = fasterActions(moduleName, 'labelSelection');
export const getGroupId = fasterActions(moduleName, 'getGroupId');

// 标签分组-标签审核
export const getLabelVerifyOrDraftList = fasterActions(moduleName, 'getLabelVerifyOrDraftList');
export const deleteLabel = fasterActions(moduleName, 'deleteLabel');
export const releaseSingleLabel = fasterActions(moduleName, 'releaseSingleLabel');
export const releaseBatchLabels = fasterActions(moduleName, 'releaseBatchLabels');

// 标签分组-标签草稿
export const submitSingleLabel = fasterActions(moduleName, 'submitSingleLabel');
export const submitBatchLabels = fasterActions(moduleName, 'submitBatchLabels');


// 标签分组-标签历史
export const getLabelHistoryList = fasterActions(moduleName, 'getLabelHistoryList');

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const storeLabelSearchData = createAction(`${moduleName}/storeLabelSearchData`);
export const changeEditedStepFormData = createAction(`${moduleName}/changeEditedStepFormData`);
// 标签查询
export const getLabelSearchList = fasterActions(moduleName, 'getLabelSearchList');
export const clearLabelSearchList = createAction(`${moduleName}/clearLabelSearchList`);

// 操作环节配置-选择标签-点击选择的标签下拉的数据
export const getLinkPageLabelDropdownList = fasterActions(moduleName, 'getLinkPageLabelDropdownList');

export const clearTree = createAction(`${moduleName}/clearTree`);

// 同步标签-获取推送平台
export const getPushPlatformList = fasterActions(moduleName, 'getPushPlatformList');
// 同步标签-绑定推送的标签信息(更新删除二合一)
export const updateBindPushLabels = fasterActions(moduleName, 'updateBindPushLabels');
// 同步标签-获取绑定推送的标签信息
export const getPushLabelBindList = fasterActions(moduleName, 'getPushLabelBindList');
// 同步标签-推送数据
export const pushLabels = fasterActions(moduleName, 'pushLabels');
// 同步标签-推送列表-分页查询
export const getPushLabelPageList = fasterActions(moduleName, 'getPushLabelPageList');

//新码标签下拉框(树) 用于营销
export const loadCodeLabelSelect = fasterActions(moduleName, 'loadCodeLabelSelect');

const initialState = {
  labelSearchData: {
    labelType: '',
    selectValue: '',
    searchValue: '',
  },
  dictPaging: {
    page: 0,
    size: 20,
  },
  userDefinedLabelData: [],
  editedStepFormData: [],
  userDefinedDetail: {},
  userDefinedStep: 0,
  menuList: [],
  labelData: {},
  labelVerifyOrDraftList: {},
  labelHistoryList: {},
  labelSelectionList: [],
  labelSearchList: [],
  labelSearchLoading: false,
  linkPageLabelDropdownList: {},
  pushPlatformList: [],
  pushLabelBindList: [],
  pushLabelPageList: {},
  codeLabelSelectTwoTree: {},
  codeLabelSelectThreeTree: {},
}



const reducer = handleActions(
  {
    [storeLabelSearchData]: (state, { payload }) => {
      return { ...state, labelSearchData: { ...payload } }
    },

    [changeDictPaging]: (state, { payload }) => {
      return { ...state, dictPaging: { ...payload } }
    },

    [clearTree]: (state) => {
      return { ...state, menuList: [] }
    },

    [clearLabelSearchList]: (state) => {
      return { ...state, labelSearchList: [] }
    },

    [changeEditedStepFormData]: (state, { payload }) => {
      return { ...state, editedStepFormData: payload }
    },

    // 获取列表
    [getUserDefinedLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getUserDefinedLabelData.SUCCESS]: (state, { payload }) => ({ ...state, userDefinedLabelData: payload.content || [] }),
    [getUserDefinedLabelData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 禁用，启用
    [enableUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [enableUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enableUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 删除标签组
    [deleteGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [deleteGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deleteGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //推送设置
    [getbindGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getbindGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, bindGroupUserDefinedLabelList: payload.content || [] }),
    [getbindGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [bindGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [bindGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state }),
    [bindGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getPlatforms.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getPlatforms.SUCCESS]: (state, { payload }) => ({ ...state, platforms: payload.content || [] }),
    [getPlatforms.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //推送标签
    [pushGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload, loadpushGroupUserDefinedLabel: true }),
    [pushGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, loadpushGroupUserDefinedLabel: false }),
    [pushGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadpushGroupUserDefinedLabel: false }),

    //推送历史
    [pushrecordGroupUserDefinedLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload, loadpushrecordGroupUserDefinedLabel: true }),
    [pushrecordGroupUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, loadpushrecordGroupUserDefinedLabel: false, pushrecordGroupUserDefinedLabelList: payload }),
    [pushrecordGroupUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadpushrecordGroupUserDefinedLabel: false, }),




    //翻页
    [updateUserDefinedStep]: (state, { payload }) => { return { ...state, ...payload } },

    // 创建
    [postUserDefinedLabel.REQUEST]: (state, { payload }) => { return { ...state, changeUserDefinedLoading: true } },
    [postUserDefinedLabel.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: false }),
    [postUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, error: payload }),

    // 获取详情
    [getUserDefinedDetail.REQUEST]: (state, { payload }) => ({ ...state, userDefinedDetailLoading: true }),
    [getUserDefinedDetail.SUCCESS]: (state, { payload }) => { return { ...state, userDefinedDetail: payload || {}, userDefinedDetailLoading: false } },
    [getUserDefinedDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, userDefinedDetailLoading: false }),
    // 修改标签组
    [patchUserDefinedLabel.REQUEST]: (state, { payload }) => { return { ...state, changeUserDefinedLoading: true, } },
    [patchUserDefinedLabel.SUCCESS]: (state, { payload }) => { return { ...state, changeUserDefinedLoading: false } },
    [patchUserDefinedLabel.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading: true, error: payload }),


    [patchUserDefinedLabelStep2.REQUEST]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, ...payload }),
    [patchUserDefinedLabelStep2.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: false }),
    [patchUserDefinedLabelStep2.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, error: payload }),


    [patchUserDefinedLabelStep3.REQUEST]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, ...payload }),
    [patchUserDefinedLabelStep3.SUCCESS]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: false }),
    [patchUserDefinedLabelStep3.FAIL]: (state, { payload }) => ({ ...state, changeUserDefinedLoading3: true, error: payload }),

    [menuBatch.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [menuBatch.SUCCESS]: (state, { payload }) => ({ ...state }),
    [menuBatch.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [menuTree.REQUEST]: (state, { payload }) => { return { ...state, ...payload, labelData: [], menuList: [] } },
    [menuTree.SUCCESS]: (state, { payload }) => ({ ...state, menuList: payload }),
    [menuTree.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [menuEdit.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [menuEdit.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [menuEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [menuDel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [menuDel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [menuDel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload, labelData: [] }),
    [getLabelData.SUCCESS]: (state, { payload }) => ({ ...state, labelData: payload }),
    [getLabelData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [editLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [editLabelData.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editLabelData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [editLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [editLabel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [delLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [delLabelData.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [delLabelData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getLabelVerifyOrDraftList.REQUEST]: (state, { payload }) => ({ ...state, ...payload, labelVerifyOrDraftList: {} }),
    [getLabelVerifyOrDraftList.SUCCESS]: (state, { payload }) => ({ ...state, labelVerifyOrDraftList: payload }),
    [getLabelVerifyOrDraftList.FAIL]: (state, { payload }) => ({ ...state, error: payload, labelVerifyOrDraftList: {} }),

    [getLabelHistoryList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getLabelHistoryList.SUCCESS]: (state, { payload }) => ({ ...state, labelHistoryList: payload }),
    [getLabelHistoryList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [deleteLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [deleteLabel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [deleteLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [releaseSingleLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [releaseSingleLabel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [releaseSingleLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [releaseBatchLabels.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [releaseBatchLabels.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [releaseBatchLabels.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [submitSingleLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [submitSingleLabel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [submitSingleLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [submitBatchLabels.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [submitBatchLabels.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [submitBatchLabels.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [delLabel.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [delLabel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [delLabel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createLabelData.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [createLabelData.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createLabelData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [labelSelection.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [labelSelection.SUCCESS]: (state, { payload }) => ({ ...state, labelSelectionList: payload }),
    [labelSelection.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getLabelSearchList.REQUEST]: (state, { payload }) => ({ ...state, labelSearchList: [], labelSearchLoading: true, }),
    [getLabelSearchList.SUCCESS]: (state, { payload }) => ({ ...state, labelSearchList: payload, labelSearchLoading: false, }),
    [getLabelSearchList.FAIL]: (state, { payload }) => ({ ...state, error: payload, labelSearchLoading: false, }),

    [getGroupId.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getGroupId.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [getGroupId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getLinkPageLabelDropdownList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [getLinkPageLabelDropdownList.SUCCESS]: (state, { payload }) => ({ ...state, linkPageLabelDropdownList: payload }),
    [getLinkPageLabelDropdownList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 新下拉框(树) 营销
    [loadCodeLabelSelect.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [loadCodeLabelSelect.SUCCESS]: (state, { payload }) => ({ ...state, codeLabelSelectTwoTree: payload.list2, codeLabelSelectThreeTree: payload.list3, }),
    [loadCodeLabelSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


     // 同步标签-获取推送平台
     [getPushPlatformList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
     [getPushPlatformList.SUCCESS]: (state, { payload }) => ({ ...state, pushPlatformList: payload }),
     [getPushPlatformList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
 
     // 同步标签-绑定推送的标签信息(更新删除二合一)
     [updateBindPushLabels.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
     [updateBindPushLabels.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
     [updateBindPushLabels.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
 
     // 同步标签-获取绑定推送的标签信息
     [getPushLabelBindList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
     [getPushLabelBindList.SUCCESS]: (state, { payload }) => ({ ...state, pushLabelBindList: payload }),
     [getPushLabelBindList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
 
     // 同步标签-推送数据
     [pushLabels.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
     [pushLabels.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
     [pushLabels.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
 
     // 同步标签-推送列表-分页查询
     [getPushLabelPageList.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
     [getPushLabelPageList.SUCCESS]: (state, { payload }) => ({ ...state, pushLabelPageList: payload }),
     [getPushLabelPageList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },
  initialState
)

export default reducer;