import React from "react";

const SvgArrowRight = props => (
  <svg
    className="arrow_right_svg__icon"
    viewBox="0 0 1024 1024"
    width={200}
    height={200}
    {...props}
  >
    <defs>
      <style />
    </defs>
    <path
      d="M430.933 490.667L256 665.6l59.733 59.733L550.4 490.667 315.733 256 256 315.733l174.933 174.934zm256 0L512 665.6l59.733 59.733L806.4 490.667 576 256l-64 59.733 174.933 174.934z"
      fill="#BBB"
    />
  </svg>
);

export default SvgArrowRight;
