import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { TabList } from '$bere';

import { changeTabbarStatus, changeNavbarBottomStatus } from '@/redux/modules/ui';
import { draftCount, draftCountTime } from '@/redux/modules/promotions/promotions';
import { draftCount as draftCountPointMall } from '@/redux/modules/pointMall/pointMall';
import { draftCount as draftCountWhiteOff } from '@/redux/modules/whiteOff/whiteOff';
import { draftCount as draftCountTask, draftCountTime as draftCountTimeTask, draftCountPublish as draftCountPublishTask } from '@/redux/modules/memberTask/memberTask';
import { draftCount as draftCountLive, draftCountTime as draftCountTimeLive, draftCountPublish as draftCountPublishLive } from '@/redux/modules/liveManageMent/liveManageMent';
@withRouter
@connect(
  (state) => (
    {
      nowRouter: state.ui.nowRouter,
      lvThreeMenuOfLvTwo: state.ui.lvThreeMenuOfLvTwo,
      draftCountResult: state.promotions.draftCount,
      draftCountTimeResult: state.promotions.draftCountTime,
      draftCountpointMallResult: state.pointMall.draftCount,
      draftCountWhiteOffResult: state.whiteOff.draftCount,

      draftCountTaskResult: state.memberTask.draftCount,
      draftCountTimeTaskResult: state.memberTask.draftCountTime,
      draftCountPublishTaskResult: state.memberTask.draftCountPublish,

      draftCountLiveResult: state.liveManageMent.draftCount,
      draftCountTimeLiveResult: state.liveManageMent.draftCountTime,
      draftCountPublishLiveResult: state.liveManageMent.draftCountPublish,
    }
  ),
  {
    changeTabbarStatus, changeNavbarBottomStatus,
    draftCount: draftCount.REQUEST,
    draftCountTime: draftCountTime.REQUEST,
    draftCountPointMall: draftCountPointMall.REQUEST,
    draftCountWhiteOff: draftCountWhiteOff.REQUEST,

    draftCountTask: draftCountTask.REQUEST,
    draftCountTimeTask: draftCountTimeTask.REQUEST,
    draftCountPublishTask: draftCountPublishTask.REQUEST,

    draftCountLive: draftCountLive.REQUEST,
    draftCountTimeLive: draftCountTimeLive.REQUEST,
    draftCountPublishLive: draftCountPublishLive.REQUEST,


  }
)
class Tabbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      needCountRouters: ['promotionnormal', 'promotiondevelop', 'promotiondraft', 'promotiontoBeReleased'],

      needCountRoutersPointerMall: ['pointsmallformal', 'pointsmalltest', 'pointsmalldraft'],
      needCountRoutersWhiteOff: ['promotionverification_normal', 'promotionverification_develop', 'promotionverification_draft'],

      needCountRoutersTask: ['membercentertaskPublish', 'membercentertaskDratf', 'membercentertaskToBeReleased'],

      needCountRoutersLive: ['promotionpublished', 'promotionunpublish', 'promotiontoBeAnnounced'],
    }
  }

  componentWillReceiveProps(nextProps) {
    const { nowRouter, draftCount, draftCountTime, draftCountPointMall, draftCountWhiteOff, draftCountTask, draftCountTimeTask, draftCountPublishTask,
      draftCountLive, draftCountTimeLive, draftCountPublishLive
    } = nextProps;

    if (this.props.nowRouter !== nowRouter && this.state.needCountRouters.includes(nowRouter)) {
      draftCount({ activeType: 'promotions' });
      draftCountTime({ activeType: 'promotions' })
    }
    else if (this.props.nowRouter !== nowRouter && this.state.needCountRoutersPointerMall.includes(nowRouter)) {
      draftCountPointMall()
    }
    else if (this.props.nowRouter !== nowRouter && this.state.needCountRoutersWhiteOff.includes(nowRouter)) {
      draftCountWhiteOff()
    }

    else if (this.props.nowRouter !== nowRouter && this.state.needCountRoutersTask.includes(nowRouter)) {
      draftCountTask()
      draftCountTimeTask()
      draftCountPublishTask()
    }
    else if (this.props.nowRouter !== nowRouter && this.state.needCountRoutersLive.includes(nowRouter)) {
      draftCountLive()
      draftCountTimeLive()
      draftCountPublishLive()
    }
  }

  getNowTabs = (nowKey) => {
    const { lvThreeMenuOfLvTwo } = this.props;
    const object = lvThreeMenuOfLvTwo;
    let result = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const element = object[key];
        element.forEach(v => {
          if (v.url === nowKey) {
            result = element;
          }
        });
      }
    }
    return result;
  }

  changeTab = (val) => {
    this.props.history.push(`/${val}`);
  }

  render() {
    const { nowRouter, draftCountResult, draftCountTimeResult, draftCountpointMallResult, draftCountWhiteOffResult, draftCountTaskResult, draftCountTimeTaskResult, draftCountPublishTaskResult,
      draftCountLiveResult, draftCountTimeLiveResult, draftCountPublishLiveResult
    } = this.props;



    const children = this.getNowTabs(nowRouter);
    const tabList = children.map(v => {
console.log(nowRouter,444)
      let number = '';
      if (this.state.needCountRouters.includes(nowRouter) && v.url === 'promotiondraft' && draftCountResult) {
        number = '(' + draftCountResult.content + ')'
      }
      else if (this.state.needCountRouters.includes(nowRouter) && v.url === 'promotiontoBeReleased' && draftCountTimeResult) {
        number = '(' + draftCountTimeResult.content + ')'
      }


      else if (this.state.needCountRoutersPointerMall.includes(nowRouter) && v.url === 'pointsmalldraft' && draftCountpointMallResult) {
        number = '(' + draftCountpointMallResult.content + ')'
      }
      else if (this.state.needCountRoutersWhiteOff.includes(nowRouter) && v.url === 'promotionverification_draft' && draftCountWhiteOffResult) {
        number = '(' + draftCountWhiteOffResult.content + ')'
      }
      else if (this.state.needCountRoutersTask.includes(nowRouter) && v.url === 'membercentertaskDratf' && draftCountTaskResult) {
        number = '(' + draftCountTaskResult.content + ')'
      }
      else if (this.state.needCountRoutersTask.includes(nowRouter) && v.url === 'membercentertaskToBeReleased' && draftCountTimeTaskResult) {
        number = '(' + draftCountTimeTaskResult.content + ')'
      }
      else if (this.state.needCountRoutersTask.includes(nowRouter) && v.url === 'membercentertaskPublish' && draftCountPublishTaskResult) {
        number = '(' + draftCountPublishTaskResult.content + ')'
      }


      else if (this.state.needCountRoutersLive.includes(nowRouter) && v.url === 'promotionunpublish' && draftCountLiveResult) {
        number = '(' + draftCountLiveResult.content + ')'
      }
      else if (this.state.needCountRoutersLive.includes(nowRouter) && v.url === 'promotiontoBeAnnounced' && draftCountTimeLiveResult) {
        number = '(' + draftCountTimeLiveResult.content + ')'
      }
      else if (this.state.needCountRoutersLive.includes(nowRouter) && v.url === 'promotionpublished' && draftCountPublishLiveResult) {
        number = '(' + draftCountPublishLiveResult.content + ')'
      }


      return {
        value: v.url,
        text: `${JSON.parse(v.name).zh_CN}${number}`
      }
    })

    return (
      <div>
        {children && children.length && children.length > 0 ?
          <div className="tabbar">
            <TabList
              list={tabList}
              value={nowRouter}
              onChange={this.changeTab}
            >
            </TabList>
          </div> : ''}

      </div>

    );
  }
}

export default Tabbar;