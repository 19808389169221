import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'labelBatchQuery';

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
// 标签码数据批量下载-分页列表
export const getLabelBatchQueryList = fasterActions(moduleName, 'getLabelBatchQueryList');
// 标签码数据批量下载-下载文件
export const handleTagBatchDownload = fasterActions(moduleName, 'handleTagBatchDownload');
// 标签码数据批量下载-标签批量下载任务创建
export const handleCreateLabelBatchQuery = fasterActions(moduleName, 'handleCreateLabelBatchQuery');

const initialState = {
    dictPaging: {
        page: 0,
        size: 20,
    },
    labelBatchQueryList: {}
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, dictPaging: { ...payload } }
        },

        [getLabelBatchQueryList.REQUEST]: (state, { payload }) => ({ ...state }),
        [getLabelBatchQueryList.SUCCESS]: (state, { payload }) => ({ ...state, labelBatchQueryList: payload, }),
        [getLabelBatchQueryList.FAIL]: (state, { payload }) => ({ ...state }),

        [handleTagBatchDownload.REQUEST]: (state, { payload }) => ({ ...state }),
        [handleTagBatchDownload.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [handleTagBatchDownload.FAIL]: (state, { payload }) => ({ ...state }),

        [handleCreateLabelBatchQuery.REQUEST]: (state, { payload }) => ({ ...state }),
        [handleCreateLabelBatchQuery.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [handleCreateLabelBatchQuery.FAIL]: (state, { payload }) => ({ ...state }),
    },
    initialState
)

export default reducer;