import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './receivers';
import { ajaxBaseConfig, actionApi } from '../../utils';

interface ReceiversPageParamsAction {
    payload: actions.ReceiversPageParams
}
interface SaveRecipientAction {
    payload: {
        eventId: string | number,
        addressBooks: (string | number)[],
        callback: () => void
    }
}
// 页面列表
export const getPageDataEpic = actions$ => actions$.pipe(
    ofType(actions.getPageData.REQUEST.toString()),
    mergeMap((action: ReceiversPageParamsAction) => {
        actionApi();

        const { page = 0, size = 20, eventId = '', warnStatus = 1, work = '' } = action.payload;
        const searchString = `?page=${page}&size=${size}&eventId=${eventId}&warnStatus=${warnStatus}&work=${work}`;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.warn.receivers.query}${searchString}`,
            method: 'GET',
        }).pipe(
            map(res => actions.getPageData.SUCCESS(res.response)),
            catchError(error => of(actions.getPageData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 弹窗列表
export const getModalDataEpic = actions$ => actions$.pipe(
    ofType(actions.getModalData.REQUEST.toString()),
    mergeMap((action: ReceiversPageParamsAction) => {
        actionApi();

        const { page = 0, size = 20, eventId = '', warnStatus = 2, work = '', callback } = action.payload;
        const searchString = `?page=${page}&size=${size}&eventId=${eventId}&warnStatus=${warnStatus}&work=${work}`;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.warn.receivers.query}${searchString}`,
            method: 'GET'
        }).pipe(
            map(res => {
                if (callback) {
                    callback(res.response)
                }
                return actions.getModalData.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getModalData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 保存 - 新增配置人員
export const saveReceiversEpic = actions$ => actions$.pipe(
    ofType(actions.saveReceivers.REQUEST.toString()),
    mergeMap((action: SaveRecipientAction) => {
        actionApi();

        const { eventId = '', addressBooks = [], callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.warn.receivers.save}`,
            method: 'POST',
            body: {
                eventId, addressBooks
            }
        }).pipe(
            map(res => {
                if (callback) {
                    callback()
                }
                return actions.getModalData.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getModalData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 删除配置人員
export const deleteOneEpic = actions$ => actions$.pipe(
    ofType(actions.deleteOne.REQUEST.toString()),
    mergeMap((action: { payload: { addressBookId: string | number, callback: () => void } }) => {
        actionApi();

        const { addressBookId = '', callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.warn.receivers.deleteOne}/${addressBookId}`,
            method: 'GET',
        }).pipe(
            map(res => {
                if (callback) {
                    callback()
                }
                return actions.deleteOne.SUCCESS(res.response)
            }),
            catchError(error => of(actions.deleteOne.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 清空配置人員
export const deleteAllEpic = actions$ => actions$.pipe(
    ofType(actions.deleteAll.REQUEST.toString()),
    mergeMap((action: { payload: { eventId: string | number, callback: () => void } }) => {
        actionApi();

        const { eventId = '', callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.warn.receivers.deleteAll}/${eventId}`,
            method: 'GET',
        }).pipe(
            map(res => {
                if (callback) {
                    callback()
                }
                return actions.deleteAll.SUCCESS(res.response)
            }),
            catchError(error => of(actions.deleteAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 预警发送方式
export const sendTypeEpic = actions$ => actions$.pipe(
    ofType(actions.sendType.REQUEST.toString()),
    mergeMap((action: { payload: { addressBookId: string | number, pitchOn: number, callback: () => void } }) => {
        actionApi();

        const { addressBookId = '', pitchOn, callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.warn.receivers.pitchOn}/${addressBookId}/${pitchOn}`,
            method: 'GET',
        }).pipe(
            map(res => {
                if (callback) {
                    callback()
                }
                return actions.sendType.SUCCESS(res.response)
            }),
            catchError(error => of(actions.sendType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);



