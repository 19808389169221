import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeSource';

export const loadCodeSourceList = fasterActions(moduleName, 'loadCodeSourceList');
export const loadSelectList = fasterActions(moduleName, 'loadSelectList');
export const createCodeSource = fasterActions(moduleName, 'createCodeSource');
export const loadCodeSourceById = fasterActions(moduleName, 'loadCodeSourceById');
export const editCodeSource = fasterActions(moduleName, 'editCodeSource');

export const getDistribution = fasterActions(moduleName, 'getDistribution');
export const editDistribution = fasterActions(moduleName, 'editDistribution');

const INIT_CREATEOREDIT = `${moduleName}/INIT_CREATEOREDIT`;

export function initCreateOrEdit() {
  return { type: INIT_CREATEOREDIT, payload: {
    codeSourceType: 'PLATFORM_STANDARD',
    attributeSeparatorEnum: 'COMMA',
    thirdPartyCodeFaced: [{
      count: 1
    }],
  } };
}

const initialState = {
  createOrEdit: {
    codeSourceType: 'PLATFORM_STANDARD',
    attributeSeparatorEnum: 'COMMA',
    thirdPartyCodeFaced: [{
      count: 1
    }],
  }
}

const reducer = handleActions(
  {
    [loadSelectList.REQUEST]: (state, { payload }) => ({ ...state, loadSelectListLoading: true }),
    [loadSelectList.SUCCESS]: (state, { payload }) => {
      let selectList = [];
      if (payload.content) {
        selectList = payload.content.map(v => {
          return {
            text: v.name,
            value: v.id
          }
        })
      }

      selectList.splice(0,0,{text:'请选择',value:''})
      return { ...state, loadSelectListLoading: false, loadCodePrintingConfigListLoaded: true, selectList };
    },
    [loadSelectList.FAIL]: (state, { payload }) => ({ ...state, loadSelectListLoading: false, loadCodePrintingConfigListLoaded: false, error: payload }),

    [loadCodeSourceList.REQUEST]: (state, { payload }) => ({ ...state, loadCodeSourceListLoading: true }),
    [loadCodeSourceList.SUCCESS]: (state, { payload }) => ({ ...state, loadCodeSourceListLoading: false, loadCodeSourceListLoaded: true, codeSourceList: payload }),
    [loadCodeSourceList.FAIL]: (state, { payload }) => ({ ...state, loadCodeSourceListLoading: false, loadCodeSourceListLoaded: false, error: payload }),

    [createCodeSource.REQUEST]: (state, { payload }) => ({ ...state, createCodeSourceLoading: true }),
    [createCodeSource.SUCCESS]: (state, { payload }) => ({ ...state, createCodeSourceLoading: false, createCodeSourceLoaded: true, createOrEdit: {
      codeSourceType: 'PLATFORM_STANDARD',
      attributeSeparatorEnum: 'COMMA',
      thirdPartyCodeFaced: [{
        count: 1
      }],
    } }),
    [createCodeSource.FAIL]: (state, { payload }) => ({ ...state, createCodeSourceLoading: false, createCodeSourceLoaded: false, error: payload }),

    [loadCodeSourceById.REQUEST]: (state, { payload }) => ({ ...state, loadCodeSourceByIdLoading: true }),
    [loadCodeSourceById.SUCCESS]: (state, { payload }) => ({ ...state, loadCodeSourceByIdLoading: false, loadCodeSourceByIdLoaded: true, createOrEdit: payload }),
    [loadCodeSourceById.FAIL]: (state, { payload }) => ({ ...state, loadCodeSourceByIdLoading: false, loadCodeSourceByIdoaded: false, error: payload }),

    [editCodeSource.REQUEST]: (state, { payload }) => ({ ...state, editCodeSourceLoading: true }),
    [editCodeSource.SUCCESS]: (state, { payload }) => ({ ...state, editCodeSourceLoading: false, editCodeSourceLoaded: true, createOrEdit: {
      codeSourceType: 'PLATFORM_STANDARD',
      attributeSeparatorEnum: 'COMMA',
      thirdPartyCodeFaced: [{
        count: 1
      }],
    } }),
    [editCodeSource.FAIL]: (state, { payload }) => ({ ...state, editCodeSourceLoading: false, editCodeSourceLoaded: false, error: payload }),




    [getDistribution.REQUEST]: (state, { payload }) => ({ ...state, getDistributionLoading: true }),
    [getDistribution.SUCCESS]: (state, { payload }) => ({ ...state, getDistributionLoading: false }),
    [getDistribution.FAIL]: (state, { payload }) => ({ ...state, getDistributionLoading: false, error: payload }),

    [editDistribution.REQUEST]: (state, { payload }) => ({ ...state, editDistributionLoading: true }),
    [editDistribution.SUCCESS]: (state, { payload }) => ({ ...state, editDistributionLoading: false }),
    [editDistribution.FAIL]: (state, { payload }) => ({ ...state, editDistributionLoading: false, error: payload }),

    [INIT_CREATEOREDIT]: (state, { payload }) => ({
      ...state, createOrEdit: payload
    }),
  },
  initialState
)



export default reducer;