import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'fieldAssign';

//字段分配
export const fieldAssignList = fasterActions(moduleName, 'fieldAssignPage');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const typeList = fasterActions(moduleName, 'typeList');
export const fieldAssignAdd = fasterActions(moduleName, 'fieldAssignAdd');
export const fieldAssignDelete = fasterActions(moduleName, 'fieldAssignDelete');
export const fieldAssignEseQuery = fasterActions(moduleName, 'fieldAssignEseQuery');

export const fieldAssignSelection = fasterActions(moduleName, 'fieldAssignSelection');

export const fieldAssignDictMap = fasterActions(moduleName, 'fieldAssignDictMap');



const initialState = {
    dictPaging: {
        page: 0,
        size: 20,
        dictDataType: "PRODUCT",
        dictTypeList:[]
    },
    fieldAssignData:{},
    typeData:[],
    eseData:[],
    fieldAssignDictMapData: [],
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, dictPaging: { ...payload} }
        },

        [fieldAssignList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [fieldAssignList.SUCCESS]: (state, { payload }) => ({ ...state, fieldAssignData: payload, loading: false }),
        [fieldAssignList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [typeList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [typeList.SUCCESS]: (state, { payload }) => ({ ...state, typeData: payload, loading: false }),
        [typeList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [fieldAssignAdd.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [fieldAssignAdd.SUCCESS]: (state, { payload }) => ({ ...state, fieldAssignData: payload, loading: false }),
        [fieldAssignAdd.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [fieldAssignDelete.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [fieldAssignDelete.SUCCESS]: (state, { payload }) => ({ ...state, fieldAssignData: payload, loading: false }),
        [fieldAssignDelete.FAIL]: (state, { payload }) => ({ ...state, loading: false }),


        [fieldAssignSelection.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [fieldAssignSelection.SUCCESS]: (state, { payload }) => ({ ...state, fieldAssignSelection: payload, loading: false }),
        [fieldAssignSelection.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [fieldAssignDictMap.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [fieldAssignDictMap.SUCCESS]: (state, { payload }) => ({ ...state, fieldAssignDictMapData: payload, loading: false }),
        [fieldAssignDictMap.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
        
        [fieldAssignEseQuery.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [fieldAssignEseQuery.SUCCESS]: (state, { payload }) => ({ ...state, eseData: payload, loading: false }),
        [fieldAssignEseQuery.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
        
    },
    initialState
);

export default reducer;
