import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'outReport';

/**
 * TODO: 
 */

export const getFlow = fasterActions(moduleName, 'getFlow');
export const getOutStatistic = fasterActions(moduleName, 'getOutStatistic');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
const initialState = {
    pageData:[],
    pageParams:{
      page:0,
      size:20,
      startTime:'',
      endTime:''
    },
    loading:false,
    tableData:{},
    tableDataFlow:{}
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload},tableData:{content:[], totalElements:0} }
    },

    [getFlow.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [getFlow.SUCCESS]: (state, { payload }) => ({ ...state, tableDataFlow: payload, loading: false }),
    [getFlow.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [getOutStatistic.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [getOutStatistic.SUCCESS]: (state, { payload }) => ({ ...state, tableData: payload, loading: false }),
    [getOutStatistic.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
  },
  initialState
);

export default reducer;
