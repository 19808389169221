import { handleActions, createAction } from "redux-actions";
import { fasterActions } from "../../utils";

const moduleName = "antiblack";

// 黑名单
export const loadBlackList = fasterActions(moduleName, "loadBlackList");
export const createBlackUser = fasterActions(moduleName, 'createBlackUser');
export const createBlackUserOne = fasterActions(moduleName, 'createBlackUserOne');
export const editBlackUser = fasterActions(moduleName, 'editBlackUser');
export const delBlackUser = fasterActions(moduleName, 'delBlackUser');
export const loadUserOne = fasterActions(moduleName, 'loadUserOne');

 export const exportBlackList = fasterActions(moduleName, "exportBlackList");
export const loadSetting = fasterActions(moduleName, "loadSetting");

export const createOrEditSetting = fasterActions(moduleName, "createOrEditSetting");


const initialState = {
  loadingblack: false,
  blackListData: { content: [] },
  //  [{
  //   user: '名称',
  //   type: 'openId',
  //   remark: '备注',
  //   createTime: new Date().getTime()
  // }, {
  //   user: '名称1',
  //   type: 'IP',
  //   remark: '备注',
  //   createTime: new Date().getTime()
  // }],
  // blackListPaging: {
  //   page: 0,
  //   size: 20,
  //   sort: "",
  //   totalElements: 0,
  //   type: '',
  //   id: '',
  //   reason: '',
  //   expiredTime: new Date(),
  //   startTime: new Date(),
  //   endTime: new Date(),
  //   status: ''
  // },

  // whiteListData: [{
  //   user: '名称',
  //   type: 'openId',
  //   remark: '备注',
  //   createTime: new Date().getTime()
  // }, {
  //   user: '名称1',
  //   type: 'IP',
  //   remark: '备注',
  //   createTime: new Date().getTime()
  // }],
  // whiteListPaging: {
  //   page: 0,
  //   size: 20,
  //   sort: "",
  //   totalElements: 0,
  //   type: '',
  //   id: '',
  //   reason: '',
  //   expiredTime: new Date(),
  //   startTime: new Date(),
  //   endTime: new Date(),
  // },
};

const reduce = handleActions(
  {
    // 黑名单
    [loadBlackList.REQUEST]: (state, { payload }) => ({ ...state, loadingblack: true, }),
    [loadBlackList.SUCCESS]: (state, { payload }) => ({ ...state, loadingblack: false, blackListData: payload }),
    [loadBlackList.FAIL]: (state, { payload }) => ({ ...state, loadingblack: false }),

    [createBlackUser.REQUEST]: (state, { payload }) => { return { ...state } },
    [createBlackUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createBlackUser.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [createBlackUserOne.REQUEST]: (state, { payload }) => { return { ...state } },
    [createBlackUserOne.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createBlackUserOne.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


    [editBlackUser.REQUEST]: (state, { payload }) => { return { ...state } },
    [editBlackUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [editBlackUser.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [delBlackUser.REQUEST]: (state, { payload }) => { return { ...state } },
    [delBlackUser.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [delBlackUser.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [exportBlackList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportBlackList.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportBlackList.FAIL]: (state, { payload }) => ({ ...state,}),
    
    [loadUserOne.REQUEST]: (state, { payload }) => { return { ...state } },
    [loadUserOne.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [loadUserOne.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),
    //查询设置
    [loadSetting.REQUEST]: (state, { payload }) => { return { ...state } },
    [loadSetting.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [loadSetting.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [createOrEditSetting.REQUEST]: (state, { payload }) => { return { ...state } },
    [createOrEditSetting.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createOrEditSetting.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),


  },
  initialState
);

export default reduce;
