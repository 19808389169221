import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax } from 'rxjs/ajax';

import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './linkDetails';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { apiBaseConfig } from '@/redux/utils';

export const getLinkDetailListEpic = actions$ => actions$.pipe(
    ofType(actions.getLinkDetailList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, linkUploadId = '', systemName = '', orderType = '', orderCode = '', startDate = '', endDate = '' } = action.payload;
        let params = `?page=${page}&size=${size}`;
        if (linkUploadId) params += `&linkUploadId=${linkUploadId}`;
        if (systemName) params += `&systemName=${systemName}`;
        // if (orderType) params += `&orderType=BANQUET_ORDER`;
        if (orderType) params += `&orderType=${orderType}`;
        if (orderCode) params += `&orderCode=${orderCode}`;
        if (startDate) params += `&startDate=${startDate}`;
        if (endDate) params += `&endDate=${endDate}`;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.dataManage.linkDataCheck.detailList}${params}`,
        }).pipe(
            map(res => actions.getLinkDetailList.SUCCESS(res.response)),
            catchError(error => of(actions.getLinkDetailList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

export const handleLinkDetailRemarkEpic = actions$ => actions$.pipe(
    ofType(actions.handleLinkDetailRemark.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, remark, callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.dataManage.linkDataCheck.detailUpdate}`,
            body: { id, remark },
            method: 'PUT'
        }).pipe(
            map(res => {
                callback && callback();
                return actions.handleLinkDetailRemark.SUCCESS(res.response);
            }),
            catchError(error => of(actions.handleLinkDetailRemark.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);