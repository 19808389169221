import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'warnRecords';

/**
 * TODO: 微信管理
 */

export const warnRecords = fasterActions(moduleName, 'warnRecords');
export const warnRecordsStatistics = fasterActions(moduleName, 'warnRecordsStatistics');
export const warnRecordsInfo = fasterActions(moduleName, 'warnRecordsInfo');






const initialState = {

}

const reducer = handleActions(
  {

    [warnRecords.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [warnRecords.SUCCESS]: (state, { payload }) => ({ ...state, warnRecordsList: payload, loading: false }),
    [warnRecords.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [warnRecordsStatistics.REQUEST]: (state, { payload }) => ({ ...state, payload, statisloading: true }),
    [warnRecordsStatistics.SUCCESS]: (state, { payload }) => ({ ...state, warnRecordsStatisticsList: payload, statisloading: false }),
    [warnRecordsStatistics.FAIL]: (state, { payload }) => ({ ...state, error: payload, statisloading: false }),

    [warnRecordsInfo.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [warnRecordsInfo.SUCCESS]: (state, { payload }) => ({ ...state, warnRecordsInfoList: payload, loading: false }),
    [warnRecordsInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
  },

  initialState
);

export default reducer;
