import { of } from 'rxjs'
import { ofType } from 'redux-observable'
import { mergeMap, map, catchError } from 'rxjs/operators'
import { ajax } from '../../ajax'
import urls from '@/api/urls'
import * as uiActions from '../ui'
import * as actions from './writeOffShop'
import { ajaxBaseConfig, actionApi,apiBaseConfig } from '../../utils'

export const writeOffStoreEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffStore.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      const {
        writeOffPolicy = [],
        terminalGroup = [],
        terminalLevel = [],
        brand = [],
        work = '',
        page = 0,
        size = 20,
        sort = 'id,desc'
      } = action.payload
      const params = new URLSearchParams()
      params.append('page', page)
      params.append('size', size)
      return ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.writeOffShop.writeOffStore}?${params.toString()}`,
        body: {
          writeOffPolicy: writeOffPolicy.join(','),
          terminalGroup: terminalGroup.join(','),
          terminalLevel: terminalLevel.join(','),
          brand: brand.join(','),
          work,
          sort
        }
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffStore.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(actions.writeOffStore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    })
  )

export const writeOffEmptyAllEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffEmptyAll.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.writeOffShop.writeOffEmptyAll}`
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffEmptyAll.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(
            actions.writeOffEmptyAll.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        )
      )
    })
  )

export const writeOffEditEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffEdit.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      return ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.writeOffShop.writeOffEdit}`,
        body: action.payload.data
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffEdit.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(actions.writeOffEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    })
  )

export const writeOffDeleteEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffDelete.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      const { id } = action.payload
      return ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.writeOffShop.writeOffDelete}/${id}`
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffDelete.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(actions.writeOffDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    })
  )

export const writeOffPolicyEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffPolicy.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      return ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.writeOffShop.writeOffPolicy}`,
        body: action.payload.data
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffPolicy.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(actions.writeOffPolicy.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    })
  )

export const writeOffStoreAllEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffStoreAll.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.writeOffShop.writeOffStoreAll}`
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffStoreAll.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(
            actions.writeOffStoreAll.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        )
      )
    })
  )

export const rewardPolicyListEpic = actions$ =>
  actions$.pipe(
    ofType(actions.rewardPolicyList.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      return ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        url: `${urls.writeOffShop.writeOffPolicyFind}`
      }).pipe(
        map(res => {
          action.payload?.callback && action.payload.callback(res.response)
          return actions.rewardPolicyList.SUCCESS(res.response)
        }),
        catchError(error =>
          of(
            actions.rewardPolicyList.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        )
      )
    })
  )

export const writeOffImgEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffImg.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      return ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.baseUrl}/tm/portal/write-off-terminal/v1/img`,
        body: action.payload.data
      }).pipe(
        map(res => {
          action.payload?.callback && action.payload.callback(res.response)
          return actions.writeOffImg.SUCCESS(res.response)
        }),
        catchError(error =>
          of(actions.writeOffImg.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    })
  )

// 核销导入
export const writeOffUploadEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffUpload.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      return ajax({
        ...apiBaseConfig,
        method: 'POST',
        url: `${urls.data.upload.uploadStore}`,
        body: action.payload.formData
      }).pipe(
        map(res => {
          action.payload?.callback && action.payload.callback(res.response)
          return actions.writeOffUpload.SUCCESS(res.response)
        }),
        catchError(error =>
          of(actions.writeOffUpload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    })
  )


// 核销明细列表
export const writeOffDetailEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffDetail.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      const {
        keywords = '',
        writeOffStartTime = '',
        writeOffEndTime = '',
        policyCodes = [],
        policyIds = [],
        writeOffModels = [],
        page = 0,
        size = 20,
        sort = 'writeOffTime,desc'
      } = action.payload
      let str = `?page=${page}&size=${size}&sort=${sort}&keyword=${keywords}&writeOffStartTime=${writeOffStartTime}&writeOffEndTime=${writeOffEndTime}&policyIds=${policyIds}&writeOffModels=${writeOffModels}`
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.writeOffShop.writeOffDetail}${str}`
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffDetail.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(actions.writeOffDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    })
  )

// 核销明细列表导出
export const writeOffDetailExportEpic = actions$ =>
  actions$.pipe(
    ofType(actions.writeOffDetailExport.REQUEST.toString()),

    mergeMap(action => {
      actionApi()
      const {
        keywords = '',
        writeOffStartTime = '',
        writeOffEndTime = '',
        policyCodes = [],
        policyIds = [],
        writeOffModels = []
      } = action.payload
      return ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.writeOffShop.writeOffDetailExport}`,
        body: {
          keyword: keywords,
          writeOffStartTime,
          writeOffEndTime,
          // policyCodes,
          policyIds,
          writeOffModels
        }
      }).pipe(
        map(res => {
          action.payload.callback && action.payload.callback(res.response)
          return actions.writeOffDetailExport.SUCCESS(res.response || [])
        }),
        catchError(error =>
          of(
            actions.writeOffDetailExport.FAIL(error.xhr.response),
            uiActions.notification(error.xhr.response)
          )
        )
      )
    })
  )
