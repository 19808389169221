import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './outReport';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

const parseParam = function params(obj) {
    let result = '';
    let item;
    for (item in obj) {
        if (obj[item] && String(obj[item])) {
            result += `&${item}=${obj[item]}`;
        }
    }
    if (result) {
        result = '?' + result.slice(1);
    }
    return result;
}


// 分页查询终端返利统计报表
export const getFlowEpic = actions$ => actions$.pipe(
    ofType(actions.getFlow.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, startTime = '', endTime = '', order = '', year = '', productCodes = [], dealerCodes = [], wareHouseCodes = [], orderTypes = [] } = action.payload;
        let data = { page, size, startTime, endTime, order, year, productCodes, dealerCodes, wareHouseCodes, orderTypes }
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.outReport.flow}${parseParam(data)}`
        }).pipe(
            map(res => actions.getFlow.SUCCESS(res.response)),
            catchError(error => of(actions.getFlow.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
// 分页查询终端返利统计报表
export const getOutStatisticEpic = actions$ => actions$.pipe(
    ofType(actions.getOutStatistic.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, order = '', year = '',startTime = '', endTime = '', productCodes = [], dealerCodes = [], wareHouseCodes = [], orderTypes = [] } = action.payload;
        let data = { page, size, order, year, productCodes, dealerCodes, wareHouseCodes, orderTypes,startTime, endTime  }
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.outReport.outStatistic}${parseParam(data)}`
        }).pipe(
            map(res => actions.getOutStatistic.SUCCESS(res.response)),
            catchError(error => of(actions.getOutStatistic.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);
