import * as React from "react";

function SvgLayout(props) {
  return (
    <svg width={12} height={14} {...props}>
      <path
        d="M6.292.875a.292.292 0 01.291.292v.583a.292.292 0 01-.291.292H1.333v9.916h4.959a.292.292 0 01.291.292v.583a.292.292 0 01-.291.292H1.333a1.167 1.167 0 01-1.165-1.108l-.001-.059V2.042C.167 1.42.654.908 1.275.876l.058-.001h4.959zm2.66 3.002l2.504 2.504a.875.875 0 01.04 1.196l-.04.042-2.504 2.504a.292.292 0 01-.412 0l-.413-.413a.292.292 0 010-.412l1.714-1.715H3.958a.292.292 0 01-.291-.291v-.584a.292.292 0 01.291-.291h5.883L8.127 4.702a.292.292 0 010-.412l.413-.413a.292.292 0 01.412 0z"
        fill="#222429"
        fillRule="nonzero"
      />
    </svg>
  );
}

export default SvgLayout;
