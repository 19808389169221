import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'amountBill';

export const loadList = fasterActions(moduleName, 'loadList');
export const loadOverview = fasterActions(moduleName, 'loadOverview');
export const downLoadAsyn = fasterActions(moduleName, 'downLoadAsyn');

const initialState = {
    loading: false,
    loaded: false,

    oLoading: false, //红包概览

    list: {},
};

const reducer = handleActions({
    [loadList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, list: payload }),
    [loadList.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, list: {}, error: payload }),

    [loadOverview.REQUEST]: (state, { payload }) => ({ ...state,  oLoading: true }),
    [loadOverview.SUCCESS]: (state, { payload }) => ({ ...state, oLoading: false, list: payload }),
    [loadOverview.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),

    [downLoadAsyn.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
    [downLoadAsyn.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [downLoadAsyn.FAIL]: (state, { payload }) => ({ ...state, oLoading: false, error: payload }),

}, initialState);

export default reducer;


