import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './certificate';
import { ajaxBaseConfig, actionApi,apiBaseConfig } from '../../utils';
// 企业证书下拉
export const loadCertificateSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadCertificateSelections.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.code.certificate.certificateUrlAll}?status=${action.payload.status}&factoryId=${action.payload.factoryId}&bindingIpc=${true}`,
      }).pipe(
        map(res => actions.loadCertificateSelections.SUCCESS(res.response.content)),
        catchError(error => of (actions.loadCertificateSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// certificateAll
// 证书列表
export const loadCertificateListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadCertificateList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    console.log(action.payload)
    // const { certificateListParam = {}, factorySearchKey= '' } = state$.value.certificate;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.certificate.certificate}?page=${action.payload.page}&size=${action.payload.size}&factoryId=${action.payload.factoryId}`,
      }).pipe(
        map(res => actions.loadCertificateList.SUCCESS(Object.assign(res.response))),
        catchError(error => of(actions.loadCertificateList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
// 工控机列表
export const loadIpcListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadIpcList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    console.log(action.payload)
    // const { certificateListParam = {}, factorySearchKey= '' } = state$.value.certificate;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.loadIpc.loadIpcList}?page=${action.payload.page}&size=${action.payload.size}&certCode=${action.payload.certCode}`,
      }).pipe(
        map(res => actions.loadIpcList.SUCCESS(Object.assign(res.response))),
        catchError(error => of(actions.loadIpcList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
// 多有工控机
export const loadIpcSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.loadIpcSelections.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.code.certificate.certificateAll}?status=${action.payload.status}&factoryId=${action.payload.factoryId}`,
      }).pipe(
        map(res => actions.loadIpcSelections.SUCCESS(res.response.content)),
        catchError(error => of (actions.loadIpcSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const loadCertificateIdEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadCertificateId.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id = '', callback } = state$.value.certificate;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.certificate.certificate}/${id}`,
      }).pipe(
        map(res => { 
          callback && callback(res.response);
          return actions.loadCertificateId.SUCCESS(Object.assign(res.response));
        }),
        catchError(error => of(actions.loadCertificateId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
export const enableOrDisabledCertificateEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.enableOrDisabledCertificate.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id = '', status = '' ,factoryId} = action.payload;
    console.log(action.payload)
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.code.certificate.certificate }/${ id }/status?status=${status}`,
        method: 'PATCH',
      }).pipe(
        concatMap(res => [actions.enableOrDisabledCertificate.SUCCESS(Object.assign(res.response)), actions.loadCertificateList.REQUEST({...state$.value.certificate.certificateListParam, page: 0 ,factoryId:factoryId})]),
        catchError(error => of(actions.enableOrDisabledCertificate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
export const enableOrDisabledIpcEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.enableOrDisabledIpc.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id = '', status = '',factoryId } = action.payload;
    console.log(factoryId)
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.code.loadIpc.loadIpcList}/${ id }/status?status=${status}`,
        method: 'PATCH',
      }).pipe(
        concatMap(res => [actions.enableOrDisabledIpc.SUCCESS(Object.assign(res.response)), actions.loadIpcList.REQUEST({...state$.value.certificate.certificateListParam, page: 0,certCode: factoryId })]),
        catchError(error => of(actions.enableOrDisabledIpc.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);


// 创建
export const loadCertificateAddEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadCertificateAdd.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.code.certificate.certificate }`,
        method: 'POST',
        body: action.payload.data,
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback(res);
          return [actions.loadCertificateAdd.SUCCESS(Object.assign(res.response)), actions.loadCertificateList.REQUEST({ ...state$.value.certificate.certificateListParam, page: 0 ,factoryId:action.payload.data.factoryId })]
        }),
        catchError(error => of(actions.loadCertificateAdd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
// 编辑
export const loadCertificateEditEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadCertificateEdit.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.code.certificate.certificate }`,
        method: 'PUT',
        body: action.payload.data,
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback(res);
          return [actions.loadCertificateEdit.SUCCESS(Object.assign(res.response)), actions.loadCertificateList.REQUEST({ ...state$.value.certificate.certificateListParam, page: 0  ,factoryId:action.payload.data.factoryId})]
        }),
        catchError(error => of(actions.loadCertificateEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);
// 工控机
export const ipcEditEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.ipcEdit.REQUEST.toString()),
  mergeMap(action => { actionApi()
    console.log(action.payload)
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.code.loadIpc.loadIpcList }`,
        method: 'PUT',
        body: action.payload.data,
      }).pipe(
        concatMap(res => {
          action.payload.callback && action.payload.callback(res);
          return [actions.ipcEdit.SUCCESS(Object.assign(res.response)),actions.loadIpcList.REQUEST({ ...state$.value.certificate.certificateListParam, page: 0, factoryId:action.payload.factoryId,certCode:action.payload.certCode })]
        }),
        catchError(error => of(actions.ipcEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);

export const factoriesEpic = actions$ => actions$.pipe(
  ofType(actions.factories.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: urls.data.factory.factories,
    }).pipe(
      map(res => actions.factories.SUCCESS(res.response)),
      catchError(error => of(actions.factories.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// 导出产品
export const exportCertificateEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.exportCertificate.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { name = '', callback } =  action.payload;
   
    return (
      ajax({
        ...apiBaseConfig,
        url: `${ urls.code.certificate.certificate}/software/download?name=${name}`,
      }).pipe(
        map(res => { 
          callback && callback(res);
          return actions.exportCertificate.SUCCESS(res.response);
        }),
        catchError(error => of(actions.exportCertificate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    )
   }
  )
);