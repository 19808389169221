import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './seqnum';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadSeqnumUnauditedsEpic = actions$ => actions$.pipe(
  ofType(actions.loadSeqnumUnauditeds.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = 'id,desc', eseSeqnumStart = '', eseSeqnumEnd = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchInformations.seqnumEnter}?query=${idOrName}&eseSeqnumStart=${eseSeqnumStart}&eseSeqnumEnd=${eseSeqnumEnd}&startTime=${applyStart}&endTime=${applyEnd}&registerStatus=${factoryIds?factoryIds.length!=0?factoryIds:'UNREGISTERED,REGISTERED,REVOCATION_ACTIVATION':'UNREGISTERED,REGISTERED,REVOCATION_ACTIVATION'}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadSeqnumUnauditeds.SUCCESS(res.response)),
      catchError(error => of(actions.loadSeqnumUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const loadSeqnumPassesEpic = actions$ => actions$.pipe(
  ofType(actions.loadSeqnumPasses.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = 'id,desc', eseSeqnumStart = '', eseSeqnumEnd = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchInformations.seqnumEnter}?query=${idOrName}&eseSeqnumStart=${eseSeqnumStart}&eseSeqnumEnd=${eseSeqnumEnd}&startTime=${applyStart}&endTime=${applyEnd}&registerStatus=${factoryIds?factoryIds.length!=0?factoryIds:'TO_BE_ACTIVATED,ACTIVATED':'TO_BE_ACTIVATED,ACTIVATED'}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadSeqnumPasses.SUCCESS(res.response)),
      catchError(error => of(actions.loadSeqnumPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const seqnumEnterEpic = actions$ => actions$.pipe(
  ofType(actions.seqnumEnter.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.code.batchInformations.seqnumEnter}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.seqnumEnter.SUCCESS(res)
      }),
      catchError(error => of(actions.seqnumEnter.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const seqnumChangeEpic = actions$ => actions$.pipe(
  ofType(actions.seqnumChange.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.batchInformations.seqnumEnter}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.seqnumChange.SUCCESS(res)
      }),
      catchError(error => of(actions.seqnumChange.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);




export const seqnumActiveEpic = actions$ => actions$.pipe(
  ofType(actions.seqnumActive.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.batchInformations.seqnumActive}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.seqnumActive.SUCCESS(res)
      }),
      catchError(error => of(actions.seqnumActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const revocationEpic = actions$ => actions$.pipe(
  ofType(actions.revocation.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      body: data,
      url: `${urls.code.batchInformations.seqnumRevocation}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.revocation.SUCCESS(res)
      }),
      catchError(error => of(actions.revocation.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
  );

  export const checkEpic = actions$ => actions$.pipe(
    ofType(actions.check.REQUEST.toString()),
    mergeMap(action => { actionApi()
      const { data, callback } = action.payload;
      return ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        body: data,
        url: urls.code.batchInformations.check(data)
      }).pipe(
        map(res => {
          // let array = [
          //   res.response,
          //   ...state$.value.plan.planAllList,
  
          // ];
          callback && callback()
          return actions.check.SUCCESS(res)
        }),
        catchError(error => of(actions.check.FAIL(error.xhr.response)) //, uiActions.notification(error.xhr.response)
        )
      )
    }
    )
  );
  export const seqnumCheckEpic = actions$ => actions$.pipe(
    ofType(actions.seqnumCheck.REQUEST.toString()),
    mergeMap(action => { actionApi()
      const { data, callback } = action.payload;
      return ajax({
        ...ajaxBaseConfig,
        method: 'GET',
        body: data,
        url: urls.code.batchInformations.seqnumCheck(data)
      }).pipe(
        map(res => {
          // let array = [
          //   res.response,
          //   ...state$.value.plan.planAllList,
  
          // ];
          callback && callback(res.response)
          return actions.seqnumCheck.SUCCESS( )
        }),
        catchError(error =>{  callback && callback(error.xhr.response) ;return of(actions.seqnumCheck.FAIL(error.xhr.response))} //, uiActions.notification(error.xhr.response)
        )
      )
    }
    )
  );

  
  export const seqnumDeleteEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.seqnumDelete.REQUEST),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.code.batchInformations.delete}/${data.id}`,
        }).pipe(
            map(res => {
                callback && callback()
                return actions.seqnumDelete.SUCCESS(res.response)
            }),
            catchError(error => of(actions.seqnumDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
// export const loadApplyReturnsEpic = actions$ => actions$.pipe(
//   ofType(actions.loadApplyReturns.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.applyReturns}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}`
//     }).pipe(
//       map(res => actions.loadApplyReturns.SUCCESS(res.response)),
//       catchError(error => of(actions.loadApplyReturns.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const sendBatchsEpic = actions$ => actions$.pipe(
//   ofType(actions.sendBatchs.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     return ajax({
//       ...ajaxBaseConfig,
//       url: urls.code.batchApplys.sendBatchs(action.payload),
//     }).pipe(
//       mergeMap(res => actions.sendBatchs.SUCCESS(res.response)),
//       catchError(error => of(actions.sendBatchs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const loadAuditUnauditedsEpic = actions$ => actions$.pipe(
//   ofType(actions.loadAuditUnauditeds.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', auditStart = '', auditEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.auditUnauditeds}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&auditStart=${auditStart}&auditEnd=${auditEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
//     }).pipe(
//       map(res => actions.loadAuditUnauditeds.SUCCESS(res.response)),
//       catchError(error => of(actions.loadAuditUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const loadAuditPassesEpic = actions$ => actions$.pipe(
//   ofType(actions.loadAuditPasses.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.auditPasses}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
//     }).pipe(
//       map(res => actions.loadAuditPasses.SUCCESS(res.response)),
//       catchError(error => of(actions.loadAuditPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const auditEpic = actions$ => actions$.pipe(
//   ofType(actions.audit.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'POST',
//       url: urls.code.batchApplys.audit,
//       body: action.payload
//     }).pipe(
//       mergeMap(res => [actions.audit.SUCCESS(res.response), uiActions.updateNotPassDialogStatus(false)]),
//       catchError(error => of(actions.audit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

