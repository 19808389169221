import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//查询工具不要encode
import { ajax } from 'rxjs/ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './queryTools';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { encode } from 'iconv-lite';

/**
 * TODO: 查询工具
 */
// 查询用户
export const loadQueryUserMetadataEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadQueryUserMetadata.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { userMetadataSearchkey = '', enterprisesSelectionsId = '' } = state$.value.queryTools;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.users.matedata}?keyword=${userMetadataSearchkey ? encodeURIComponent(userMetadataSearchkey) : ''}&eseId=${enterprisesSelectionsId}`,
      }).pipe(
        map(res => actions.loadQueryUserMetadata.SUCCESS(res && res.response && res.response.content ? res.response.content : [])),
        catchError(error => of(actions.loadQueryUserMetadata.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//用户扫码记录
export const loadscanRecordListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadscanRecordList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', scanRecoredSearchUuid = '', currentOpenId, currentAppid, loadscanRecordPage: { page = '', size = '', sort = '' } } = state$.value.queryTools;
    let url = `${urls.queryTools.users.scanRecords}?appId=${currentAppid}&openId=${currentOpenId}&eseId=${enterprisesSelectionsId}&sort=${sort}&eseSeqNum=${scanRecoredSearchUuid}&page=${page}&size=${size}`;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: url,
      }).pipe(
        map(res => actions.loadscanRecordList.SUCCESS(res.response || {})),
        catchError(error => of(actions.loadscanRecordList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//导出用户扫码记录
export const exportscanRecordListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.exportscanRecordList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    const { enterprisesSelectionsId = '', scanRecoredSearchUuid = '', currentOpenId, currentAppid, loadscanRecordPage: { page = '', size = '', sort = '' } } = state$.value.queryTools;
    let url = `${urls.queryTools.users.export}/exportUserRecords?appId=${currentAppid}&openId=${currentOpenId}&eseId=${enterprisesSelectionsId}&sort=${sort}&eseSeqNum=${scanRecoredSearchUuid}`;
    //&page=${page}&size=${size}
    return (
      ajax({
        ...ajaxBaseConfig,
        url: url,
      }).pipe(
        map(res => {
          callback && callback(res);
          return actions.exportscanRecordList.SUCCESS(res.response || {});
        }),
        catchError(error => of(actions.exportscanRecordList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



//用户中奖记录
export const loadlotteryRecordListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadlotteryRecordList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', lotteryRecoredSearchUuid = '', currentOpenId, currentAppid, loadlotteryRecordPage: { prizeType = '', promotionType = '', page = '', size = '', sort = '' }, hit = true } = state$.value.queryTools;
    let url = `${urls.queryTools.users.lotteryRecords}?appId=${currentAppid}&openId=${currentOpenId}&eseId=${enterprisesSelectionsId}&sort=${sort}&promotionTypes=${promotionType}&uuid=${lotteryRecoredSearchUuid}&page=${page}&size=${size}`;
    if (hit) {
      url = url + `&prizeTypes=${prizeType}`;
    }
    if (hit != 'all') {
      url = url + `&hit=${hit}`;
    }
    return (
      ajax({
        ...ajaxBaseConfig,
        url: url,
      }).pipe(
        map(res => actions.loadlotteryRecordList.SUCCESS(res.response || {})),
        catchError(error => of(actions.loadlotteryRecordList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//导出用户中奖记录
export const exportLotteryRecordListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.exportLotteryRecordList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload;
    const { enterprisesSelectionsId = '', lotteryRecoredSearchUuid = '', currentOpenId, currentAppid, loadlotteryRecordPage: { prizeType = '', promotionType = '', page = '', size = '', sort = '' }, hit = true } = state$.value.queryTools;
    let url = `${urls.queryTools.users.export}/exportUserLotteryRecords?appId=${currentAppid}&openId=${currentOpenId}&eseId=${enterprisesSelectionsId}&sort=${sort}&promotionTypes=${promotionType}&uuid=${lotteryRecoredSearchUuid}`;
    //  page=${page}&size=${size}
    if (hit) {
      url = url + `&prizeTypes=${prizeType}`;
    }
    if (hit != 'all') {
      url = url + `&hit=${hit}`;
    }
    return (
      ajax({
        ...ajaxBaseConfig,
        url: url,
      }).pipe(
        map(res => {
          callback && callback(res);
          return actions.exportLotteryRecordList.SUCCESS(res.response || {});
        }),
        catchError(error => of(actions.exportLotteryRecordList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//重试用户中奖
export const againLotteryRecordEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.againLotteryRecord.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.users.export}/claimRetry?uuid=${data.uuid}&openId=${data.openId}&appId=${data.appId}&prizeOrderNumber=${data.prizeOrderNumber}`,
        method: 'POST',
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res);
          return actions.againLotteryRecord.SUCCESS(res.response || {});
        }),
        catchError(error => of(actions.againLotteryRecord.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


//用户积分记录
export const loadpointRecordListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadpointRecordList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', currentOpenId, currentAppid, } = state$.value.queryTools;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.users.pointRecords}?appId=${currentAppid}&openId=${currentOpenId}&eseId=${enterprisesSelectionsId}`,
      }).pipe(
        map(res => actions.loadpointRecordList.SUCCESS(res && res.response && res.response.content ? res.response.content : [])),
        catchError(error => {
          return of(actions.loadpointRecordList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        })
      )
    )
  })
);
// TODO:用户积分详情记录
export const loadpointRecordDetailListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadpointRecordDetailList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { loadpointRecordDetailListPage = {}, currentOpenId, currentAppid, enterprisesSelectionsId = '' } = state$.value.queryTools;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.users.pointDetails}?appId=${currentAppid}&openId=${currentOpenId}&pointType=${loadpointRecordDetailListPage.pointType}&eseId=${enterprisesSelectionsId}&page=${loadpointRecordDetailListPage.page}&size=${loadpointRecordDetailListPage.size}&sort=prs.ct,DESC`,
      }).pipe(
        map(res => actions.loadpointRecordDetailList.SUCCESS(res.response || {})),
        catchError(error => of(actions.loadpointRecordDetailList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//用户卡片记录
export const loadcardRecordListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadcardRecordList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', currentOpenId, currentAppid } = state$.value.queryTools;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.users.cardRecords}?appId=${currentAppid}&openId=${currentOpenId}&eseId=${enterprisesSelectionsId}`,
      }).pipe(
        map(res => actions.loadcardRecordList.SUCCESS(res && res.response && res.response.content ? res.response.content : [])),
        catchError(error => of(actions.loadcardRecordList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//用户卡片明细列表
export const loadcardRecordDetailListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadcardRecordDetailList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { loadcardRecordDetailListPage = {}, currentOpenId, currentAppid, enterprisesSelectionsId = '' } = state$.value.queryTools;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.users.cardDetails}?appId=${currentAppid}&openId=${currentOpenId}&typeId=${loadcardRecordDetailListPage.cardType}&eseId=${enterprisesSelectionsId}&page=${loadcardRecordDetailListPage.page}&size=${loadcardRecordDetailListPage.size}&sort=crs.ct,DESC`,
      }).pipe(
        map(res => actions.loadcardRecordDetailList.SUCCESS(res.response || {})),
        catchError(error => of(actions.loadcardRecordDetailList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

/* 
** 二维码信息查询
*/
// 二维码基本信息
export const getCodeInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getCodeInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '' } = state$.value.queryTools;
    const { type, query, callback } = action.payload;
    let escapeQuery = query;
    if (type == 1) {
      escapeQuery = encodeURIComponent(query); //decodeURI   escape
    }
    let url = `${urls.queryTools.tags.info}?eseId=${enterprisesSelectionsId}&query=${escapeQuery}`


    if (type == 4) {
      url = url + '&mpQueryTagType=PINCODE'
    }
    if (type == 5) {
      url = url + '&mpQueryTagType=PLATFORM_STANDARD_CO'
    }
    return ajax({
      ...ajaxBaseConfig,
      url: url
    }).pipe(
      concatMap(res => {
        // const { tagUrl, eseSeqnum } = res.response;
        // if (tagUrl && eseSeqnum) {
        //   return [actions.getCodeInfo.SUCCESS(res.response), actions.getDrawRecord.REQUEST({}), actions.getScanRecord.REQUEST({})]
        // } else {
        //   return [actions.getCodeInfo.SUCCESS(res.response)]
        // }
        callback && callback(res.response);
        return [actions.getCodeInfo.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getCodeInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const getCodeInfoMoreEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getCodeInfoMore.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { id } = action.payload;
    // let escapeQuery = query;
    // if (type == 1) {
    //   escapeQuery = encodeURIComponent(query); //decodeURI   escape
    // }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.links.links}/logs/${id}`
    }).pipe(
      concatMap(res => {
        // const { tagUrl, eseSeqnum } = res.response;
        // if (tagUrl && eseSeqnum) {
        //   return [actions.getCodeInfo.SUCCESS(res.response), actions.getDrawRecord.REQUEST({}), actions.getScanRecord.REQUEST({})]
        // } else {
        //   return [actions.getCodeInfo.SUCCESS(res.response)]
        // }
        return [actions.getCodeInfoMore.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getCodeInfoMore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// 抽奖信息
export const getDrawRecordEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getDrawRecord.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', tagSearch } = state$.value.queryTools;
    const { page = 0, size = 20, uuid = '', sort = '', prizeType = [], promotionType = [], callback, hit = true } = action.payload; //&promotionTypes=${promotionType}

    let url = `${urls.queryTools.tags.lotteryRecords}?page=${page}&size=${size}&sort=${sort}&codeUri=${tagSearch.codeUri}&senNum=${tagSearch.senNum}&uuid=${uuid}&eseId=${enterprisesSelectionsId}`;
    if (hit) {
      url = url + `&prizeTypes=${prizeType}`;
    }
    if (hit != 'all') {
      url = url + `&hit=${hit}`;
    }

    return ajax({
      ...ajaxBaseConfig,
      url: url
    }).pipe(
      map(res => {
        callback && callback(res);
        return actions.getDrawRecord.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getDrawRecord.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)
//导出抽奖信息
export const drawRecordExportEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.drawRecordExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', tagSearch, codeInfo } = state$.value.queryTools;
    const { page = 0, size = 20, uuid = '', sort = '', prizeType = [], promotionType = [], callback, hit = true } = action.payload; //&promotionTypes=${promotionType}

    let url = `${urls.queryTools.tags.export}/exportTagLotteryRecords?sort=${sort}&codeUri=${tagSearch.codeUri}&senNum=${tagSearch.senNum}&uuid=${uuid}&eseId=${enterprisesSelectionsId}&eseSeqnum=${codeInfo.eseSeqnum}`;
    if (hit) {
      url = url + `&prizeTypes=${prizeType}`;
    }
    if (hit != 'all') {
      url = url + `&hit=${hit}`;
    }
    return ajax({
      ...ajaxBaseConfig,
      url: url
    }).pipe(
      map(res => {
        callback && callback(res);
        return actions.drawRecordExport.SUCCESS(res.response);
      }),
      catchError(error => of(actions.drawRecordExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)


//激励信息
export const getIncentiveRecordsEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getIncentiveRecords.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', tagSearch } = state$.value.queryTools;
    const { page = 0, size = 20, uuid = '', sort = '', prizeType = [], promotionType = [], callback } = action.payload; //&promotionTypes=${promotionType}
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.tags.incentiveRecords}?page=${page}&size=${size}&sort=${sort}&prizeTypes=${prizeType}&codeUri=${tagSearch.codeUri}&senNum=${tagSearch.senNum}&uuid=${uuid}&eseId=${enterprisesSelectionsId}`
    }).pipe(
      map(res => {
        callback && callback(res);
        return actions.getIncentiveRecords.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getIncentiveRecords.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)




// 扫码信息
export const getScanRecordEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getScanRecord.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', tagSearch } = state$.value.queryTools;
    const { page = 0, size = 20, ip = '', openId = '', sort = 'qrs.ti,desc', callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.queryTools.tags.queryRecords}?page=${page}&size=${size}&sort=${sort}&codeUri=${tagSearch.codeUri}&senNum=${tagSearch.senNum}&ip=${ip}&openId=${openId}&eseId=${enterprisesSelectionsId}`
    }).pipe(
      map(res => {
        callback && callback(res);
        return actions.getScanRecord.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getScanRecord.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

// 导出扫码信息
export const scanRecordExportEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.scanRecordExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { enterprisesSelectionsId = '', tagSearch, codeInfo } = state$.value.queryTools;
    const { page = 0, size = 20, ip = '', openId = '', sort = 'qrs.ti,desc', callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      // page=${page}&size=${size}&
      url: `${urls.queryTools.tags.export}/exportTagRecords?sort=${sort}&codeUri=${tagSearch.codeUri}&senNum=${tagSearch.senNum}&ip=${ip}&openId=${openId}&eseId=${enterprisesSelectionsId}&eseSeqnum=${codeInfo.eseSeqnum}`
    }).pipe(
      map(res => {
        callback && callback(res);
        return actions.scanRecordExport.SUCCESS(res.response);
      }),
      catchError(error => of(actions.scanRecordExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

/* 
** 活动匹配
*/




export const getActiveCodeInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getActiveCodeInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    let escapeQuery = data.query;
    if (data.type == 1) {
      escapeQuery = encodeURIComponent(data.query); //decodeURI   escape
    }
    let url = `${urls.queryTools.tags.info}?eseId=${data.eseId}&query=${escapeQuery}`


    if (data.type == 4) {
      url = url + '&mpQueryTagType=PINCODE'

    }
    return ajax({
      ...ajaxBaseConfig,
      url: url
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.getActiveCodeInfo.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getActiveCodeInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



export const getActiveMatchEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getActiveMatch.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;

    let url = `${urls.queryTools.tags.promotion}?uri=${data.uri}&seqNum=${data.seqNum}&eseId=${data.eseId}&authType=${data.authType}&appId=${data.appId}&expectPromotionId=${data.expectPromotionId}&promotionType=${data.promotionType}&longitude=${data.longitude}&latitude=${data.latitude}`


    return ajax({
      ...ajaxBaseConfig,
      url: url
    }).pipe(
      concatMap(res => {
        callback && callback(res.response);
        return [actions.getActiveMatch.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getActiveMatch.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const getCodeIdsInfoMoreEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getCodeIdsInfoMore.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    // let escapeQuery = query;
    // if (type == 1) {
    //   escapeQuery = encodeURIComponent(query); //decodeURI   escape
    // }
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.dataManage.links.links}/logs-ids?ids=${data.ids}`
    }).pipe(
      concatMap(res => {
        callback && callback(res.response)
        // const { tagUrl, eseSeqnum } = res.response;
        // if (tagUrl && eseSeqnum) {
        //   return [actions.getCodeInfo.SUCCESS(res.response), actions.getDrawRecord.REQUEST({}), actions.getScanRecord.REQUEST({})]
        // } else {
        //   return [actions.getCodeInfo.SUCCESS(res.response)]
        // }
        return [actions.getCodeIdsInfoMore.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.getCodeIdsInfoMore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
