import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './miniProgramConfig';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 小程序
 */
// 获取已设置的二维码规则
export const qrcodeJumpEpic = actions$ => actions$.pipe(
  ofType(actions.qrcodeJump.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: urls.wechat.miniProgramConfig.qrcodeJump(action.payload)
    }).pipe(
      map(res => actions.qrcodeJump.SUCCESS(res.response)),
      catchError(error => of(actions.qrcodeJump.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 增加或修改二维码规则
export const qrcodeJumpEditEpic = actions$ => actions$.pipe(
  ofType(actions.qrcodeJumpEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: 'post',
      url: urls.wechat.miniProgramConfig.qrcodeJump(action.payload.appId),
      body: action.payload,
    }).pipe(
      map(res => actions.qrcodeJumpEdit.SUCCESS(res.status)),
      catchError(error => of(actions.qrcodeJumpEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 增加或修改二维码规则并发布
export const qrcodeJumpReleaseEpic = actions$ => actions$.pipe(
  ofType(actions.qrcodeJumpRelease.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: 'post',
      url: urls.wechat.miniProgramConfig.qrcodeJumpRelease,
      body: action.payload,
    }).pipe(
      map(res => actions.qrcodeJumpRelease.SUCCESS(res.status)),
      catchError(error => of(actions.qrcodeJumpRelease.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 获取体验小程序的体验二维码
export const qrcodeEpic = actions$ => actions$.pipe(
  ofType(actions.qrcode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { appId, path } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      responseType: "blob",
      url: `${urls.wechat.miniProgramConfig.qrcode}?appId=${appId}&path=${path}`,
    }).pipe(
      map(res => actions.qrcode.SUCCESS(res.response)),
      catchError(error => of(actions.qrcode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 获取该小程序的类目
export const categoryListEpic = actions$ => actions$.pipe(
  ofType(actions.categoryList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.wechat.miniProgramConfig.categoryList}?appId=${action.payload}`,
    }).pipe(
      map(res => actions.categoryList.SUCCESS(res.response)),
      catchError(error => of(actions.categoryList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 获取小程序的第三方提交代码的页面配置
export const pageEpic = actions$ => actions$.pipe(
  ofType(actions.page.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.wechat.miniProgramConfig.page}?appId=${action.payload}`,
    }).pipe(
      map(res => actions.page.SUCCESS(res.response)),
      catchError(error => of(actions.page.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 删除二维码规则
export const qrcodeJumpDeleteEpic = actions$ => actions$.pipe(
  ofType(actions.qrcodeJumpDelete.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.wechat.miniProgramConfig.qrcodeJumpDelete}`,
      method: 'post',
      body: action.payload,
    }).pipe(
      mergeMap(res => [actions.qrcodeJumpDelete.SUCCESS(res.status), uiActions.notification('删除成功', 'success')]),
      catchError(error => of(actions.qrcodeJumpDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);