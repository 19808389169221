import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils.js';
import urls from '@/api/urls.ts';

const moduleName: string = 'receivers';

/**
 * TODO: 接收人
 */

export const getPageData = fasterActions(moduleName, 'getPageData');

export const getModalData = fasterActions(moduleName, 'getModalData');

export const saveReceivers = fasterActions(moduleName, 'saveReceivers');

export const deleteOne = fasterActions(moduleName, 'deleteOne');

export const deleteAll = fasterActions(moduleName, 'deleteAll');

export const sendType = fasterActions(moduleName, 'sendType');

export const changePaging = createAction(`${moduleName}/changePaging`);

export const changeModalParams = createAction(`${moduleName}/changeModalParams`);

export interface ReceiversPageParams {
    page?: number,
    size?: number,
    eventId: string,
    work: string,
    warnStatus?: number | string
    callback?: (x:any) => void
}

interface ReceiversPageData {
    content: [],
    totalPages: number,
    [propsName: string]: any
}

interface ReceiversInitialState {
    pageParams: ReceiversPageParams,
    modalParams: ReceiversPageParams,
    loading: boolean,
    modalLoading: boolean,
    pageData: ReceiversPageData,
    modalData: ReceiversPageData,
}

const initialState: ReceiversInitialState = {
    pageParams: {
        page: 0,
        size: 20,
        work: '',
        eventId: ''
    },
    loading: false,
    modalLoading: false,
    pageData: {
        content: [],
        totalPages: 0
    },
    modalData: {
        content: [],
        totalPages: 0
    },
    modalParams: {
        page: 0,
        size: 20,
        work: '',
        eventId: ''
    }
}

const reducer = handleActions(
    {
        [changePaging]: (state, { payload }) => {
            return { ...state, pageParams: { ...payload } }
        },

        [changeModalParams]: (state, { payload }) => {
            return { ...state, modalParams: { ...payload } }
        },

        [getPageData.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true, }),
        [getPageData.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
        [getPageData.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [getModalData.REQUEST]: (state, { payload }) => ({ ...state, modalData: {}, modalLoading: true, }),
        [getModalData.SUCCESS]: (state, { payload }) => ({ ...state, modalData: payload, modalLoading: false }),
        [getModalData.FAIL]: (state, { payload }) => ({ ...state, modalLoading: false }),

        [saveReceivers.REQUEST]: (state, { payload }) => ({ ...state, }),
        [saveReceivers.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [saveReceivers.FAIL]: (state, { payload }) => ({ ...state, }),

        [deleteOne.REQUEST]: (state, { payload }) => ({ ...state, }),
        [deleteOne.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [deleteOne.FAIL]: (state, { payload }) => ({ ...state, }),

        [deleteAll.REQUEST]: (state, { payload }) => ({ ...state, }),
        [deleteAll.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [deleteAll.FAIL]: (state, { payload }) => ({ ...state, }),

        [sendType.REQUEST]: (state, { payload }) => ({ ...state, }),
        [sendType.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [sendType.FAIL]: (state, { payload }) => ({ ...state, }),
    },
    initialState
);

export default reducer;
