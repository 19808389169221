import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeUpload';
import { ajaxBaseConfig, actionApi ,apiBaseConfig} from '../../utils';

export const loadCodeUploadEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeUpload.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.codeUpload.codeUpload}/logs?search=${name}&page=${page}&size=${size}&sort=${sort}&linkCode=peercorrelation`
    }).pipe(
      map(res => actions.loadCodeUpload.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodeUpload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const loadCodeUploadSelectEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeUploadSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { idOrName = '', page = 0, size = 100, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.codedb.codedb}/selections?query=${idOrName}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadCodeUploadSelect.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodeUploadSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const createCodeUploadEpic = actions$ => actions$.pipe(
  ofType(actions.createCodeUpload.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data,formData, callback } = action.payload;
    return ajax({
      ...apiBaseConfig,
      method: 'POST',
      body: formData,
      url: `${urls.data.codeUpload.codeUpload}/file?parentType=${data.parentType}&linkCode=${data.linkCode}&dataJson=${data.dataJson}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.createCodeUpload.SUCCESS(res)
      }),
      catchError(error => of(actions.createCodeUpload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
export const editCodedbEpic = actions$ => actions$.pipe(
  ofType(actions.editCodedb.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      body: data,
      url: `${urls.data.codedb.codedb}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.editCodedb.SUCCESS(res)
      }),
      catchError(error => of(actions.editCodedb.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


