import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './mpUsers';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 小程序用户
 */
// 获取小程序用户
export const loadMpuserEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadMpuser.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { currentAppId = '', mpuserParam = {} } = state$.value.mpUsers;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.mpUsers.mpUsers}?appId=${currentAppId}&page=${mpuserParam.page}&size=${mpuserParam.size}&sort=${mpuserParam.sort}&word=${mpuserParam.word}`,
    }).pipe(
      map(res => actions.loadMpuser.SUCCESS(res.response)),
      catchError(error => of(actions.loadMpuser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);
// 修改小程序用户
export const editMpuserEpic = actions$ => actions$.pipe(
  ofType(actions.editMpuser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.data.mpUsers.mpUsers,
      method: "PUT",
      body: action.payload.data
    }).pipe(
      concatMap(res => { 
        action.payload && action.payload.callback && action.payload.callback();
        return [actions.editMpuser.SUCCESS(res.response), actions.loadMpuser.REQUEST()];
      }),
      catchError(error => of(actions.editMpuser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
    }
  )
);
// 禁用启用小程序用户
export const enableOrDisabledMpuserEpic = actions$ => actions$.pipe(
  ofType(actions.enableOrDisabledMpuser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.mpUsers.mpUsers}/${action.payload.id}/status?status=${action.payload.status}`,
      method: "PATCH",
      body: action.payload
    }).pipe(
      concatMap(res =>[ actions.enableOrDisabledMpuser.SUCCESS(res.response), actions.loadMpuser.REQUEST()]),
      catchError(error => of(actions.enableOrDisabledMpuser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 解绑删除小程序用户
export const deleteMpuserEpic = actions$ => actions$.pipe(
  ofType(actions.deleteMpuser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.mpUsers.mpUsers}/${action.payload.id}`,
      method: "DELETE"
    }).pipe(
      concatMap(res => [actions.deleteMpuser.SUCCESS(res.response), actions.loadMpuser.REQUEST()]),
      catchError(error => of(actions.deleteMpuser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 绑定小程序用户
export const getTokenMpuserEpic = actions$ => actions$.pipe(
  ofType(actions.getTokenMpuser.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { addressBookId='', callback } = action.payload;
    return  ajax({
      ...ajaxBaseConfig,
      responseType: "text",
      url: `${urls.data.mpUsers.mpUsers}/${addressBookId}/token`,
    }).pipe(
      map(res => { 
        callback && callback(res.response);
        return actions.getTokenMpuser.SUCCESS(res.response)
      }),
      catchError(error => of(actions.getTokenMpuser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

