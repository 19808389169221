import { createAction } from 'redux-actions';

export const actionApi = () => {
  // console.log('postMessage');
  if (process.env.NODE_ENV === 'development') {
    return
  }

}

export const fasterActions = (moduleName, actionName) => {
  const action = {
    REQUEST: createAction(`${moduleName}/${actionName}_REQUEST`),
    SUCCESS: createAction(`${moduleName}/${actionName}_SUCCESS`),
    FAIL: createAction(`${moduleName}/${actionName}_FAIL`),
  }
  return action;
}

export const ajaxBaseConfig = {
  headers: { 'Content-Type': 'application/json;charset=utf-8', 'X-CSRF-TOKEN': window.__INITIAL_STATE__.backend['X-CSRF-TOKEN'] || '', 'X-TC-TOKEN': window.__INITIAL_STATE__.backend['X-TC-TOKEN'] || '' },
  withCredentials: true,
}

export const apiBaseConfig = {
  headers: { 'X-CSRF-TOKEN': window.__INITIAL_STATE__.backend['X-CSRF-TOKEN'] || '', 'X-TC-TOKEN': window.__INITIAL_STATE__.backend['X-TC-TOKEN'] || '' },
  withCredentials: true,
}

export const setScrollTop = () => {
  let appMain = document.getElementsByClassName('app-main')[0];
  if (appMain) {
    appMain.scrollTop = 0;
  }
}