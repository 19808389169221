import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './activityInfomation';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 单据管理
 */

// 单据类型列表
export const activityTypeListEpic = actions$ => actions$.pipe(
    ofType(actions.activityTypeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page, size, word = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.activity.activityType}?page=${page}&size=${size}&word=${word}`
        }).pipe(
            map(res => actions.activityTypeList.SUCCESS(res.response)),
            catchError(error => of(actions.activityTypeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 禁用、启用
export const enableOrDisabledActivityTypeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledActivityType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.activity.status}?id=${id}&status=${status}`,
                method: 'PUT',
            }).pipe(
                concatMap(res => [actions.enableOrDisabledActivityType.SUCCESS(res.response), actions.activityTypeList.REQUEST(state$.value.activityInfomation.activityPaging)]),
                catchError(error => of(actions.enableOrDisabledActivityType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 表单详情
export const activityTypeInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.activityTypeInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { typeId = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.activity.activityType}/${typeId}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.activityTypeInfo.SUCCESS(res.response);
                }),
                catchError(error => of(actions.activityTypeInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 创建单据
export const createActivityTypeEpic = actions$ => actions$.pipe(
    ofType(actions.createActivityType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                method: data.id ? 'PUT' : 'POST',
                url: `${urls.data.activity.activityType}`,
                body: action.payload.data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.createActivityType.SUCCESS(res.response);
                }),
                catchError(error => of(actions.createActivityType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 单据数据
export const activityDataListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.activityDataList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, code = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.activity.activityData}?page=${page}&size=${size}&name=${code}&code=${code}`,
            }).pipe(
                map(res => actions.activityDataList.SUCCESS(res.response)),
                catchError(error => of(actions.activityDataList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 单据数据查看详情
export const activityDataInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.activityDataInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.activity.activityData}/${id}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.activityDataInfo.SUCCESS(res.response);
                }),
                catchError(error => of(actions.activityDataInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
// 宴会下拉
export const loadbanquetSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadbanquetSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { status} = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.banquet.select}?status=${status}`,
            }).pipe(
                map(res => {
                    return actions.loadbanquetSelect.SUCCESS(res.response);
                }),
                catchError(error => {
                    return of(actions.loadbanquetSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
            )
        )
    })
);

