import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './pools';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// Epics
export const getPoolTableDataEpic = actions$ => actions$.pipe(
    ofType(actions.getPoolTable.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, word, poolStatus, poolUseStatus, promotionCategories = '' } = action.payload;
        let poolUseStatuses = '', poolStatuses = '';
        poolUseStatus.map(val => { if (val != null) poolUseStatuses += `poolUseStatuses=${val}&`; })
        poolStatus.map(val => { if (val != null) poolStatuses += `poolStatuses=${val}&` })
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}?page=${page}&size=${size}&${poolUseStatuses}${poolStatuses}word=${word}&promotionCategories=${promotionCategories == 'no' ? '' : promotionCategories}`
        }).pipe(
            map(res => actions.getPoolTable.SUCCESS(res.response)),
            catchError(error => of(actions.getPoolTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const deletePoolIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deletePool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.promotion.pools.delete}/${action.payload}`
        }).pipe(
            mergeMap(res => [actions.deletePool.SUCCESS(res), actions.getPoolTable.REQUEST(state$.value.pools.poolTableSearch)]),
            catchError(error => of(actions.deletePool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)

/* pool-edit */
export const getPrizeListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPrizeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.promotion.prizes.prizes}?page=${542}&size=${20}&type=&keyWords=&sort=asc` // 479  542
            url: `${urls.promotion.prizes.prizes}`
        }).pipe(
            map(res => actions.getPrizeList.SUCCESS(res.response)),
            catchError(error => of(actions.getPrizeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)



//多奖池
export const getPoolGroupEpic = actions$ => actions$.pipe(
    ofType(actions.getPoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, word, poolStatus, poolUseStatus, sort } = action.payload;
        let poolUseStatuses = '', poolStatuses = '';
        poolUseStatus.map(val => { if (val != null) poolUseStatuses += `poolUseStatuses=${val}&`; })
        poolStatus.map(val => { if (val != null) poolStatuses += `poolStatuses=${val}&` })
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.poolGroup}?page=${page}&size=${size}&sort=${sort}&${poolUseStatuses}${poolStatuses}word=${word}`
        }).pipe(
            map(res => actions.getPoolGroup.SUCCESS(res.response)),
            catchError(error => of(actions.getPoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
//多奖池停用启用
export const enableOrDisabledEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabled.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.promotion.pools.poolGroup}/${status}/${id}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.enableOrDisabled.SUCCESS(res.response), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]),
                catchError(error => of(actions.enableOrDisabled.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
//多奖池删除
export const deletePoolGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deletePoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.promotion.pools.poolGroup}/${action.payload}`
        }).pipe(
            mergeMap(res => [actions.deletePoolGroup.SUCCESS(res), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]),
            catchError(error => of(actions.deletePoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)
//多奖池新增
export const postPoolGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postPoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.promotion.pools.poolGroup}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.postPoolGroup.SUCCESS(res.response), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]
            }),
            catchError(error => of(actions.postPoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const patchPoolGroupEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.patchPoolGroup.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: data,
            url: `${urls.promotion.pools.poolGroup}`
        }).pipe(
            concatMap(res => {
                callback && callback(res.response);
                return [actions.patchPoolGroup.SUCCESS(res.response), actions.getPoolGroup.REQUEST(state$.value.pools.poolGroupTableSearch)]
            }),
            catchError(error => of(actions.patchPoolGroup.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



//预算


export const budgetEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.budget.REQUEST.toString()),
    mergeMap(action => {
        actionApi()

        const { poolData, id, merchantBudget } = action.payload;
        const { totalBudget, cashBudget } = poolData;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: {},
            // url: `${urls.promotion.pools.budget}/${id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.pools.budget}/${id}?totalBudget=${totalBudget}&cashBudget=${cashBudget}`
        }).pipe(
            concatMap(res => {
                return [actions.budget.SUCCESS(res.response), actions.postPoolPrize.REQUEST({ ...action.payload, merchantBudget })]
                //
            }),
            catchError(error => of(actions.budget.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const putBudgetEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putBudget.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, callback } = action.payload;
        const { totalBudget, cashBudget } = poolData;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: {},
            // url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${poolData.totalBudget}`
            url: `${urls.promotion.pools.budget}/${poolData.id}?totalBudget=${totalBudget}&cashBudget=${cashBudget}`,
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.putBudget.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putBudget.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


/* 商户号充值预算 */
export const rechargeBudgetEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.rechargeBudget.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, merchantBudget, callback, remark = '' } = action.payload;
        const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE' } = merchantBudget;

        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: {},
            url: `${urls.promotion.pools.recharge}/${id}?merchantRechargeBudget=${merchantRechargeBudget}&exceedChargeOptionType=${exceedChargeOptionType}`,
        }).pipe(
            // map(res => {
            //     callback && callback(res.response);
            //     return actions.rechargeBudget.SUCCESS(res.response)
            // }),
            concatMap(res => {
                if (remark) {
                    return [actions.rechargeBudget.SUCCESS(res.response), actions.postPoolPrize.REQUEST({ ...action.payload })]
                } else {
                    return [actions.rechargeBudget.SUCCESS(res.response)]
                }
            }),
            catchError(error => of(actions.rechargeBudget.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)






/* 
** 创建修改奖池奖品
*/
export const postPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postPool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()

        const { poolData, prizeList = [], merchantBudget, history, quanxian = false } = action.payload;
        const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE', warningValue } = merchantBudget;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: { ...poolData, merchantRechargeBudget, exceedChargeOptionType, warningValue },
            url: `${urls.promotion.pools.pools}`
        }).pipe(
            concatMap(res => {
                if (quanxian) {
                    return [actions.postPool.SUCCESS(res.response), actions.budget.REQUEST({ ...res.response, prizeList, history, poolData, merchantBudget })]
                }
                else {
                    return [actions.postPool.SUCCESS(res.response), actions.postPoolPrize.REQUEST({ ...res.response, prizeList, history, merchantBudget })]
                }
            }),
            catchError(error => of(actions.postPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const putPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putPoolName.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolData, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: poolData,
            url: `${urls.promotion.pools.pools}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.putPoolName.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putPoolName.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 批量创建、修改奖池
*/
export const postPoolPrizeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.postPoolPrize.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, eseId, prizeList, remark = '', history, appId, merchantNumber, merchantId, merchantBudget } = action.payload;
        const { merchantRechargeBudget, exceedChargeOptionType = 'SEND_PRIZE', warningValue } = merchantBudget;
        let myPrizeList = JSON.parse(JSON.stringify(prizeList));
        const reform = (obj, keyArr = []) => {
            let newObj = {};
            Object.keys(obj).forEach(key => {
                const val = obj[key];
                if (!keyArr.includes(key)) {
                    newObj[key] = val;
                }
            })
            return newObj;
        }
        if (prizeList.length) {
            myPrizeList.map((item, index, self) => {
                item.poolId = id; item.eseId = eseId; item.requireWarning = !item.requireWarning; item.putCount = Number(item.putCount);
                let obj = {};
                if (item.prizeId) {
                    obj = reform(item, ['text', 'prizeCoupon', 'disabled', 'codePutCount', 'prizeRedPacket']);
                } else {
                    item.prizeId = item.id;
                    obj = reform(item, ['text', 'id']);
                }
                return self[index] = obj;
            })
        }
        let myPoolPrizes = { poolId: id, poolPrizes: myPrizeList };
        if (remark) {
            myPoolPrizes = { ...myPoolPrizes, remark, appId, merchantNumber, merchantId, merchantRechargeBudget, exceedChargeOptionType, warningValue };
        }

        const myUrl = remark ? `${urls.promotion.poolPrizes.edit}` : `${urls.promotion.poolPrizes.create}`;
        return ajax({
            ...ajaxBaseConfig,
            method: remark ? 'PUT' : 'POST',
            body: myPoolPrizes,
            url: myUrl
        }).pipe(
            // map(res => {
            //     history.push('/promotionpool');
            //     return actions.postPoolPrize.SUCCESS(res);
            // }),
            concatMap(res => {
                history.push('/promotionpool');
                return [actions.postPoolPrize.SUCCESS(res)]
                // if (remark) {
                //     return [actions.postPoolPrize.SUCCESS(res)]
                // } else {
                //     return [actions.postPoolPrize.SUCCESS(res), actions.rechargeBudget.REQUEST({ id, merchantBudget })]
                // }
            }),
            catchError(error => of(actions.postPoolPrize.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
**  获取奖池奖品详情
 */
export const getPoolIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolDataId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}/${poolId}`
        }).pipe(
            mergeMap(res => {
                return [actions.getPoolDataId.SUCCESS(res.response), actions.getPoolPrizeDataId.REQUEST({ poolData: res.response, poolId, callback })]
            }),
            catchError(error => of(actions.getPoolDataId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getPoolPrizeDataIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolPrizeDataId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, poolData, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolPrizes.poolPrizes}/${poolId}`
        }).pipe(
            map(res => {
                const { content: prizeList = [] } = res.response;
                callback && callback({ poolData, prizeList, remark: poolData.remark || '' });
                return actions.getPoolPrizeDataId.SUCCESS(res.response);
            }),
            catchError(error => of(actions.getPoolPrizeDataId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 奖池历史版本
*/
export const getPoolHistoryTableEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolHistoryTable.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, page, size } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolHistorys.pool}/${poolId}?page=${page}&size=${size}`
        }).pipe(
            map(res => {
                return actions.getPoolHistoryTable.SUCCESS(res.response);
            }),
            catchError(error => of(actions.getPoolHistoryTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getPoolHistoryInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolHistoryInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { poolId, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolHistorys.poolHistorys}/${poolId}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.getPoolHistoryInfo.SUCCESS(res.response);
            }),
            catchError(error => of(actions.getPoolHistoryInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 校验奖品是否可被删除
*/
export const checkPrizeDeleteEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.checkPrizeDelete.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.poolPrizes.check}/${id}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.checkPrizeDelete.SUCCESS(res.response);
            }),
            catchError(error => of(actions.checkPrizeDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
/* 
** 奖池树，用于会员中心，自定义标签
*/
export const getPoolTreeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolTree.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { promotionIds = '', callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}/selections${promotionIds ? '?promotionIds=' + promotionIds : ''}`
        }).pipe(
            map(res => {

                callback && callback(res.response.content || []);
                return actions.getPoolTree.SUCCESS(res.response.content || []);
            }),
            catchError(error => of(actions.getPoolTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getPoolTreeMoreEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolTreeMore.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { promotionIds = '', callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.pools.pools}/selections${promotionIds ? '?promotionIds=' + promotionIds : ''}`
        }).pipe(
            map(res => {
                callback && callback(res.response.content || []);
                return actions.getPoolTreeMore.SUCCESS(res.response.content || []);
            }),
            catchError(error => of(actions.getPoolTreeMore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
/* 
**  不统计奖池奖品列表
 */
export const poolPageByEseIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.poolPageByEseId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { eseId, page, size } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.promotion.poolPrizes.poolPrizes}/${eseId}/page?page=${page}&size=${size}`
        }).pipe(
            map(res => actions.poolPageByEseId.SUCCESS(res.response)),
            catchError(error => of(actions.poolPageByEseId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
** 数据统计标记
*/
export const poolPrizeNotNeedStatisticsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.poolPrizeNotNeedStatistics.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { poolPrizeId, notNeedStatistics } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: action.payload,
            url: `${urls.promotion.poolPrizes.poolPrizes}/${poolPrizeId}/notNeedStatistics?notNeedStatistics=${notNeedStatistics}`
        }).pipe(
            map(res => actions.poolPrizeNotNeedStatistics.SUCCESS(res.status)),
            catchError(error => of(actions.poolPrizeNotNeedStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 
**  获取奖池奖品详情
 */
export const getPoolDataByEseIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getPoolDataByEseId.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { eseId } = action.payload;
        return ajax({
            ...apiBaseConfig,
            url: `${urls.promotion.pools.pools}?eseId=${eseId}`
        }).pipe(
            map(res => actions.getPoolDataByEseId.SUCCESS(res.response)),
            catchError(error => of(actions.getPoolDataByEseId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
