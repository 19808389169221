import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { getNowTabs } from '@/utils/tableUtils.js';
import urls from '@/api/urls';
import BackArrow from '@/icons/BackArrow';
import queryString from 'query-string';
import ResetpwdDialog from '../../SystemLogin/ResetpwdDialog';
import { toggleChangePWDDialog } from '@/redux/modules/users/users'
import FileCenter from './FileCenter';
import FileCenterIcon from '@/icons/FileCenter';
import EditIcon from '@/icons/Edit';
import LayoutIcon from '@/icons/Layout';
import { handleLogout } from '@/redux/modules/ui'
import {systemSignOut} from '@/redux/modules/users/users'

@withRouter
@connect(
  (state) => ({
    sidebarStatus: state.ui.sidebarStatus,
    lvThreeMenuOfLvTwo: state.ui.lvThreeMenuOfLvTwo,
    lvTwoMenuUrls: state.ui.lvTwoMenuUrls,
    lvOneMenuUrls: state.ui.lvOneMenuUrls,
    nowRouter: state.ui.nowRouter,
    navbar: state.ui.navbar,
    openIds: state.users.openIds,
    showChangePWDDialog: state.users.showChangePWDDialog,
  }
  ),
  {
    toggleChangePWDDialog,
    handleLogout,
    systemSignOut:systemSignOut.REQUEST
  }
)
class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFileCenter: false,
    }
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {

  }

  handleRollback = () => {
    const { history } = this.props;
    // console.log(history)
    // history.goBack();
    if (window.location.href.indexOf('promotionactive/activity/edit/conditions') >= 0) {

      if (window.location.href.indexOf('promotionId=') >= 0) {
        history.replace('/promotionactive/activity/edit?action=edit&promotionId=' + window.location.href.split('promotionId=')[1].split('&')[0] + '&step=0&again=true')
      }
      else {
        history.replace('/promotionactive/activity/edit?action=create&promotionCategory=' + window.location.href.split('promotionCategory=')[1].split('&')[0] + '&step=0&again=true')

      }


    }
    else if (window.location.href.indexOf('promotionantiFake/activity/edit/conditions') >= 0) {

      if (window.location.href.indexOf('promotionId=') >= 0) {
        history.replace('/promotionantiFake/activity/edit?action=edit&promotionId=' + window.location.href.split('promotionId=')[1].split('&')[0] + '&step=0&again=true')
      }
      else {
        history.replace('/promotionantiFake/activity/edit?action=create&promotionCategory=' + window.location.href.split('promotionCategory=')[1].split('&')[0] + '&step=0&again=true')

      }
    }
    //只要路径里有/wswe/merchants，则去商户号列表页
    else if (window.location.href.indexOf('/wswe/merchants') >= 0) {
      history.replace('/wswe');
    }
    else {
      history.goBack();
    }

    // window.parent.history.back();
  }

  handleLogout(){    
    this.props.systemSignOut();
    this.props.handleLogout();

    if (urls.baseUrl == 'https://mzt.jncjt.cn/api-gateway') {
      window.location.href = 'https://mzt.jncjt.cn/index-system.html#/SystemLogin'
    }
    else {
      window.location.href = 'https://mztuat.jnc.cn/index-system.html#/SystemLogin'
    }
  }

  render() {
    const { nowRouter, location, lvThreeMenuOfLvTwo, navbar, lvOneMenuUrls = [], lvTwoMenuUrls = [], openIds, showChangePWDDialog, toggleChangePWDDialog } = this.props;
    const tabList = getNowTabs(nowRouter, lvThreeMenuOfLvTwo, location.pathname);
    const navbarBottomStatus = !(tabList.length > 0);
    const parsed = queryString.parse(location.search);
    const isNoBottomLine =
    (nowRouter === 'promotionactive' && parsed['action'] === 'view') ||
    (nowRouter === 'promotionantiFake' && parsed['action'] === 'view') ||
    (nowRouter === 'promotionverification' && parsed['action'] === 'view') ||
    (nowRouter === 'membercentersystem' && parsed['action'] === 'view') ||
    (nowRouter === 'membercentertag' && parsed['action'] === 'view') ||
    (nowRouter === 'pointsmallmall' && parsed['action'] === 'view') ||
    //大屏抽奖
    (nowRouter === 'luckydrawdrawactivity' && parsed['action'] === 'view') ||
    (nowRouter === 'codetagConfig' && parsed['action'] === 'edit') ||
    (nowRouter === 'datadict') ||
    (nowRouter === 'datafieldAssign') ||
    (nowRouter === 'reportlink') ||
    (location.pathname === '/dataenterprise/allot') ||
    (location.pathname === '/membercentermanage/allot') ||
    (location.pathname === '/promotionactive/mate') ||
    (location.pathname === '/dmlinkUpload') ||
    (location.pathname === '/reportbill') ||
    (location.pathname === '/reportoverview') ||
    (location.pathname === '/reportstatistics') ||
    (location.pathname === '/reportrealtimeoverview') ||
    (location.pathname === '/pointsmallorders') ||
    (location.pathname === '/reportsjfBill');



    return (
      <div className={`navbar ${navbarBottomStatus && !isNoBottomLine ? 'border-bottom' : ''}`}>
        {
          !navbarBottomStatus || !navbar || lvTwoMenuUrls.includes(location.pathname.replace('/', '')) || lvOneMenuUrls.includes(location.pathname.replace('/', '')) ? '' :
            <BackArrow onClick={this.handleRollback} width="20" height="20" style={{ display: 'inline-block', position: 'relative', top: '5px', marginRight: '30px', cursor: 'pointer' }} />
        }
        <h3 style={{ display: 'inline-block' }}>
          {navbar}
        </h3>

        {(openIds.userType == 'SYSTEM' || openIds.type == 'TY') &&
          <React.Fragment>
            <div style={{ float: 'right', marginRight: 20, cursor: 'pointer' }} onClick={() => { this.handleLogout()}}>退出登录</div>
            <LayoutIcon onClick={() => { this.handleLogout(); }} width="20" height="20" style={{ float: 'right', display: 'inline-block', position: 'relative', top: '17px', cursor: 'pointer' }} />
          </React.Fragment>
        }

     
        <React.Fragment>
          <div style={{ float: 'right', marginRight: 20, cursor: 'pointer' }} onClick={() => { this.setState({ showFileCenter: true }) }}>文件中心</div>
          <FileCenterIcon onClick={() => { this.setState({ showFileCenter: true }) }} width="20" height="20" style={{ float: 'right', display: 'inline-block', position: 'relative', top: '19px', cursor: 'pointer' }} />
        </React.Fragment>
        {this.state.showFileCenter && <FileCenter
          visible={this.state.showFileCenter}
          handleCancel={() => {
            this.setState({ showFileCenter: false })
          }}>
        </FileCenter>}

        <React.Fragment>
          <div style={{ float: 'right', marginRight: 20, cursor: 'pointer' }} onClick={() => { toggleChangePWDDialog(true) }}>修改密码</div>
          <EditIcon onClick={() => { toggleChangePWDDialog(true) }} width="20" height="20" style={{ transform: 'scale(0.7)', float: 'right', display: 'inline-block', position: 'relative', top: '14px', cursor: 'pointer' }} />
        </React.Fragment>

        {showChangePWDDialog && <ResetpwdDialog ischangepwd="ischangepwd" />}


      </div>

    );
  }
}

export default Navbar;

