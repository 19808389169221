import { of } from "rxjs";
import { ofType } from "redux-observable";
import { mergeMap, map, catchError, concatMap } from "rxjs/operators";
import { ajax } from "../../ajax";
import urls from "@/api/urls";
import * as uiActions from "../ui";
import queryString from "query-string";
import * as actions from "./antiblack";
import { ajaxBaseConfig, actionApi, apiBaseConfig } from "../../utils";

// 黑名单列表
export const loadBlackListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadBlackList.REQUEST.toString()),
  mergeMap((action) => {
    actionApi();
    const { protectUserType = '', user = '', protectType, protectStatus = '', blackReason = '', page = 0, size = 20, sort = 'id,desc', dataRange: { from = '', to = '' } = {}, } = action.payload;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.users.blackUsers}?user=${user}&protectUserType=${protectUserType}&protectStatus=${protectStatus}&protectType=${protectType}&blackReason=${blackReason}&createdTime=${from&&to?from+','+to:''}&page=${page}&size=${size}&sort=${sort}`,
    }).pipe(
      map((res) => actions.loadBlackList.SUCCESS(res.response)),
      catchError((error) =>
        of(
          actions.loadBlackList.FAIL(error.xhr.response),
          uiActions.notification(error.xhr.response)
        )
      )
    );
  })
);

// 创建用户
export const createBlackUserEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createBlackUser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.blackUsers}/batch`,
        method: 'POST',
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.createBlackUser.SUCCESS(res.response)
        }),
        catchError(error => of(actions.createBlackUser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 创建用户导入
export const createBlackUserOneEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createBlackUserOne.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload
    return (
      ajax({
        ...apiBaseConfig,
        url: `${urls.data.users.blackUsers}`,
        method: 'POST',
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.createBlackUserOne.SUCCESS(res.response)
        }),
        catchError(error => of(actions.createBlackUserOne.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// // 第三方码文件上传
// export const thirdPartCodeHandleModifyEpic = actions$ => actions$.pipe(
//   ofType(actions.thirdPartCodeHandleModify.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi();
//     return ajax({
//       ...apiBaseConfig,
//       url: `${urls.code.thirdPartCodeHandle.thirdPartCodeHandle}`,
//       body: action.payload,
//       method: 'post',
//     }).pipe(
//       mergeMap(res => [actions.thirdPartCodeHandleModify.SUCCESS(res.status), uiActions.notification('导入成功', 'success')]),
//       catchError(error => of(actions.thirdPartCodeHandleModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );




export const editBlackUserEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.editBlackUser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.blackUsers}/batch`,
        method: 'PUT',
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.editBlackUser.SUCCESS(res.response)
        }),
        catchError(error => of(actions.editBlackUser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const delBlackUserEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.delBlackUser.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.blackUsers}/batch`,
        method: 'DELETE',
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.delBlackUser.SUCCESS(res.response)
        }),
        catchError(error => of(actions.delBlackUser.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



export const loadUserOneEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadUserOne.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { user, eseId = '', callback } = action.payload;
    let url = `${urls.data.users.blackUsers}/user?user=${user}`;
    if (eseId) {
      url =  `${urls.data.users.blackUsers}/user/${user}/${eseId}`;
    }
    return (
      ajax({
        ...ajaxBaseConfig,
        url: url,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.loadUserOne.SUCCESS(res.response)
        }),
        catchError(error => of(actions.loadUserOne.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//导出
export const exportBlackListEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.exportBlackList.REQUEST.toString()),
  mergeMap((action) => {
    actionApi();
    const { callback } = action.payload
    const { protectUserType = '', user = '', protectType, protectStatus = '', blackReason = '', page = 0, size = 20, sort = 'id,desc', dataRange: { from = '', to = '' } = {}, } = action.payload.data;

    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.users.blackUsers}/export?user=${user}&protectUserType=${protectUserType}&protectStatus=${protectStatus}&protectType=${protectType}&blackReason=${blackReason}&createdTime=${from&&to?from+','+to:''}&page=${page}&size=${size}&sort=${sort}`,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.exportBlackList.SUCCESS(res.response)
      }),
      catchError((error) =>
        of(
          actions.exportBlackList.FAIL(error.xhr.response),
          uiActions.notification(error.xhr.response)
        )
      )
    );
  })
);



export const loadSettingEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadSetting.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { callback } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.blackUsers}/setup`,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.loadSetting.SUCCESS(res.response)
        }),
        catchError(error => of(actions.loadSetting.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
export const createOrEditSettingEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.createOrEditSetting.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.users.blackUsers}/setup`,
        method: data.id ? 'PUT' : 'POST',
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.createOrEditSetting.SUCCESS(res.response)
        }),
        catchError(error => of(actions.createOrEditSetting.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



