// import '@babel/polyfill';
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import ReactDOM from 'react-dom';
import React from 'react';
// import * as stores from './stores';
import App from './pages/App';
import './styles/index.scss';
import 'moment/locale/zh-cn';
import configureStore from './redux/store/configureStore';

const initialState = window.__INITIAL_STATE__;
console.log('initialState',initialState);
export const store = configureStore(initialState);
// configure({ enforceActions: 'always' });
console.log(store);

ReactDOM.render((
  <App store={store} />
), document.getElementById('root'));



