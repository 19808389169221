import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';

import * as actions from './flowDetail';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 窜货明细列表
export const flowDetailSumEpic = actions$ => actions$.pipe(
    ofType(actions.flowDetailSum.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',fleeingGoodsType='',dealerIds = [],shopIds=[],productIds=[],seqStatus=[],seqConfIds=[],eseSeqNum='',encode='',provinceCode='',cityCode=''} = action.payload;
        let dealerIdsVal = dealerIds||'';
        let shopIdsVal = shopIds||'';
        let productIdsVal = productIds||'';
        let seqStatusVal = seqStatus||'';
        let seqConfIdsVal = seqConfIds||'';
        let fleeingGoodsTypeVal = fleeingGoodsType.value || '';
        let provinceCodeVal = provinceCode || '';
        let cityCodeVal = cityCode || '';
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.flowDate.flowDateList}?dealerIds=${dealerIdsVal}&shopIds=${shopIdsVal}&page=${page}&size=${size}&startTime=${startTime}&endTime=${endTime}&fleeingGoodsType=${fleeingGoodsTypeVal}&productIds=${productIdsVal}&seqStatus=${seqStatusVal}&seqConfIds=${seqConfIdsVal}&eseSeqNum=${eseSeqNum}&encode=${encode}&provinceCode=${provinceCodeVal}&cityCode=${cityCodeVal}&sort=scan_time,desc`
        }).pipe(
            map(res => actions.flowDetailSum.SUCCESS(res.response)),
            catchError(error => of(actions.flowDetailSum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    } 
    )
);

// 窜货码状态
export const maStatusDateEpic = actions$ => actions$.pipe(
    ofType(actions.maStatusDate.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        // const  {type = 'scan-report'} = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.flowDate.maStatus}`
        }).pipe(
            map(res => actions.maStatusDate.SUCCESS(res.response)),
            catchError(error => of(actions.maStatusDate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 窜货码规格
export const maSpecsListEpic = actions$ => actions$.pipe(
    ofType(actions.maSpecsList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        // const  {type = 'scan-report'} = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.flowDate.maSpecs}`
        }).pipe(
            map(res => actions.maSpecsList.SUCCESS(res.response)),
            catchError(error => of(actions.maSpecsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// // 筛选选下载
// export const selectLoadEpic = actions$ => actions$.pipe(
//     ofType(actions.selectLoad.REQUEST.toString()),
//     mergeMap(action => {
//         actionApi();

//         const {type=1,json}=action.payload;
//         let url = '';
//         if(type == 1){
//             url = `${urls.scan.downloadStatistics}`;
//         }else if(type==2){
//             url = `${urls.scan.downloadBanquet}`;
//         }else if(type==3){
//             url = `${urls.scan.downloadConsumer}`;
//         }
//         return ajax({
//             ...ajaxBaseConfig,
//             url: url,
//             method:'POST',
//             body:JSON.stringify(json)
//         }).pipe(
//             map(res => actions.selectLoad.SUCCESS(res.response)),
//             catchError(error => of(actions.selectLoad.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//         )
//     }
//     )
// );




