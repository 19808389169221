import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'batchInformation';

export const loadApplyUnauditeds = fasterActions(moduleName, 'loadApplyUnauditeds');
 export const loadApplyPasses = fasterActions(moduleName, 'loadApplyPasses');
 export const loadInfo= fasterActions(moduleName, 'loadHisloadInfotory');
 export const loadHistory = fasterActions(moduleName, 'loadHistory');
 export const batchEnter = fasterActions(moduleName, 'batchEnter');
 export const batchActive = fasterActions(moduleName, 'batchActive');
 export const revocation = fasterActions(moduleName, 'revocation');
 export const check = fasterActions(moduleName, 'check');
 
 
 export const query_settings = fasterActions(moduleName, 'query_settings');
 export const applet_settings = fasterActions(moduleName, 'applet_settings');
 

// export const loadApplyReturns = fasterActions(moduleName, 'loadApplyReturns');
// export const sendBatchs = fasterActions(moduleName, 'sendBatchs');
// export const loadAuditUnauditeds = fasterActions(moduleName, 'loadAuditUnauditeds');
// export const loadAuditPasses = fasterActions(moduleName, 'loadAuditPasses');
// export const audit = fasterActions(moduleName, 'audit');



const initialState = {
  loadApplyUnauditedsLoading: false,
  loadApplyUnauditedsLoaded: false,
  loadApplyPassesLoading: false,
  loadApplyPassesLoaded: false,
  loadHistoryLoading: false,
  loadHistoryLoaded: false,
  loadInfoLoading: false,
  loadInfoLoaded: false,
  checkMessage:false,
  // loadApplyReturnsLoading: false,
  // loadApplyReturnsLoaded: false,
}

const reducer = handleActions(
  {
    [loadApplyUnauditeds.REQUEST]: (state, { payload }) => ({ ...state,  loadApplyUnauditedsLoading: true }),
    [loadApplyUnauditeds.SUCCESS]: (state, { payload }) => ({ ...state, loadApplyUnauditedsLoading: false, loadApplyUnauditedsLoaded: true, applyUnauditeds: payload }),
    [loadApplyUnauditeds.FAIL]: (state, { payload }) => ({ ...state, loadApplyUnauditedsLoading: false, loadApplyUnauditedsLoaded: false, error: payload }),
    [loadApplyPasses.REQUEST]: (state, { payload }) => ({ ...state,  loadApplyPassesLoading: true }),
    [loadApplyPasses.SUCCESS]: (state, { payload }) => ({ ...state, loadApplyPassesLoading: false, loadApplyPassesLoaded: true, applyPasses: payload }),
    [loadApplyPasses.FAIL]: (state, { payload }) => ({ ...state, loadApplyPassesLoading: false, loadApplyPassesLoaded: false, error: payload }),

    [loadInfo.REQUEST]: (state, { payload }) => ({ ...state,  loadInfoLoading: true }),
    [loadInfo.SUCCESS]: (state, { payload }) => ({ ...state, loadInfoLoading: false, loadInfoLoaded: true, Info: payload }),
    [loadInfo.FAIL]: (state, { payload }) => ({ ...state, loadInfoLoading: false, loadInfoLoaded: false, error: payload }),

    [loadHistory.REQUEST]: (state, { payload }) => ({ ...state,  loadHistoryLoading: true }),
    [loadHistory.SUCCESS]: (state, { payload }) => ({ ...state, loadHistoryLoading: false, loadHistoryLoaded: true, History: payload }),
    [loadHistory.FAIL]: (state, { payload }) => ({ ...state, loadHistoryLoading: false, loadHistoryLoaded: false, error: payload }),

    [batchEnter.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [batchEnter.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [batchEnter.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [batchActive.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [batchActive.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [batchActive.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [revocation.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [revocation.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [revocation.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [check.REQUEST]: (state, { payload }) => ({ ...state, }),
    [check.SUCCESS]: (state, { payload }) => ({ ...state, checkMessage:false }),
    [check.FAIL]: (state, { payload }) => ({ ...state,  checkMessage: payload.emsg }),


    [applet_settings.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [applet_settings.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [applet_settings.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [query_settings.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [query_settings.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [query_settings.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    // [sendBatchs.REQUEST]: (state, { payload }) => ({ ...state,  sendBatchsLoading: true }),
    // [sendBatchs.SUCCESS]: (state, { payload }) => ({ ...state, sendBatchsLoading: false, sendBatchsLoaded: true, sendBatchs: payload }),
    // [sendBatchs.FAIL]: (state, { payload }) => ({ ...state, sendBatchsLoading: false, sendBatchsLoaded: false, error: payload }),
    // [loadAuditUnauditeds.REQUEST]: (state, { payload }) => ({ ...state,  loadAuditUnauditedsLoading: true }),
    // [loadAuditUnauditeds.SUCCESS]: (state, { payload }) => ({ ...state, loadAuditUnauditedsLoading: false, loadAuditUnauditedsLoaded: true, auditUnauditeds: payload }),
    // [loadAuditUnauditeds.FAIL]: (state, { payload }) => ({ ...state, loadAuditUnauditedsLoading: false, loadAuditUnauditedsLoaded: false, error: payload }),
    // [loadAuditPasses.REQUEST]: (state, { payload }) => ({ ...state,  loadAuditPassesLoading: true }),
    // [loadAuditPasses.SUCCESS]: (state, { payload }) => ({ ...state, loadAuditPassesLoading: false, loadAuditPassesLoaded: true, auditPasses: payload }),
    // [loadAuditPasses.FAIL]: (state, { payload }) => ({ ...state, loadAuditPassesLoading: false, loadAuditPassesLoaded: false, error: payload }),
    // [audit.REQUEST]: (state, { payload }) => ({ ...state,  auditLoading: true }),
    // [audit.SUCCESS]: (state, { payload }) => ({ ...state, auditLoading: false, auditLoaded: true, audit: payload }),
    // [audit.FAIL]: (state, { payload }) => ({ ...state, auditLoading: false, auditLoaded: false, error: payload }),
  },
  initialState
)

export default reducer;