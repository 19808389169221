import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import * as actions from './precinct';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 字段分配
 */

// 列表
export const precinctListEpic = actions$ => actions$.pipe(
    ofType(actions.precinctList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20, name = "", code = "", precinctName = "", user = "", status = "" } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.precinct.dict}?page=${page}&size=${size}&name=${name}&code=${code}&precinctName=${precinctName}&user=${user}&status=${status}`
        }).pipe(
            map(res => actions.precinctList.SUCCESS(res.response)),
            catchError(error => of(actions.precinctList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 新增
export const precinctAddEpic = actions$ => actions$.pipe(
    ofType(actions.precinctAdd.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { user = "", precinctRegion = [], status = "", operate = "new", page = 0, size = 20 } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.precinct.dict}/${operate}`,
            method: operate == "new" ? 'post' : 'put',
            body: JSON.stringify(precinctRegion)
        }).pipe(

            concatMap(res => [actions.precinctList.REQUEST({ page: page, size: size, status: status })]),
            catchError(error => of(actions.precinctAdd.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//修改状态
export const changeStatusEpic = actions$ => actions$.pipe(
    ofType(actions.changeStatus.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { id, cstatus, version, status, page = 0, size = 20 } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.precinct.changeStatus}/${id}/${cstatus}/${version}`,
            method: 'PATCH',
        }).pipe(
            concatMap(res => [actions.precinctList.REQUEST({ page: page, size: size, status: status })]),
            catchError(error => of(actions.changeStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)
// 下拉框
export const precinctSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.precinctSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { status, precinctName = '', code } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.precinct.dict}/selections?status=${status}&precinctName=${precinctName}&code=${code}&size=${10}&isPage=${true}`
        }).pipe(
            map(res => actions.precinctSelection.SUCCESS(res.response)),
            catchError(error => of(actions.precinctSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

