import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';


const moduleName = 'pointMallPresents';

/**
 * TODO: 订单管理
 */

export const presentsPools = fasterActions(moduleName, 'presentsPools');
export const presentsPoolsVersions = fasterActions(moduleName, 'presentsPoolsVersions');
export const presentsPoolsById = fasterActions(moduleName, 'presentsPoolsById');
export const presentsPoolsPageById = fasterActions(moduleName, 'presentsPoolsPageById');
export const presentsPoolsModify = fasterActions(moduleName, 'presentsPoolsModify');
// 礼品库 礼品保存草稿
export const presentsPoolsDraft = fasterActions(moduleName, 'presentsPoolsDraft');
export const presentsPoolsModifyName = fasterActions(moduleName, 'presentsPoolsModifyName');
export const presentsPoolsDeliverStatistics = fasterActions(moduleName, 'presentsPoolsDeliverStatistics');

// export const presentsdeliver = fasterActions(moduleName, 'presentsPoolsModify');

export const presents = fasterActions(moduleName, 'presents');


//新增礼品
export const createPresents = fasterActions(moduleName, 'createPresents');
//更新礼品
export const editPresents = fasterActions(moduleName, 'editPresents');
//排序礼品
export const sortPresents = fasterActions(moduleName, 'sortPresents');
//删除礼品
export const delPresents = fasterActions(moduleName, 'delPresents');
//礼品库不分页
export const presentsPoolsSelect = fasterActions(moduleName, 'presentsPoolsSelect');
// 商城管理 礼品数据分页
export const presentsPoolsPage = fasterActions(moduleName, 'presentsPoolsPage');
//搜索特定礼品
export const presentsPoolsTypeValue = fasterActions(moduleName, 'presentsPoolsTypeValue');
//礼品组列表
export const getPresentsGroup = fasterActions(moduleName, 'getPresentsGroup');
//礼品组下拉
export const presentsGroupSelect = fasterActions(moduleName, 'presentsGroupSelect');
//新增礼品组
export const createPresentsGroup = fasterActions(moduleName, 'createPresentsGroup');
//删除礼品组
export const deletePresentsGroup = fasterActions(moduleName, 'deletePresentsGroup');
//礼品库历史不分页
export const presentsPoolsSelectHistory = fasterActions(moduleName, 'presentsPoolsSelectHistory');
//清空数据
export const unmount = fasterActions(moduleName, 'unmount');


// export const presentsCount = fasterActions(moduleName, 'presentsCount');

// export const presentsExport = fasterActions(moduleName, 'presentsExport');
// export const presentsRemark = fasterActions(moduleName, 'presentsRemark');

// export const presentsById = fasterActions(moduleName, 'presentsById');
// export const presentsDetail = fasterActions(moduleName, 'presentsDetail');
// export const presentsThirdDetail = fasterActions(moduleName, 'presentsThirdDetail');
// export const presentsRegLogistics = fasterActions(moduleName, 'presentsRegLogistics');




const initialState = {
  presentsPoolsfilterValue: '',
  presentsGroupList: { length: 0, content: [] },
  presentsGroupsSelection: [],
}

const reducer = handleActions(
  {

    [presentsPools.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [presentsPools.SUCCESS]: (state, { payload }) => ({ ...state, presentsPoolsList: payload, loading: false }),
    [presentsPools.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),



    [presentsPoolsVersions.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [presentsPoolsVersions.SUCCESS]: (state, { payload }) => ({ ...state, presentsPoolsVersionsList: payload, loading: false }),
    [presentsPoolsVersions.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    [presentsPoolsById.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [presentsPoolsById.SUCCESS]: (state, { payload }) => {
      return ({ ...state, presentsPoolsById: payload, loading: false })
    }

    ,
    [presentsPoolsById.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [presentsPoolsPageById.REQUEST]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
    [presentsPoolsPageById.SUCCESS]: (state, { payload }) => {
      if (payload.content) {
        payload.content.forEach(val => {
          if (!val.presentWarn) {
            val.presentWarn = {};
          } else if (val.presentWarn.warnStatus == 'warn') {
            val.presentWarn.warnStatus = false;
          }
          if (val.presentWarn.warnNumber == 0) {
            val.presentWarn.warnNumber = '';
          }
          if (val.outOfStockType == 'NORMAL') {
            val.outOfStockType = false;
          }
        })
      } else {
        payload.content = [];
      }
      return ({ ...state, presentsPoolsPageById: payload, loading: false })
    },
    [presentsPoolsPageById.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
    [presentsPoolsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, presentsPoolsModifyLoaded: false, loading: true }),
    [presentsPoolsModify.SUCCESS]: (state, { payload }) => ({ ...state, presentsPoolsModifyLoaded: payload, loading: false }),
    [presentsPoolsModify.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [presentsPoolsModifyName.REQUEST]: (state, { payload }) => ({ ...state, payload, presentsPoolsModifyNameLoaded: false }),
    [presentsPoolsModifyName.SUCCESS]: (state, { payload }) => ({ ...state, presentsPoolsModifyNameLoaded: payload }),
    [presentsPoolsModifyName.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [presentsPoolsDeliverStatistics.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [presentsPoolsDeliverStatistics.SUCCESS]: (state, { payload }) => ({ ...state, presentsPoolsDeliverStatistics: payload }),
    [presentsPoolsDeliverStatistics.FAIL]: (state, { payload }) => ({ ...state, error: payload }),






    [presents.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [presents.SUCCESS]: (state, { payload }) => ({ ...state, presentsList: payload, loading: false }),
    [presents.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    //新增礼品
    [createPresents.REQUEST]: (state, { payload }) => ({ ...state }),
    [createPresents.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createPresents.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //更新礼品
    [editPresents.REQUEST]: (state, { payload }) => ({ ...state }),
    [editPresents.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editPresents.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //排序礼品
    [sortPresents.REQUEST]: (state, { payload }) => ({ ...state }),
    [sortPresents.SUCCESS]: (state, { payload }) => ({ ...state }),
    [sortPresents.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    //删除礼品
    [delPresents.REQUEST]: (state, { payload }) => ({ ...state }),
    [delPresents.SUCCESS]: (state, { payload }) => ({ ...state }),
    [delPresents.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    //礼品库不分页


    [presentsPoolsSelect.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [presentsPoolsSelect.SUCCESS]: (state, { payload }) => {
      let presentsPoolsTypeValueList = payload.filter(key => { return (key.presentName || '').indexOf(state.presentsPoolsfilterValue) >= 0 || (key.id.toString()).indexOf(state.presentsPoolsfilterValue) >= 0 });
      return { ...state, presentsPoolsSelection: presentsPoolsTypeValueList, presentsPoolsAllSelection: payload, loading: false }

    },


    [presentsPoolsSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
    // 礼品库分页
    [presentsPoolsPage.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [presentsPoolsPage.SUCCESS]: (state, { payload }) => {
      let presentsPoolsTypeValueList = payload.content.filter(key => { return (key.presentName || '').indexOf(state.presentsPoolsfilterValue) >= 0 || (key.id.toString()).indexOf(state.presentsPoolsfilterValue) >= 0 });
      return { ...state, presentsPoolsPageData: { content: presentsPoolsTypeValueList, totalElements: payload.totalElements }, loading: false }

    },
    [presentsPoolsPage.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
    [presentsPoolsTypeValue.REQUEST]: (state, { payload }) => {
      let presentsPoolsTypeValueList = state.presentsPoolsAllSelection.filter(key => { return (key.presentName || '').indexOf(payload) >= 0 || (key.id.toString()).indexOf(payload) >= 0 });
      return { ...state, presentsPoolsfilterValue: payload, presentsPoolsSelection: presentsPoolsTypeValueList }
      // return { ...state, PlanfilterValue: payload }
    },

    // 礼品库 礼品草稿数据保存
    [presentsPoolsDraft.REQUEST]: (state, { payload }) => ({ ...state, payload, presentsPoolsModifyLoaded: false }),
    [presentsPoolsDraft.SUCCESS]: (state, { payload }) => ({ ...state, presentsPoolsModifyLoaded: payload }),
    [presentsPoolsDraft.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getPresentsGroup.REQUEST]: (state, { payload }) => ({ ...state, getPresentsGroupLoading: true, }),
    [getPresentsGroup.SUCCESS]: (state, { payload }) => ({ ...state, presentsGroupList: payload, getPresentsGroupLoading: false, }),
    [getPresentsGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload, getPresentsGroupLoading: false, }),
    [presentsGroupSelect.REQUEST]: (state, { payload }) => ({ ...state }),
    [presentsGroupSelect.SUCCESS]: (state, { payload }) => ({ ...state, presentsGroupsSelection: payload }),
    [presentsGroupSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [createPresentsGroup.REQUEST]: (state, { payload }) => ({ ...state, createPresentsGroupLoading: true, }),
    [createPresentsGroup.SUCCESS]: (state, { payload }) => ({ ...state, createPresentsGroupLoading: false, }),
    [createPresentsGroup.FAIL]: (state, { payload }) => ({ ...state, createPresentsGroupLoading: false, error: payload }),
    [deletePresentsGroup.REQUEST]: (state, { payload }) => ({ ...state }),
    [deletePresentsGroup.SUCCESS]: (state, { payload }) => ({ ...state }),
    [deletePresentsGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload }),




    [presentsPoolsSelectHistory.REQUEST]: (state, { payload }) => ({ ...state }),
    [presentsPoolsSelectHistory.SUCCESS]: (state, { payload }) => ({ ...state }),
    [presentsPoolsSelectHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [unmount.REQUEST]: (state, { payload }) => ({
      presentsPoolsSelection: { length: 0, content: [] },
      presentsPoolsfilterValue: '',
      presentsGroupList: { length: 0, content: [] },
      presentsGroupsSelection: [],
    }),


    /////////////
    // [presentsCount.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    // [presentsCount.SUCCESS]: (state, { payload }) => ({ ...state, presentsCount: payload }),
    // [presentsCount.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // [presentsExport.REQUEST]: (state, { payload }) => ({ ...state, payload, presentsExportLoaded: false }),
    // [presentsExport.SUCCESS]: (state, { payload }) => ({ ...state, presentsExportLoaded: payload }),
    // [presentsExport.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // [presentsRemark.REQUEST]: (state, { payload }) => ({ ...state, payload, presentsRemarkLoaded: false }),
    // [presentsRemark.SUCCESS]: (state, { payload }) => ({ ...state, presentsRemarkLoaded: payload }),
    // [presentsRemark.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // [presentsById.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    // [presentsById.SUCCESS]: (state, { payload }) => ({ ...state, presentsById: payload }),
    // [presentsById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    // [presentsDetail.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    // [presentsDetail.SUCCESS]: (state, { payload }) => ({ ...state, presentsDetailList: payload, loading: false }),
    // [presentsDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // [presentsThirdDetail.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    // [presentsThirdDetail.SUCCESS]: (state, { payload }) => ({ ...state, presentsThirdDetail: payload, loading: false }),
    // [presentsThirdDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    // [presentsRegLogistics.REQUEST]: (state, { payload }) => ({ ...state, payload, presentsRegLogisticsLoaded: false }),
    // [presentsRegLogistics.SUCCESS]: (state, { payload }) => ({ ...state, presentsRegLogisticsLoaded: payload }),
    // [presentsRegLogistics.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },

  initialState
);

export default reducer;
