import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './marketing';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadListEpic = actions$ => actions$.pipe(
    ofType(actions.loadList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const {
            dataVersion = 'NORMAL',
            endDate = '',
            type: periodType,
            startDate,
            statisticsType = 'SCAN',
            dimension = '',
            ids,
            areaDataType = '',
            areaName = '',
            areaType = '',
            page = 0,
            size=20,
        } = action.payload;
        const url = urls.statistics.marketing.list;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&pageSize=${size}&page=${page+1}&dataVersion=${dataVersion}&periodType=${periodType}&startDate=${startDate}&statisticsType=${statisticsType}&dimension=${dimension}&ids=${ids.join(',')}&areaDataType=${areaDataType}&areaName=${areaName}&areaType=${areaType}`
        }).pipe(
            map(res => actions.loadList.SUCCESS(res.response)),
            catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);