import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Input, Button, FormGroupList, StaticFormGroup, notification } from '$bere';
import FormGroupField from '@/components/FormGroupField';
import { reduxForm } from 'redux-form';
import { updateCodeParamsDetailDialogStatus } from '@/redux/modules/ui';
import { systemLogin, toggleChangePWDDialog } from '@/redux/modules/users/users';
import logoIMg from './../../assets/codeCenter/jiannanchunlogo.png';
import './SystemLogin.scss';
import ResetpwdDialog from './ResetpwdDialog';
import JSEncrypt from '@/libs/encrypt.js';

const required = (text) => (value) => {
  return (!value || (value + '').trim() === '' ? text : undefined);
  // return (value || typeof value === 'number' ? undefined : '请填写备注');
}

const requiredAccount = required('请输入账号');
const requiredPassword = required('请输入密码');


@withRouter
@connect(
  state => ({
    showChangePWDDialog: state.users.showChangePWDDialog,
    form: state.form.SystemLoginForm
  }),
  {
    systemLogin: systemLogin.REQUEST,
    toggleChangePWDDialog
  }
)
class SystemLogin extends React.Component {

  componentDidMount() {
    document.onkeydown = (e) => {
      if (!e)
        e = window.event;//火狐中是 window.event
      if ((e.keyCode || e.which) == 13) {
        this.refs.submit.handleClick();
      }
    }
  }

  componentDidUpdate() {

  }

  login = (values) => {
    const { systemLogin, history } = this.props;
    // systemLogin({ body: values, history });
    let { password ,account} = values;

    
    var encrypt  = new JSEncrypt();
    encrypt.setPublicKey(`MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQqJ4SyujGCKwQvNzsKVMcoSQnoE60zKSPoUen7c+FRqJqineEFNhk7Hc8qWfZDbzMtIAMeg78+SgiOJ1es+KOIUOncaN0dae34QP5AdmORAm1AUXG8osmCe54SU0cnnIwS6L6RE6OIiFZzf//Eo3blf1x3sBLc6WZ/KDSqRLKCwIDAQAB`);
    var encrypted = encrypt.encrypt(password);
    let pwdObj = {account,password:encrypted};

    systemLogin({ body: pwdObj, history });

  }

  resetPWD = () => {
    const {form = {values:{}}, toggleChangePWDDialog} = this.props;
    if(form.values && form.values.account){
      toggleChangePWDDialog(true);
    }else{
      notification.error("请输入账号后重置");
    }
  }
  render() {
    const { handleSubmit, showChangePWDDialog, toggleChangePWDDialog } = this.props;
    return (
      <div className="SystemLogin">
     
        <div className="main-block">
          <div className="img" style={{ width: 'auto', whiteSpace: 'nowrap', textAlign: 'center', top: '-50%' }}>
            <h1>四川剑南春(集团)有限责任公司</h1>
            <h2>优码系统</h2>
          </div>
        {/* <img className="img"src={logoIMg}/> */}
          {/* <div className="title">剑南春优码系统</div> */}
          <div className="form">
            <FormGroupList>
              <FormGroupField
                name="account"
                label="帐号"
                component={Input}
                validate={[requiredAccount]}

              ></FormGroupField>

              <FormGroupField
                name="password"
                label="密码"
                type="password"
                component={Input}
                validate={[requiredPassword]}
              ></FormGroupField>

              <StaticFormGroup
                    label={''}
                    className="btn-resetpwd"
                >
                  {/* <span onClick={()=>{this.resetPWD()}}>重置密码</span> */}
                  </StaticFormGroup>
            </FormGroupList>
          </div>
          <Button ref="submit" type="submit" onClick={handleSubmit(this.login)}>登录</Button>
        </div>
        {/* <iframe style={{width: '100%', height: 1200}} src="">

        </iframe> */}
        { showChangePWDDialog && <ResetpwdDialog ischangepwd={false} />}

        <div
          style={{
            textAlign: 'center',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            fontSize: 12
          }}>
          <a href={`http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51068302000140`} target="_blank" style={{ color: 'revert', marginRight: 10 }}>
            川公网安备51068302000140号
          </a>
          <a href="https://beian.miit.gov.cn/" target="_blank" style={{ color: 'revert' }}>
            蜀ICP备05018250号-5
          </a>
        </div>
      </div>
    )
  }
}

let SystemLoginForm = reduxForm({
  form: 'SystemLoginForm', // a unique identifier for this form
  enableReinitialize: true,
})(SystemLogin)

export default SystemLoginForm;