import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'miniProgramDevelopers';

/**
 * TODO: 微信管理
 */

export const developers = fasterActions(moduleName, 'developers');
export const developersApp = fasterActions(moduleName, 'developersApp');
export const developersRelation = fasterActions(moduleName, 'developersRelation');

const initialState = {

}

const reducer = handleActions(
  {
    [developers.REQUEST]: (state, { payload }) => ({ ...state }),
    [developers.SUCCESS]: (state, { payload }) => {
      if (payload.content) {
        payload.content.forEach(val => {
          val.text = val.name;
          val.value = val.wechatId;
        });

      } else {
        payload.content = [];
      }
      return ({ ...state, developers: payload })
    },
    [developers.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [developersApp.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [developersApp.SUCCESS]: (state, { payload }) => ({ ...state, developersApp: payload }),
    [developersApp.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [developersRelation.REQUEST]: (state, { payload }) => ({ ...state, payload, developersRelation: '' }),
    [developersRelation.SUCCESS]: (state, { payload }) => ({ ...state, developersRelation: payload }),
    [developersRelation.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },

  initialState
);

export default reducer;
