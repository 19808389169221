import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './miniProgramDevelopers';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 小程序开发者管理
 */
// 开发者列表
export const developersEpic = actions$ => actions$.pipe(
  ofType(actions.developers.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: urls.wechat.miniProgramDevelopers.developers
    }).pipe(
      map(res => actions.developers.SUCCESS(res.response)),
      catchError(error => of(actions.developers.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
// 获取小程序的开发者
export const developersAppEpic = actions$ => actions$.pipe(
  ofType(actions.developersApp.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: urls.wechat.miniProgramDevelopers.developersApp(action.payload)
    }).pipe(
      map(res => actions.developersApp.SUCCESS(res.response)),
      catchError(error => of(actions.developersApp.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 企业选择小程序开发者
export const developersRelationEpic = actions$ => actions$.pipe(
  ofType(actions.developersRelation.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      method: 'post',
      body: action.payload,
      url: urls.wechat.miniProgramDevelopers.developersRelation
    }).pipe(
      map(res => actions.developersRelation.SUCCESS(res.status)),
      catchError(error => of(actions.developersRelation.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


