import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'writeOffShop';

export const writeOffStore = fasterActions(moduleName, 'writeOffStore'); // 终端列表
export const writeOffEmptyAll = fasterActions(moduleName, 'writeOffEmptyAll'); // 清空终端列表
export const writeOffEdit = fasterActions(moduleName, 'writeOffEdit'); // 选择终端 -- 终端编辑确认
export const writeOffDelete = fasterActions(moduleName, 'writeOffDelete'); // 删除终端
export const writeOffPolicy = fasterActions(moduleName, 'writeOffPolicy'); // 政策/批量政策
export const writeOffStoreAll = fasterActions(moduleName, 'writeOffStoreAll'); // 选择终端 -- 终端编辑确认
export const rewardPolicyList = fasterActions(moduleName, 'rewardPolicyList'); // 核销政策
export const writeOffImg = fasterActions(moduleName, 'writeOffImg')
export const writeOffUpload= fasterActions(moduleName, 'writeOffUpload'); // 核销导入

export const writeOffDetail = fasterActions(moduleName, 'writeOffDetail'); // 核销明细列表
export const writeOffDetailExport = fasterActions(moduleName, 'writeOffDetailExport'); // 核销明细导出


const initialState = {
  loading: false, // 列表loading
  createLoading: false,
  banquetPolicyTestForm: {}, // 表单信息
}

const reducer = handleActions(
  {
    [writeOffStore.REQUEST]: (state, { payload }) => ({ ...state,writeOffStoreLoading: true}),
    [writeOffStore.SUCCESS]: (state, { payload }) => ({ ...state,writeOffStoreLoading: false,writeOffStoreList: payload}),
    [writeOffStore.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffStoreLoading: false}),

    [writeOffEmptyAll.REQUEST]: (state, { payload }) => ({ ...state,writeOffEmptyAllLoading: true}),
    [writeOffEmptyAll.SUCCESS]: (state, { payload }) => ({ ...state,writeOffEmptyAllLoading: false,}),
    [writeOffEmptyAll.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffEmptyAllLoading: false}),

    [writeOffEdit.REQUEST]: (state, { payload }) => ({ ...state,writeOffEditLoading: true}),
    [writeOffEdit.SUCCESS]: (state, { payload }) => ({ ...state,writeOffEditLoading: false,writeOffEditList: payload}),
    [writeOffEdit.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffEditLoading: false}),

    [writeOffDelete.REQUEST]: (state, { payload }) => ({ ...state,writeOffDeleteLoading: true}),
    [writeOffDelete.SUCCESS]: (state, { payload }) => ({ ...state,writeOffDeleteLoading: false}),
    [writeOffDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffDeleteLoading: false}),

    [writeOffPolicy.REQUEST]: (state, { payload }) => ({ ...state,writeOffPolicyLoading: true}),
    [writeOffPolicy.SUCCESS]: (state, { payload }) => ({ ...state,writeOffPolicyLoading: false}),
    [writeOffPolicy.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffPolicyLoading: false}),

    [writeOffStoreAll.REQUEST]: (state, { payload }) => ({ ...state,writeOffStoreAllLoading: true}),
    [writeOffStoreAll.SUCCESS]: (state, { payload }) => ({ ...state,writeOffStoreAllLoading: false,writeOffStoreAllList: payload}),
    [writeOffStoreAll.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffStoreAllLoading: false}),

    [rewardPolicyList.REQUEST]: (state, { payload }) => ({ ...state,rewardPolicyListLoading: true }),
    [rewardPolicyList.SUCCESS]: (state, { payload }) => ({ ...state, rewardPolicyList: payload,rewardPolicyListLoading: false }),
    [rewardPolicyList.FAIL]: (state, { payload }) => ({ ...state, error: payload,rewardPolicyListLoading: false}),

    [writeOffImg.REQUEST]: state => state,
    [writeOffImg.SUCCESS]: state => state,
    [writeOffImg.FAIL]: state => state,

    [writeOffUpload.REQUEST]: (state, { payload }) => ({ ...state,}),
    [writeOffUpload.SUCCESS]: (state, { payload }) => ({ ...state,}),
    [writeOffUpload.FAIL]: (state, { payload }) => ({ ...state, error: payload}),

    [writeOffDetail.REQUEST]: (state, { payload }) => ({ ...state,writeOffDetailLoading: true}),
    [writeOffDetail.SUCCESS]: (state, { payload }) => ({ ...state,writeOffDetailLoading: false,writeOffDetailList: payload}),
    [writeOffDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffDetailLoading: false}),

    [writeOffDetailExport.REQUEST]: (state, { payload }) => ({ ...state,writeOffDetailExportLoading: true}),
    [writeOffDetailExport.SUCCESS]: (state, { payload }) => ({ ...state,writeOffDetailExportLoading: false}),
    [writeOffDetailExport.FAIL]: (state, { payload }) => ({ ...state, error: payload,writeOffDetailExportLoading: false}),
  },
  initialState
);

export default reducer;
