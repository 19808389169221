import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeDataManagement';

export const loadCodedb = fasterActions(moduleName, 'loadCodedb');
export const loadCodedbSelect = fasterActions(moduleName, 'loadCodedbSelect');
export const createCodedb = fasterActions(moduleName, 'createCodedb');
export const editCodedb = fasterActions(moduleName, 'editCodedb');

//  码数据变更
export const loadcodeDatachangeList = fasterActions(moduleName, 'loadcodeDatachangeList');
export const saveCodeDataChange = fasterActions(moduleName, 'saveCodeDataChange');
export const batchCodeDataChange = fasterActions(moduleName, 'batchCodeDataChange');

// 搜索关键字
export const changeKeyWords = createAction(`${moduleName}/changeKeyWords`);

//  码环节查看
export const loadLinkUploadLogsList = fasterActions(moduleName, 'loadLinkUploadLogsList');
export const exports = fasterActions(moduleName, 'exports');
export const reTry = fasterActions(moduleName, 'reTry');

//  码数据查看
export const getTagDatas = fasterActions(moduleName, 'getTagDatas');
export const deleteTagDatas = fasterActions(moduleName, 'deleteTagDatas');
export const postTagDatas = fasterActions(moduleName, 'postTagDatas');
export const exportsTagDatas = fasterActions(moduleName, 'exportsTagDatas');
//  码数据下载
export const getTagDatasExport = fasterActions(moduleName, 'getTagDatasExport');
export const deleteTagDatasExport = fasterActions(moduleName, 'deleteTagDatasExport');
export const postTagDatasExport = fasterActions(moduleName, 'postTagDatasExport');
export const exportsTagDatasExport = fasterActions(moduleName, 'exportsTagDatasExport');
// 清除列表
export const clearLinkUploadLogsList = createAction(`${moduleName}/clearLinkUploadLogsList`);
export const clearTagDatasList = createAction(`${moduleName}/clearTagDatasList`);

export const changeDictPaging = fasterActions(moduleName, 'changeDictPaging');

const initialState = {

  // 码数据变更
  codeDatachangeList: [],
  paging: {
    page: 0,
    size: 20,
    field: {}
  },
}

const reducer = handleActions(
  {
    [changeDictPaging]: (state, { payload }) => {
      return { ...state, paging: { ...payload } }
    },

    [loadcodeDatachangeList.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, codeDatachangelListLoading: true }),
    [loadcodeDatachangeList.SUCCESS]: (state, { payload }) => {
      return ({ ...state, codeDatachangelListLoading: false, codeDatachangeList: payload.content, totalElements: payload.totalElements })
    },
    [loadcodeDatachangeList.FAIL]: (state, { payload }) => ({ ...state, codeDatachangelListLoading: false, error: payload }),

    // 搜索关键字
    [changeKeyWords]: (state, { payload }) => {
      return ({ ...state, paging: { ...state.paging, keyWords: payload, page: 0 } })
    },

    // 单码查询变更
    [saveCodeDataChange.REQUEST]: (state, { payload }) => ({ ...state, saveCodeDataChangeLoading: true }),
    [saveCodeDataChange.SUCCESS]: (state, { payload }) => ({ ...state, saveCodeDataChangeLoading: false }),
    [saveCodeDataChange.FAIL]: (state, { payload }) => ({ ...state, saveCodeDataChangeLoading: false, error: payload }),

    // 批量查询变更
    [batchCodeDataChange.REQUEST]: (state, { payload }) => ({ ...state, batchCodeDataChangeLoading: true }),
    [batchCodeDataChange.SUCCESS]: (state, { payload }) => ({ ...state, batchCodeDataChangeLoading: false }),
    [batchCodeDataChange.FAIL]: (state, { payload }) => ({ ...state, batchCodeDataChangeLoading: false, error: payload }),

    // 码环节查看
    [loadLinkUploadLogsList.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, linkUploadLogsListLoading: true }),
    [loadLinkUploadLogsList.SUCCESS]: (state, { payload }) => {
      return ({ ...state, linkUploadLogsListLoading: false, linkUploadLogsList: payload.content, paging: { ...state.paging, page: payload.number, size: payload.size }, totalElements: payload.totalElements || 0 })
    },
    [loadLinkUploadLogsList.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),

    // 清除列表
    [clearLinkUploadLogsList]: (state, { payload }) => ({ ...state, linkUploadLogsList: [] }),

    [exports.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, linkUploadLogsListLoading: true }),
    [exports.SUCCESS]: (state, { payload }) => {
      return ({ ...state, linkUploadLogsListLoading: false, })
    },
    [exports.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),

    // 重试
    [reTry.REQUEST]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: true }),
    [reTry.SUCCESS]: (state, { payload }) => {
      return ({ ...state, linkUploadLogsListLoading: false, })
    },
    [reTry.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),


    // // 码数据查看
    // [getTagDatas.REQUEST]: (state, { payload }) =>{
    //   return  ({ ...state, paging: {...payload}, linkUploadLogsListLoading: true })},
    // [getTagDatas.SUCCESS]: (state, { payload }) =>{
    //   return  ({ ...state, linkUploadLogsListLoading: false,  tagDatasList: payload, totalElements: payload.length || 0 })
    // },
    // [getTagDatas.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false,  error: payload }),


    // [deleteTagDatas.REQUEST]: (state, { payload }) => ({ ...state, paging: {...payload}, linkUploadLogsListLoading: true }),
    // [deleteTagDatas.SUCCESS]: (state, { payload }) =>{
    //   return  ({ ...state, linkUploadLogsListLoading: false,  })
    // },
    // [deleteTagDatas.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false,  error: payload }),

    // 码数据查询记录保存
    [postTagDatas.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, tagDatasListLoading: true }),
    [postTagDatas.SUCCESS]: (state, { payload }) => {
      return ({ ...state, tagDatasListLoading: false, tagDatasList: payload.content, totalElements: payload.totalElements || 0 })
    },
    [postTagDatas.FAIL]: (state, { payload }) => ({ ...state, tagDatasListLoading: false, error: payload }),
    [clearTagDatasList]: (state, { payload }) => ({ ...state, tagDatasList: [] }),

    // 码数据查询记录下载
    [exportsTagDatas.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, linkUploadLogsListLoading: true }),
    [exportsTagDatas.SUCCESS]: (state, { payload }) => {
      return ({ ...state, linkUploadLogsListLoading: false, })
    },
    [exportsTagDatas.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),

    // 码数据下载
    [getTagDatasExport.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, linkUploadLogsListLoading: true }),
    [getTagDatasExport.SUCCESS]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, tagDatasListExports: payload, totalElements: payload.length || 0 }),
    [getTagDatasExport.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),


    [deleteTagDatasExport.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, linkUploadLogsListLoading: true }),
    [deleteTagDatasExport.SUCCESS]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, }),
    [deleteTagDatasExport.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),

    // 码数据查询记录保存
    [postTagDatasExport.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, linkUploadLogsListLoading: true }),
    [postTagDatasExport.SUCCESS]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, }),
    [postTagDatasExport.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),

    // 码数据查询记录下载
    [exportsTagDatasExport.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, linkUploadLogsListLoading: true }),
    [exportsTagDatasExport.SUCCESS]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, }),
    [exportsTagDatasExport.FAIL]: (state, { payload }) => ({ ...state, linkUploadLogsListLoading: false, error: payload }),
  },
  initialState
)

export default reducer;