import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './scancodeStatistics';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 扫码统计列表
export const statisticsListEpic = actions$ => actions$.pipe(
    ofType(actions.statisticsList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const {callback, page = 0, size = 20,startTime='',endTime='',dataVersion = { value: 'NORMAL', text: '正式版' },sort = 'tagdate,desc',franchiserId=[],shopName='',poolIds='',productId=[],prizeId='',officePost={},cityManagerPost={},promotionId='',promotionStatus=''} = action.payload;
        let promotionIdVal=(promotionId=='all')?'':promotionId;
        let franchiserIdValue = franchiserId||'';
        let productIdValue = productId||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';
        let poolIdValue = (poolIds == 'all')?'':poolIds;
        let prizeIdValue = (prizeId == 'all')?'':prizeId;
        let dataVersionValue=dataVersion.value || 'NORMAL';
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.statistics}?dataVersion=${dataVersionValue}&poolIds=${poolIdValue}&page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTime}&franchiserIds=${franchiserIdValue}&shopName=${shopName}&productIds=${productIdValue}&prizeId=${prizeIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&promotionIds=${promotionIdVal}&promotionStatus=${promotionStatusVal}`
        }).pipe(
            map(res => {
                callback && callback()
                return actions.statisticsList.SUCCESS(res.response || []) 
            }),
            catchError(error => of(actions.statisticsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 扫码统计汇总列表
export const statisticSumEpic = actions$ => actions$.pipe(
    ofType(actions.statisticSum.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { startTime='',endTime='',dataVersion = { value: 'NORMAL', text: '正式版' },franchiserId=[],shopName='',poolIds='',productId=[],prizeId='',officePost={},cityManagerPost={},promotionId='',promotionStatus=''} = action.payload;
        let promotionIdVal=(promotionId=='all')?'':promotionId;
        let franchiserIdValue = franchiserId||'';
        // let shopIdValue = shopId||'';
        let productIdValue = productId||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';
        let poolIdValue = (poolIds == 'all')?'':poolIds;
        let prizeIdValue = (prizeId == 'all')?'':prizeId;
        let dataVersionValue=dataVersion.value || 'NORMAL';
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.sumData}?dataVersion=${dataVersionValue}&poolIds=${poolIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserIds=${franchiserIdValue}&shopName=${shopName}&productIds=${productIdValue}&prizeId=${prizeIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&promotionIds=${promotionIdVal}&promotionStatus=${promotionStatusVal}`
        }).pipe(
            map(res => actions.statisticSum.SUCCESS(res.response)),
            catchError(error => of(actions.statisticSum.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//筛选下载类表
export const fixedDownloadEpic = actions$ => actions$.pipe(
    ofType(actions.fixedDownload.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        const  {type = 'scan-report'} = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.fixedDownload}?type=${type}`
        }).pipe(
            map(res => actions.fixedDownload.SUCCESS(res.response)),
            catchError(error => of(actions.fixedDownload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 筛选选下载
export const selectLoadEpic = actions$ => actions$.pipe(
    ofType(actions.selectLoad.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        const {type=1,json}=action.payload;
        let url = '';
        if(type == 1){
            url = `${urls.scan.downloadStatistics}`;
        }else if(type==2){
            url = `${urls.scan.downloadBanquet}`;
        }else if(type==3){
            url = `${urls.scan.downloadConsumer}`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url: url,
            method:'POST',
            body:JSON.stringify(json)
        }).pipe(
            map(res => actions.selectLoad.SUCCESS(res.response)),
            catchError(error => of(actions.selectLoad.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//异步下载
export const statisticSumDownEpic = actions$ => actions$.pipe(
    ofType(actions.statisticSumDown.REQUEST.toString()),
    mergeMap(action => { 
        actionApi();
        const {callback, startTime = '', endTime = '', sort = null, franchiserId = [], shopName = '', dataVersion = { value: 'NORMAL', text: '正式版' },productId = [], prizeId = '', officePost = {}, cityManagerPost = {},poolIds='',promotionId='' ,promotionStatus='' } = action.payload;
        const url = urls.scan.statisticSumDown;
        let franchiserIdValue = franchiserId || '';
        let productIdValue = productId || '';
        
        let officePostValue = officePost.value || '';
        let cityManagerPostValue = cityManagerPost.value || '';
        let poolIdValue = (poolIds == 'all')?'':poolIds;
        let prizeIdValue = (prizeId == 'all')?'':prizeId;
        let dataVersionValue=dataVersion.value || 'NORMAL';
        let promotionIdVal=(promotionId=='all')?'':promotionId;
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?dataVersion=${dataVersionValue}&poolIds=${poolIdValue}&startTime=${startTime}&endTime=${endTime}&franchiserIds=${franchiserIdValue}&shopName=${shopName}&productIds=${productIdValue}&prizeId=${prizeIdValue}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&promotionIds=${promotionIdVal}&promotionStatus=${promotionStatusVal}`
        }).pipe(
            map(res => {
                callback && callback()
                return actions.statisticSumDown.SUCCESS(res.response || []) 
            }),
            catchError(error => 
                of(actions.statisticSumDown.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
            )
        )
    })
);




