import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeTempConfig';

export const loadTemplateConfigs = fasterActions(moduleName, 'loadTemplateConfigs');
export const addTemplateConfig = fasterActions(moduleName, 'addTemplateConfig');
export const editTemplateConfig = fasterActions(moduleName, 'editTemplateConfig');

export const loadFields = fasterActions(moduleName, 'loadFields');
export const loadTagMaterials = fasterActions(moduleName, 'loadTagMaterials');
export const addTagMaterial = fasterActions(moduleName, 'addTagMaterial');
export const editTagMaterial = fasterActions(moduleName, 'editTagMaterial');
export const loadTemplateConfigById = fasterActions(moduleName, 'loadTemplateConfigById');
export const loadDistributionList = fasterActions(moduleName, 'loadDistributionList');
export const templateDistribution = fasterActions(moduleName, 'templateDistribution');
 


const initialState = {
  createOrEdit: {
    faceds: [
      {
        level: 'FIRST_LAYER',
        position: 'A',
      }
    ],
    codeSourceType: 'PLATFORM_STANDARD',
    direction: 'TRANSVERSE',
    encryption: 'COMMON',
    bindType: 'BATCH',
    type: 'NORMAL',
    length: '38',

  } 
}

const reducer = handleActions(
  {
    [loadTemplateConfigs.REQUEST]: (state, { payload }) => ({ ...state, loadTemplateConfigsLoading: true, createOrEdit: {
      faceds: [
        {
          level: 'FIRST_LAYER',
          position: 'A',
        }
      ],
      codeSourceType: 'PLATFORM_STANDARD',
      direction: 'TRANSVERSE',
      encryption: 'COMMON',
      bindType: 'BATCH',
      type: 'NORMAL',
      length: '38',
  
    }  }),
    [loadTemplateConfigs.SUCCESS]: (state, { payload }) => ({ ...state, loadTemplateConfigsLoading: false, loadTemplateConfigsLoaded: true, templateConfigs: payload }),
    [loadTemplateConfigs.FAIL]: (state, { payload }) => ({ ...state, loadTemplateConfigsLoading: false, loadTemplateConfigsLoaded: false, error: payload }),

    [addTemplateConfig.REQUEST]: (state, { payload }) => ({ ...state, addTemplateConfigLoading: true }),
    [addTemplateConfig.SUCCESS]: (state, { payload }) => ({ ...state, addTemplateConfigLoading: false, addTemplateConfigLoaded: true, createOrEdit: {
      faceds: [
        {
          level: 'FIRST_LAYER',
          position: 'A',
        }
      ],
      codeSourceType: 'PLATFORM_STANDARD',
      direction: 'TRANSVERSE',
      encryption: 'COMMON',
      bindType: 'BATCH',
      type: 'NORMAL',
      length: '38',
  
    }  }),
    [addTemplateConfig.FAIL]: (state, { payload }) => ({ ...state, addTemplateConfigLoading: false, addTemplateConfigLoaded: false, error: payload }),

    [editTemplateConfig.REQUEST]: (state, { payload }) => ({ ...state, editTemplateConfigLoading: true }),
    [editTemplateConfig.SUCCESS]: (state, { payload }) => ({ ...state, editTemplateConfigLoading: false, editTemplateConfigLoaded: true, createOrEdit: {
      faceds: [
        {
          level: 'FIRST_LAYER',
          position: 'A',
        }
      ],
      codeSourceType: 'PLATFORM_STANDARD',
      direction: 'TRANSVERSE',
      encryption: 'COMMON',
      bindType: 'BATCH',
      type: 'NORMAL',
      length: '38',
  
    }  }),
    [editTemplateConfig.FAIL]: (state, { payload }) => ({ ...state, editTemplateConfigLoading: false, editTemplateConfigLoaded: false, error: payload }),

    [loadFields.REQUEST]: (state, { payload }) => ({ ...state, loadFieldsLoading: true }),
    [loadFields.SUCCESS]: (state, { payload }) => {
      let fields = [];
      if (payload && payload.content) {
        fields = payload.content.map(v => {
          return {
            
            text: v.fieldName,
            id: v.fieldCode
          }
        })
      }
      return { ...state, loadFieldsLoading: false, loadFieldsLoaded: true, fields }
    },
    [loadFields.FAIL]: (state, { payload }) => ({ ...state, loadFieldsLoading: false, loadFieldsLoaded: false, error: payload }),


    [loadTagMaterials.REQUEST]: (state, { payload }) => ({ ...state, loadTagMaterialsLoading: true }),
    [loadTagMaterials.SUCCESS]: (state, { payload }) => {
      let tagMaterials = [];
      if (payload && payload.content) {
        tagMaterials = payload.content.map(v => {
          return {
            text: v.name,
            value: v.id,
            version: v.version
          }
        })
      }
      return { ...state, loadTagMaterialsLoading: false, loadTagMaterialsLoaded: true, tagMaterials }
    },
    [loadTagMaterials.FAIL]: (state, { payload }) => ({ ...state, loadTagMaterialsLoading: false, loadTagMaterialsLoaded: false, error: payload }),
    
    [addTagMaterial.REQUEST]: (state, { payload }) => ({ ...state, addTagMaterialLoading: true }),
    [addTagMaterial.SUCCESS]: (state, { payload }) => ({ ...state, addTagMaterialLoading: false, addTagMaterialLoaded: true, }),
    [addTagMaterial.FAIL]: (state, { payload }) => ({ ...state, addTagMaterialLoading: false, addTagMaterialLoaded: false, error: payload }),

    [editTagMaterial.REQUEST]: (state, { payload }) => ({ ...state, editTagMaterialLoading: true }),
    [editTagMaterial.SUCCESS]: (state, { payload }) => ({ ...state, editTagMaterialLoading: false, editTagMaterialLoaded: true, }),
    [editTagMaterial.FAIL]: (state, { payload }) => ({ ...state, editTagMaterialLoading: false, editTagMaterialLoaded: false, error: payload }),

    [loadTemplateConfigById.REQUEST]: (state, { payload }) => ({ ...state, loadTemplateConfigByIdLoading: true }),
    [loadTemplateConfigById.SUCCESS]: (state, { payload }) => ({ ...state, loadTemplateConfigByIdLoading: false, loadTemplateConfigByIdLoaded: true, createOrEdit: payload }),
    [loadTemplateConfigById.FAIL]: (state, { payload }) => ({ ...state, loadTemplateConfigByIdLoading: false, loadTemplateConfigByIdLoaded: false, error: payload }),

    [loadDistributionList.REQUEST]: (state, { payload }) => ({ ...state, loadDistributionListLoading: true }),
    [loadDistributionList.SUCCESS]: (state, { payload }) => ({ ...state, loadDistributionListLoading: false, loadDistributionListLoaded: true, distributionList: payload }),
    [loadDistributionList.FAIL]: (state, { payload }) => ({ ...state, loadDistributionListLoading: false, loadDistributionListLoaded: false, error: payload }),

    [templateDistribution.REQUEST]: (state, { payload }) => ({ ...state, templateDistributionLoading: true }),
    [templateDistribution.SUCCESS]: (state, { payload }) => ({ ...state, templateDistributionLoading: false, templateDistributionLoaded: true }),
    [templateDistribution.FAIL]: (state, { payload }) => ({ ...state, templateDistributionLoading: false, templateDistributionLoaded: false, error: payload }),

    
  },
  initialState
)



export default reducer;