import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './amountBill';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadListEpic = actions$ => actions$.pipe(
    ofType(actions.loadList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        let { dataVersion = 'NORMAL', page = 0, size = 20, startDate, endDate, amountEnd = '', poolName="",amountStart = '', appId = '', selectDeptId=[],activityId = [], productId = [], merchantBillNo = '',merchantId='', sendListId = '', wechatRedpacketType ='', scenceName = '', billType="",dealerName='',promotionStatus='' } = action.payload;
        // appId = '小茗同学';
        const url = urls.statistics.amountBill.list;
        
        activityId = activityId?activityId:[]
        productId = productId||[]
        billType = billType.value?billType.value:"支出,收入"
        merchantId = merchantId.value?merchantId.value:[]
        poolName = poolName?poolName:[]
        wechatRedpacketType = wechatRedpacketType.value?wechatRedpacketType.value:[]
        scenceName = scenceName.value?scenceName.value:[]
        appId = appId.value?appId.value:[]
        selectDeptId = selectDeptId.value?selectDeptId.value:[]
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?dataVersion=${dataVersion}&pageSize=${size}&page=${page+1}&startDate=${startDate}&endDate=${endDate}&poolIds=${poolName}&amountEnd=${amountEnd}&amountStart=${amountStart}&appId=${appId}&selectDeptId=${selectDeptId}&promotionId=${activityId}&productIds=${productId}&merchantBillNo=${merchantBillNo}&merchantIds=${merchantId}&sendListId=${sendListId}&wechatRedpacketType=${wechatRedpacketType}&scenceName=${scenceName}&billType=${billType}&dealerName=${dealerName}&promotionStatus=${promotionStatusVal}`
        }).pipe(
            map(res => actions.loadList.SUCCESS(res.response)),
            catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadOverviewEpic = actions$ => actions$.pipe(
    ofType(actions.loadOverview.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { promotionCategory, promotionType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.promotion.promotions.valid
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?promotionCategory=${promotionCategory}&promotionType=${promotionType}`
        }).pipe(
            map(res => actions.loadOverview.SUCCESS(res.response)),
            catchError(error => of(actions.loadOverview.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const downLoadAsynEpic = actions$ => actions$.pipe(
    ofType(actions.downLoadAsyn.REQUEST.toString()),
    mergeMap(action => { 
        actionApi();
        const { callback } = action.payload;
        let { dataVersion = 'NORMAL', page = 0, size, amountEnd = '', amountStart = '', appId = '', selectDeptId = {}, activityId = "", productId = "", merchantBillNo = '', sendListId = '', wechatRedpacketType = '', scenceName = '', merchantId = "", poolName = "", billType = "",token="",startDate='',endDate='',csrf='',promotionStatus='' } = action.payload.data;
        
        const url = urls.statistics.amountBill.download;

        activityId = activityId ? activityId : ''
        productId = productId || []
        billType = billType.value ? billType.value : '支出,收入'
        merchantId = merchantId.value ? merchantId.value : ''
        poolName = poolName ? poolName : ''
        wechatRedpacketType = wechatRedpacketType.value ? wechatRedpacketType.value : ''
        scenceName = scenceName.value ? scenceName.value : ''
        appId = appId.value ? appId.value : ''
        selectDeptId = selectDeptId.value ? selectDeptId.value : ''
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?&dataVersion=${dataVersion}&pageSize=${size}&page=${page + 1}&startDate=${startDate}&endDate=${endDate}&amountEnd=${amountEnd}&amountStart=${amountStart}&appId=${appId}&selectDeptId=${selectDeptId}&promotionId=${activityId}&productId=${productId}&merchantBillNo=${merchantBillNo}&sendListId=${sendListId}&wechatRedpacketType=${wechatRedpacketType}&scenceName=${scenceName}&merchantIds=${merchantId}&poolIds=${poolName}&billType=${billType}&promotionStatus=${promotionStatusVal}`
        }).pipe(
            map(res => {
                callback && callback()
                return actions.downLoadAsyn.SUCCESS(res.response || []) 
            }),
            catchError(error => of(actions.downLoadAsyn.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);