import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './uploadLibrary';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 图片库
 */

export const loadLibraryListEpic = actions$ => actions$.pipe(
  ofType(actions.loadLibraryList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.upload.uploadLibrary}?jumpType=${data.jumpType}&keyword=${data.keyword}&size=${data.size}&page=${data.page}&sort=${data.sort}`,
      }).pipe(
        map(res => {
          callback && callback();
          return actions.loadLibraryList.SUCCESS(res.response)
        }),
        catchError(error => of(actions.loadLibraryList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const uploadLibraryEpic = actions$ => actions$.pipe(
  ofType(actions.uploadLibrary.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.data.upload.uploadLibrary}`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback();
          return actions.uploadLibrary.SUCCESS()
        }),
        catchError(error => of(actions.uploadLibrary.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const delLibraryEpic = actions$ => actions$.pipe(
  ofType(actions.delLibrary.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.data.upload.uploadLibrary}/${data}`,
      }).pipe(
        map(res => {
          callback && callback();
          return actions.delLibrary.SUCCESS()
        }),
        // catchError(error => of(actions.delLibrary.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);





// 优惠券种类下拉列表
export const couponCodePacketExportEpic = actions$ => actions$.pipe(
  ofType(actions.couponCodePacketExport.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { couponId = '', num = 0, remark = '', callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.coupon.couponCodePacketExport(couponId, num, remark)}`,
      }).pipe(
        map(res => {
          callback && callback();
          return actions.couponCodePacketExport.SUCCESS()
        }),
         catchError(error => of(actions.couponCodePacketExport.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
