import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeStatistics';

export const loadcodeStatistics = fasterActions(moduleName, 'loadcodeStatistics');
export const loadcodeStatisticsSelect = fasterActions(moduleName, 'loadcodeStatisticsSelect');
export const loadcodeStatisticsExcel = fasterActions(moduleName, 'loadcodeStatisticsExcel');


const initialState = {
  codeStatisticsList: {},
  codeStatisticsListSelect: {},
  loadcodeStatisticsLoading: false,
  loadcodeStatisticsLoaded: false,
  createcodeStatisticsing:false,
}



const reducer = handleActions(
  {
    [loadcodeStatistics.REQUEST]: (state, { payload }) => ({ ...state, loadcodeStatisticsLoading: true }),
    [loadcodeStatistics.SUCCESS]: (state, { payload }) => ({ ...state, loadcodeStatisticsLoading: false, loadcodeStatisticsLoaded: true, codeStatisticsList: payload }),
    [loadcodeStatistics.FAIL]: (state, { payload }) => ({ ...state, loadcodeStatisticsLoading: false, loadcodeStatisticsLoaded: false, error: payload }),


    [loadcodeStatisticsSelect.REQUEST]: (state, { payload }) => ({ ...state, loadcodeStatisticsLoading: true }),
    [loadcodeStatisticsSelect.SUCCESS]: (state, { payload }) => ({ ...state, loadcodeStatisticsLoading: false, loadcodeStatisticsLoaded: true, codeStatisticsListSelect: payload }),
    [loadcodeStatisticsSelect.FAIL]: (state, { payload }) => ({ ...state, loadcodeStatisticsLoading: false, loadcodeStatisticsLoaded: false, error: payload }),

    [loadcodeStatisticsExcel.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadcodeStatisticsExcel.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [loadcodeStatisticsExcel.FAIL]: (state, { payload }) => ({ ...state, error: payload }),





  },
  initialState
)

export default reducer;