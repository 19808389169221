import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './dealerChange';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';


export const dealerChangeListEpic = actions$ => actions$.pipe(
    ofType(actions.dealerChangeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = '', size = '', code = '', old_dealer = [], new_dealer = [], date = {} } = action.payload;
        let sourceDealerId = JSON.stringify(old_dealer) === '[]' ? '' : old_dealer[0].id;
        let targetDealerId = JSON.stringify(new_dealer) === '[]' ? '' : new_dealer[0].id;
        let startDate = JSON.stringify(date) === '{}' ? '' : Date.parse(date.fromFormat);
        let endDate = JSON.stringify(date) === '{}' ? '' : Date.parse(date.toFormat);
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.dealerChange.dict}?page=${page}&size=${size}&code=${code}&sourceDealerId=${sourceDealerId}&targetDealerId=${targetDealerId}&startDate=${startDate}&endDate=${endDate}`
            // url: `${urls.data.dealerChange.dict}?page=${page}&size=${size}&code=${code}`
            // url: `${urls.data.dealerChange.dict}?page=${page}&size=${size}`
        }).pipe(
            map(res => actions.dealerChangeList.SUCCESS(res.response)),
            catchError(error => of(actions.dealerChangeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);