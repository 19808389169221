import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeSupplierScrap';

export const loadcodeScrap = fasterActions(moduleName, 'loadcodeScrap');
export const loadcodeScrapSelect = fasterActions(moduleName, 'loadcodeScrapSelect');
export const createcodeScrap = fasterActions(moduleName, 'createcodeScrap');
// export const codeScrapDownload = fasterActions(moduleName, 'codeScrapDownload');
export const factoriesList = fasterActions(moduleName, 'factoriesList');



const initialState = {
  codeScrapList: {},
  factoriesList:{},
  codeScrapListSelect: {},
  loadcodeScrapLoading: false,
  loadcodeScrapLoaded: false,
  createcodeScraping:false,
}



const reducer = handleActions(
  {
    [loadcodeScrap.REQUEST]: (state, { payload }) => ({ ...state, loadcodeScrapLoading: true }),
    [loadcodeScrap.SUCCESS]: (state, { payload }) => ({ ...state, loadcodeScrapLoading: false, loadcodeScrapLoaded: true, codeScrapList: payload }),
    [loadcodeScrap.FAIL]: (state, { payload }) => ({ ...state, loadcodeScrapLoading: false, loadcodeScrapLoaded: false, error: payload }),


    [loadcodeScrapSelect.REQUEST]: (state, { payload }) => ({ ...state, loadcodeScrapLoading: true }),
    [loadcodeScrapSelect.SUCCESS]: (state, { payload }) => ({ ...state, loadcodeScrapLoading: false, loadcodeScrapLoaded: true, codeScrapListSelect: payload }),
    [loadcodeScrapSelect.FAIL]: (state, { payload }) => ({ ...state, loadcodeScrapLoading: false, loadcodeScrapLoaded: false, error: payload }),


    [createcodeScrap.REQUEST]: (state, { payload }) => ({ ...state,createcodeScraping:true, }),
    [createcodeScrap.SUCCESS]: (state, { payload }) => ({ ...state, createcodeScraping:false,}),
    [createcodeScrap.FAIL]: (state, { payload }) => ({ ...state, createcodeScraping:false, error: payload }),

    [factoriesList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [factoriesList.SUCCESS]: (state, { payload }) => ({ ...state,  factoriesList: payload }),
    [factoriesList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // [codeScrapDownload.REQUEST]: (state, { payload }) => ({ ...state, }),
    // [codeScrapDownload.SUCCESS]: (state, { payload }) => ({ ...state}),
    // [codeScrapDownload.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    




  },
  initialState
)

export default reducer;