import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { FormGroup } from '@tencent/bere-form'

/**
 * 带 FormGroup 特性的 PureField
 * @param props
 * @returns {*}
 * @constructor
 */
function FormGroupField(props) {
  const {
    component,
    name,
    visible,
    ...rest
  } = props
  if (visible) {
    return (
      <Field
      // noUpdateOnBlur={noUpdateOnBlur}
        name={name}
        control={component}
        component={FormGroup}
        {...rest}
      />
    )
  }
  return null
}

FormGroupField.propTypes = {
  /** 表单域名 */
  name: PropTypes.string.isRequired,
  /** 表单域是否可见 */
  visible: PropTypes.bool,
  /** 表单组件 */
  component: PropTypes.func.isRequired
}

FormGroupField.defaultProps = {
  visible: true
}

export default FormGroupField
