import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import { _set } from '../../../utils/commonUtils';
import urls from '@/api/urls';

const moduleName = 'customWineTemplate';

/**
 * TODO: 酒自定义模版
 */

export const loadCustomWinesList = fasterActions(moduleName, 'loadCustomWinesList');
export const loadCustomWinesSelections = fasterActions(moduleName, 'loadCustomWinesSelections');
export const createCustomWine = fasterActions(moduleName, 'createCustomWine');
export const editCustomWine = fasterActions(moduleName, 'editCustomWine');
export const ableCustomWine = fasterActions(moduleName, 'ableCustomWine');
export const delCustomWine = fasterActions(moduleName, 'delCustomWine');
export const publishCustomWine = fasterActions(moduleName, 'publishCustomWine');
export const loadCustomWineHistoriesById = fasterActions(moduleName, 'loadCustomWineHistoriesById');


export const customWineById = fasterActions(moduleName, 'customWineById');
export const customWineHistoryById = fasterActions(moduleName, 'customWineHistoryById');
export const customWineModify = fasterActions(moduleName, 'customWineModify');
export const customWineSetLatest = fasterActions(moduleName, 'customWineSetLatest');

export const fontFamily = fasterActions(moduleName, 'fontFamily');
export const fontFamilyModify = fasterActions(moduleName, 'fontFamilyModify');





export const templateJsonModify = fasterActions(moduleName, 'templateJsonModify');

export const getWineData = fasterActions(`${moduleName}/getWineData`);
export const updateWineData = createAction(`${moduleName}/updateWineData`);
export const getTestWineData = createAction(`${moduleName}/getTestWineData`);
export const updateFontFamily = createAction(`${moduleName}/updateFontFamily`);


//留资信息
export const getRetention = fasterActions(moduleName, 'getRetention');
export const patchRetention = fasterActions(moduleName, 'patchRetention');
export const exportRetention = fasterActions(moduleName, 'exportRetention');





const initialState = {
  // wineData: {
  //   case: {},
  //   box: {},
  //   bottle: {},
  // }
  customWinesList: [],
  loadCustomWinesListLoading: false,
  loadCustomWinesListLoaded: false,

  customWinesSelectionsList: [],
}



const reducer = handleActions(
  {
    //列表
    [loadCustomWinesList.REQUEST]: (state, { payload }) => ({ ...state, loadCustomWinesListLoading: true }),
    [loadCustomWinesList.SUCCESS]: (state, { payload }) => ({ ...state, loadCustomWinesListLoading: false, loadCustomWinesListLoaded: true, customWinesList: payload }),
    [loadCustomWinesList.FAIL]: (state, { payload }) => ({ ...state, loadCustomWinesListLoading: false, loadCustomWinesListLoaded: false, error: payload }),

    // select
    [loadCustomWinesSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadCustomWinesSelections.SUCCESS]: (state, { payload }) => {
      const selector = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ value: elem.id, text: elem.name, id: elem.id });
        }
      }
      return ({ ...state, customWinesSelectionsList: selector });
    },
    [loadCustomWinesSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createCustomWine.REQUEST]: (state, { payload }) => ({ ...state }),
    [createCustomWine.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createCustomWine.FAIL]: (state, { payload }) => ({ ...state }),
    [editCustomWine.REQUEST]: (state, { payload }) => ({ ...state }),
    [editCustomWine.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editCustomWine.FAIL]: (state, { payload }) => ({ ...state }),
    [ableCustomWine.REQUEST]: (state, { payload }) => ({ ...state }),
    [ableCustomWine.SUCCESS]: (state, { payload }) => ({ ...state }),
    [ableCustomWine.FAIL]: (state, { payload }) => ({ ...state }),
    [delCustomWine.REQUEST]: (state, { payload }) => ({ ...state }),
    [delCustomWine.SUCCESS]: (state, { payload }) => ({ ...state }),
    [delCustomWine.FAIL]: (state, { payload }) => ({ ...state }),
    [publishCustomWine.REQUEST]: (state, { payload }) => ({ ...state }),
    [publishCustomWine.SUCCESS]: (state, { payload }) => ({ ...state }),
    [publishCustomWine.FAIL]: (state, { payload }) => ({ ...state }),
    [loadCustomWineHistoriesById.REQUEST]: (state, { payload }) => ({ ...state, loadCustomWineHistoriesByIdLoading: true }),
    [loadCustomWineHistoriesById.SUCCESS]: (state, { payload }) => ({ ...state, loadCustomWineHistoriesByIdLoading: false, loadCustomWineHistoriesByIdLoaded: true, customWineHistories: payload }),
    [loadCustomWineHistoriesById.FAIL]: (state, { payload }) => ({ ...state, loadCustomWineHistoriesByIdLoading: false, loadCustomWineHistoriesByIdLoaded: false, error: payload }),




    [customWineById.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, customWineModifyLoaded: false, wineData: {} }),
    [customWineById.SUCCESS]: (state, { payload }) => ({ ...state, customWineDetail: payload, loading: false }),
    [customWineById.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [customWineHistoryById.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true, wineData: {} }),
    [customWineHistoryById.SUCCESS]: (state, { payload }) => {
      let customWineDetail = payload;
      if (customWineDetail.customTemplateString) {
        try {
          Object.assign(customWineDetail, JSON.parse(customWineDetail.customTemplateString))
        } catch (error) {

        }
      }
      return ({ ...state, customWineDetail, loading: false })
    },
    [customWineHistoryById.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [customWineModify.REQUEST]: (state, { payload }) => ({ ...state, payload, customWineModifyLoaded: false, loading: true }),
    [customWineModify.SUCCESS]: (state, { payload }) => ({ ...state, customWineModifyLoaded: payload, loading: false }),
    [customWineModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [customWineSetLatest.REQUEST]: (state, { payload }) => ({ ...state, payload, customWineSetLatestLoaded: false, loading: false }),
    [customWineSetLatest.SUCCESS]: (state, { payload }) => ({ ...state, customWineSetLatestLoaded: payload, loading: true }),
    [customWineSetLatest.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [fontFamily.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [fontFamily.SUCCESS]: (state, { payload }) => ({ ...state, fontFamilySelection: payload, loading: false }),
    [fontFamily.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [fontFamilyModify.REQUEST]: (state, { payload }) => ({ ...state, payload, fontFamilyModifyLoaded: false, }),
    [fontFamilyModify.SUCCESS]: (state, { payload }) => ({ ...state, fontFamilyModifyLoaded: payload, fontFamilySelection: payload, }),
    [fontFamilyModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [templateJsonModify.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [templateJsonModify.SUCCESS]: (state, { payload }) => ({ ...state, templateJsonModifyLoaded: payload, loading: false }),
    [templateJsonModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),






    [getWineData.REQUEST]: (state, { payload }) => ({ ...state, payload, wineDataLoaded: false }),
    [getWineData.SUCCESS]: (state, { payload }) => {
      return { ...state, wineData: payload || {}, sourceWineData: JSON.parse(JSON.stringify(payload)), wineDataLoaded: true }
    },
    [getWineData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [updateFontFamily]: (state, { payload }) => {
      return { ...state, fontFamilySelection: payload }
    },

    [getTestWineData]: (state, { payload }) => {
      return { ...state, wineData: payload, sourceWineData: JSON.parse(JSON.stringify(payload)) }
    },

    [updateWineData]: (state, { payload: { value = '', key, type, updateJson = true } }) => {
      let { wineData } = state;
      switch (type) {
        case 'remove': case 'update':
          wineData = value;
          break;
        default:
          _set(wineData, key, value)
          break;
      }

      return { ...state, wineData: Object.assign({}, { ...wineData }), updateJson }
    },



    //留资信息
    [getRetention.REQUEST]: (state, { payload }) => ({ ...state, getRetentionLoading: true }),
    [getRetention.SUCCESS]: (state, { payload }) => ({ ...state, getRetentionLoading: false, getRetentionLoaded: true, retentionList: payload }),
    [getRetention.FAIL]: (state, { payload }) => ({ ...state, getRetentionLoading: false, getRetentionLoaded: false, error: payload }),
    [patchRetention.REQUEST]: (state, { payload }) => ({ ...state }),
    [patchRetention.SUCCESS]: (state, { payload }) => ({ ...state }),
    [patchRetention.FAIL]: (state, { payload }) => ({ ...state }),
    [exportRetention.REQUEST]: (state, { payload }) => ({ ...state }),
    [exportRetention.SUCCESS]: (state, { payload }) => ({ ...state }),
    [exportRetention.FAIL]: (state, { payload }) => ({ ...state }),
    
  },

  initialState
);

export default reducer;
