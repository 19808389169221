import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'terminalManagement';

/**
 * TODO: 
 */

// 主数据字典管理
export const changeTerminalPaging = createAction(`${moduleName}/changeTerminalPaging`);
export const terminalList = fasterActions(moduleName, 'terminalList');
export const enableOrDisabledTerminal = fasterActions(moduleName, 'enableOrDisabledTerminal');
export const terminalInfo = fasterActions(moduleName, 'terminalInfo');
export const createTerminal = fasterActions(moduleName, 'createTerminal');
export const terminalSelection = fasterActions(moduleName, 'terminalSelection');

const initialState = {
    terminalPaging: {
        page: 0,
        size: 20,
    },
    terminalData: {},
}

const reducer = handleActions(
    {
        [changeTerminalPaging]: (state, { payload }) => {
            return { ...state, terminalPaging: { ...payload, page: 0 } }
        },

        [terminalList.REQUEST]: (state, { payload }) => ({ ...state, terminalPaging: payload, loading: true }),
        [terminalList.SUCCESS]: (state, { payload }) => ({ ...state, terminalData: payload, loading: false }),
        [terminalList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [enableOrDisabledTerminal.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [enableOrDisabledTerminal.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledTerminal.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [terminalInfo.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [terminalInfo.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [terminalInfo.FAIL]: (state, { payload }) => ({ ...state, payload }),

        [createTerminal.REQUEST]: (state, { payload }) => ({ ...state, payload, createStatus: false }),
        [createTerminal.SUCCESS]: (state, { payload }) => ({ ...state, createStatus: payload }),
        [createTerminal.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [terminalSelection.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
        [terminalSelection.SUCCESS]: (state, { payload }) => {
            let terminalSelection = [];
            for (let elem of payload.content) {
                const obj = { ...elem, value: elem.id, text: elem.name }
                if (elem.code) {
                    obj.text += '_' + elem.code;
                }
                terminalSelection.push(obj);
            }
            return ({ ...state, terminalSelection })
        },
        [terminalSelection.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },
    initialState
);

export default reducer;
