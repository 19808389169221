import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'dealerGroup';

export const loadPageList = fasterActions(moduleName, 'loadPageList');
export const pageAdd = fasterActions(moduleName, 'pageAdd');
export const pageEdit = fasterActions(moduleName, 'pageEdit');
export const enableChange = fasterActions(moduleName, 'enableChange');
export const findByDealerId = fasterActions(moduleName, 'findByDealerId');
export const dealerQuery = fasterActions(moduleName, 'dealerQuery');
export const download = fasterActions(moduleName, 'download');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const editDealerProduct = fasterActions(`${moduleName}/editDealerProduct`);
export const selectProduct = fasterActions(`${moduleName}/selectProduct`);
// uploadFactorySearchKey
const initialState = {
  pageData:[],
  pageParams:{},
  loading:false,
  dealerData:{},
  dealerSelection:{},
  downloadData:'',
  selectProductList:[]
}

const reducer = handleActions(
  {
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [loadPageList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadPageList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [loadPageList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [download.REQUEST]: (state, { payload }) => ({ ...state }),
    [download.SUCCESS]: (state, { payload }) => ({ ...state, downloadData: payload }),
    [download.FAIL]: (state, { payload }) => ({ ...state}),

    [pageAdd.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [pageAdd.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [pageAdd.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [pageEdit.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [pageEdit.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [pageEdit.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [enableChange.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [enableChange.SUCCESS]: (state, { payload }) => ({ ...state}),
    [enableChange.FAIL]: (state, { payload }) => ({ ...state}),
    
    [findByDealerId.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [findByDealerId.SUCCESS]: (state, { payload }) => ({ ...state,dealerData:payload}),
    [findByDealerId.FAIL]: (state, { payload }) => ({ ...state}),
    
    [editDealerProduct.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [editDealerProduct.SUCCESS]: (state, { payload }) => ({ ...state, editDealerProduct: payload}),
    [editDealerProduct.FAIL]: (state, { payload }) => ({ ...state}),

    [selectProduct.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [selectProduct.SUCCESS]: (state, { payload }) => ({ ...state, selectProductList: payload}),
    [selectProduct.FAIL]: (state, { payload }) => ({ ...state}),

    // [dealerQuery.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    // [dealerQuery.SUCCESS]: (state, { payload }) => ({ ...state,dealerSelection:payload}),
    // [dealerQuery.FAIL]: (state, { payload }) => ({ ...state}),

    [dealerQuery.REQUEST]: (state, { payload }) => ({ ...state, payload}),
    [dealerQuery.SUCCESS]: (state, { payload = []}) => { 
      
      payload = payload.content.map((item)=>{
        return {text:item.name,value:item.id,disabled:false}
      })
      return {...state,dealerSelection:payload}
    },
    [dealerQuery.FAIL]: (state, { payload }) => ({ ...state}),
  },
  initialState
)

export default reducer;