import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import * as actions from './codePrintingConfig';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { trims } from '@/utils/commonUtils.js';


export const loadCodePrintingConfigListEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodePrintingConfigList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tagbatchConfigs.tagbatchConfigs}?idOrName=${idOrName}&page=${page}&size=${size}&=${sort}`
    }).pipe(
      map(res => actions.loadCodePrintingConfigList.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodePrintingConfigList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadCodeParamsDetailEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodeParamsDetail.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.tagbatchConfigs.codeParamsDetail(action.payload)
    }).pipe(
      mergeMap(res => [actions.loadCodeParamsDetail.SUCCESS(res.response), uiActions.updateCodeParamsDetailDialogStatus(true)]),
      catchError(error => of(actions.loadCodeParamsDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadTagMaterialsListEpic = actions$ => actions$.pipe(
  ofType(actions.loadTagMaterialsList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.tagMaterials.tagMaterials
    }).pipe(
      map(res => actions.loadTagMaterialsList.SUCCESS(res.response)),
      catchError(error => of(actions.loadTagMaterialsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const loadTemplateConfigsListEpic = actions$ => actions$.pipe(
  ofType(actions.loadTemplateConfigsList.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.templateConfigs.selectTemplate
    }).pipe(
      map(res => actions.loadTemplateConfigsList.SUCCESS(res.response)),
      catchError(error => of(actions.loadTemplateConfigsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const addConfigEpic = actions$ => actions$.pipe(
  ofType(actions.addConfig.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.tagbatchConfigs.tagbatchConfigs,
      method: 'POST',
      body: trims(action.payload.params)
    }).pipe(
      map(res => {
        action.payload.history.replace('/codetagConfig');
        return actions.addConfig.SUCCESS(res.response);
      }),
      catchError(error => of(actions.addConfig.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

export const editConfigEpic = actions$ => actions$.pipe(
  ofType(actions.editConfig.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.tagbatchConfigs.tagbatchConfigs,
      method: 'PUT',
      body: trims(action.payload.params)
    }).pipe(
      map(res => {
        action.payload.history.replace('/codetagConfig');
        return actions.editConfig.SUCCESS(res.response);
      }),
      catchError(error => of(actions.editConfig.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);


export const loadCodePrintingConfigByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodePrintingConfigById.REQUEST.toString()),
  mergeMap(action => { actionApi()
    // const { idOrName = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.tagbatchConfigs.tagbatchConfigsDetailAll(action.payload)
    }).pipe(
      map(res => actions.loadCodePrintingConfigById.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodePrintingConfigById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);
export const loadSelectEpic = actions$ => actions$.pipe(
  ofType(actions.loadSelect.REQUEST.toString()),
  mergeMap(action => { actionApi()
    // const { idOrName = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      // url: urls.code.tagbatchConfigs.selector,
      url: `${urls.code.tagbatchConfigs.tagbatchConfigs}/allots?isPage=false`,
    }).pipe(
      map(res => actions.loadSelect.SUCCESS(res.response.content)),
      catchError(error => of(actions.loadSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )}
  )
);

// 核销码配置
export const cancelverifyCodeTemplateListEpic = actions$ => actions$.pipe(
  ofType(actions.cancelverifyCodeTemplateList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.templateConfigs.selectCancelverifyCodeTemplate}`
    }).pipe(
      map(res => actions.cancelverifyCodeTemplateList.SUCCESS(res.response)),
      catchError(error => of(actions.cancelverifyCodeTemplateList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 创建编辑核销码规格
export const submitCancelverifyCodeTemplateListEpic = actions$ => actions$.pipe(
  ofType(actions.submitCancelverifyCodeTemplateList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    /**
     * data: { templateConfigId, name, eseDomainId}
     */
    const { data, listPharams } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.tagbatchConfigs.submitCancelverifyCode}`,
      method: 'POST',
      body: data
    }).pipe(
      // map(res => actions.submitCancelverifyCodeTemplateList.SUCCESS(res.response)),
      concatMap(res => [actions.submitCancelverifyCodeTemplateList.SUCCESS(res.response), actions.loadCodePrintingConfigList.REQUEST(listPharams)]),
      catchError(error => of(actions.submitCancelverifyCodeTemplateList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);