import { handleActions } from "redux-actions"
import { fasterActions } from "../../utils"

const moduleName = "newsManage"

export const getNewsManageList = fasterActions(moduleName, 'getNewsManageList');
export const getArrivalRate = fasterActions(moduleName, 'getArrivalRate');
export const termination = fasterActions(moduleName, 'termination');
export const cancel = fasterActions(moduleName, 'cancel');
export const msgModify = fasterActions(moduleName, 'msgModify');
export const getMsgInfoById = fasterActions(moduleName, 'getMsgInfoById');
export const errorNews = fasterActions(moduleName, 'errorNews');
export const newsDelete = fasterActions(moduleName, 'newsDelete');
export const cancelSend = fasterActions(moduleName, 'cancelSend');

const initialState = {
  perPageCountList: [20, 50, 100], // 每页大小候选, 
}
const reducer = handleActions(
  {
    // 获取消息管理列表
    [getNewsManageList.REQUEST]: (state, { payload }) => ({ ...state, loading: true}),
    [getNewsManageList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, list: payload}),
    [getNewsManageList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload}),
    // 获取触达率列表
    [getArrivalRate.REQUEST]: (state, { payload }) => ({ ...state, arrivalRateLoading: true}),
    [getArrivalRate.SUCCESS]: (state, { payload }) => ({ ...state, arrivalRateLoading: false,arrivalRateList: payload}),
    [getArrivalRate.FAIL]: (state, { payload }) => ({ ...state, arrivalRateLoading: false,arrivalRateError: payload}),
    // 终止
    [termination.REQUEST]: (state, { payload }) => ({ ...state, terminationLoading: true}),
    [termination.SUCCESS]: (state, { payload }) => ({ ...state, terminationLoading: false}),
    [termination.FAIL]: (state, { payload }) => ({ ...state, terminationLoading: false}),
    // 取消
    [cancel.REQUEST]: (state, { payload }) => ({ ...state, cancelLoading: true}),
    [cancel.SUCCESS]: (state, { payload }) => ({ ...state, cancelLoading: false}),
    [cancel.FAIL]: (state, { payload }) => ({ ...state, cancelLoading: false }),
    // 删除
    [newsDelete.REQUEST]: (state, { payload }) => ({ ...state, newsDeleteLoading: true}),
    [newsDelete.SUCCESS]: (state, { payload }) => ({ ...state, newsDeleteLoading: false}),
    [newsDelete.FAIL]: (state, { payload }) => ({ ...state, newsDeleteLoading: false}),
    // 新增/修改
    [msgModify.REQUEST]: (state, { payload }) => ({ ...state }),
    [msgModify.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [msgModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 查看详情
    [getMsgInfoById.REQUEST]:(state, { payload }) => ({ ...state }),
    [getMsgInfoById.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [getMsgInfoById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    // 查看失败消息详情
    [errorNews.REQUEST]: (state, { payload }) => ({ ...state,errorNewsLoading: true }),
    [errorNews.SUCCESS]: (state, { payload }) => ({ ...state,errorNewsLoading: false,errorNewsList: payload }),
    [errorNews.FAIL]: (state, { payload }) => ({ ...state,errorNewsLoading: false,errorNews: payload }),
    // 取消周期发送
    [cancelSend.REQUEST]: (state, { payload }) => ({ ...state,cancelSendLoading: true }),
    [cancelSend.SUCCESS]: (state, { payload }) => ({ ...state,cancelSendLoading: false}),
    [cancelSend.FAIL]: (state, { payload }) => ({ ...state,cancelSendLoading: false}),
  },
  initialState 
)

export default reducer