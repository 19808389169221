import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './brandRedpackConfig';
import { ajaxBaseConfig, actionApi } from '../../utils';


// 列表
export const brandRedpackConfigSelectEpic = (actions$) => actions$.pipe(
  ofType(actions.brandRedpackConfigSelect.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    // const { page = 0, size = 20, type = '', keyWords = '', sort = '' } = action.payload.paging;
    return (
      noecAjax({
        ...ajaxBaseConfig,
        url: urls.wechat.brandRedPack.brandRedPack
        // url: `${urls.promotion.prizes.prizes}?page=${page}&size=${size}&type=${type}&keyWords=${keyWords ? encodeURIComponent(keyWords) : ''}&sort=${sort}`
      }).pipe(
        map(res => actions.brandRedpackConfigSelect.SUCCESS(res.response)),
        catchError(error => of(actions.brandRedpackConfigSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 创建
export const brandRedpackConfigCreateEpic = (actions$) => actions$.pipe(
  ofType(actions.brandRedpackConfigCreate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data } = action.payload;
    return (
      noecAjax({
        ...ajaxBaseConfig,
        url: urls.wechat.brandRedPack.brandRedPack,
        method: 'POST',
        body: data
      }).pipe(
        // map(res => actions.brandRedpackConfigCreate.SUCCESS(res.response)),
        // 刷新，连续调用两个接口
        concatMap(res => [actions.brandRedpackConfigCreate.SUCCESS(res.response), actions.brandRedpackConfigSelect.REQUEST()]),
        catchError(error => of(actions.brandRedpackConfigCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 修改
export const brandRedpackConfigEditEpic = (actions$) => actions$.pipe(
  ofType(actions.brandRedpackConfigEdit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data } = action.payload;
    return (
      noecAjax({
        ...ajaxBaseConfig,
        url: urls.wechat.brandRedPack.brandRedPack,
        method: 'PUT',
        body: data
      }).pipe(
        // 刷新，连续调用两个接口
        concatMap(res => [actions.brandRedpackConfigEdit.SUCCESS(res.response), actions.brandRedpackConfigSelect.REQUEST()]),
        catchError(error => of(actions.brandRedpackConfigEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);