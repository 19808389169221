import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './billInfomation';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 单据管理
 */

// 单据类型列表
export const billTypeListEpic = actions$ => actions$.pipe(
    ofType(actions.billTypeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page, size, query = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.bill.billType}?page=${page}&size=${size}&query=${query}`
        }).pipe(
            map(res => actions.billTypeList.SUCCESS(res.response)),
            catchError(error => of(actions.billTypeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 禁用、启用
export const enableOrDisabledBillTypeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledBillType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.bill.billType}/${id}/status?status=${status}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.enableOrDisabledBillType.SUCCESS(res.response), actions.billTypeList.REQUEST(state$.value.billInfomation.billPaging)]),
                catchError(error => of(actions.enableOrDisabledBillType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 表单详情
export const billTypeInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.billTypeInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { typeId = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.bill.billType}/${typeId}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.billTypeInfo.SUCCESS(res.response);
                }),
                catchError(error => of(actions.billTypeInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 创建单据
export const createBillTypeEpic = actions$ => actions$.pipe(
    ofType(actions.createBillType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                method: data.id ? 'PUT' : 'POST',
                url: `${urls.data.bill.billType}`,
                body: action.payload.data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.createBillType.SUCCESS(res.response);
                }),
                catchError(error => of(actions.createBillType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 单据数据
export const billDataListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.billDataList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page, size, query = '',type='' } = action.payload;
        console.log(type)
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.bill.billData}?page=${page}&size=${size}&search=${query}`+ (type.length>0?`&terms=${type}`:''),
            }).pipe(
                map(res => actions.billDataList.SUCCESS(res.response)),
                catchError(error => of(actions.billDataList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 单据数据查看详情
export const billDataInfoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.billDataInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.bill.billData}/${id}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.billDataInfo.SUCCESS(res.response);
                }),
                catchError(error => of(actions.billDataInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 下拉框
export const loadbillDataSelectionsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadbillDataSelections.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { status} = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.bill.billSelection}?status=${status}`,
            }).pipe(
                map(res => actions.loadbillDataSelections.SUCCESS(res.response)),
                catchError(error => of(actions.loadbillDataSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 下拉框2
export const loadbillDataDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadbillDataData.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        // const { status} = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.bill.billData}/type-fields`,
            }).pipe(
                map(res => actions.loadbillDataData.SUCCESS(res.response)),
                catchError(error => of(actions.loadbillDataData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


// 单据条件查询
export const getbillRecordEpic = actions$ => actions$.pipe(
    ofType(actions.getbillRecord.REQUEST.toString()),
    mergeMap(action => {
      actionApi()
      const { code = '', type = '' } = action.payload
      return ajax({
        ...ajaxBaseConfig,
        url: `${urls.data.bill.billRecord}?code=${code}&type=${type}`,
      }).pipe(
        map(res => {
          return actions.getbillRecord.SUCCESS(res.response);
        }),
        catchError(error => {
          return of(actions.getbillRecord.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        }
        )
      )
    }
    )
  );