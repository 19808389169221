import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './prizePointTypes';
import { ajaxBaseConfig, actionApi } from '../../utils';



/**
 * TODO: 积分类型
 */
// 积分下拉列表
export const loadPrizePointTypesEpic = actions$ => actions$.pipe(
  ofType(actions.loadPrizePointTypes.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.prizePointTypes.prizePointTypes}`
    }).pipe(
      map(res => actions.loadPrizePointTypes.SUCCESS(res.response.content)),
      catchError(error => of(actions.loadPrizePointTypes.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

// 卡片详情
export const pointTypeDetailEpic = actions$ => actions$.pipe(
  ofType(actions.loadPointTypeDetail.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.prizePointTypes.prizePointTypes}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.loadPointTypeDetail.SUCCESS(res.response);
        }),
        catchError(error => of (actions.loadPointTypeDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 积分创建
export const pointTypeCreateEpic = actions$ => actions$.pipe(
  ofType(actions.pointTypeCreate.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.promotion.prizePointTypes.prizePointTypes }`,
        method: 'POST',
        body: JSON.stringify(data)
      }).pipe(
        concatMap(res => [actions.pointTypeCreate.SUCCESS(res.response), actions.loadPrizePointTypes.REQUEST(), actions.pointTypeDialogVisibale({pointTypeId:0, isPointTypeDialogVisibale:false})]),
        catchError(error => of (actions.pointTypeCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 积分编辑
export const pointTypeEditEpic = actions$ => actions$.pipe(
  ofType(actions.pointTypeEdit.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.promotion.prizePointTypes.prizePointTypes }`,
        method: 'PUT',
        body: JSON.stringify(data)
      }).pipe(
        concatMap(res => [actions.pointTypeEdit.SUCCESS(res.response), actions.loadPrizePointTypes.REQUEST(), actions.pointTypeDialogVisibale({pointTypeId:0, isPointTypeDialogVisibale:false})]),
        catchError(error => of (actions.pointTypeEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);