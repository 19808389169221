import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './memberTask';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';


//list
export const getTaskListEpic = actions$ => actions$.pipe(
  ofType(actions.getTaskList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { taskStatus, taskStatusPublish = '', name = '', taskType = '', page = '', size = '', sort = '', system = '', dataRange: { from = '', to = '' } = {}, callback } = action.payload;
    let url = `${urls.memberShip.memberTask.memberTask}/all?taskStatus=${taskStatus}`;
    if (taskStatus == 'PUBLISH') {
      url = `${urls.memberShip.memberTask.memberTask}/publish?taskStatus=${taskStatusPublish}`;
    }
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${url}&keyword=${name}&system=${system}&taskType=${taskType}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.getTaskList.SUCCESS(res.response || [])
        }),
        catchError(error => of(actions.getTaskList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const getTaskByIdEpic = actions$ => actions$.pipe(
  ofType(actions.getTaskById.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;

    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.memberShip.memberTask.memberTask}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.getTaskById.SUCCESS(res.response)
        }),
        // catchError(error => of(actions.getTaskById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



//create
export const createTaskEpic = actions$ => actions$.pipe(
  ofType(actions.createTask.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.memberShip.memberTask.memberTask}/create`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.createTask.SUCCESS(res.response)
        }),
        catchError(error => of(actions.createTask.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const editTaskEpic = actions$ => actions$.pipe(
  ofType(actions.editTask.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.memberShip.memberTask.memberTask}/update`,
        body: data,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.editTask.SUCCESS(res.response)
        }),
        catchError(error => of(actions.editTask.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 删除会员体系
export const deleteTaskmEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.deleteTask.REQUEST),
  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload || {};
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTask}/${id}/delete`,
      method: 'DELETE'
    }).pipe(
      concatMap(res => {
        callback && callback();
        return [actions.deleteTask.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.deleteTask.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

/* 
**  发布
 */
export const publishTaskEpic = (actions$, store) => actions$.pipe(
  ofType(actions.publishTask.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.memberShip.memberTask.memberTask}/${data.id}/publish`,
      // url: `${urls.promotion.promotions.publish(action.payload.id)}?&authType=${action.payload.authType}`,
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.publishTask.SUCCESS(res.response)
      }),
      catchError(error => of(actions.publishTask.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


/* 
**  发布定时
*/
export const publishTaskTimeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.publishTaskTime.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.memberShip.memberTask.memberTask}/${data.id}/toBeReleased`,
      body: data
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.publishTaskTime.SUCCESS(res.response)
      }),
      catchError(error => of(actions.publishTaskTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



/* 
**  取消发布
*/

export const deletePublishTimeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.deletePublishTime.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.memberShip.memberTask.memberTask}/${data.id}/unPublish`,
      body: {
        remark: '',
      }
    }).pipe(
      map(res => {
        callback && callback(res.response)
        return actions.deletePublishTime.SUCCESS(res.response)
      }),
      catchError(error => of(actions.deletePublishTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const disabledOrEnableTaskEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.disabledOrEnableTask.REQUEST),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload || {};
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTask}/${data.id}/${data.taskPublishStatus == 'ENABLE' ? 'recovery' : 'pause'}?id=${data.id}`,
      method: 'PATCH',
      data: data,
    }).pipe(
      concatMap(res => {
        callback && callback();
        return [actions.disabledOrEnableTask.SUCCESS(res.response)]
      }),
      catchError(error => of(actions.disabledOrEnableTask.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)


/* 
** 历史版本
*/
export const getTaskHistoryTableEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getTaskHistoryTable.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { taskId, page, size, keywords = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTaskHistory}/all?taskId=${taskId}&keyword=${keywords}&page=${page}&size=${size}`
    }).pipe(
      map(res => {
        return actions.getTaskHistoryTable.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getTaskHistoryTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)


export const getTaskHistoryInfoEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getTaskHistoryInfo.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { taskId, id, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTaskHistory}/${taskId}?version=${id}`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getTaskHistoryInfo.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getTaskHistoryInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)

export const loadTimeAllEpic = actions$ => actions$.pipe(
  ofType(actions.loadTimeAll.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTask}/toBeReleasedIds`
    }).pipe(
      map(res => {
        // action.payload.callback && action.payload.callback(res.response);
        return actions.loadTimeAll.SUCCESS(res.response)
      }
      ),
      catchError(error => of(actions.loadTimeAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const getTaskForSystemEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.getTaskForSystem.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { systemId, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTask}/selections?systemId=${systemId}`
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.getTaskForSystem.SUCCESS(res.response);
      }),
      catchError(error => of(actions.getTaskForSystem.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
)




export const draftCountEpic = actions$ => actions$.pipe(
  ofType(actions.draftCount.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTask}/draft-count?memberTaskStatus=DRAFT`
    }).pipe(
      map(res => actions.draftCount.SUCCESS(res.response)),
      catchError(error => of(actions.draftCount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const draftCountTimeEpic = actions$ => actions$.pipe(
  ofType(actions.draftCountTime.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTask}/toBeReleased-count?memberTaskStatus=TO_BE_RELEASED`
    }).pipe(
      map(res => actions.draftCountTime.SUCCESS(res.response)),
      catchError(error => of(actions.draftCountTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const draftCountPublishEpic = actions$ => actions$.pipe(
  ofType(actions.draftCountPublish.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.memberShip.memberTask.memberTask}/publish-count?memberTaskStatus=PUBLISH`
    }).pipe(
      map(res => actions.draftCountPublish.SUCCESS(res.response)),
      catchError(error => of(actions.draftCountPublish.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

 