import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'flowDetail';

/**
 * TODO: 
 */

// export const statisticsList = fasterActions(moduleName, 'statisticsList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
// export const fixedDownload = fasterActions(moduleName, 'fixedDownload');
// export const selectLoad = fasterActions(moduleName, 'selectLoad');
export const flowDetailSum = fasterActions(moduleName, 'flowDetailSum');
export const maStatusDate = fasterActions(moduleName, 'maStatusDate');
export const maSpecsList = fasterActions(moduleName, 'maSpecsList');
 

const initialState = {
  pageData:[],
  pageParams:{
    page:0,
    size:20,
    startTime:'',
    endTime:'',
    
  },
  flowData:{},
  downloadData:[],
  loading:false,
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [maStatusDate.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [maStatusDate.SUCCESS]: (state, { payload }) => {
        let maStatusDate= [];
        for (let elem of payload.content) {
            const obj = { ...elem, value: elem.id, text: elem.name }
            if (elem.code) {
                obj.text += '_' + elem.code;
            }
            maStatusDate.push(obj);
        }
        // console.log(maStatusDate)
        return ({ ...state, maStatusDate})
    },
    [maStatusDate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [maSpecsList.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
    [maSpecsList.SUCCESS]: (state, { payload }) => {
        let maSpecsList= [];
        for (let elem of payload.content) {
            const obj = { ...elem, value: elem.id, text: elem.name }
            if (elem.code) {
                obj.text += '_' + elem.code;
            }
            maSpecsList.push(obj);
        }
        // console.log(maSpecsList)
        return ({ ...state, maSpecsList})
    },
    [maSpecsList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [flowDetailSum.REQUEST]: (state, { payload }) => ({ ...state, flowData: {}, loading: true }),
    [flowDetailSum.SUCCESS]: (state, { payload }) => ({ ...state, flowData: payload, loading: false }),
    [flowDetailSum.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    // [fixedDownload.REQUEST]: (state, { payload }) => ({ ...state, downloadData: [], }),
    // [fixedDownload.SUCCESS]: (state, { payload }) => ({ ...state, downloadData: payload }),
    // [fixedDownload.FAIL]: (state, { payload }) => ({ ...state }),

    // [selectLoad.REQUEST]: (state, { payload }) => ({ ...state }),
    // [selectLoad.SUCCESS]: (state, { payload }) => ({ ...state}),
    // [selectLoad.FAIL]: (state, { payload }) => ({ ...state }),
  },
  initialState
);

export default reducer;
