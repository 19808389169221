import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions, setScrollTop } from '../../utils';

const moduleName = 'pools';

/**
 * 奖池列表、搜索、删除
*/
export const getPoolTable = fasterActions(moduleName, 'getPoolTable');
export const changeKewWords = createAction(`${moduleName}/changeKewWords`);
export const deletePool = fasterActions(moduleName, 'deletePool');
export const poolPageByEseId = fasterActions(moduleName, 'poolPageByEseId');
export const poolPrizeNotNeedStatistics = fasterActions(moduleName, 'poolPrizeNotNeedStatistics');

//多奖池
export const getPoolGroup = fasterActions(moduleName, 'getPoolGroup');
export const changeGroupKewWords = createAction(`${moduleName}/changeGroupKewWords`);
export const enableOrDisabled = fasterActions(moduleName, 'enableOrDisabled');
export const deletePoolGroup = fasterActions(moduleName, 'deletePoolGroup');
export const postPoolGroup = fasterActions(moduleName, 'postPoolGroup');
export const patchPoolGroup = fasterActions(moduleName, 'patchPoolGroup');
/**
 * 奖池奖品创建、修改
*/
export const getPrizeList = fasterActions(moduleName, 'getPrizeList');
// export const setPrizeDisabled = createAction(`${moduleName}/setPrizeDisabled`);
export const postPool = fasterActions(moduleName, 'postPool');
export const putPoolName = fasterActions(moduleName, 'putPoolName');
export const postPoolPrize = fasterActions(moduleName, 'postPoolPrize');


//预算
export const budget = fasterActions(moduleName, 'budget');
export const putBudget = fasterActions(moduleName, 'putBudget');


/**
 * 奖池奖品获取详情
*/
export const getPoolDataId = fasterActions(moduleName, 'getPoolDataId');
export const getPoolPrizeDataId = fasterActions(moduleName, 'getPoolPrizeDataId');
export const getPoolDataByEseId = fasterActions(moduleName, 'getPoolDataByEseId');

/* 
** 根据奖池id查询历史版本、根据历史id查询详情
*/
export const getPoolHistoryTable = fasterActions(moduleName, 'getPoolHistoryTable');
export const getPoolHistoryInfo = fasterActions(moduleName, 'getPoolHistoryInfo');

/* 
** 校验奖品是否可删除
*/
export const checkPrizeDelete = fasterActions(moduleName, 'checkPrizeDelete');
/* 
** 奖池树， 用于会员中心，自定义标签
*/
export const getPoolTree = fasterActions(moduleName, 'getPoolTree');
export const getPoolTreeMore = fasterActions(moduleName, 'getPoolTreeMore');


/* 商户号充值预算 */
export const rechargeBudget = fasterActions(moduleName, 'rechargeBudget');
const initialPoolState = {
    poolTableSearch: {
        page: 0,
        size: 20, // 每页大小
        word: '',
        promotionCategories: 'NORMAL',
        poolStatus: [],
        poolUseStatus: []
    },
    poolHistorySearch: {
        page: 0,
        size: 20
    },
    poolTableData: [],
    poolHistoryData: [],
    totalElements: '', /* 总数据条数 */
    perPageCountList: [20, 50, 100], // 每页大小候选

    prizeTree: [],
    poolUseStatus: '',




    poolGroupTableSearch: {
        page: 0,
        size: 20, // 每页大小
        word: '',
        sort: 'id,desc',
        poolStatus: [],
        poolUseStatus: []
    },
    poolGroupTableData: [],
    grouptotalElements: '',//多奖池总数据条数
}

// Reducer
const reducer = handleActions(
    {
        /* 奖池列表、搜索 */
        [getPoolTable.REQUEST]: (state, { payload }) => {
            setScrollTop();
            return { ...state, loading: true, poolTableSearch: payload };
        },
        [getPoolTable.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements } = payload || {};
            return { ...state, loading: false, poolTableData: content, totalElements }
        },
        [getPoolTable.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
        [changeKewWords]: (state, { payload }) => {
            console.log(payload)
            return { ...state, poolTableSearch: { ...state.poolTableSearch, ...payload, page: 0 } }
        },

        /* 删除 */
        [deletePool.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [deletePool.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [deletePool.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        //多奖池
        [getPoolGroup.REQUEST]: (state, { payload }) => { return { ...state, poolGrouploading: true, poolGroupTableSearch: payload }; },
        [getPoolGroup.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements } = payload || {};
            return { ...state, poolGrouploading: false, poolGroupTableData: content, grouptotalElements: totalElements }
        },
        [getPoolGroup.FAIL]: (state, { payload }) => ({ ...state, poolGrouploading: false, error: payload }),

        [changeGroupKewWords]: (state, { payload }) => {
            return { ...state, poolGroupTableSearch: { ...state.poolGroupTableSearch, word: payload, page: 0 } }
        },
        //多奖池启用停用
        [enableOrDisabled.REQUEST]: (state, { payload }) => { return { ...state } },
        [enableOrDisabled.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [enableOrDisabled.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

        //多奖池删除
        [deletePoolGroup.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [deletePoolGroup.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [deletePoolGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        //多奖池修改
        [postPoolGroup.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [postPoolGroup.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [postPoolGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        [patchPoolGroup.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [patchPoolGroup.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [patchPoolGroup.FAIL]: (state, { payload }) => ({ ...state, error: payload }),




        /* 获取奖品 */
        [getPrizeList.REQUEST]: (state, { payload }) => ({ ...state, pLoading: true }),
        [getPrizeList.SUCCESS]: (state, { payload }) => {
            // const newContent = payload.content.map((item, index, self) => {
            //     return self[index] = { ...item, text: item.name, expiredType: 'NOTHING' }
            // })
            // return { ...state, pLoading: false, prizeTree: newContent }
            payload.content.forEach(val => {
                val.text = val.name;
                val.value = val.id;
            });

            return { ...state, pLoading: false, prizeTree: payload.content || [] }
        },
        [getPrizeList.FAIL]: (state, { payload }) => ({ ...state, pLoading: false, error: payload }),
        /* 设置禁用奖品 */
        // [setPrizeDisabled]: (state, { payload }) => {
        //     const { prizeList } = payload;
        //     state.prizeTree.map(item => {
        //         item.disabled = false;
        //         prizeList.map(prizeItem => {
        //             if (prizeItem.prizeId) {
        //                 if (item.id == prizeItem.prizeId) {
        //                     item.disabled = true;
        //                 }
        //             } else {
        //                 if (item.id == prizeItem.id) {
        //                     item.disabled = true;
        //                 }
        //             }
        //         })
        //         return item;
        //     })
        //     return { ...state, }
        // },

        /*预算*/
        [budget.REQUEST]: (state, { payload }) => ({ ...state, disabled: true, payload }),
        [budget.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [budget.FAIL]: (state, { payload }) => ({ ...state, disabled: false, }),
        [putBudget.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [putBudget.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [putBudget.FAIL]: (state, { payload }) => ({ ...state, }),



        /* 奖池创建、修改 */
        [postPool.REQUEST]: (state, { payload }) => ({ ...state, disabled: true, payload }),
        [postPool.SUCCESS]: (state, { payload }) => ({ ...state, disabled: !payload.prizeList ? true : false }),
        [postPool.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),
        [putPoolName.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [putPoolName.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [putPoolName.FAIL]: (state, { payload }) => ({ ...state, }),
        [postPoolPrize.REQUEST]: (state, { payload }) => ({ ...state, disabled: true, payload }),
        [postPoolPrize.SUCCESS]: (state, { payload }) => ({ ...state, disabled: false }),
        [postPoolPrize.FAIL]: (state, { payload }) => ({ ...state, disabled: false, error: payload }),
        /* 获取奖池奖品详情 */
        [getPoolDataId.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [getPoolDataId.SUCCESS]: (state, { payload }) => ({ ...state }),
        [getPoolDataId.FAIL]: (state, { payload }) => ({ ...state, payload }),
        [getPoolPrizeDataId.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [getPoolPrizeDataId.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
        [getPoolPrizeDataId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        /* 根据奖池id查询历史版本 */
        [getPoolHistoryTable.REQUEST]: (state, { payload }) => ({ ...state, loading: true, poolHistorySearch: payload }),
        [getPoolHistoryTable.SUCCESS]: (state, { payload }) => {
            const { content = [], totalElements } = payload || {};
            return { ...state, loading: false, poolHistoryData: content, totalElements }
        },
        [getPoolHistoryTable.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
        /* 根据奖池历史id查询详情 */
        [getPoolHistoryInfo.REQUEST]: (state, { payload }) => ({ ...state, loading: true, payload }),
        [getPoolHistoryInfo.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, payload }),
        [getPoolHistoryInfo.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
        /* 校验奖品是否可删除 */
        [checkPrizeDelete.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [checkPrizeDelete.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
        [checkPrizeDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        /* 奖池下拉树， 用于会员中心，自定义标签 */
        [getPoolTree.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [getPoolTree.SUCCESS]: (state, { payload }) => {
            let selector = [];
            if (payload) {
                for (let elem of payload.values()) {
                    if (elem.poolPrizes) {
                        elem.poolPrizes.map(item => {
                            item.text = item.name;
                            item.value = item.id;
                        });
                        elem.children = elem.poolPrizes;
                    }
                    selector.push({ ...elem, value: elem.id, text: elem.name });
                }
            }
            return ({ ...state, poolTree: selector })
        },
        [getPoolTree.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        /* 奖池下拉树， 用于会员中心，自定义标签 */
        [getPoolTreeMore.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [getPoolTreeMore.SUCCESS]: (state, { payload }) => {
            return ({ ...state })
        },
        [getPoolTreeMore.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [poolPageByEseId.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [poolPageByEseId.SUCCESS]: (state, { payload }) => ({ ...state, poolPageByEseId: payload, loading: false }),
        [poolPageByEseId.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
        [poolPrizeNotNeedStatistics.REQUEST]: (state, { payload }) => ({ ...state, payload, poolPrizeNotNeedStatisticsLoaded: false }),
        [poolPrizeNotNeedStatistics.SUCCESS]: (state, { payload }) => ({ ...state, poolPrizeNotNeedStatisticsLoaded: payload }),
        [poolPrizeNotNeedStatistics.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [getPoolDataByEseId.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [getPoolDataByEseId.SUCCESS]: (state, { payload }) => {
            const prizeListUnderPool = {};
            if (payload.content) {
                payload.content.map(val => {
                    val.text = val.name;
                    val.value = val.id;
                    if (val.poolPrizes) {
                        val.poolPrizes.map(v => {
                            v.text = v.name;
                            v.value = v.id;
                        });
                    }
                    prizeListUnderPool[val.id] = val.poolPrizes || [];
                });
            }

            return ({ ...state, poolDataByEseId: payload, prizeListUnderPool })
        },
        [getPoolDataByEseId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


        [rechargeBudget.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [rechargeBudget.SUCCESS]: (state, { payload }) => ({ ...state, loading: false }),
        [rechargeBudget.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
    },
    initialPoolState
)
export default reducer;