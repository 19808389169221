import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './warehouse';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 字段分配
 */

// 列表
export const warehouseListEpic = actions$ => actions$.pipe(
  ofType(actions.warehouseList.REQUEST.toString()), 
  mergeMap(action => {
      actionApi();
      const { page, size,name='',code='',status=''} = action.payload;
      return ajax({
          ...ajaxBaseConfig,
          url: `${urls.data.warehouse.dict}?page=${page}&size=${size}&status=${status}&name=${name}&code=${name}`
      }).pipe(
          map(res => actions.warehouseList.SUCCESS(res.response)),
          catchError(error => of(actions.warehouseList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
  }
  )
);

// 下拉
export const warehouseSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.warehouseSelection.REQUEST.toString()), 
    mergeMap(action => {
        actionApi();
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.warehouse.seletion}`
        }).pipe(
            map(res => actions.warehouseSelection.SUCCESS(res.response)),
            catchError(error => of(actions.warehouseSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
  );

  // 修改状态
  export const warehouseStatusEpic = actions$ => actions$.pipe(
    ofType(actions.warehouseStatus.REQUEST.toString()), 
    mergeMap(action => {
        actionApi();
        const {id='',status} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.warehouse.dict}/${id}/status?status=${status}`,
            method:'PATCH'
        }).pipe(
            map(res => actions.warehouseList.REQUEST({page:0,size:20})),
            catchError(error =>{return of(actions.warehouseStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
  );

  // 仓库 下拉数据
  export const loadwarehousesQueryEpic = actions$ => actions$.pipe(
    ofType(actions.loadwarehousesQuery.REQUEST.toString()), 
    mergeMap(action => {
        actionApi();
        const {status} = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.warehouse.warehousesQuery}?status=${status}`,
        }).pipe(
            map(res => actions.loadwarehousesQuery.SUCCESS(res.response)),
            catchError(error =>{return of(actions.loadwarehousesQuery.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
  );
    export const loadwarehousesQueryNewEpic = actions$ => actions$.pipe(
        ofType(actions.loadwarehousesQueryNew.REQUEST.toString()), 
        mergeMap(action => {
            actionApi();
            const {status} = action.payload;
            return ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.warehouse.warehousesQueryNew}?status=${status}`,
            }).pipe(
                map(res => actions.loadwarehousesQueryNew.SUCCESS(res.response)),
                catchError(error =>{return of(actions.loadwarehousesQueryNew.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
            )
        }
        )
      );

  // 新增 修改
export const warehouseUpdateEpic = actions$ => actions$.pipe(
    ofType(actions.warehouseUpdate.REQUEST.toString()), 
    mergeMap(action => {
        actionApi();
        const {name="",code="",id='',method='POST',provinceCode="",cityCode="",countyCode="",type=undefined,address=""} = action.payload;
        let params = {};
            params = { name, code, type, provinceCode, cityCode, countyCode, address }
        let newId = id;
        if(newId){
            newId = `/${id}`;
        }
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.data.warehouse.add}${newId}`,
            method:method,
            body:params
        }).pipe(
            map(res => actions.warehouseList.REQUEST({page:0,size:20})),
            catchError(error =>{return of(actions.warehouseUpdate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))})
        )
    }
    )
  );
