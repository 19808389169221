import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './prizeRegister';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const getPrizeInfoTableDataEpic = actions$ => actions$.pipe(
    ofType(actions.getPrizeRegisterTable.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, sort = '', keyWords = '', promotionKeyWords = '', prizeKeyWords = '', prizeType = [], exportStatus = [], registerShipStatus = [], promotionCategory = '', planIds = '' } = action.payload;
        let theStatus = registerShipStatus;
        if (registerShipStatus.length >= 3) {
            theStatus = [];
        }
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.registers.registers}?page=${page}&size=${size}&sort=${sort}&phone=${keyWords ? encodeURIComponent(keyWords) : ''}&promotionIds=${promotionKeyWords}&prizeIds=${prizeKeyWords}&promotionCategory=${promotionCategory == 'no' || (promotionKeyWords && promotionKeyWords.length > 0) ? '' : promotionCategory}&planIds=${planIds}&prizeType=${prizeType}&exportStatus=${exportStatus}&status=${theStatus}`
        }).pipe(
            map(res => actions.getPrizeRegisterTable.SUCCESS(res.response)),
            catchError(error => of(actions.getPrizeRegisterTable.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

/* 奖品登记 */
export const patchRegisterEpic = actions$ => actions$.pipe(
    ofType(actions.patchRegister.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, updateExpress, callback } = action.payload;
        const { id, expressName = '', expressNo = '', adminRemark = '' } = data;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: data,
            url: `${urls.promotion.registers.registers}?id=${id}&adminRemark=${adminRemark}&expressName=${expressName}&expressNo=${expressNo}&updateExpress=${updateExpress}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.patchRegister.SUCCESS(res.response)
            }),
            catchError(error => of(actions.patchRegister.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


/* 导出 */
export const exportRegisterEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.exportRegister.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        const { page = 0, size = 20, sort = '', keyWords = '', promotionKeyWords = '', prizeKeyWords = '', prizeType = [], exportStatus = [], registerShipStatus = [], promotionCategory = '', planIds = '', registerTime = '', exportRemark = '', } = data;
        let theStatus = registerShipStatus;
        if (registerShipStatus.length >= 3) {
            theStatus = [];
        }
        return ajax({
            ...ajaxBaseConfig,
            // url: `${urls.promotion.registers.export}?page=${page}&size=${size}&sort=${sort}&keyWords=${keyWords}&prizeType=${prizeType}&exportStatus=${exportStatus}&status=${status}&registerTime=${registerTime}&email=602019979@qq.com`
            url: `${urls.promotion.registers.export}?page=${page}&size=${size}&sort=${sort}&phone=${keyWords ? encodeURIComponent(keyWords) : ''}&promotionIds=${promotionKeyWords}&prizeIds=${prizeKeyWords}&promotionCategory=${promotionCategory == 'no' || (promotionKeyWords && promotionKeyWords.length > 0) ? '' : promotionCategory}&planIds=${planIds}&prizeType=${prizeType}&exportStatus=${exportStatus}&status=${theStatus}&registerTime=${registerTime}&exportRemark=${exportRemark}`
        }).pipe(
            mergeMap(res => {
                callback && callback(res.status);
                return [actions.exportRegister.SUCCESS(res.response)]
            }),
            catchError(error => of(actions.exportRegister.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
