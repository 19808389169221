import React from 'react';
import { connect } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { notification as Notification, PageLoading } from '$bere';

import { AppMain, Navbar, Tabbar, Sidebar } from './components/index';
import NoPermission from '../NoPermission';
import UcodeLogin from '../Ucode/UcodeLogin';
import ExtractionCode from '../Ucode/ExtractionCode';
import queryString from 'query-string';

import ArrowRight from '@/icons/ArrowRight';
import { toggleSidebar, loadPermissions } from '@/redux/modules/ui';
import { checkOpenId, selfInfo, checkAccount } from '@/redux/modules/users/users';
import { getNowTabs } from '@/utils/tableUtils.js';
import { routes } from '../../routes';
import NoMatch from '../NoMatch'
import SystemLogin from '../SystemLogin/SystemLogin';

@connect(
  (state) => ({
    sidebarStatus: state.ui.sidebarStatus,
    backend: state.backend,
    user: state.users,
    lvThreeMenuOfLvTwo: state.ui.lvThreeMenuOfLvTwo,
    nowRouter: state.ui.nowRouter,
    notification: state.ui.notification,
    allMenuUrls: state.ui.allMenuUrls,
    userType: state.users.userType
  }),
  {
    toggleSidebar,
    loadPermissions,
    selfInfo: selfInfo.REQUEST,
    checkOpenId: checkOpenId.REQUEST,
    checkAccount: checkAccount.REQUEST
  }
)
class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extractionCode: false,
    }
  }
  componentDidMount() {
    const { checkOpenId, backend, checkAccount } = this.props;
    // 优码包提取 拼参数在url后面
    const query = queryString.parse(window.location.search);
    const { extraction_url, extractionCode } = query;
    if (extraction_url || extractionCode) {
      this.setState({ extractionCode: true });
      return;
    }

    // 查询openid
    if (process.env.NODE_ENV === 'development') {
      // checkOpenId('ying2');
      // checkOpenId('yan');
      // checkOpenId('123456');
      // checkOpenId('12345X');
      // 剑南春单点登录新写
      if (backend.system !== undefined && !backend.system) {

        let route = '';
        if (window.location.hash === '#/luckydrawconfig' && query.tasteOrder) {
          route = window.location.origin + window.location.pathname + '?jncAccount=' + encodeURIComponent(backend.jncAccount) + window.location.hash +
            '?tasteOrder=' + query.tasteOrder || 'xxx';
        }

        else if (window.location.hash === '#/luckydrawdrawactivity/luckydrawconfig/detail' && query.tasteOrder) {
          route = window.location.origin + window.location.pathname + '?jncAccount=' + encodeURIComponent(backend.jncAccount) + window.location.hash +
            '?tasteOrder=' + query.tasteOrder || 'xxx';
        }

        checkAccount({ account: backend.jncAccount || '', requestTime: query.requestTime || '', route: route || '', source: query.requestTime ? 'TPM' : '' });
        return;


      }


      if (this.props.userType !== 'SYSTEM' && window.location.hash.split('/')[1] !== 'SystemLogin') {
        this.systemEntry();
      }



    } else {

      if (backend.system !== undefined && !backend.system) {

        let route = '';
        if (window.location.hash === '#/luckydrawconfig' && query.tasteOrder) {
          route = window.location.origin + window.location.pathname + '?jncAccount=' + encodeURIComponent(backend.jncAccount) + window.location.hash +
            '?tasteOrder=' + query.tasteOrder || 'xxx';
        }

        else if (window.location.hash === '#/luckydrawdrawactivity/luckydrawconfig/detail' && query.tasteOrder) {
          route = window.location.origin + window.location.pathname + '?jncAccount=' + encodeURIComponent(backend.jncAccount) + window.location.hash +
            '?tasteOrder=' + query.tasteOrder || 'xxx';
        }

        checkAccount({ account: backend.jncAccount || '', requestTime: query.requestTime || '', route: route || '', source: query.requestTime ? 'TPM' : '' });
        return;


      }
      


      if (backend.system) {
        if (window.location.hash.split('/')[1] !== 'SystemLogin') {
          this.systemEntry();
        }
      } else {
        // checkOpenId(backend.open_id || '');
        checkAccount({ account: backend.jncAccount || '' });
      }
    }


  }

  componentDidUpdate() {

  }

  systemEntry = () => {
    const { loadPermissions, selfInfo } = this.props;
    loadPermissions(window.location.hash.split('/')[1]);
    selfInfo();
  }

  renderUcodeLogin = () => {
    return (
      <section className="app-main withtab">
        <UcodeLogin hideButton={true}></UcodeLogin>
      </section>
    )
  }

  renderAppMain(isMenuUrl, nowUrl, allMenuUrls, routesPathArray) {
    if (isMenuUrl) {
      if (!routesPathArray.includes(nowUrl)) {
        return this.renderUcodeLogin()
      }
      if (allMenuUrls.includes(nowUrl) || nowUrl === '' || window.location.hostname == 'localhost') {
        return <AppMain></AppMain>
      }
      return this.renderUcodeLogin()
    }
    return <AppMain></AppMain>
  }

  _renderPage = () => {
    const { backend: backEnd, nowRouter, lvThreeMenuOfLvTwo, sidebarStatus, toggleSidebar, user: { openIds, registration, checkOpenIdLoaded, checkOpenIdError, checkAccountLoaded, checkAccountError }, allMenuUrls, userType = 'SYSTEM' } = this.props;
    const hashUrl = window.location.hash;

    let backend;
    if (process.env.NODE_ENV === 'development') {
      backend = {}
      backend = { system: true }
    } else {
      backend = backEnd;
    }
    const isMenuUrl = hashUrl.split('/').length === 2;
    // const nowUrl = hashUrl.split('/')[1].split('?')[0];
    const nowUrl = hashUrl.substring(hashUrl.lastIndexOf('/') + 1, hashUrl.includes('?') ? hashUrl.indexOf('?') : hashUrl.length);
    const tabList = getNowTabs(nowRouter, lvThreeMenuOfLvTwo)
    const tabbarStatus = tabList.length > 0;
    const routesPathArray = routes.map(v => v.path && v.path.split('/')[1]);
    switch (true) {
      case checkAccountError:
        return (<SystemLogin />)

      case this.state.extractionCode:
        return (<ExtractionCode />);
      // 内部登录
      case backend.system && nowUrl === 'SystemLogin':

        return (<SystemLogin />)
      // 查询到openid用下，正常显示
      case (!!openIds || !!registration) && !backend.system || (backend.system && !!openIds) || (checkAccountError === false && !backend.system):
        return (<div className={`app-wrapper ${sidebarStatus ? '' : 'hideSidebar'}`} >
          <div className="sidebar-container">
            <Sidebar></Sidebar>
          </div>
          <div className="main-sidebar-button">
            {
              sidebarStatus ? '' :
                <div className="qc-menu-fold qc-menu-fold-right" onClick={() => { toggleSidebar(); }}>
                  <ArrowRight className="menu-arrow" width="16" height="16" />
                </div>
            }
          </div>
          <div className={`main-container ${tabbarStatus ? 'with-tab' : 'without-tab'}`}>
            {/* <div style={{ height: 40 }}></div> */}
            <Navbar></Navbar>
            <Tabbar></Tabbar>
            {
              allMenuUrls ?
                this.renderAppMain(isMenuUrl, nowUrl, allMenuUrls, routesPathArray)
                : <PageLoading />
            }

          </div>
        </div>)
      // 优码包提取链接，直接显示优码包提取页面
      case window.location.pathname !== '/index-system.html' && window.location.pathname !== '/index-system-redirect.html' && window.parent === window && process.env.NODE_ENV !== 'development':
        return (<UcodeLogin />)
      case !!checkOpenIdError:    // 查询openid用户报错
        // return <div>{checkOpenIdError}</div>
        return <UcodeLogin hideButton={true}></UcodeLogin>
      // 没有查询到openid用下，显示平台登陆
      case checkOpenIdLoaded && !openIds && !registration:
        return (<UcodeLogin />);

    }
  }

  render() {
    return (
      <HashRouter>
        {this._renderPage()}
      </HashRouter>

    )
  }
}

export default MainLayout;

