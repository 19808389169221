import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './thirdPartCodeHandle';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 第三方码
 */

// Epics
// 第三方码文件上传列表
export const thirdPartCodeHandleEpic = actions$ => actions$.pipe(
  ofType(actions.thirdPartCodeHandle.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { keywords = '', page, size } = action.payload;
    let url = `${urls.code.thirdPartCodeHandle.thirdPartCodeHandle}?page=${page}&size=${size}&sort=id,desc`;
    if (keywords) {
      url += `&idOrName=${keywords}`;
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.thirdPartCodeHandle.SUCCESS(res.response)),
      catchError(error => of(actions.thirdPartCodeHandle.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 第三方码文件上传
export const thirdPartCodeHandleModifyEpic = actions$ => actions$.pipe(
  ofType(actions.thirdPartCodeHandleModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...apiBaseConfig,
      url: `${urls.code.thirdPartCodeHandle.thirdPartCodeHandle}`,
      body: action.payload,
      method: 'post',
    }).pipe(
      mergeMap(res => [actions.thirdPartCodeHandleModify.SUCCESS(res.status), uiActions.notification('导入成功', 'success')]),
      catchError(error => of(actions.thirdPartCodeHandleModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

