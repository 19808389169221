import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'coupon';

/**
 * TODO: 优惠券管理
 */
// 优惠券树
export const loadCouponConfigsTree = fasterActions(moduleName, 'loadCouponConfigsTree');
// 优惠券品牌
export const loadCouponConfigsAll = fasterActions(moduleName, 'loadCouponConfigsAll');
export const createCouponConfig = fasterActions(moduleName, 'createCouponConfig');
export const putCouponConfig = fasterActions(moduleName, 'putCouponConfig');
export const disabledOrEnableCouponConfig = fasterActions(moduleName, 'disabledOrEnableCouponConfig');
// 优惠券种类
export const loadCouponConfigDetailsAll = fasterActions(moduleName, 'loadCouponConfigDetailsAll');
export const createCouponConfigDetail = fasterActions(moduleName, 'createCouponConfigDetail');
export const putCouponConfigDetail = fasterActions(moduleName, 'putCouponConfigDetail');
export const disabledOrEnableCouponConfigDetail = fasterActions(moduleName, 'disabledOrEnableCouponConfigDetail');
export const delCouponConfigDetail = fasterActions(moduleName, 'delCouponConfigDetail');
// 优惠券导出
export const couponCodePacketExport = fasterActions(moduleName, 'couponCodePacketExport');

const initialState = {
    couponLoading: false,
    couponConfigsTree: [],
    couponConfigsAllList: [],
    couponConfigDetailsAllList: [],
}

const reducer = handleActions(
    {

        // 优惠券树  
        [loadCouponConfigsTree.REQUEST]: (state, { payload }) => ({ ...state, couponLoading: true }),
        [loadCouponConfigsTree.SUCCESS]: (state, { payload }) => ({ ...state, couponConfigsTree: payload, couponLoading: false }),
        [loadCouponConfigsTree.FAIL]: (state, { payload }) => ({ ...state, error: payload, couponLoading: false }),

        // 优惠券品牌
        [loadCouponConfigsAll.REQUEST]: (state, { payload }) => ({ ...state }),
        [loadCouponConfigsAll.SUCCESS]: (state, { payload }) => {
            const selector = [];
            if (payload) {
                for (let elem of payload.values()) {
                    selector.push({ ...elem, value: elem.id, text: elem.name })
                }
            }
            return ({ ...state, couponConfigsAllList: selector })
        },
        [loadCouponConfigsAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [createCouponConfig.REQUEST]: (state, { payload }) => ({ ...state }),
        [createCouponConfig.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createCouponConfig.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [putCouponConfig.REQUEST]: (state, { payload }) => ({ ...state }),
        [putCouponConfig.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putCouponConfig.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [disabledOrEnableCouponConfig.REQUEST]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableCouponConfig.SUCCESS]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableCouponConfig.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



        // 优惠券种类
        [loadCouponConfigDetailsAll.REQUEST]: (state, { payload }) => ({ ...state }),
        [loadCouponConfigDetailsAll.SUCCESS]: (state, { payload }) => {
            const selector = [];
            if (payload) {
                for (let elem of payload.values()) {
                    selector.push({ ...elem, value: elem.id, text: elem.name })
                }
            }
            return ({ ...state, couponConfigDetailsAllList: selector })
        },
        [loadCouponConfigDetailsAll.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [createCouponConfigDetail.REQUEST]: (state, { payload }) => ({ ...state }),
        [createCouponConfigDetail.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createCouponConfigDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [putCouponConfigDetail.REQUEST]: (state, { payload }) => ({ ...state }),
        [putCouponConfigDetail.SUCCESS]: (state, { payload }) => ({ ...state }),
        [putCouponConfigDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [disabledOrEnableCouponConfigDetail.REQUEST]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableCouponConfigDetail.SUCCESS]: (state, { payload }) => ({ ...state }),
        [disabledOrEnableCouponConfigDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [delCouponConfigDetail.REQUEST]: (state, { payload }) => ({ ...state }),
        [delCouponConfigDetail.SUCCESS]: (state, { payload }) => ({ ...state }),
        [delCouponConfigDetail.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // 优惠券导出
        [couponCodePacketExport.REQUEST]: (state, { payload }) => ({ ...state }),
        [couponCodePacketExport.SUCCESS]: (state, { payload }) => ({ ...state }),
        [couponCodePacketExport.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },
    initialState
);

export default reducer;
