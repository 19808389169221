import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './reportResource';
import { ajaxBaseConfig, actionApi } from '../../utils';
import {loadPoolList} from "./reportResource";
// 所有活动
export const loadActivityListEpic = actions$ => actions$.pipe(
    ofType(actions.loadActivityList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const url = urls.statistics.resources.activity;
        const { dataVersion = 'NORMAL' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadActivityList.SUCCESS(res.response)),
            catchError(error => of(actions.loadActivityList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
// 看板活动deptactivity
export const loaddeptactivityListEpic = actions$ => actions$.pipe(
    ofType(actions.loaddeptactivityList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const url = urls.statistics.resources.deptactivity;
        const { dataVersion = 'NORMAL' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loaddeptactivityList.SUCCESS(res.response)),
            catchError(error => of(actions.loaddeptactivityList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadProductListEpic = actions$ => actions$.pipe(
    ofType(actions.loadProductList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const url = urls.statistics.resources.product;
        // const { dataVersion = 'NORMAL' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url,
            // url: `${url}?dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadProductList.SUCCESS(res.response)),
            catchError(error => of(actions.loadProductList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadPrizeListEpic = actions$ => actions$.pipe(
    ofType(actions.loadPrizeList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { payload = {
            poolId: '',
        } } = action;
        const { poolId = '' } = payload;
        const url = urls.statistics.resources.prize;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?pool=${poolId}`,
        }).pipe(
            map(res => actions.loadPrizeList.SUCCESS(res.response)),
            catchError(error => of(actions.loadPrizeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadPrizeListAllotsEpic = actions$ => actions$.pipe(
    ofType(actions.loadPrizeListAllots.REQUEST.toString()),
    mergeMap(action => { actionApi()
        // const { payload = {
        //     poolId: '',
        // } } = action;
        // const { poolId = '' } = action.payload;
        // const url = urls.statistics.resources.prize;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.promotion.prizes.prizes}/allots?isPage=false`,
        }).pipe(
            map(res => actions.loadPrizeListAllots.SUCCESS(res.response)),
            catchError(error => of(actions.loadPrizeListAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadPoolListEpic = actions$ => actions$.pipe(
    ofType(actions.loadPoolList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const url = urls.statistics.resources.pool;
        return ajax({
            ...ajaxBaseConfig,
            url,
        }).pipe(
            map(res => actions.loadPoolList.SUCCESS(res.response)),
            catchError(error => of(actions.loadPoolList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadPoolListAllotsEpic = actions$ => actions$.pipe(
    ofType(actions.loadPoolListAllots.REQUEST.toString()),
    mergeMap(action => { actionApi()
        // const url = urls.statistics.resources.poolAllots;
        const url = urls.promotion.pools.pools;
        return ajax({
            ...ajaxBaseConfig,
            url:`${url}/allots?isPage=false`,
        }).pipe(
            map(res => actions.loadPoolListAllots.SUCCESS(res.response)),
            catchError(error => of(actions.loadPoolListAllots.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadProvinceListEpic = actions$ => actions$.pipe(
    ofType(actions.loadProvinceList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const url = urls.statistics.resources.area;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?level=${1}`,
        }).pipe(
            map(res => actions.loadProvinceList.SUCCESS(res.response)),
            catchError(error => of(actions.loadProvinceList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadCityListEpic = actions$ => actions$.pipe(
    ofType(actions.loadCityList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { parentId = '' } = action.payload;
        const url = urls.statistics.resources.area;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?level=${2}&parentId=${parentId}`,
        }).pipe(
            map(res => actions.loadCityList.SUCCESS(res.response)),
            catchError(error => of(actions.loadCityList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadRedPacketsTypeEpic = actions$ => actions$.pipe(
    ofType(actions.loadRedPacketsTypeList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const url = urls.statistics.resources.redPacketsType;
        const { dataVersion = 'NORMAL' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?type=${2}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadRedPacketsTypeList.SUCCESS(res.response)),
            catchError(error => of(actions.loadRedPacketsTypeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadRedPacketsSourceEpic = actions$ => actions$.pipe(
    ofType(actions.loadRedPacketsSourceList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.resources.redPacketsSource;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?type=${6}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadRedPacketsSourceList.SUCCESS(res.response)),
            catchError(error => of(actions.loadRedPacketsSourceList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadAppletsListEpic = actions$ => actions$.pipe(
    ofType(actions.loadAppletsList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.resources.wechatSubscription;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?type =${4}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadAppletsList.SUCCESS(res.response)),
            catchError(error => of(actions.loadAppletsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
// 奖池么形成 商户号  jackpotList, commercialList
export const jackpotListEpic = actions$ => actions$.pipe(
    ofType(actions.jackpotList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.resources.jackpotption;

        return ajax({
            ...ajaxBaseConfig,
            url: `${url}`,
        }).pipe(
            map(res => actions.jackpotList.SUCCESS(res.response)),
            catchError(error => of(actions.jackpotList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const commercialListEpic = actions$ => actions$.pipe(
    ofType(actions.commercialList.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { dataVersion = 'NORMAL',appId='',selectDeptId=''} = action.payload;
        const url = urls.statistics.resources.commercialption;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?name=${appId}&selectDeptId=${selectDeptId}`,
        }).pipe(
            map(res => actions.commercialList.SUCCESS(res.response)),
            catchError(error => of(actions.commercialList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
