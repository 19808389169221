import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './dataOverview';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadStatisticsEpic = actions$ => actions$.pipe(
    ofType(actions.loadStatistics.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { dataVersion } = action.payload;
        const url = urls.statistics.dataOverview.statistics;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadStatistics.SUCCESS(res.response)),
            catchError(error => of(actions.loadStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadParticipantEpic = actions$ => actions$.pipe(
    ofType(actions.loadParticipant.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL'} = action.payload;
        const url = urls.statistics.dataOverview.participant;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.loadParticipant.SUCCESS(res.response)),
            catchError(error => of(actions.loadParticipant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadBonusTrendEpic = actions$ => actions$.pipe(
    ofType(actions.loadBonusTrend.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.dataOverview.bonusTrend;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.loadBonusTrend.SUCCESS(res.response)),
            catchError(error => of(actions.loadBonusTrend.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadScanWinTrendEpic = actions$ => actions$.pipe(
    ofType(actions.loadScanWinTrend.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.dataOverview.scanWinTrend;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.loadScanWinTrend.SUCCESS(res.response)),
            catchError(error => of(actions.loadScanWinTrend.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadActiveCityEpic = actions$ => actions$.pipe(
    ofType(actions.loadActiveCity.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.dataOverview.activeCity;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.loadActiveCity.SUCCESS(res.response)),
            catchError(error => of(actions.loadActiveCity.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadActiveCREpic = actions$ => actions$.pipe(
    ofType(actions.loadActiveCR.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.dataOverview.activityCr;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.loadActiveCR.SUCCESS(res.response)),
            catchError(error => of(actions.loadActiveCR.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadAmountTrendEpic = actions$ => actions$.pipe(
    ofType(actions.loadAmountTrend.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.dataOverview.amountTrend;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.loadAmountTrend.SUCCESS(res.response)),
            catchError(error => of(actions.loadAmountTrend.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadPayingTrendEpic = actions$ => actions$.pipe(
    ofType(actions.loadPayingTrend.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.dataOverview.payingTrend;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadPayingTrend.SUCCESS(res.response)),
            catchError(error => of(actions.loadPayingTrend.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadWinRatioEpic = actions$ => actions$.pipe(
    ofType(actions.loadWinRatio.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { periodType = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.dataOverview.winRatio;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?periodType=${periodType}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadWinRatio.SUCCESS(res.response)),
            catchError(error => of(actions.loadWinRatio.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);