import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'isvUser';

// isv用户
export const isvUser = fasterActions(moduleName, 'isvUser');
export const isvUserById = fasterActions(moduleName, 'isvUserById');
export const isvUserStatus = fasterActions(moduleName, 'isvUserStatus');
export const isvUserModify = fasterActions(moduleName, 'isvUserModify');
export const isvUserModifyPwd = fasterActions(moduleName, 'isvUserModifyPwd');


export const isvUserWhitelists = fasterActions(moduleName, 'isvUserWhitelists');
export const isvUserWhitelistsStatus = fasterActions(moduleName, 'isvUserWhitelistsStatus');
export const isvUserWhitelistsModify = fasterActions(moduleName, 'isvUserWhitelistsModify');

export const isvUserEnterprises = fasterActions(moduleName, 'isvUserEnterprises');
export const isvUserAllocation = fasterActions(moduleName, 'isvUserAllocation');
export const isvUserEnterprisesRemove = fasterActions(moduleName, 'isvUserEnterprisesRemove');
export const isvUserAssignRoles = fasterActions(moduleName, 'isvUserAssignRoles');
export const isvUserEnterprisesList = fasterActions(moduleName, 'isvUserEnterprisesList');
export const isvUserAssignRolesList = fasterActions(moduleName, 'isvUserAssignRolesList');




const initialState = {


}

const reducer = handleActions(
  {

    [isvUser.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [isvUser.SUCCESS]: (state, { payload }) => ({ ...state, isvUser: payload, loading: false }),
    [isvUser.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [isvUserById.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [isvUserById.SUCCESS]: (state, { payload }) => ({ ...state, isvUserById: payload }),
    [isvUserById.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserStatusLoaded: false }),
    [isvUserStatus.SUCCESS]: (state, { payload }) => ({ ...state, isvUserStatusLoaded: payload, }),
    [isvUserStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserModify.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserModifyLoaded: false }),
    [isvUserModify.SUCCESS]: (state, { payload }) => ({ ...state, isvUserModifyLoaded: payload, }),
    [isvUserModify.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserModifyPwd.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserModifyLoaded: false }),
    [isvUserModifyPwd.SUCCESS]: (state, { payload }) => ({ ...state, isvUserModifyLoaded: payload, }),
    [isvUserModifyPwd.FAIL]: (state, { payload }) => ({ ...state }),

    
    [isvUserWhitelists.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [isvUserWhitelists.SUCCESS]: (state, { payload }) => ({ ...state, isvUserWhitelists: payload, loading: false }),
    [isvUserWhitelists.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [isvUserWhitelistsStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserWhitelistsStatusLoaded: false }),
    [isvUserWhitelistsStatus.SUCCESS]: (state, { payload }) => ({ ...state, isvUserWhitelistsStatusLoaded: payload, }),
    [isvUserWhitelistsStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserWhitelistsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserWhitelistsModifyLoaded: false }),
    [isvUserWhitelistsModify.SUCCESS]: (state, { payload }) => ({ ...state, isvUserWhitelistsModifyLoaded: payload, }),
    [isvUserWhitelistsModify.FAIL]: (state, { payload }) => ({ ...state }),


    [isvUserEnterprises.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [isvUserEnterprises.SUCCESS]: (state, { payload }) => ({ ...state, isvUserEnterprises: payload, loading: false }),
    [isvUserEnterprises.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
    
    [isvUserAllocation.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserModifyLoaded: false }),
    [isvUserAllocation.SUCCESS]: (state, { payload }) => ({ ...state, isvUserModifyLoaded: payload, }),
    [isvUserAllocation.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserEnterprisesRemove.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserEnterprisesRemoveLoaded: false }),
    [isvUserEnterprisesRemove.SUCCESS]: (state, { payload }) => ({ ...state, isvUserEnterprisesRemoveLoaded: payload, }),
    [isvUserEnterprisesRemove.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserAssignRoles.REQUEST]: (state, { payload }) => ({ ...state, payload, isvUserAssignRolesLoaded: false }),
    [isvUserAssignRoles.SUCCESS]: (state, { payload }) => ({ ...state, isvUserAssignRolesLoaded: payload, }),
    [isvUserAssignRoles.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserEnterprisesList.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [isvUserEnterprisesList.SUCCESS]: (state, { payload }) => ({ ...state, isvUserEnterprisesList: payload.result }),
    [isvUserEnterprisesList.FAIL]: (state, { payload }) => ({ ...state }),

    [isvUserAssignRolesList.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [isvUserAssignRolesList.SUCCESS]: (state, { payload }) => ({ ...state, isvUserAssignRolesList: payload.result }),
    [isvUserAssignRolesList.FAIL]: (state, { payload }) => ({ ...state }),

  },
  initialState
);

export default reducer;
