import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './screenData';
import { ajaxBaseConfig, actionApi, apiBaseConfig } from '../../utils';
import { trims } from '@/utils/commonUtils.js';





// Epics
//pool
export const loadPoolListEpic = actions$ => actions$.pipe(
    ofType(actions.loadPoolList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keyword = '', page = 0, size = 20, sort = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.data.prize}/page?keyword=${keyword}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadPoolList.SUCCESS(res.response)),
            catchError(error => of(actions.loadPoolList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const putPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putPool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.prize}/update`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.putPool.SUCCESS(res.response)
                }),
                catchError(error => of(actions.putPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const syncPoolEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.syncPool.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.prize}/sync`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.syncPool.SUCCESS(res.response)
                }),
                catchError(error => of(actions.syncPool.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const loadPoolSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadPoolSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.prize}/selections`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadPoolSelect.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadPoolSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


//bg
export const loadBgListEpic = actions$ => actions$.pipe(
    ofType(actions.loadBgList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keyword = '', page = 0, size = 20, sort = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.data.bg}/page?keyword=${keyword}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadBgList.SUCCESS(res.response)),
            catchError(error => of(actions.loadBgList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const addBgEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addBg.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.bg}/create`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.addBg.SUCCESS(res.response)
                }),
                catchError(error => of(actions.addBg.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const putBgEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putBg.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.bg}/update`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.putBg.SUCCESS(res.response)
                }),
                catchError(error => of(actions.putBg.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const delBgEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delBg.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.bg}/delete`,
                method: 'POST',
                body: { id },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.delBg.SUCCESS(res.response)
                }),
                catchError(error => of(actions.delBg.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const loadBgSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadBgSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.bg}/selections`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadBgSelect.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadBgSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);



//award
export const loadAwardListEpic = actions$ => actions$.pipe(
    ofType(actions.loadAwardList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keyword = '', page = 0, size = 20, sort = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.data.award}/page?keyword=${keyword}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadAwardList.SUCCESS(res.response)),
            catchError(error => of(actions.loadAwardList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const addAwardEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addAward.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.award}/create`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.addAward.SUCCESS(res.response)
                }),
                catchError(error => of(actions.addAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const putAwardEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putAward.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.award}/update`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.putAward.SUCCESS(res.response)
                }),
                catchError(error => of(actions.putAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const delAwardEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delAward.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.award}/delete`,
                method: 'POST',
                body: { id },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.delAward.SUCCESS(res.response)
                }),
                catchError(error => of(actions.delAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const loadAwardSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadAwardSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.award}/selections`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadAwardSelect.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadAwardSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);



//order
export const loadOrderListEpic = actions$ => actions$.pipe(
    ofType(actions.loadOrderList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keyword = '', prizeIds = '', page = 0, size = 20, sort = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.data.order}/page?keyword=${keyword}&prizeIds=${prizeIds}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadOrderList.SUCCESS(res.response)),
            catchError(error => of(actions.loadOrderList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);


export const loadOrderByIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadOrderById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.order}/${id}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadOrderById.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadOrderById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const loadOrderByNoEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadOrderByNo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { type, orderNo, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.order}/find?type=${type}&orderNo=${orderNo}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadOrderByNo.SUCCESS(res.response)
                }),
                catchError(error => { callback && callback(error.response); return of(actions.loadOrderByNo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)) })
            )
        )
    })
);


export const addOrderEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addOrder.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.order}/create`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.addOrder.SUCCESS(res.response)
                }),
                catchError(error => of(actions.addOrder.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const putOrderEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.putOrder.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.order}/update`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.putOrder.SUCCESS(res.response)
                }),
                catchError(error => of(actions.putOrder.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const delOrderEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delOrder.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.order}/delete`,
                method: 'POST',
                body: { id },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.delOrder.SUCCESS(res.response)
                }),
                catchError(error => of(actions.delOrder.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const loadOrderSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadOrderSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keyword, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.data.order}/selections?keyword=${keyword}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadOrderSelect.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadOrderSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const loadOrderTasteSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadOrderTasteSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.bill.billData}/taste-order?owner=${data?.owner}&code=${data?.code}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadOrderTasteSelect.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadOrderTasteSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);




//active
export const loadActiveListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadActiveList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { isDraft, tabType, keyword = '', activityStatus = '', activityKeyword = '', orderKeyword = '', orderType = '', dataRangeTime = {}, page = 0, size = 20, sort = '' } = action.payload;
        let url = isDraft ? `${urls.screenLottery.active.active}/draft/page` : `${urls.screenLottery.active.active}/page`;

        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?keyword=${keyword}&activityStatus=${activityStatus}&orderKeyword=${orderKeyword}&orderType=${orderType}&activityKeyword=${activityKeyword}&activityStartTime=${dataRangeTime?.from || ''}&activityEndTime=${dataRangeTime?.to || ''}&tabType=${tabType}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadActiveList.SUCCESS(res.response)),
            catchError(error => of(actions.loadActiveList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadActiveByIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadActiveById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.active}/${id}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadActiveById.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadActiveById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


export const addActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.active}/draft/create`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.addActive.SUCCESS(res.response)
                }),
                catchError(error => of(actions.addActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const editActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.active}/draft/update`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.editActive.SUCCESS(res.response)
                }),
                catchError(error => of(actions.editActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const delActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.active}/draft/delete`,
                method: 'POST',
                body: { id },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.delActive.SUCCESS(res.response)
                }),
                catchError(error => of(actions.delActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


export const publishActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.publishActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.active}/draft/publish`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.publishActive.SUCCESS(res.response)
                }),
                catchError(error => of(actions.publishActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const disabledOrEnableActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.disabledOrEnableActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.active}/${data.status == 'ENABLE' ? 'recovery' : 'pause'}`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.disabledOrEnableActive.SUCCESS(res.response)
                }),
                catchError(error => of(actions.disabledOrEnableActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const confirmActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.confirmActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.lottery}/confirm`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.confirmActive.SUCCESS(res.response)
                }),
                catchError(error => of(actions.confirmActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


export const loadActiveHistoriesByIdEpic = (actions$, store) => actions$.pipe(
    ofType(actions.loadActiveHistoriesById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keywords, activityId = '', page = 0, size = 10, sort } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.active.active}/histories?activityId=${activityId}&keyword=${keywords}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadActiveHistoriesById.SUCCESS(res.response)),
            catchError(error => of(actions.loadActiveHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const getActiveHistoryEpic = actions$ => actions$.pipe(
    ofType(actions.getActiveHistory.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { activityId = '', historyId, step, page = 0, size = 50, sort } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.active.active}/step/${historyId}?step=${step}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => {
                return actions.getActiveHistory.SUCCESS({ res: res.response, step: action.payload.step })
            }),
            catchError(error => of(actions.getActiveHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const getActiveHistoryWinEpic = (actions$, store) => actions$.pipe(
    ofType(actions.getActiveHistoryWin.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { keywords, activityId = '', historyId = '', page = 0, size = 10, sort = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.active.active}/histories/winner-record/${activityId}/${historyId}?page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.getActiveHistoryWin.SUCCESS(res.response)),
            catchError(error => of(actions.getActiveHistoryWin.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);



//participant
export const loadParticipantListEpic = actions$ => actions$.pipe(
    ofType(actions.loadParticipantList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { activityId = '', keyword = '', terminalIds = '', page = 0, size = 20, sort = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.active.participant}/page?activityId=${activityId}&keyword=${keyword}&terminalIds=${terminalIds}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadParticipantList.SUCCESS(res.response)),
            catchError(error => of(actions.loadParticipantList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadParticipantByIdEpic = actions$ => actions$.pipe(
    ofType(actions.loadParticipantById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.active.participant}/${id}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.loadParticipantById.SUCCESS(res.response)
            }),
            catchError(error => of(actions.loadParticipantById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);




export const addParticipantEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addParticipant.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.participant}/create`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.addParticipant.SUCCESS(res.response)
                }),
                catchError(error => of(actions.addParticipant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const editParticipantEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editParticipant.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.participant}/update`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.editParticipant.SUCCESS(res.response)
                }),
                catchError(error => of(actions.editParticipant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const delParticipantEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delParticipant.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.participant}/delete`,
                method: 'POST',
                body: { id },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.delParticipant.SUCCESS(res.response)
                }),
                catchError(error => of(actions.delParticipant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const loadParticipantSelectEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadParticipantSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { activityId = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.participant}/selections?activityId=${activityId}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadParticipantSelect.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadParticipantSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const emptyParticipantEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.emptyParticipant.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { activityId, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.participant}/clear`,
                method: 'POST',
                body: { activityId },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.emptyParticipant.SUCCESS(res.response)
                }),
                catchError(error => of(actions.emptyParticipant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);



export const sureParticipantEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.sureParticipant.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, lotteryTicket, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.participant}/exist?activityId=${id}&lotteryTicket=${lotteryTicket}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.sureParticipant.SUCCESS(res.response)
                }),
                catchError(error => of(actions.sureParticipant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const importParticipantEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.importParticipant.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, formData, callback } = action.payload || {};
        return (
            ajax({
                ...apiBaseConfig,
                url: `${urls.screenLottery.active.participant}/import`,
                method: 'POST',
                body: formData
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.importParticipant.SUCCESS(res.response)
                }),
                catchError(error => { callback && callback({ ...error.response, status: error.status }); return of(actions.importParticipant.FAIL(error.xhr.response)) })

            )
        )
    })
);

//inAward
export const loadInAwardListEpic = actions$ => actions$.pipe(
    ofType(actions.loadInAwardList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { activityId = '', keyword = '', page = 0, size = 20, sort = '' } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.screenLottery.active.inAward}/page?activityId=${activityId}&keyword=${keyword}&page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.loadInAwardList.SUCCESS(res.response)),
            catchError(error => of(actions.loadInAwardList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const addInAwardEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.addInAward.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.inAward}/create`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.addInAward.SUCCESS(res.response)
                }),
                catchError(error => of(actions.addInAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const editInAwardEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editInAward.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.inAward}/update`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.editInAward.SUCCESS(res.response)
                }),
                catchError(error => of(actions.editInAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const delInAwardEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.delInAward.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.inAward}/delete`,
                method: 'POST',
                body: { id },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.delInAward.SUCCESS(res.response)
                }),
                catchError(error => of(actions.delInAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const disabledOrEnableInActiveEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.disabledOrEnableInActive.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.inAward}/status`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.disabledOrEnableInActive.SUCCESS(res.response)
                }),
                catchError(error => of(actions.disabledOrEnableInActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);
export const appointInAwardEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.appointInAward.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.inAward}/appoint`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.appointInAward.SUCCESS(res.response)
                }),
                catchError(error => of(actions.appointInAward.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);



export const loadLotteryByIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadLotteryById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.lottery}/info`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadLotteryById.SUCCESS(res.response)
                }),
                // catchError(error => of(actions.loadLotteryById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const loadLotteryParticipantEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadLotteryParticipant.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { activityId = '', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.lottery}/participants?activityId=${activityId}&size=10000`,
                method: 'POST',
                body: { activityId, size: 10000, page: 0 },
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadLotteryParticipant.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadLotteryParticipant.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

export const loadLotteryWinEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadLotteryWin.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { activityId = '', size = 1000, page = 0, sort = 'id,desc', callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.lottery}/winner-record?activityId=${activityId}&page=${page}&size=${size}&sort=${sort}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.loadLotteryWin.SUCCESS(res.response)
                }),
                catchError(error => of(actions.loadLotteryWin.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);


export const simulateLotteryEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.simulateLottery.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.lottery}/simulate`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    console.log(res)
                    callback && callback({ ...res.response, status: res.status });
                    return actions.simulateLottery.SUCCESS(res.response)
                }),
                catchError(error => { callback && callback({ ...error.response, status: error.status }); return of(actions.simulateLottery.FAIL(error.xhr.response)) })
            )
        )
    })
);
export const trueLotteryEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.trueLottery.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.lottery}/draw`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback({ ...res.response, status: res.status });
                    return actions.trueLottery.SUCCESS(res.response)
                }),
                catchError(error => { callback && callback({ ...error.response, status: error.status }); return of(actions.trueLottery.FAIL(error.xhr.response)) })
            )
        )
    })
);

export const loadLotteryByRoundEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.loadLotteryByRound.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.screenLottery.active.lottery}/round`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    callback && callback({ ...res.response, status: res.status });
                    return actions.loadLotteryByRound.SUCCESS(res.response)
                }),
                catchError(error => { callback && callback({ ...error.response, status: error.status }); return of(actions.loadLotteryByRound.FAIL(error.xhr.response)) })
            )
        )
    })
);

