import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './scancodeDetail';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 宴会扫码
export const banquetListEpic = actions$ => actions$.pipe(
    ofType(actions.banquetList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',poolIds='',sort = 'lotteryTime,desc',franchiserId=[],shopName='',productId=[],prizeId='',officePost={},cityManagerPost={},seqConfId={},tagType={},seqNum='',banquetCode='',promotionId = '', dataVersion = { value: 'NORMAL', text: '正式版' },boxLogisticsNum='',eseSeqNum='',heLogisticsNum='',promotionStatus='',storeCodeOrName='',phoneNumber='',consigneePhoneNumber=''} = action.payload;
        
        let promotionIdVal=(promotionId=='all')?'':promotionId;
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus;
        let poolIdVal=(poolIds=='all')?'':poolIds;
        let prizeIdVal=(prizeId=='all')?'':prizeId;
        let franchiserIdValue = franchiserId||'';
        let productIdValue = productId||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';
        let seqConfIdValue = seqConfId.value||'';
        let tagTypeValue = tagType.value || '';
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];
        // let endTimeValue = endTime+1000 * 1 * 24 * 3600;
        let endTimeValue = endTime;
        // let promotionIdValue = [promotionId.value] || [];
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.banquet}?poolIds=${poolIdVal}&page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTimeValue}&franchiserIds=${franchiserIdValue}&shopName=${shopName}&productIds=${productIdValue}&prizeId=${prizeIdVal}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&seqConfId=${seqConfIdValue}&tagType=${tagTypeValue}&seqNum=${seqNum}&banquetCode=${banquetCode}&dataVersion=${dataVersionValue}&promotionIds=${promotionIdVal}&boxLogisticsNum=${boxLogisticsNum}&heLogisticsNum=${heLogisticsNum}&eseSeqNum=${eseSeqNum}&promotionStatus=${promotionStatusVal}&storeCodeOrName=${storeCodeOrName}&phoneNumber=${phoneNumber}&consigneePhoneNumber=${consigneePhoneNumber}`
        }).pipe(
            map(res => actions.banquetList.SUCCESS(res.response)),
            catchError(error => of(actions.banquetList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// 消费者扫码
export const consumerListEpic = actions$ => actions$.pipe(
    ofType(actions.consumerList.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { page = 0, size = 20,startTime='',endTime='',poolIds='',sort = 'lotteryTime,desc',franchiserId=[],shopName='',boxLogisticsNum='',productId=[],prizeId='',officePost={},cityManagerPost={},seqNum='',dataVersion = { value: 'NORMAL', text: '正式版' },promotionId = '',seqConfId={},tagType={},eseSeqNum='',heLogisticsNum='',promotionStatus='',storeCodeOrName='',phoneNumber='',consigneePhoneNumber=''} = action.payload;

        let promotionIdVal=(promotionId=='all')?'':promotionId;
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus;
        let poolIdVal=(poolIds=='all')?'':poolIds;
        let prizeIdVal=(prizeId=='all')?'':prizeId;
        let seqConfIdValue = seqConfId.value||'';
        let tagTypeValue = tagType.value || '';
        let franchiserIdValue = franchiserId||'';
        let productIdValue = productId||'';
        let officePostValue = officePost.value||'';
        let cityManagerPostValue = cityManagerPost.value||'';
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];
        // let endTimeValue = endTime+1000 * 1 * 24 * 3600;
        let endTimeValue = endTime
        
        // let promotionIdValue = [promotionId.value] || [];
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.consumer}?poolIds=${poolIdVal}&page=${page}&size=${size}&sort=${sort}&startTime=${startTime}&endTime=${endTimeValue}&franchiserIds=${franchiserIdValue}&shopName=${shopName}&productIds=${productIdValue}&prizeId=${prizeIdVal}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&seqNum=${seqNum}&dataVersion=${dataVersionValue}&promotionIds=${promotionIdVal}&boxLogisticsNum=${boxLogisticsNum}&seqConfId=${seqConfIdValue}&tagType=${tagTypeValue}&heLogisticsNum=${heLogisticsNum}&eseSeqNum=${eseSeqNum}&promotionStatus=${promotionStatusVal}&storeCodeOrName=${storeCodeOrName}&phoneNumber=${phoneNumber}&consigneePhoneNumber=${consigneePhoneNumber}`
        }).pipe(
            map(res => actions.consumerList.SUCCESS(res.response)),
            catchError(error => of(actions.consumerList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//办事处下拉框
export const officePostSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.officePostSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url:`${urls.scan.officePostNew}`
        }).pipe(
            map(res => actions.officePostSelection.SUCCESS(res.response.content)),
            catchError(error => of(actions.officePostSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//城市经理下拉框
export const cityManagerPostSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.cityManagerPostSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url:`${urls.scan.cityManagerPost}`
        }).pipe(
            map(res => actions.cityManagerPostSelection.SUCCESS(res.response.content)),
            catchError(error => of(actions.cityManagerPostSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//码类型下拉框
export const tagTypeSelectionEpic = actions$ => actions$.pipe(
    ofType(actions.tagTypeSelection.REQUEST.toString()),
    mergeMap(action => {
        actionApi();

        return ajax({
            ...ajaxBaseConfig,
            url:`${urls.scan.tagType}`
        }).pipe(
            map(res => actions.tagTypeSelection.SUCCESS(res.response.content)),
            catchError(error => of(actions.tagTypeSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

//活动下拉
export const activitiesEpic = actions$ => actions$.pipe(
    ofType(actions.activities.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { dataVersion = { value: 'NORMAL' } } = action.payload;
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.activities}?dataVersion=${dataVersionValue}`
        }).pipe(
            map(res => actions.activities.SUCCESS(res.response)),
            catchError(error => of(actions.activities.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

// //活动状态下拉
// export const loadPromotionEpic = actions$ => actions$.pipe(
//     ofType(actions.loadPromotion.REQUEST.toString()),
//     mergeMap(action => {
//         actionApi();
//         const { dataVersion = { value: 'NORMAL' } } = action.payload;
//         let dataVersionValue = [dataVersion.value] || ['NORMAL'];
//         return ajax({
//             ...ajaxBaseConfig,
//             url: `${urls.scan.loadPromotion}?dataVersion=${dataVersionValue}`
//         }).pipe(
//             map(res => actions.loadPromotion.SUCCESS(res.response)),
//             catchError(error => of(actions.loadPromotion.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//         )
//     }
//     )
// );

//活动下拉
export const activitiesNewEpic = actions$ => actions$.pipe(
    ofType(actions.activitiesNew.REQUEST.toString()),
    mergeMap(action => {
        actionApi();
        const { dataVersion = { value: 'NORMAL' } ,remark=''} = action.payload;
        let dataVersionValue = [dataVersion.value] || ['NORMAL'];
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.scan.activities}?dataVersion=${dataVersionValue}&remark=${remark}`
        }).pipe(
            map(res => actions.activitiesNew.SUCCESS(res.response)),
            catchError(error => of(actions.activitiesNew.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

export const banquetDownLoadAsynEpic = actions$ => actions$.pipe(
    ofType(actions.banquetDownLoadAsyn.REQUEST.toString()),
    mergeMap(action => { 
        actionApi();
        const { callback,startTime = '', endTime = '', sort = null, franchiserId = [], shopName = '', boxLogisticsNum='',productId = [], prizeId = '', officePost = {}, cityManagerPost = {}, seqConfId = {},tagType={}, seqNum = '', banquetCode = '', poolIds = '',dataVersion = { value: 'NORMAL', text: '正式版' } ,promotionId = '',heLogisticsNum='',eseSeqNum='' ,promotionStatus='',storeCodeOrName='',phoneNumber='',consigneePhoneNumber='' } = action.payload;
        // let { } = data||{};
        // console.log(banquetCode,'ceshi--banquetCode---',data.banquetCode,'action.payload',action.payload)
        const url = urls.scan.downloadBanquet;
        let promotionIdVal=(promotionId=='all')?'':promotionId;
        let poolIdVal=(poolIds=='all')?'':poolIds;
        let prizeIdVal=(prizeId=='all')?'':prizeId;
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus;
        let franchiserIdValue = franchiserId || '';
        let productIdValue = productId || '';
        let officePostValue = officePost.value || '';
        let cityManagerPostValue = cityManagerPost.value || '';
        let seqConfIdValue = seqConfId.value || '';
        let tagTypeValue = tagType.value || '';
        // let poolIdValue = poolId.value || '';
        let dataVersionValue = [dataVersion.value] || [];
        // let endTimeValue = endTime+1000 * 1 * 24 * 3600;
        let endTimeValue = endTime
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?poolIds=${poolIdVal}&startTime=${startTime}&endTime=${endTimeValue}&franchiserIds=${franchiserIdValue}&shopName=${shopName}&productIds=${productIdValue}&prizeId=${prizeIdVal}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&seqConfId=${seqConfIdValue}&tagType=${tagTypeValue}&seqNum=${decodeURIComponent(seqNum)}&banquetCode=${banquetCode}&dataVersion=${dataVersionValue}&promotionIds=${promotionIdVal}&boxLogisticsNum=${boxLogisticsNum}&heLogisticsNum=${heLogisticsNum}&eseSeqNum=${eseSeqNum}&promotionStatus=${promotionStatusVal}&storeCodeOrName=${storeCodeOrName}&phoneNumber=${phoneNumber}&consigneePhoneNumber=${consigneePhoneNumber}`
        }).pipe(
            map(res => {
                callback && callback()
                return actions.banquetDownLoadAsyn.SUCCESS(res.response || []) 
            }),
            catchError(error => of(actions.banquetDownLoadAsyn.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const consumerDownLoadAsynEpic = actions$ => actions$.pipe(
    ofType(actions.consumerDownLoadAsyn.REQUEST.toString()),
    mergeMap(action => { 
        actionApi();
        const { callback ,startTime = '', endTime = '', sort = null, franchiserId = [], shopName = '', boxLogisticsNum='',productId = [], prizeId = '', officePost = {}, cityManagerPost = {}, seqConfId = {},tagType={}, seqNum = '', banquetCode = '', poolIds = '',dataVersion = { value: 'NORMAL', text: '正式版' } ,promotionId = '',eseSeqNum='',heLogisticsNum='',promotionStatus='',storeCodeOrName='',phoneNumber='',consigneePhoneNumber=''} = action.payload;
        // let {  } = action.payload.data;
        const url = urls.scan.downloadConsumer;
        let promotionIdVal=(promotionId=='all')?'':promotionId;
        let poolIdVal=(poolIds=='all')?'':poolIds;
        let promotionStatusVal=(promotionStatus=='all')?'':promotionStatus;
        let prizeIdVal=(prizeId=='all')?'':prizeId;
        let franchiserIdValue = franchiserId || '';
        let productIdValue = productId || '';
        let officePostValue = officePost.value || '';
        let cityManagerPostValue = cityManagerPost.value || '';
        let tagTypeValue = tagType.value || '';
        let dataVersionValue = [dataVersion.value] || [];
        // let endTimeValue = endTime+1000 * 1 * 24 * 3600;
        let endTimeValue = endTime
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?poolIds=${poolIdVal}&startTime=${startTime}&endTime=${endTimeValue}&franchiserIds=${franchiserIdValue}&shopName=${shopName}&productIds=${productIdValue}&prizeId=${prizeIdVal}&officePost=${officePostValue}&cityManagerPost=${cityManagerPostValue}&tagType=${tagTypeValue}&seqNum=${decodeURIComponent(seqNum)}&dataVersion=${dataVersionValue}&promotionIds=${promotionIdVal}&boxLogisticsNum=${boxLogisticsNum}&heLogisticsNum=${heLogisticsNum}&eseSeqNum=${eseSeqNum}&promotionStatus=${promotionStatusVal}&storeCodeOrName=${storeCodeOrName}&phoneNumber=${phoneNumber}&consigneePhoneNumber=${consigneePhoneNumber}`
        }).pipe(
            map(res => {
                callback && callback()
                return actions.consumerDownLoadAsyn.SUCCESS(res.response || []) 
            }),
            catchError(error => 
                of(actions.consumerDownLoadAsyn.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);






