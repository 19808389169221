import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'contractInfomation';

/**
 * TODO: 
 */

// 主数据字典管理
export const changeContractPaging = createAction(`${moduleName}/changeContractPaging`);
export const changeContractDataPaging = createAction(`${moduleName}/changeContractDataPaging`);
export const contractTypeList = fasterActions(moduleName, 'contractTypeList');
export const contractTypeInfo = fasterActions(moduleName, 'contractTypeInfo');
export const createContractType = fasterActions(moduleName, 'createContractType');
export const enableOrDisabledContractType = fasterActions(moduleName, 'enableOrDisabledContractType');
export const contractDataList = fasterActions(moduleName, 'contractDataList');
export const contractDataInfo = fasterActions(moduleName, 'contractDataInfo');
export const contractSelections = fasterActions(moduleName, 'contractSelections');
export const createContractData = fasterActions(moduleName, 'createContractData');


const initialState = {
    contractPaging: {
        page: 0,
        size: 20,
    },
    contractDataPaging: {
        page: 0,
        size: 20
    },
    contractTypeData: {},
    contractData: {},
    contractTypeInfo: {},
    contractSelections: [],
}

const reducer = handleActions(
    {
        [changeContractPaging]: (state, { payload }) => {
            return { ...state, contractPaging: { ...payload, page: 0 } }
        },

        [changeContractDataPaging]: (state, { payload }) => {
            return { ...state, contractDataPaging: { ...payload, page: 0 } }
        },

        [contractTypeList.REQUEST]: (state, { payload }) => ({ ...state, contractPaging: payload, loading: true }),
        [contractTypeList.SUCCESS]: (state, { payload }) => ({ ...state, contractTypeData: payload, loading: false }),
        [contractTypeList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [enableOrDisabledContractType.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [enableOrDisabledContractType.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledContractType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [contractTypeInfo.REQUEST]: (state, { payload }) => ({ ...state, payload, }),
        [contractTypeInfo.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [contractTypeInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [createContractType.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [createContractType.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createContractType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [contractDataList.REQUEST]: (state, { payload }) => ({ ...state, contractDataPaging: payload, loading: true }),
        [contractDataList.SUCCESS]: (state, { payload }) => ({ ...state, contractData: payload, loading: false }),
        [contractDataList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [contractDataInfo.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [contractDataInfo.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [contractDataInfo.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [contractSelections.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [contractSelections.SUCCESS]: (state, { payload }) => {
            if (payload) {
                payload.forEach(v => { v.text = v.name, v.value = v.id });
            }
            return ({ ...state, contractSelections: payload });
        },
        [contractSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [createContractData.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [createContractData.SUCCESS]: (state, { payload }) => ({ ...state }),
        [createContractData.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },
    initialState
);

export default reducer;
