import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';


const moduleName = 'codingRegister';

/**
 * TODO: 微信管理
 */

export const loadCodingRegisterList = fasterActions(moduleName, 'loadCodingRegisterList');
export const loadCodingRegisterId = fasterActions(moduleName, 'loadCodingRegisterId');
export const loadtemplate = fasterActions(moduleName, 'loadtemplate');
// 元素列表
export const loadCodingTemList = fasterActions(moduleName, 'loadCodingTemList');
// 元素新增 修改
export const loadTemAdd = fasterActions(moduleName, 'loadTemAdd');
// 获取元素id
export const loadTemId = fasterActions(moduleName, 'loadTemId');





const initialState = {

}

const reducer = handleActions(
  {
    [loadCodingRegisterList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadCodingRegisterList.SUCCESS]: (state, { payload }) => ({ ...state, codingRegisterList: payload, loading: false }),
    [loadCodingRegisterList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),
    
    [loadCodingTemList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadCodingTemList.SUCCESS]: (state, { payload }) => ({ ...state, codingTemList : payload, loading: false }),
    [loadCodingTemList.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [loadCodingRegisterId.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [loadCodingRegisterId.SUCCESS]: (state, { payload }) => ({ ...state, loadCodingRegisterId: payload }),
    [loadCodingRegisterId.FAIL]: (state, { payload }) => ({ ...state, error: payload })
    ,
    [loadTemId.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [loadTemId.SUCCESS]: (state, { payload }) => ({ ...state,loadTemId: payload, loading: false }),
    [loadTemId.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),



    [loadtemplate.REQUEST]: (state, { payload }) => ({ ...state, payload, miniProgramMaterialsLoaded: '', miniProgramMaterialsModifyLoaded: '' }),
    [loadtemplate.SUCCESS]: (state, { payload }) => {
      let miniProgramMaterialsLoaded = '', miniProgramMaterialsModifyLoaded = '';
      const { method, status } = payload;
      switch (method) {
        case 'PATCH': case 'DELETE':
          miniProgramMaterialsLoaded = status;
          break;
        case 'POST': case 'PUT':
          miniProgramMaterialsModifyLoaded = status;
          break;
      }
      console.log(miniProgramMaterialsLoaded)
      return ({ ...state, miniProgramMaterialsLoaded, miniProgramMaterialsModifyLoaded })
    },
    [loadtemplate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [loadTemAdd.REQUEST]: (state, { payload }) => ({ ...state, payload, loadCodingTemListObj: '', loadTemAddObj: '' }),
    [loadTemAdd.SUCCESS]: (state, { payload }) => {
      let loadCodingTemListObj = '', loadTemAddObj = '';
      const { method, status } = payload;
      switch (method) {
        case 'PATCH': case 'DELETE':
          loadCodingTemListObj = status;
          break;
        case 'POST': case 'PUT':
          loadTemAddObj = status;
          break;
      }

      return ({ ...state, loadCodingTemListObj, loadTemAddObj })
    },
    [loadTemAdd.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },

  initialState
);

export default reducer;
