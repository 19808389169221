import React from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { setOpenKeys, setInitOpenKeysByRouter, loadPermissions, setNavBar } from '@/redux/modules/ui';
import { routes } from '../../../../routes';

const { SubMenu, Item: MenuItem } = Menu;

@withRouter
@connect(
  (state) => {
    return {
      permissions: state.ui.permissions,
      lvTwoMenuOfLvOne: state.ui.lvTwoMenuOfLvOne,
      lvThreeMenuOfLvTwo: state.ui.lvThreeMenuOfLvTwo,
      selectedKeysOfRouter: state.ui.selectedKeysOfRouter,
      openKeys: state.ui.openKeys,
      nowRouter: state.ui.nowRouter,
      navbar: state.ui.navbar,
      allMenuUrls: state.ui.allMenuUrls,
      lvTwoMenuUrls: state.ui.lvTwoMenuUrls,
    }
  },
  { setOpenKeys, setInitOpenKeysByRouter, loadPermissions, setNavBar }
)
class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.routesEnum = {}; // 路由枚举
  }

  componentDidMount() {
    const { setInitOpenKeysByRouter, loadPermissions, location, allMenuUrls, lvTwoMenuUrls, history } = this.props;
    // loadPermissions(location.pathname.split('/')[1]);
    // 带自定义名字的路由
    routes.forEach(({ name, path }) => {
      if (name) {
        this.routesEnum[path] = name;
      }
    });

    if (window.localStorage.getItem('_navbar')) {
      this.props.setNavBar(window.localStorage.getItem('_navbar'));
    }


    this.props.history.listen((location, action) => {
      setInitOpenKeysByRouter(location.pathname.split('/')[1]);
      // if (!(allMenuUrls.includes(location.pathname.split('/')[1]) || lvTwoMenuUrls.includes(location.pathname.split('/')[1]))) {
      //   history.push('/root');
      // }
      const { selectedKeysOfRouter } = this.props;
      // 监听路由是否是，2，3级路由/自定义路由，设置navbar
      const currentRouter = location.pathname.substr(1);
      let navbar;
      if (currentRouter) {
        if (selectedKeysOfRouter[currentRouter]) {
          navbar = selectedKeysOfRouter[currentRouter].name.zh_CN;
        } else if (this.routesEnum[`/${currentRouter}`]) {
          navbar = this.routesEnum[`/${currentRouter}`];
        }
      }

      if (navbar) {
        this.props.setNavBar(navbar);
      }
    });
  }

  componentDidUpdate(prevProps) {
    // 第一次进来，赋值navbar
    if (this.props.selectedKeysOfRouter !== prevProps.selectedKeysOfRouter) {
      const { location, selectedKeysOfRouter } = this.props;
      const currentRouter = location.pathname.substr(1);
      // 路由列表有 用路由列表,没有用缓存里的
      let navbar;
      if (selectedKeysOfRouter[currentRouter]) {
        navbar = selectedKeysOfRouter[currentRouter].name.zh_CN;
      } else if (this.routesEnum[`/${currentRouter}`]) {
        navbar = this.routesEnum[`/${currentRouter}`];
      } else {
        navbar = window.localStorage.getItem('_navbar') || '';
      }

      if (navbar) {
        this.props.setNavBar(navbar);
      }
    }
  }

  onOpenChange = (openKeys) => {
    this.props.setOpenKeys(openKeys);
  }

  handleLevelOneItem = () => {
    const { permissions = [] } = this.props;
    const subMenu = permissions.map((v, i) => {
      if (v.url !== '') {
        return (
          <MenuItem key={v.url}>
            <span>{JSON.parse(v.name).zh_CN}</span>
          </MenuItem>
        )
      }
      return (
        <SubMenu
          key={v.permission}
          title={JSON.parse(v.name).zh_CN}
        >
          {this.handleLevelTwoItem(v.children)}
        </SubMenu>
      )
    });
    return subMenu;
  }

  handleLevelTwoItem = (levelTwoItems) => {
    const menuItem = levelTwoItems.map((v, k) => {
      return (
        <MenuItem
          key={v.url}
        >
          {JSON.parse(v.name).zh_CN}
        </MenuItem>
      )
    });
    return menuItem;

  }

  handleItemClick = ({ item, key, keyPath }) => {
    const { history, location, lvThreeMenuOfLvTwo } = this.props;
    const levelThreeRouters = lvThreeMenuOfLvTwo[key];

    let url = key;
    if (levelThreeRouters && levelThreeRouters.length > 0) {
      url = levelThreeRouters[0].url;
    }
    if (location.pathname === `/${url}`) return;
    history.push(`/${url}`);
    //数据大屏全屏 暂时不要自动全屏
    // if (key == 'reportscreen') {
    //     setTimeout(() => {
    //       this.fullScreen()   
    //     }, 1000);
     
  

    // }
  }

  fullScreen = () => {
    let element = document.querySelector("#digitalScreenShow")
    console.log(element)
    //IE 10及以下ActiveXObject
    if (window.ActiveXObject) {
      console.log("IE 10及以下ActiveXObject");
      let WsShell = new ActiveXObject("WScript.Shell");
      WsShell.SendKeys("{F11}");
    }
    //HTML W3C 提议
    else if (element.requestFullScreen) {
        console.log("HTML W3C 提议");
        element.requestFullScreen();
    }
    //IE11
    else if (element.msRequestFullscreen) {
        console.log("IE11");
        element.msRequestFullscreen();
    }
    // Webkit (works in Safari5.1 and Chrome 15)
    else if (element.webkitRequestFullScreen) {
        console.log("Webkit");
        element.webkitRequestFullScreen();
    }
    // Firefox (works in nightly)
    else if (element.mozRequestFullScreen) {
        console.log("Firefox");
        element.mozRequestFullScreen();
    }
  }

  render() {
    const { selectedKeysOfRouter, openKeys, nowRouter } = this.props;
    const main = this.handleLevelOneItem();
    const keyObj = selectedKeysOfRouter[nowRouter];

    return (
      <div>
        <Menu
          mode="inline"
          onClick={this.handleItemClick}
          selectedKeys={[keyObj ? keyObj.selectedKey : '']}
          openKeys={openKeys}
          onOpenChange={this.onOpenChange}
        >
          {main}
        </Menu>
      </div>

    )
  }
}

export default withRouter(SideMenu);