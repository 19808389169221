import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'linkSummary';

export const getLinkSummaryList = fasterActions(moduleName, 'getLinkSummaryList');
export const handleLinkSummaryRemark = fasterActions(moduleName, 'handleLinkSummaryRemark');


const initialState = {

    // 码数据变更
    codeDatachangeList: [],
    paging: {
        page: 0,
        size: 20,
    },
    linkSummaryList: {}, // 环节数据核对分页列表
}

const reducer = handleActions(
    {
        // 环节数据核对汇总分页列表
        [getLinkSummaryList.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, }),
        [getLinkSummaryList.SUCCESS]: (state, { payload }) => ({ ...state, linkSummaryList: payload }),
        [getLinkSummaryList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        // 环节数据核对 环节汇总备注修改
        [handleLinkSummaryRemark.REQUEST]: (state, { payload }) => ({ ...state, }),
        [handleLinkSummaryRemark.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [handleLinkSummaryRemark.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    },
    initialState
)

export default reducer;