import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './role';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epic
// 角色列表
export const getRolesListEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getRolesList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, word = '', sort = 'id,desc' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.roles.role}?page=${page}&size=${size}&word=${word}&sort=${sort}`,
            }).pipe(
                map(res => {
                    return actions.getRolesList.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.getRolesList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 禁用、启用角色
export const enableOrDisabledRoleEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.enableOrDisabledRole.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', status = '' } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.roles.role}/${id}/status?status=${status}`,
                method: 'PATCH',
            }).pipe(
                concatMap(res => [actions.enableOrDisabledRole.SUCCESS(Object.assign(res.response)), actions.getRolesList.REQUEST(state$.value.role.roleSearch)]),
                catchError(error => of(actions.enableOrDisabledRole.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 创建角色
export const createRoleEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.createRole.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, history } = action.payload
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.roles.role}`,
                method: 'POST',
                body: data,
            }).pipe(
                map(res => {
                    history.goBack();
                    return actions.createRole.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.createRole.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 编辑角色
export const editRoleEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.editRole.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, history } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.roles.role}`,
                method: 'PUT',
                body: data,
            }).pipe(
                map(res => {
                    history.goBack();
                    return actions.editRole.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.editRole.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 根据id查角色详情
export const getRoleDataEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getRoleData.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.roles.role}/${id}`,
            }).pipe(
                map(res => {
                    callback && callback(res.response);
                    return actions.getRoleData.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.getRoleData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 根据id查角色影响范围
export const getRoleScopeEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getRoleScope.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.roles.role}/${action.payload.id}/scope`,
            }).pipe(
                map(res => {
                    return actions.getRoleScope.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.getRoleScope.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 角色选框
export const getRoleSelectionsEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.getRoleSelections.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return (
            ajax({
                ...ajaxBaseConfig,
                url: `${urls.data.roles.selections}?roleType=${action.payload}`,
            }).pipe(
                map(res => {
                    return actions.getRoleSelections.SUCCESS(Object.assign(res.response))
                }),
                catchError(error => of(actions.getRoleSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
            )
        )
    })
);

// 删除
export const deleteRoleIdEpic = (actions$, state$) => actions$.pipe(
    ofType(actions.deleteRole.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.data.roles.role}/${action.payload}`
        }).pipe(
            mergeMap(res => [actions.deleteRole.SUCCESS(res), actions.getRolesList.REQUEST(state$.value.role.roleSearch)]),
            catchError(error => of(actions.deleteRole.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
)