import React from 'react';

import { AppRouter } from '../../../routes';
import { notification as Notification } from '$bere';

class AppMain extends React.Component {

  componentDidUpdate() {

  }

  render() {
    const isDavinci = location.hash.includes('davinci')
    return (
      <section className={`app-main withtab ${isDavinci?'davinci-main':''}`}>
        <div className="main">
          {AppRouter()}
        </div>
      </section>
    );
  }
}

export default AppMain;

