import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'accessories';

export const loadSeqnumUnauditeds = fasterActions(moduleName, 'loadSeqnumUnauditeds');
 export const loadSeqnumPasses = fasterActions(moduleName, 'loadSeqnumPasses');



 export const accessoriesChange = fasterActions(moduleName, 'accessoriesChange');

 export const accessoriesActive = fasterActions(moduleName, 'accessoriesActive');
 export const revocation = fasterActions(moduleName, 'revocation');
 export const check = fasterActions(moduleName, 'check');


 export const accessoriesDelete = fasterActions(moduleName, 'accessoriesDelete');


// export const loadApplyReturns = fasterActions(moduleName, 'loadApplyReturns');
// export const sendBatchs = fasterActions(moduleName, 'sendBatchs');
// export const loadAuditUnauditeds = fasterActions(moduleName, 'loadAuditUnauditeds');
// export const loadAuditPasses = fasterActions(moduleName, 'loadAuditPasses');
// export const audit = fasterActions(moduleName, 'audit');



const initialState = {
  loadSeqnumUnauditedsLoading: false,
  loadSeqnumUnauditedsLoaded: false,
  loadSeqnumPassesLoading: false,
  loadSeqnumPassesLoaded: false,
  loadHistoryLoading: false,
  loadHistoryLoaded: false,

  checkMessage:false,
  // loadApplyReturnsLoading: false,
  // loadApplyReturnsLoaded: false,
}

const reducer = handleActions(
  {
    [loadSeqnumUnauditeds.REQUEST]: (state, { payload }) => ({ ...state,  loadSeqnumUnauditedsLoading: true }),
    [loadSeqnumUnauditeds.SUCCESS]: (state, { payload }) => ({ ...state, loadSeqnumUnauditedsLoading: false, loadSeqnumUnauditedsLoaded: true, applyUnauditeds: payload }),
    [loadSeqnumUnauditeds.FAIL]: (state, { payload }) => ({ ...state, loadSeqnumUnauditedsLoading: false, loadSeqnumUnauditedsLoaded: false, error: payload }),
    [loadSeqnumPasses.REQUEST]: (state, { payload }) => ({ ...state,  loadSeqnumPassesLoading: true }),
    [loadSeqnumPasses.SUCCESS]: (state, { payload }) => ({ ...state, loadSeqnumPassesLoading: false, loadSeqnumPassesLoaded: true, applyPasses: payload }),
    [loadSeqnumPasses.FAIL]: (state, { payload }) => ({ ...state, loadSeqnumPassesLoading: false, loadSeqnumPassesLoaded: false, error: payload }),



    [accessoriesChange.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [accessoriesChange.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [accessoriesChange.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [accessoriesActive.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [accessoriesActive.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [accessoriesActive.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [revocation.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [revocation.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true }),
    [revocation.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),

    [check.REQUEST]: (state, { payload }) => ({ ...state, }),
    [check.SUCCESS]: (state, { payload }) => ({ ...state, checkMessage:false }),
    [check.FAIL]: (state, { payload }) => ({ ...state,  checkMessage: payload.emsg }),


  

    [accessoriesDelete.REQUEST]: (state, { payload }) => ({ ...state}),
    [accessoriesDelete.SUCCESS]: (state, { payload }) => ( { ...state }),
    [accessoriesDelete.FAIL]: (state, { payload }) => ({ ...state,error: payload }),
    // [sendBatchs.REQUEST]: (state, { payload }) => ({ ...state,  sendBatchsLoading: true }),
    // [sendBatchs.SUCCESS]: (state, { payload }) => ({ ...state, sendBatchsLoading: false, sendBatchsLoaded: true, sendBatchs: payload }),
    // [sendBatchs.FAIL]: (state, { payload }) => ({ ...state, sendBatchsLoading: false, sendBatchsLoaded: false, error: payload }),
    // [loadAuditUnauditeds.REQUEST]: (state, { payload }) => ({ ...state,  loadAuditUnauditedsLoading: true }),
    // [loadAuditUnauditeds.SUCCESS]: (state, { payload }) => ({ ...state, loadAuditUnauditedsLoading: false, loadAuditUnauditedsLoaded: true, auditUnauditeds: payload }),
    // [loadAuditUnauditeds.FAIL]: (state, { payload }) => ({ ...state, loadAuditUnauditedsLoading: false, loadAuditUnauditedsLoaded: false, error: payload }),
    // [loadAuditPasses.REQUEST]: (state, { payload }) => ({ ...state,  loadAuditPassesLoading: true }),
    // [loadAuditPasses.SUCCESS]: (state, { payload }) => ({ ...state, loadAuditPassesLoading: false, loadAuditPassesLoaded: true, auditPasses: payload }),
    // [loadAuditPasses.FAIL]: (state, { payload }) => ({ ...state, loadAuditPassesLoading: false, loadAuditPassesLoaded: false, error: payload }),
    // [audit.REQUEST]: (state, { payload }) => ({ ...state,  auditLoading: true }),
    // [audit.SUCCESS]: (state, { payload }) => ({ ...state, auditLoading: false, auditLoaded: true, audit: payload }),
    // [audit.FAIL]: (state, { payload }) => ({ ...state, auditLoading: false, auditLoaded: false, error: payload }),
  },
  initialState
)

export default reducer;