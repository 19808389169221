import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'codeBatchQuery';

export const query = fasterActions(moduleName, 'query');
export const log = fasterActions(moduleName, 'log');
export const download = fasterActions(moduleName, 'download');
export const orderQuery = fasterActions(moduleName, 'orderQuery');

const initialState = {
    logList:[],
    logLoading:false,
}

const reducer = handleActions(
  {
      [query.REQUEST]: (state, { payload }) => ({ ...state,  }),
      [query.SUCCESS]: (state, { payload }) => ({ ...state,}),
      [query.FAIL]: (state, { payload }) => ({ ...state,}),

      [log.REQUEST]: (state, { payload }) => ({ ...state,  logList: [],logLoading:true, }),
      [log.SUCCESS]: (state, { payload }) => ({ ...state, logList: payload ,logLoading:false,}),
      [log.FAIL]: (state, { payload }) => ({ ...state,logLoading:false,  }),

      [orderQuery.REQUEST]: (state, { payload }) => ({ ...state,  }),
      [orderQuery.SUCCESS]: (state, { payload }) => ({ ...state,}),
      [orderQuery.FAIL]: (state, { payload }) => ({ ...state,}),
  },
  initialState
)

export default reducer;