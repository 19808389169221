import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './miniProgramParameter';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 企业管理
 */


//list
export const loadParameterListEpic = actions$ => actions$.pipe(
  ofType(actions.loadParameterList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'id,desc', appId } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.parameter.parameter}/page?appId=${appId}&name=${keywords}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.loadParameterList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadParameterList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createParameterEpic = actions$ => actions$.pipe(
  ofType(actions.createParameter.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.queryTools.parameter.parameter}?appId=${data.appId}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createParameter.SUCCESS(res)
        }),
        catchError(error => of(actions.createParameter.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editParameterEpic = actions$ => actions$.pipe(
  ofType(actions.editParameter.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.queryTools.parameter.parameter}?appId=${data.appId}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editParameter.SUCCESS(res)
        }),
        catchError(error => of(actions.editParameter.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//able

export const ableParameterEpic = actions$ => actions$.pipe(
  ofType(actions.ableParameter.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.queryTools.parameter.parameter}/${data.id}/status?status=${data.status}`,
        //  body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableParameter.SUCCESS(res)
        }),
        catchError(error => of(actions.ableParameter.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通信录查询by id
export const loadParameterIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadParameterId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.parameter.parameter}/${data.id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.loadParameterId.SUCCESS(res.response)

        }),
        catchError(error => of(actions.loadParameterId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



export const mediaListParameterEpic = actions$ => actions$.pipe(
  ofType(actions.mediaListParameter.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const { page = 0, size = 20 } = data;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.parameter.parameter}/mediaList?appId=${data.appId}&mediaType=${data.mediaType}&page=${page}&size=${size}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.mediaListParameter.SUCCESS(res.response)

        }),
        catchError(error => of(actions.mediaListParameter.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);



export const mediaListParameterImageEpic = actions$ => actions$.pipe(
  ofType(actions.mediaListParameterImage.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const { page = 0, size = 20 } = data;
    return (
      ajax({
        ...ajaxBaseConfig,
        // &page=${page}&size=${size}
        url: `${urls.queryTools.parameter.wechatMedia}/page?appId=${data.appId}&mediaType=${data.mediaType}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.mediaListParameterImage.SUCCESS(res.response)

        }),
        catchError(error => of(actions.mediaListParameterImage.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const mediaListsyncEpic = actions$ => actions$.pipe(
  ofType(actions.mediaListsync.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    const { page = 0, size = 20 } = data;
    return (
      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.queryTools.parameter.wechatMedia}/synchronizeMedia?appId=${data.appId}&mediaType=${data.mediaType}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.mediaListsync.SUCCESS(res.response)

        }),
        catchError(error => of(actions.mediaListsync.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);





export const thumbMediaIdEpic = actions$ => actions$.pipe(
  ofType(actions.thumbMediaId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.queryTools.parameter.parameter}/media?appId=${data.appId}&mediaId=${data.mediaId}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.thumbMediaId.SUCCESS(res.response)

        }),
        catchError(error => of(actions.thumbMediaId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);




