import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'scancodeDetail';

/**
 * TODO: 
 */

export const banquetList = fasterActions(moduleName, 'banquetList');
export const consumerList = fasterActions(moduleName, 'consumerList');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const officePostSelection = fasterActions(moduleName, 'officePostSelection');
export const cityManagerPostSelection = fasterActions(moduleName, 'cityManagerPostSelection');
export const tagTypeSelection = fasterActions(moduleName, 'tagTypeSelection');
export const banquetDownLoadAsyn = fasterActions(moduleName, 'banquetDownLoadAsyn');
export const consumerDownLoadAsyn = fasterActions(moduleName, 'consumerDownLoadAsyn');
//活动下拉
export const activities = fasterActions(moduleName, 'activities');
//活动状态下拉
// export const loadPromotion = fasterActions(moduleName, 'loadPromotion');
export const activitiesNew = fasterActions(moduleName, 'activitiesNew');

const initialState = {
    pageData:[],
    pageParams:{
      page:0,
      size:20,
      startTime:'',
      endTime:''
    },
    officePostData:[],
    cityManagerPostData:[],
    tagTypeData:[],
    loading:false,
    activitiesData:[],
    // promotionStatus:[],
    activitiesNewData:[],
}

const reducer = handleActions(
  { 
    [changeDictPaging]: (state, { payload }) => {
        return { ...state, pageParams: { ...payload} }
    },

    [banquetList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [banquetList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [banquetList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [activities.REQUEST]: (state, { payload }) => ({ ...state, activitiesData: [],  }),
    [activities.SUCCESS]: (state, { payload }) => ({ ...state, activitiesData: payload.content, }),
    [activities.FAIL]: (state, { payload }) => ({ ...state }),

    // [loadPromotion.REQUEST]: (state, { payload }) => ({ ...state, promotionStatus: [],  }),
    // [loadPromotion.SUCCESS]: (state, { payload }) => ({ ...state, promotionStatus: payload.content, }),
    // [loadPromotion.FAIL]: (state, { payload }) => ({ ...state }),

    [activitiesNew.REQUEST]: (state, { payload }) => ({ ...state, activitiesNewData: [],  }),
    [activitiesNew.SUCCESS]: (state, { payload }) => ({ ...state, activitiesNewData: payload.content, }),
    [activitiesNew.FAIL]: (state, { payload }) => ({ ...state }),

    [consumerList.REQUEST]: (state, { payload }) => ({ ...state, pageData: {}, loading: true }),
    [consumerList.SUCCESS]: (state, { payload }) => ({ ...state, pageData: payload, loading: false }),
    [consumerList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [officePostSelection.REQUEST]: (state, { payload }) => ({ ...state, officePostData: []}),
    [officePostSelection.SUCCESS]: (state, { payload }) => ({ ...state, officePostData: payload }),
    [officePostSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [cityManagerPostSelection.REQUEST]: (state, { payload }) => ({ ...state, cityManagerPostData: []}),
    [cityManagerPostSelection.SUCCESS]: (state, { payload }) => ({ ...state, cityManagerPostData: payload }),
    [cityManagerPostSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [tagTypeSelection.REQUEST]: (state, { payload }) => ({ ...state, tagTypeData: []}),
    [tagTypeSelection.SUCCESS]: (state, { payload }) => ({ ...state, tagTypeData: payload }),
    [tagTypeSelection.FAIL]: (state, { payload }) => ({ ...state}),

    [banquetDownLoadAsyn.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
    [banquetDownLoadAsyn.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [banquetDownLoadAsyn.FAIL]: (state, { payload }) => ({ ...state, Loading: false, error: payload }),

    [consumerDownLoadAsyn.REQUEST]: (state, { payload }) => ({ ...state,  Loading: true }),
    [consumerDownLoadAsyn.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [consumerDownLoadAsyn.FAIL]: (state, { payload }) => ({ ...state, Loading: false, error: payload }),

  },
  initialState
);

export default reducer;
