import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax } from 'rxjs/ajax';

import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './masterDataCheck';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { apiBaseConfig } from '@/redux/utils';

export const getMasterDataListEpic = actions$ => actions$.pipe(
    ofType(actions.getMasterDataList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, sourceSystem = '', masterDataType = '', startDate = '', endDate = '' } = action.payload;
        let params = `?page=${page}&size=${size}`;
        if (sourceSystem) params += `&sourceSystem=${sourceSystem}`;
        if (masterDataType) params += `&masterDataType=${masterDataType}`;
        if (startDate) params += `&startDate=${startDate}`;
        if (endDate) params += `&endDate=${endDate}`;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.dataManage.masterDataCheck.dict}${params}`,
        }).pipe(
            map(res => actions.getMasterDataList.SUCCESS(res.response)),
            catchError(error => of(actions.getMasterDataList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);

export const handleMasterDataRemarkEpic = actions$ => actions$.pipe(
    ofType(actions.handleMasterDataRemark.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { dataDate, masterDataType, remark, callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.dataManage.masterDataCheck.remark}`,
            body: { dataDate, masterDataType, remark },
            method: 'PUT'
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.handleMasterDataRemark.SUCCESS(res.response);
            }),
            catchError(error => of(actions.handleMasterDataRemark.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    }
    )
);