// 'yyyy-MM-dd hh:mm:ss.S' => 2006-07-02 08:09:04.423
import moment from 'moment';

export const formatDate = (dateStr, fmt = 'yyyy-MM-dd hh:mm:ss') => {
  if (!dateStr) return;
  const date = new Date(dateStr);
  const o = {
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日
    'h+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
    S: date.getMilliseconds(), // 毫秒
  };

  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length)); }
  for (const k in o) {
    if (new RegExp(`(${k})`).test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length))); }
  }
  return fmt;
};

export const getColorByValue = (value) => {
  switch (value) {
    case 'PAUSE':
      return '#FF7800'
    case 'NO_STARTED':
      return '#262626'
    case 'PROCESSING':
      return '#00C156'
    case 'ENDED':
      return '#888888';
    case 'PROCESS_PASS':
      return '#00C156';
    case 'PROCESS_RETURN':
      return '#e1504a';

    default:
      return '#000';
  }
};

export const getColorByValue2 = (value) => {
  switch (value) {
    case 'SUSPEND':
      return '#FF7800'
    case 'NO_STARTED':
      return '#262626'
    case 'UNDERWAY':
      return '#00C156'
    case 'ENDED':
      return '#888888';
    case 'DIS_UPPER':
      return '#888888'
    case 'UPPER':
      return '#00C156'
    case 'UNDER':
      return '#888888';
    case 'PROCESS_PASS':
      return '#00C156';
    case 'PROCESS_RETURN':
      return '#e1504a';

    default:
      return '#888888';
  }
};

export const getDefaultDateRangeList = () => {
  const now = moment();
  const excludeToday = false;
  return [{
    text: '昨天',
    value: {
      from: moment(now).subtract(1, 'd').set({
        hour: 0,
        minute: 0,
        second: 0
      }),
      to: moment(now).subtract(1, 'd').set({
        hour: 23,
        minute: 59,
        second: 59
      }),
      index: 1
    }
  }, {
    text: '近7天',
    value: {
      from: moment(now).subtract(7, 'd'),
      to: excludeToday ? moment(now).subtract(1, 'd').set({
        hour: 23,
        minute: 59,
        second: 59
      }) : moment(now),
      index: 2
    }
  }, {
    text: '近30天',
    value: {
      from: moment(now).subtract(30, 'd'),
      to: excludeToday ? moment(now).subtract(1, 'd').set({
        hour: 23,
        minute: 59,
        second: 59
      }) : moment(now),
      index: 3
    }
  }]
};

// 统一去前后空格
export const trims = (obj) => {
  for (let key in obj) {
    if (obj[key] !== null && obj[key] !== undefined) {
      if (typeof obj[key] === 'string' && key != 'password') { // obj[key].constructor === String
        obj[key] = obj[key].trim();
      } else if (typeof obj[key] === 'object') {
        trims(obj[key]);
      }
    }
  }
  return obj;
}

export const validPhoneNumber = function (phoneNumber) {
  return (/^[1][2,3,4,5,6,7,8,9][0-9]{9}$/).test(phoneNumber);
};


/** Used to match property names within property paths. */
const rePropName = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g;

/** Used to match backslashes in property paths. */
const reEscapeChar = /\\(\\)?/g;

/**
 * Converts `string` to a property path array.
 *
 * @private
 * @param {string} string The string to convert.
 * @returns {Array} Returns the property path array.
 */
const stringToPath = function (string) {
  const result = [];
  if (string.charCodeAt(0) === 46 /* . */) {
    result.push('');
  }
  string.replace(rePropName, function (match, number, quote, subString) {
    result.push(quote ? subString.replace(reEscapeChar, '$1') : (number || match));
  });
  return result;
};

/**
 * 
 * @param {*} object 
 * @param {*} path 
 * @param {*} value 
 */
 export function _set(object, path, value) {
  path = stringToPath(path);
  let length = path.length - 1;
  return path.reduce(function (obj, key, index, arr) {
    if (index == length) {
      if (obj[key] instanceof Array) {
        obj[key].push(value);
      } else {
        obj[key] = value;
      }
    }
    return (obj && obj[key]) ? obj[key] : null;
  }, object);
}

let appId = '';
switch (process.env.env) {
  case 'local': case 'dev': case 'test': default:
    appId = 'wxb8887a3ce4c72d4e';
    break;
  case 'prod':
    appId = 'wxac0aa09a80d9e96e';
    break;
}

export const templateAppId = appId;

export function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}


// 归并排序
export const mergeSort = arr => {
  //采用自上而下的递归方法
  const len = arr.length;
  if (len < 2) {
    return arr;
  }
  // length >> 1 和 Math.floor(len / 2) 等价
  let middle = Math.floor(len / 2),
    left = arr.slice(0, middle),
    right = arr.slice(middle); // 拆分为两个子数组
  return merge(mergeSort(left), mergeSort(right));
};

const merge = (left, right) => {
  const result = [];

  while (left.length && right.length) {
    // 注意: 判断的条件是小于或等于，如果只是小于，那么排序将不稳定.
    if (left[0] <= right[0]) {
      result.push(left.shift());
    } else {
      result.push(right.shift());
    }
  }

  while (left.length) result.push(left.shift());

  while (right.length) result.push(right.shift());

  return result;
};
export const downloadImg = (url = '') => {
  const name = url.slice(url.lastIndexOf('/') + 1, url.length)
  const image = new Image();
  // 解决跨域 canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous');
  image.onload = function () {
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0, image.width, image.height);
    //得到图片的base64编码数据
    const url = canvas.toDataURL('image/png');
    // 生成一个 a 标签
    const a = document.createElement('a');
    // 创建一个点击事件
    const event = new MouseEvent('click');
    // 将 a 的 download 属性设置为我们想要下载的图片的名称，若 name 不存在则使用'图片'作为默认名称
    a.download = name || '图片';
    // 将生成的 URL 设置为 a.href 属性
    a.href = url;
    // 触发 a 的点击事件
    a.dispatchEvent(event);
    // return a;
  };
  image.src = url;
}

export function isJncIframe() {
  return (
    window.location.search.includes('isJnc=') || window.location.pathname === '/index-iframe-v2.html'
  )
}

export const uniqueElementsBy = (arr, fn) =>
  arr.reduce((acc, v) => {
    if (!acc.some(x => fn(v, x))) acc.push(v)
    return acc
  }, [])