import * as React from "react";

function SvgFileCenter(props) {
  return (
    <svg width={14} height={12} {...props}>
      <path
        d="M13.258 3.662v-.966c0-.833-.658-1.757-1.54-1.757H7.28L7.098.596A1.05 1.05 0 006.16.029H1.428C.644.022 0 .687 0 1.499v9.009c0 .812.644 1.47 1.428 1.47h11.137c.791 0 1.428-.658 1.428-1.47v-5.53c.007-.483-.308-.987-.735-1.316zm-1.54-1.841c.308 0 .658.469.658.875v.609H8.54l-.784-1.484h3.962zm1.4 8.687c0 .322-.245.588-.546.588H1.428c-.301 0-.546-.266-.546-.588V1.499c0-.322.245-.588.546-.588H6.16c.063 0 .126.035.154.098l1.561 2.947a.438.438 0 00.392.238h4.06c.315 0 .791.469.791.791v5.523z"
        fillRule="nonzero"
        fill="#000"
      />
    </svg>
  );
}

export default SvgFileCenter;
