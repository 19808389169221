import { handleActions } from "redux-actions"
import { fasterActions } from "../../utils"

const moduleName = "pointMall"

// 不分页商城列表
export const loadPointMallSelections = fasterActions(moduleName, 'loadPointMallSelections');



export const draftCount = fasterActions(moduleName, 'draftCount'); // 统计草稿数量
export const loadList = fasterActions(moduleName, 'loadList');
export const loadById = fasterActions(moduleName, 'loadById');
export const createMall = fasterActions(moduleName, 'createMall');
export const editMall = fasterActions(moduleName, 'editMall');
export const deleteMallById = fasterActions(moduleName, 'deleteMallById');


const INIT_BYID = `${moduleName}/INIT_BYID`;

export function initByid() {
  return { type: INIT_BYID };
}


export const editMallSettings = fasterActions(moduleName, 'editMallSettings');

export const publishPointMall = fasterActions(moduleName, 'publishPointMall');

export const hasDraft = fasterActions(moduleName, 'hasDraft');
export const mallResume = fasterActions(moduleName, 'mallResume');


export const loadMallHistoriesById = fasterActions(moduleName, 'loadMallHistoriesById');


export const getPresentRules = fasterActions(moduleName, 'getPresentRules');
export const createOrEditPresentRules = fasterActions(moduleName, 'createOrEditPresentRules');

export const checknext = fasterActions(moduleName, 'checknext');


export const getHistory = fasterActions(moduleName, 'getHistory');




const initialState = {


  loading: false,
  listLoaded: false,
  loaded: false,
  byIdLoaded: false,
  byId: {},
  checknextRecord: {},

  editorcreatloading: false,
  historyStep1: {},
}
const reducer = handleActions(
  {


    [loadPointMallSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadPointMallSelections.SUCCESS]: (state, { payload }) => {
      const selector = [];
      if (payload) {
        for (let elem of payload) {
          selector.push({ ...elem, value: elem.id, text: elem.name });
        }
      }
      return ({ ...state, pointMallSelectionsList: selector });
    },
    [loadPointMallSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [INIT_BYID]: (state, { payload }) => ({ ...state, byId: {}, historyStep1: {} }),
    [loadList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, list: payload }),
    [loadList.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, error: payload }),
    [loadById.REQUEST]: (state, { payload }) => ({ ...state, byIdLoaded: true }),
    [loadById.SUCCESS]: (state, { payload }) => {
      return {
        ...state, byId: payload, byIdLoaded: true,
      };
    },
    [loadById.FAIL]: (state, { payload }) => ({ ...state, byIdLoaded: false, error: payload }),

    [deleteMallById.REQUEST]: (state, { payload }) => ({ ...state, deleteMallByIdLoading: true, deleteMallByIdLoaded: false, loadMallParams: payload.params }),
    [deleteMallById.SUCCESS]: (state, { payload }) => ({ ...state, deleteMallByIdLoading: false, ddeleteMallByIdLoaded: true }),
    [deleteMallById.FAIL]: (state, { payload }) => ({ ...state, deleteMallByIdLoading: false, deleteMallByIdLoaded: false, error: payload }),


    [draftCount.REQUEST]: (state, { payload }) => ({ ...state, draftCountLoading: true }),
    [draftCount.SUCCESS]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: true, draftCount: { content: payload.draft } }),
    [draftCount.FAIL]: (state, { payload }) => ({ ...state, draftCountLoading: false, draftCountLoaded: false, error: payload }),


    [createMall.REQUEST]: (state, { payload }) => ({ ...state, editorcreatloading: true }),
    [createMall.SUCCESS]: (state, { payload }) => ({ ...state, editorcreatloading: false }),
    [createMall.FAIL]: (state, { payload }) => ({ ...state, error: payload, editorcreatloading: false }),

    [editMall.REQUEST]: (state, { payload }) => ({ ...state, editorcreatloading: true }),
    [editMall.SUCCESS]: (state, { payload }) => ({ ...state, editorcreatloading: false }),
    [editMall.FAIL]: (state, { payload }) => ({ ...state, error: payload, editorcreatloading: false }),


    [editMallSettings.REQUEST]: (state, { payload }) => ({ ...state, editMallSettingsloading: true, }),
    [editMallSettings.SUCCESS]: (state, { payload }) => ({ ...state, editMallSettingsloading: false, }),
    [editMallSettings.FAIL]: (state, { payload }) => ({ ...state, editMallSettingsloading: false, error: payload }),




    [publishPointMall.REQUEST]: (state, { payload }) => ({ ...state, publishloading: true }),
    [publishPointMall.SUCCESS]: (state, { payload }) => ({ ...state, publishloading: false }),
    [publishPointMall.FAIL]: (state, { payload }) => ({ ...state, publishloading: false, error: payload }),



    [hasDraft.REQUEST]: (state, { payload }) => ({ ...state, }),
    [hasDraft.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [hasDraft.FAIL]: (state, { payload }) => ({ ...state, error: payload }),



    [mallResume.REQUEST]: (state, { payload }) => ({ ...state, }),
    [mallResume.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [mallResume.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [loadMallHistoriesById.REQUEST]: (state, { payload }) => ({ ...state, loadmallHistoriesByIdLoading: true }),
    [loadMallHistoriesById.SUCCESS]: (state, { payload }) => ({ ...state, loadmallHistoriesByIdLoading: false, mallHistories: payload }),
    [loadMallHistoriesById.FAIL]: (state, { payload }) => ({ ...state, oadPromotionHistoriesByIdLoading: false, error: payload }),




    [getPresentRules.REQUEST]: (state, { payload }) => ({ ...state, }),
    [getPresentRules.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [getPresentRules.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createOrEditPresentRules.REQUEST]: (state, { payload }) => ({ ...state, }),
    [createOrEditPresentRules.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [createOrEditPresentRules.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [checknext.REQUEST]: (state, { payload }) => ({ ...state, }),
    [checknext.SUCCESS]: (state, { payload }) => ({ ...state, checknextRecord: payload }),
    [checknext.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [getHistory.REQUEST]: (state, { payload }) => ({ ...state, }),
    [getHistory.SUCCESS]: (state, { payload }) => ({ ...state, historyStep1: payload }),
    [getHistory.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },
  initialState
)
export default reducer
