import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './realTime';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const codeNumCompareEpic = actions$ => actions$.pipe(
    ofType(actions.codeNumCompare.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { endDate = '', productId = [], activityId = [], prizeId = [], startDate = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.scanNum;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&promotionId=${activityId.join(',')}&productIds=${productId.join(',')}&prizeId=${prizeId}&startDate=${startDate}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.codeNumCompare.SUCCESS(res.response)),
            catchError(error => of(actions.codeNumCompare.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const codeCountCompareEpic = actions$ => actions$.pipe(
    ofType(actions.codeCountCompare.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { endDate = '', productId = [], activityId = [], prizeId = [], startDate = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.scanCount;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&promotionId=${activityId.join(',')}&productIds=${productId.join(',')}&prizeId=${prizeId}&startDate=${startDate}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.codeCountCompare.SUCCESS(res.response)),
            catchError(error => of(actions.codeCountCompare.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const winnerCountCompareEpic = actions$ => actions$.pipe(
    ofType(actions.winnerCountCompare.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { endDate = '', productId = [], activityId = [], prizeId = [], poolId = '', startDate = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.winCount;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&promotionId=${activityId.join(',')}&poolId=${poolId}&productIds=${productId.join(',')}&prizeId=${prizeId}&startDate=${startDate}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.winnerCountCompare.SUCCESS(res.response)),
            catchError(error => of(actions.winnerCountCompare.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const exchangeCountCompareEpic = actions$ => actions$.pipe(
    ofType(actions.exchangeCountCompare.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { endDate = '', productId = [], activityId = [], prizeId = [], poolId = '', startDate = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.prizeCount;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&promotionId=${activityId.join(',')}&poolId=${poolId}&productIds=${productId.join(',')}&prizeId=${prizeId}&startDate=${startDate}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.exchangeCountCompare.SUCCESS(res.response)),
            catchError(error => of(actions.exchangeCountCompare.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const middlePrizeAmountEpic = actions$ => actions$.pipe(
    ofType(actions.middlePrizeAmount.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { endDate = '', productId = [], activityId = [], prizeId = [], poolId = '', startDate = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.middlePrize;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&promotionId=${activityId.join(',')}&poolId=${poolId}&productIds=${productId.join(',')}&prizeId=${prizeId}&startDate=${startDate}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.middlePrizeAmount.SUCCESS(res.response)),
            catchError(error => of(actions.middlePrizeAmount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const redeemPrizeAmountEpic = actions$ => actions$.pipe(
    ofType(actions.redeemPrizeAmount.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { endDate = '', productId = [], activityId = [], prizeId = [], poolId = '', startDate = '', dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.redeemPrize;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?endDate=${endDate}&promotionId=${activityId.join(',')}&poolId=${poolId}&productIds=${productId.join(',')}&prizeId=${prizeId}&startDate=${startDate}&dataVersion=${dataVersion}`
        }).pipe(
            map(res => actions.redeemPrizeAmount.SUCCESS(res.response)),
            catchError(error => of(actions.redeemPrizeAmount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadRealTimeEchartsEpic = actions$ => actions$.pipe(
    ofType(actions.loadRealTimeEcharts.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const {
            activityId = '',
            productId = '',
            dataVersion = 'NORMAL',
            poolId = '',
            prizeId = '' } = action.payload;
        const url = urls.statistics.realTime.realTimeTrend;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?prizeId=${prizeId}&productIds=${productId}&promotionId=${activityId}&dataVersion=${dataVersion}&poolId=${poolId}`,
        }).pipe(
            map(res => actions.loadRealTimeEcharts.SUCCESS(res.response)),
            catchError(error => of(actions.loadRealTimeEcharts.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const loadMemberIncreaseEchartsEpic = actions$ => actions$.pipe(
    ofType(actions.loadMemberIncreaseEcharts.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const {
            activityId = '',
            productId = '',
            dataVersion = 'NORMAL',
            // prizeId = '',
        } = action.payload;
        const url = urls.statistics.realTime.userGrowthTrend;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?productIds=${productId}&promotionId=${activityId}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadMemberIncreaseEcharts.SUCCESS(res.response)),
            catchError(error => of(actions.loadMemberIncreaseEcharts.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadOverviewEpic = actions$ => actions$.pipe(
    ofType(actions.loadOverview.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.statistics;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadOverview.SUCCESS(res.response)),
            catchError(error => of(actions.loadOverview.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadActivityDistributionEpic = actions$ => actions$.pipe(
    ofType(actions.loadActivityDistribution.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { activityId = [], dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.activityDistribution;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?promotionId=${activityId.join(',')}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadActivityDistribution.SUCCESS(res.response)),
            catchError(error => of(actions.loadActivityDistribution.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadProductTopTenEpic = actions$ => actions$.pipe(
    ofType(actions.loadProductTopTen.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { activityId = [], dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.productTopTen;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?promotionId=${activityId.join(',')}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadProductTopTen.SUCCESS(res.response)),
            catchError(error => of(actions.loadProductTopTen.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
export const loadPrizeTopTenEpic = actions$ => actions$.pipe(
    ofType(actions.loadPrizeTopTen.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { activityId = [], dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.prizeTopTen;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?promotionId=${activityId.join(',')}&dataVersion=${dataVersion}`,
        }).pipe(
            map(res => actions.loadPrizeTopTen.SUCCESS(res.response)),
            catchError(error => of(actions.loadPrizeTopTen.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const poolListDataEpic = actions$ => actions$.pipe(
    ofType(actions.poolListData.REQUEST.toString()),
    mergeMap(action => { actionApi()
        // const { activityId = [], dataVersion = 'NORMAL' } = action.payload;
        const url = urls.statistics.realTime.poollistNew;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}`,
        }).pipe(
            map(res => actions.poolListData.SUCCESS(res.response)),
            catchError(error => of(actions.poolListData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);

export const prizeListDataEpic = actions$ => actions$.pipe(
    ofType(actions.prizeListData.REQUEST.toString()),
    mergeMap(action => { actionApi()
        const { poolId='', versions=''} = action.payload;
        const url = urls.statistics.realTime.prizeListNew;
        return ajax({
            ...ajaxBaseConfig,
            url: `${url}?poolId=${poolId}&versions=${versions}`,
        }).pipe(
            map(res => actions.prizeListData.SUCCESS(res.response)),
            catchError(error => of(actions.prizeListData.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
);
