import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import * as actions from './batchApply';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadApplyUnauditedsEpic = actions$ => actions$.pipe(
  ofType(actions.loadApplyUnauditeds.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchApplys.applyUnauditeds}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadApplyUnauditeds.SUCCESS(res.response)),
      catchError(error => of(actions.loadApplyUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const loadApplyPassesEpic = actions$ => actions$.pipe(
  ofType(actions.loadApplyPasses.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchApplys.applyPasses}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadApplyPasses.SUCCESS(res.response)),
      catchError(error => of(actions.loadApplyPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const loadApplyReturnsEpic = actions$ => actions$.pipe(
  ofType(actions.loadApplyReturns.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchApplys.applyReturns}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadApplyReturns.SUCCESS(res.response)),
      catchError(error => of(actions.loadApplyReturns.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const sendBatchsEpic = actions$ => actions$.pipe(
  ofType(actions.sendBatchs.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.batchApplys.sendBatchs(action.payload),
    }).pipe(
      mergeMap(res => [actions.sendBatchs.SUCCESS(res.response), uiActions.updateSendAgainDialogStatus(false), uiActions.notification('发送成功', 'success')]),
      catchError(error => of(actions.sendBatchs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const loadAuditUnauditedsEpic = actions$ => actions$.pipe(
  ofType(actions.loadAuditUnauditeds.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', auditStart = '', auditEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchApplys.auditUnauditeds}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&auditStart=${auditStart}&auditEnd=${auditEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadAuditUnauditeds.SUCCESS(res.response)),
      catchError(error => of(actions.loadAuditUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const loadAuditPassesEpic = actions$ => actions$.pipe(
  ofType(actions.loadAuditPasses.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.batchApplys.auditPasses}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
    }).pipe(
      map(res => actions.loadAuditPasses.SUCCESS(res.response)),
      catchError(error => of(actions.loadAuditPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const auditEpic = (actions$, store) => actions$.pipe(
  ofType(actions.audit.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: urls.code.batchApplys.audit,
      body: action.payload.auditParams
    }).pipe(
      mergeMap(res => [actions.audit.SUCCESS(res.response), uiActions.updateNotPassDialogStatus(false), actions.loadAuditUnauditeds.REQUEST(action.payload.loadListParams), actions.loadAuditPasses.REQUEST(store.value.batchApply.loadAuditPassesParams)]),
      catchError(error => of(actions.audit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const auditPassEpic = (actions$, store) => actions$.pipe(
  ofType(actions.auditPass.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: urls.code.batchApplys.audit,
      body: action.payload.auditPassParams
    }).pipe(
      mergeMap(res => [actions.auditPass.SUCCESS(res.response), uiActions.updateAuditPassDialogStatus(false), actions.loadAuditUnauditeds.REQUEST(action.payload.loadListParams), actions.loadAuditPasses.REQUEST(store.value.batchApply.loadAuditPassesParams)]),
      catchError(error => of(actions.auditPass.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

export const viewAuditEpic = actions$ => actions$.pipe(
  ofType(actions.viewAudit.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.batchApplys.viewAudit(action.payload),
    }).pipe(
      map(res => actions.viewAudit.SUCCESS(res.response)),
      catchError(error => of(actions.viewAudit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);

// 创建申请
export const batchApplysEpic = actions$ => actions$.pipe(
  ofType(actions.batchApplys.REQUEST.toString()),
  mergeMap(action =>
    ajax({
      ...ajaxBaseConfig,
      method: action.payload.id ? 'put' : 'post',
      body: action.payload,
      url: urls.code.batchApplys.batchApplys
    }).pipe(
      map(res => actions.batchApplys.SUCCESS(res.status)),
      catchError(error => of(actions.batchApplys.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  )
);

export const viewAuditLogEpic = actions$ => actions$.pipe(
  ofType(actions.viewAuditLog.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.code.batchApplys.viewAuditLog(action.payload),
    }).pipe(
      map(res => actions.viewAuditLog.SUCCESS(res.response)),
      catchError(error => of(actions.viewAuditLog.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);
