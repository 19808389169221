import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './miniProgramTesters';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 小程序
 */
// 获取已设置的二维码规则
export const testersEpic = actions$ => actions$.pipe(
  ofType(actions.testers.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: urls.wechat.miniProgramTesters.testers,
      method: action.payload.method || 'post',
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.testers.SUCCESS(res.response),uiActions.notification(action.payload.method ? '删除成功' : '添加成功', 'success')]),
      catchError(error => of(actions.testers.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
