import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'marketing';

export const loadList = fasterActions(moduleName, 'loadList');
export const loadOverview = fasterActions(moduleName, 'loadOverview');

const initialState = {
    loading: false,
    loaded: false,

    list: {},
};

const reducer = handleActions({
    [loadList.REQUEST]: (state, { payload }) => ({ ...state,  loading: true }),
    [loadList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, listLoaded: true, list: payload }),
    [loadList.FAIL]: (state, { payload }) => ({ ...state, loading: false, listLoaded: false, list: {}, error: payload }),
}, initialState);

export default reducer;


