import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'precinct';

//字段分配
export const precinctList = fasterActions(moduleName, 'precinctList');
export const precinctAdd = fasterActions(moduleName, 'precinctAdd');
export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);
export const changeStatus = fasterActions(moduleName, 'changeStatus');
export const precinctSelection = fasterActions(moduleName, 'precinctSelection');


const initialState = {
    dictPaging: {
        page: 0,
        size: 20,
        name:'',
        status:''
    },
    precinctData:[]
}

const reducer = handleActions(
    {
        [changeDictPaging]: (state, { payload }) => {
            return { ...state, dictPaging: { ...payload} }
        },

        [precinctList.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [precinctList.SUCCESS]: (state, { payload }) => ({ ...state, precinctData: payload, loading: false }),
        [precinctList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [precinctAdd.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [precinctAdd.SUCCESS]: (state, { payload }) => ({ ...state, precinctData: payload, loading: false }),
        [precinctAdd.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [changeStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [changeStatus.SUCCESS]: (state, { payload }) => ({ ...state, precinctData: payload, loading: false }),
        [changeStatus.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [precinctSelection.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [precinctSelection.SUCCESS]: (state, { payload }) => ({ ...state, precinctSelection: payload.content||[], loading: false }),
        [precinctSelection.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
        
    },
    initialState
);

export default reducer;
