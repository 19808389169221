import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './domainConfigs';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const domainConfigsByEseIdEpic = actions$ => actions$.pipe(
  ofType(actions.domainConfigsByEseId.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.domainConfigs.domainConfigsUrl}/allots?isPage=false`,
      // url: `${urls.code.domainConfigs.domainConfigsByEseId}`
    }).pipe(
      map(res => actions.domainConfigsByEseId.SUCCESS(res.response)),
      catchError(error => of(actions.domainConfigsByEseId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
//list
export const loaddomainConfigsListEpic = actions$ => actions$.pipe(
  ofType(actions.loaddomainConfigsList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc', eseId } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.code.domainConfigs.domainConfigsUrl}/${eseId}?name=${name}&page=${page}&size=${size}&sort=${sort}`,
      }).pipe(
        map(res => actions.loaddomainConfigsList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loaddomainConfigsList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//able

export const ableDomainConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.ableDomainConfigs.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.code.domainConfigs.domainConfigsUrl}/${data.id}/status?status=${data.status}`,
        //  body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableDomainConfigs.SUCCESS(res)
        }),
        catchError(error => of(actions.ableDomainConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//add

export const addDomainConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.addDomainConfigs.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.code.domainConfigs.domainConfigsUrl}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.addDomainConfigs.SUCCESS(res)
        }),
        catchError(error => of(actions.addDomainConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//add

export const editDomainConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.editDomainConfigs.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.code.domainConfigs.domainConfigsUrl}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editDomainConfigs.SUCCESS(res)
        }),
        catchError(error => of(actions.editDomainConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
export const domainConfigsByCodeSourceTypeEpic = actions$ => actions$.pipe(
  ofType(actions.domainConfigsByCodeSourceType.REQUEST.toString()),
  mergeMap(action => {
    const { codeSourceType, status = 'ENABLE' } = action.payload;
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      // url: `${urls.code.domainConfigs.domainConfigsByCodeSource}?codeSourceType=${codeSourceType}&status=${status}`
      url: `${urls.code.domainConfigs.domainConfigsUrl}/allots?isPage=false&codeSourceType=${codeSourceType}&status=${status}`
    }).pipe(
      map(res => actions.domainConfigsByCodeSourceType.SUCCESS(res.response)),
      catchError(error => of(actions.domainConfigsByCodeSourceType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const domainConfigsByCodeBankEpic = actions$ => actions$.pipe(
  ofType(actions.domainConfigsByCodeBank.REQUEST.toString()),
  mergeMap(action => {
    const { batchname = "", name = "", page = 0, size = 20, sort = 'id,desc', domainQueryType = '' } = action.payload;
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.code.domainConfigs.domainConfigsByCodeBank}/${batchname}?domainQueryType=${domainQueryType}&queryStr=${name}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.domainConfigsByCodeBank.SUCCESS(res.response)),
      catchError(error => of(actions.domainConfigsByCodeBank.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);