export const getErrorMessageByCode = (code, initialMessage) => {
  switch (code) {
    case 10001:
      window.parent.postMessage('logout', '*');
      return '登录过期，请重新登录'
    case 10004:
      window.parent.postMessage('logout', '*');
      return '腾讯认证失败';
    case 50004:
      return '操作失败，当前页面他人正在编辑，请刷新页面';
    default:
      return initialMessage || '';
  }
}

