import { handleActions } from "redux-actions"
import { fasterActions } from "../../utils"

const moduleName = "messageTemplate"

// 这个作为模板进行参考
// export const search = fasterActions(moduleName, 'search');/api-gateway/wechat-msg/v3/api-docs
export const messageTemplateList = fasterActions(moduleName, 'messageTemplateList');
export const msgTemplateModify = fasterActions(moduleName, 'msgTemplateModify');
export const msgTemplateById = fasterActions(moduleName, 'msgTemplateById');
export const deleteMsgTemplate = fasterActions(moduleName, 'deleteMsgTemplate');
export const getMsgTemplateListByAppId = fasterActions(moduleName, 'getMsgTemplateListByAppId');
export const getSubscribeCountById = fasterActions(moduleName, 'getSubscribeCountById');
export const getSubscribeBtnListByAppId = fasterActions(moduleName, 'getSubscribeBtnListByAppId');
export const getMsgListNoPageable = fasterActions(moduleName, 'getMsgListNoPageable');
export const getWechatTemplateList = fasterActions(moduleName, 'getWechatTemplateList');
const initialState = {
  perPageCountList: [20, 50, 100], // 每页大小候选, 
}
const reducer = handleActions(
  {
    // 这三个作为模板参考
    // [search.REQUEST]: (state, { payload }) => ({ ...state, }),
    // [search.SUCCESS]: (state, { payload }) => ({ ...state, list: payload }),
    // [search.FAIL]: (state, { payload }) => ({ ...state, codesError: payload }),

    // 获取消息管理列表
    [messageTemplateList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [messageTemplateList.SUCCESS]: (state, { payload }) => ({ ...state, loading: false, messageTemplateList: payload }),
    [messageTemplateList.FAIL]: (state, { payload }) => ({ ...state, loading: false, error: payload }),

    [msgTemplateModify.REQUEST]: (state, { payload }) => ({ ...state }),
    [msgTemplateModify.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [msgTemplateModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [msgTemplateById.REQUEST]: (state, { payload }) => ({ ...state }),
    [msgTemplateById.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [msgTemplateById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [deleteMsgTemplate.REQUEST]: (state, { payload }) => ({ ...state }),
    [deleteMsgTemplate.SUCCESS]: (state, { payload }) => ({ ...state, payload }),
    [deleteMsgTemplate.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getMsgTemplateListByAppId.REQUEST]: (state, { payload }) => ({ ...state }),
    [getMsgTemplateListByAppId.SUCCESS]: (state, { payload }) => { 
      let msgTemplateList;
      if(payload && payload.length > 0) {
        if (payload[0].title) {
          msgTemplateList = payload.map(item => { 
            return { text: item.title, value: item.priTmplId, type: item.type}
          });
        }
      } else {
        msgTemplateList = []
      }
      
      return ({ ...state, payload, msgTemplateList: msgTemplateList || state.msgTemplateList || [] })
    },
    [getMsgTemplateListByAppId.FAIL]: (state, { payload }) => ({ ...state, error: payload,msgTemplateList:[] }),

    [getSubscribeCountById.REQUEST]: (state, { payload }) => ({ ...state }),
    [getSubscribeCountById.SUCCESS]: (state, { payload }) => ({ ...state, subscribeCountInfo: payload }),
    [getSubscribeCountById.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getSubscribeBtnListByAppId.REQUEST]: (state, { payload }) => ({ ...state }),
    [getSubscribeBtnListByAppId.SUCCESS]: (state, { payload }) => {
      let subscribeBtnList = [];
      if (payload.content) {
        subscribeBtnList = payload.content.map(item => {
          return { text: item.subscribeName, value: item.id }
        });
      }
      return ({ ...state, payload, subscribeBtnList: subscribeBtnList || state.subscribeBtnList || [] })
    },
    [getSubscribeBtnListByAppId.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getMsgListNoPageable.REQUEST]: (state, { payload }) => ({ ...state }),
    [getMsgListNoPageable.SUCCESS]: (state, { payload }) => {
      let templateList = [];
      if (payload) {
        templateList = payload.map(item => {
          return { text: item.msgName, value: item.id, wechatTemplateId: item.wechatTemplateId}
        });
      }
      return ({ ...state, payload, templateList: templateList || state.templateList || []  })
    },
    [getMsgListNoPageable.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [getWechatTemplateList.REQUEST]: (state, { payload }) => ({ ...state }),
    [getWechatTemplateList.SUCCESS]: (state, { payload }) => { 
      let wechatTemplateList = JSON.parse(JSON.stringify(payload))
      wechatTemplateList.map(item => { 
        item.value = ''
      })
      return { ...state, wechatTemplateList: payload }
    },
    [getWechatTemplateList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
  },
  initialState 
)

export default reducer