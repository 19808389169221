import * as React from "react";

const SvgEdit = (props) => (
  <svg width={17} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.581.009A5.878 5.878 0 0 1 14.45 5.88a.622.622 0 0 1-.624.624.622.622 0 0 1-.624-.624 4.628 4.628 0 0 0-4.621-4.624A4.625 4.625 0 0 0 3.96 5.875v1.868h11.448c.766 0 1.395.612 1.392 1.375v9.472c0 .766-.63 1.398-1.395 1.398H1.397A1.391 1.391 0 0 1 .019 18.59V9.118c0-.763.612-1.375 1.378-1.375h1.315V5.875c0-1.568.61-3.04 1.718-4.148A5.83 5.83 0 0 1 8.581.009Zm6.833 8.982H1.405a.14.14 0 0 0-.139.138v9.476a.14.14 0 0 0 .14.139h14.01a.14.14 0 0 0 .14-.14h-.003V9.13a.14.14 0 0 0-.14-.138ZM8.61 12.34l1.32 1.319-3.22 3.22H5.39v-1.32l3.22-3.22Zm1.402-1.488 1.404 1.404-1.003 1.003-1.404-1.404 1.003-1.003Z"
      fill="#222429"
      fillRule="nonzero"
    />
  </svg>
);

export default SvgEdit;
