export const handSortOrder = (order) => {
  switch (order) {
    case null: case undefined:
      return 'desc';
    case 'desc':
      return 'asc';
    case 'asc':
      return 'desc';
    default:
      return null;
  }
}

export const getNowTabs = (nowRouter, lvThreeMenuOfLvTwo) => {
    const object = lvThreeMenuOfLvTwo;
    let result = [];
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const element = object[key];
        element.forEach(v => {
          if (v.url === nowRouter) {
            result = element;
          }
        });
      }
    }
    return result;
} 

export const hasChildrenMenu = (children) => {
  let result = false;
  children.forEach(element => {
    if (element.permissionType === 'MENU') {
      result = true
    }
  });
  return result;
}

export const getChildrenMenu = (children) => {
  const result = children.filter(v => v.permissionType === 'MENU');
  return result;
}