import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './prizeCardTypes';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 卡片类型
 */
// 卡片下拉列表
export const cardTypeAllEpic = actions$ => actions$.pipe(
  ofType(actions.loadCardTypeAll.REQUEST.toString()),
  mergeMap(action => { actionApi()
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.promotion.prizeCardTypes.prizeCardTypes }`,
      }).pipe(
        map(res => {
          return actions.loadCardTypeAll.SUCCESS(res.response.content);
        }),
        catchError(error => of (actions.loadCardTypeAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 卡片详情
export const cardTypeDetailEpic = actions$ => actions$.pipe(
  ofType(actions.loadCardTypeDetail.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.prizeCardTypes.prizeCardTypes}/${id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response);
          return actions.loadCardTypeDetail.SUCCESS(res.response);
        }),
        catchError(error => of (actions.loadCardTypeDetail.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 卡片创建
export const cardTypeCreateEpic = actions$ => actions$.pipe(
  ofType(actions.cardTypeCreate.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.promotion.prizeCardTypes.prizeCardTypes }`,
        method: 'POST',
        body: JSON.stringify(data)
      }).pipe(
        concatMap(res => {
          callback && callback(res.response);
          return [actions.cardTypeCreate.SUCCESS(res.response), actions.loadCardTypeAll.REQUEST(action.payload)];
        }),
        catchError(error => of (actions.cardTypeCreate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
// 卡片修改
export const cardEditEpic = actions$ => actions$.pipe(
  ofType(actions.cardTypeEdit.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${ urls.promotion.prizeCardTypes.prizeCardTypes }`,
        method: 'PUT',
        body: JSON.stringify(data)
      }).pipe(
        concatMap(res => {
          callback && callback(res.response);
          // return actions.cardTypeEdit.SUCCESS(res.response);
          return [actions.cardTypeEdit.SUCCESS(res.response), actions.loadCardTypeAll.REQUEST(action.payload)];
        }),
        catchError(error => of (actions.cardTypeEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);