import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './departments';
import { ajaxBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 小程序
 */
// 部门查询（分页查询）
export const departmentsEpic = actions$ => actions$.pipe(
  ofType(actions.departments.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { name = '', page = 0, size = 20, sort } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.departments.departments}?name=${name}&page=${page}&size=${size}&sort=${sort}`,
    }).pipe(
      map(res => actions.departments.SUCCESS(res.response)),
      catchError(error => of(actions.departments.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 部门查询（树查询）
export const departmentsTreeEpic = actions$ => actions$.pipe(
  ofType(actions.departmentsTree.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: urls.data.departments.departmentsTree
    }).pipe(
      map(res => actions.departmentsTree.SUCCESS(res.response)),
      catchError(error => of(actions.departmentsTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
    }
  )
);

// 部门添加/修改/删除
export const modifyDepartmentsEpic = actions$ => actions$.pipe(
  ofType(actions.modifyDepartments.REQUEST.toString()),
  mergeMap(action => { actionApi()
    let url = urls.data.departments.departments;
    if (action.payload.method == 'DELETE') {
      url += `/${action.payload.id}`;
    }
    return ajax({
      ...ajaxBaseConfig,
      url,
      method: action.payload.method,
      body: action.payload
    }).pipe(
      map(res => actions.modifyDepartments.SUCCESS(res.status)),
      catchError(error => of(actions.modifyDepartments.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }

  )
);

//禁用/启用
export const enableDepartmentEpic = actions$ => actions$.pipe(
  ofType(actions.enableDepartment.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { id, status } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.data.departments.enableDepartment(id, status),
      method: 'PATCH',
    }).pipe(
      map(res => actions.enableDepartment.SUCCESS(res.status)),
      catchError(error => of(actions.enableDepartment.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }

  )
);


// 部门选框
export const departmentSelectionsEpic = actions$ => actions$.pipe(
  ofType(actions.departmentSelections.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
     return ajax({
      ...ajaxBaseConfig,
      url: action.payload && action.payload.status ? `${urls.data.departments.selections}?status=${action.payload.status}` : `${urls.data.departments.selections}`
    }).pipe(
      map(res => actions.departmentSelections.SUCCESS(res.response)),
      catchError(error => of(actions.departmentSelections.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

// 部门树
export const loadDepartmentTreeEpic = actions$ => actions$.pipe(
  ofType(actions.loadDepartmentTree.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
     return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.departments.departments}/tree`
    }).pipe(
      map(res => actions.loadDepartmentTree.SUCCESS(res.response)),
      catchError(error => of(actions.loadDepartmentTree.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )}
  )
);
