import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codedb';
import { ajaxBaseConfig, actionApi } from '../../utils';

export const loadCodedbEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodedb.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '',  page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.codedb.codedb}?query=${idOrName}&page=${page}&size=${size}&sort=${sort}` 
    }).pipe(
      map(res => actions.loadCodedb.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodedb.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const loadCodedbSelectEpic = actions$ => actions$.pipe(
  ofType(actions.loadCodedbSelect.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { idOrName = '',  page = 0, size = 100, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.codedb.codedb}/selections?query=${idOrName}&page=${page}&size=${size}&sort=${sort}` 
    }).pipe(
      map(res => actions.loadCodedbSelect.SUCCESS(res.response)),
      catchError(error => of(actions.loadCodedbSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


export const createCodedbEpic = actions$ => actions$.pipe(
  ofType(actions.createCodedb.REQUEST.toString()),
  mergeMap(action => { actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      body: data,
      url: `${urls.data.codedb.codedb}`
    }).pipe(
      map(res => {
        // let array = [
        //   res.response,
        //   ...state$.value.plan.planAllList,

        // ];
        callback && callback()
        return actions.createCodedb.SUCCESS(res)
      }),
      catchError(error => of(actions.createCodedb.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
  );
  export const editCodedbEpic = actions$ => actions$.pipe(
    ofType(actions.editCodedb.REQUEST.toString()),
    mergeMap(action => { actionApi()
      const { data, callback } = action.payload;
      return ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        body: data,
        url: `${urls.data.codedb.codedb}`
      }).pipe(
        map(res => {
          // let array = [
          //   res.response,
          //   ...state$.value.plan.planAllList,
  
          // ];
          callback && callback()
          return actions.editCodedb.SUCCESS(res)
        }),
        catchError(error => of(actions.editCodedb.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    }
    )
    );


   // 码状态--stateCode

// 码状态列表 // 根据企业 分页模糊查询字段code/name
  export const stateTableCodeEpic = actions$ => actions$.pipe(
    ofType(actions.stateTableCode.REQUEST.toString()),
    mergeMap(action => { actionApi()
      const { data, callback } = action.payload;
      return ajax({
        ...ajaxBaseConfig,
        url: urls.code.stateTable.codeTable(data)
      }).pipe(
        map(res => {
          // let array = [
          //   res.response,
          //   ...state$.value.plan.planAllList,
  
          // ];
          callback && callback()
          return actions.stateTableCode.SUCCESS(res.response)
        }),
        catchError(error => of(actions.stateTableCode.FAIL(error.xhr.response))
        )
      )
    }
    )
  );
  // 创建状态
  export const stateIncreaseEpic = actions$ => actions$.pipe(
    ofType(actions.stateIncrease.REQUEST.toString()),
    mergeMap(action => { actionApi()
      const { data, callback } = action.payload;
      return ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        body: data,
        url: `${urls.code.stateTable.stateIncreaseCode}`
      }).pipe(
        map(res => {
          // let array = [
          //   res.response,
          //   ...state$.value.plan.planAllList,
  
          // ];
          callback && callback()
          return actions.stateIncrease.SUCCESS(res)
        }),
        catchError(error => of(actions.stateIncrease.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
        )
      )
    }
    )
    );
    // 修改状态
    export const stateEditEpic = actions$ => actions$.pipe(
      ofType(actions.stateEdit.REQUEST.toString()),
      mergeMap(action => { actionApi()
        const { data, callback } = action.payload;
        return ajax({
          ...ajaxBaseConfig,
          method: 'PUT',
          body: data,
          url: `${urls.code.stateTable.stateIncreaseCode}`
        }).pipe(
          map(res => {
            // let array = [
            //   res.response,
            //   ...state$.value.plan.planAllList,
    
            // ];
            callback && callback()
            return actions.stateEdit.SUCCESS(res)
          }),
          catchError(error => of(actions.stateEdit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
          )
        )
      }
      )
      );


// export const stateTableCodeEpic = actions$ => actions$.pipe(
//   ofType(actions.stateTableCode.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { data, callback } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'GET',
//       body: data,
//       url: `${urls.code.stateTable.codeTable}`
//     }).pipe(
//       map(res => {
//         // let array = [
//         //   res.response,
//         //   ...state$.value.plan.planAllList,

//         // ];
//         callback && callback()
//         return actions.stateTableCode.SUCCESS(res.response)
//       }),
//       catchError(error => of(actions.stateTableCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
//       )
//     )
//   }
//   )
// );


// export const seqnumChangeEpic = actions$ => actions$.pipe(
//   ofType(actions.seqnumChange.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { data, callback } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'PATCH',
//       body: data,
//       url: `${urls.code.batchInformations.seqnumEnter}`
//     }).pipe(
//       map(res => {
//         // let array = [
//         //   res.response,
//         //   ...state$.value.plan.planAllList,

//         // ];
//         callback && callback()
//         return actions.seqnumChange.SUCCESS(res)
//       }),
//       catchError(error => of(actions.seqnumChange.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
//       )
//     )
//   }
//   )
// );




// export const seqnumActiveEpic = actions$ => actions$.pipe(
//   ofType(actions.seqnumActive.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { data, callback } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'PATCH',
//       body: data,
//       url: `${urls.code.batchInformations.seqnumActive}`
//     }).pipe(
//       map(res => {
//         // let array = [
//         //   res.response,
//         //   ...state$.value.plan.planAllList,

//         // ];
//         callback && callback()
//         return actions.seqnumActive.SUCCESS(res)
//       }),
//       catchError(error => of(actions.seqnumActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
//       )
//     )
//   }
//   )
// );


//   export const checkEpic = actions$ => actions$.pipe(
//     ofType(actions.check.REQUEST.toString()),
//     mergeMap(action => { actionApi()
//       const { data, callback } = action.payload;
//       return ajax({
//         ...ajaxBaseConfig,
//         method: 'GET',
//         body: data,
//         url: urls.code.batchInformations.check(data)
//       }).pipe(
//         map(res => {
//           // let array = [
//           //   res.response,
//           //   ...state$.value.plan.planAllList,
  
//           // ];
//           callback && callback()
//           return actions.check.SUCCESS(res)
//         }),
//         catchError(error => of(actions.check.FAIL(error.xhr.response)) //, uiActions.notification(error.xhr.response)
//         )
//       )
//     }
//     )
//   );
//   export const seqnumCheckEpic = actions$ => actions$.pipe(
//     ofType(actions.seqnumCheck.REQUEST.toString()),
//     mergeMap(action => { actionApi()
//       const { data, callback } = action.payload;
//       return ajax({
//         ...ajaxBaseConfig,
//         method: 'GET',
//         body: data,
//         url: urls.code.batchInformations.seqnumCheck(data)
//       }).pipe(
//         map(res => {
//           // let array = [
//           //   res.response,
//           //   ...state$.value.plan.planAllList,
  
//           // ];
//           callback && callback(res.response)
//           return actions.seqnumCheck.SUCCESS( )
//         }),
//         catchError(error =>{  callback && callback(error.xhr.response) ;return of(actions.seqnumCheck.FAIL(error.xhr.response))} //, uiActions.notification(error.xhr.response)
//         )
//       )
//     }
//     )
//   );

  
//   export const seqnumDeleteEpic = (actions$, state$) => actions$.pipe(
//     ofType(actions.seqnumDelete.REQUEST),
//     mergeMap(action => {
//         actionApi()
//         const { data, callback } = action.payload;
//         return ajax({
//             ...ajaxBaseConfig,
//             method: 'DELETE',
//             url: `${urls.code.batchInformations.delete}/${data.id}`,
//         }).pipe(
//             map(res => {
//                 callback && callback()
//                 return actions.seqnumDelete.SUCCESS(res.response)
//             }),
//             catchError(error => of(actions.seqnumDelete.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//         )
//     })
// )
// // export const loadApplyReturnsEpic = actions$ => actions$.pipe(
//   ofType(actions.loadApplyReturns.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.applyReturns}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}`
//     }).pipe(
//       map(res => actions.loadApplyReturns.SUCCESS(res.response)),
//       catchError(error => of(actions.loadApplyReturns.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const sendBatchsEpic = actions$ => actions$.pipe(
//   ofType(actions.sendBatchs.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     return ajax({
//       ...ajaxBaseConfig,
//       url: urls.code.batchApplys.sendBatchs(action.payload),
//     }).pipe(
//       mergeMap(res => actions.sendBatchs.SUCCESS(res.response)),
//       catchError(error => of(actions.sendBatchs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const loadAuditUnauditedsEpic = actions$ => actions$.pipe(
//   ofType(actions.loadAuditUnauditeds.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', auditStart = '', auditEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.auditUnauditeds}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&auditStart=${auditStart}&auditEnd=${auditEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
//     }).pipe(
//       map(res => actions.loadAuditUnauditeds.SUCCESS(res.response)),
//       catchError(error => of(actions.loadAuditUnauditeds.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const loadAuditPassesEpic = actions$ => actions$.pipe(
//   ofType(actions.loadAuditPasses.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     const { idOrName = '', applyStart = '', applyEnd = '', factoryIds = '', page = 0, size = 20, sort = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.code.batchApplys.auditPasses}?idOrName=${idOrName}&applyStart=${applyStart}&applyEnd=${applyEnd}&factoryIds=${factoryIds}&page=${page}&size=${size}&sort=${sort}` //
//     }).pipe(
//       map(res => actions.loadAuditPasses.SUCCESS(res.response)),
//       catchError(error => of(actions.loadAuditPasses.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

// export const auditEpic = actions$ => actions$.pipe(
//   ofType(actions.audit.REQUEST.toString()),
//   mergeMap(action => { actionApi()
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'POST',
//       url: urls.code.batchApplys.audit,
//       body: action.payload
//     }).pipe(
//       mergeMap(res => [actions.audit.SUCCESS(res.response), uiActions.updateNotPassDialogStatus(false)]),
//       catchError(error => of(actions.audit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )}
//   )
// );

