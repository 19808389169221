import { handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'screenData';


export const loadPoolList = fasterActions(moduleName, 'loadPoolList');
export const putPool = fasterActions(moduleName, 'putPool');
export const syncPool = fasterActions(moduleName, 'syncPool');
export const loadPoolSelect = fasterActions(moduleName, 'loadPoolSelect');

export const loadBgList = fasterActions(moduleName, 'loadBgList');
export const addBg = fasterActions(moduleName, 'addBg');
export const putBg = fasterActions(moduleName, 'putBg');
export const delBg = fasterActions(moduleName, 'delBg');
export const loadBgSelect = fasterActions(moduleName, 'loadBgSelect');

export const loadAwardList = fasterActions(moduleName, 'loadAwardList');
export const addAward = fasterActions(moduleName, 'addAward');
export const putAward = fasterActions(moduleName, 'putAward');
export const delAward = fasterActions(moduleName, 'delAward');
export const loadAwardSelect = fasterActions(moduleName, 'loadAwardSelect');

export const loadOrderList = fasterActions(moduleName, 'loadOrderList');
export const loadOrderById = fasterActions(moduleName, 'loadOrderById');
export const loadOrderByNo = fasterActions(moduleName, 'loadOrderByNo');
export const addOrder = fasterActions(moduleName, 'addOrder');
export const putOrder = fasterActions(moduleName, 'putOrder');
export const delOrder = fasterActions(moduleName, 'delOrder');
export const loadOrderSelect = fasterActions(moduleName, 'loadOrderSelect');
export const loadOrderTasteSelect = fasterActions(moduleName, 'loadOrderTasteSelect');



export const loadActiveList = fasterActions(moduleName, 'loadActiveList');
export const loadActiveById = fasterActions(moduleName, 'loadActiveById');
export const addActive = fasterActions(moduleName, 'addActive');
export const editActive = fasterActions(moduleName, 'editActive');
export const delActive = fasterActions(moduleName, 'delActive');
export const publishActive = fasterActions(moduleName, 'publishActive');
export const disabledOrEnableActive = fasterActions(moduleName, 'disabledOrEnableActive');
export const confirmActive = fasterActions(moduleName, 'confirmActive');

export const loadActiveHistoriesById = fasterActions(moduleName, 'loadActiveHistoriesById');
export const getActiveHistory = fasterActions(moduleName, 'getActiveHistory');
export const getActiveHistoryWin = fasterActions(moduleName, 'getActiveHistoryWin');

export const initActiveById = fasterActions(moduleName, 'initActiveById');

export const loadParticipantList = fasterActions(moduleName, 'loadParticipantList');
export const loadParticipantById = fasterActions(moduleName, 'loadParticipantById');
export const addParticipant = fasterActions(moduleName, 'addParticipant');
export const editParticipant = fasterActions(moduleName, 'editParticipant');
export const delParticipant = fasterActions(moduleName, 'delParticipant');
export const loadParticipantSelect = fasterActions(moduleName, 'loadParticipantSelect');
export const emptyParticipant = fasterActions(moduleName, 'emptyParticipant');
export const sureParticipant = fasterActions(moduleName, 'sureParticipant');
export const importParticipant = fasterActions(moduleName, 'importParticipant');

export const loadInAwardList = fasterActions(moduleName, 'loadInAwardList');
export const addInAward = fasterActions(moduleName, 'addInAward');
export const editInAward = fasterActions(moduleName, 'editInAward');
export const delInAward = fasterActions(moduleName, 'delInAward');
export const disabledOrEnableInActive = fasterActions(moduleName, 'disabledOrEnableInActive');
export const appointInAward = fasterActions(moduleName, 'appointInAward');

export const loadLotteryById = fasterActions(moduleName, 'loadLotteryById');
export const loadLotteryParticipant = fasterActions(moduleName, 'loadLotteryParticipant');
export const loadLotteryWin = fasterActions(moduleName, 'loadLotteryWin');
export const simulateLottery = fasterActions(moduleName, 'simulateLottery');
export const trueLottery = fasterActions(moduleName, 'trueLottery');
export const loadLotteryByRound = fasterActions(moduleName, 'loadLotteryByRound');



const initialPoolState = {
    loadPoolListLoading: false,

    loadBgListLoading: false,

    loadAwardListLoading: false,

    loadOrderListLoading: false,

    loadActiveListLoading: false,

    loadParticipantListLoading: false,

    loadInAwardListLoading: false,

    loadLotteryWinLoading: false,
}

const reducer = handleActions(
    {
        [loadPoolList.REQUEST]: (state, { payload }) => ({ ...state, loadPoolListLoading: true }),
        [loadPoolList.SUCCESS]: (state, { payload }) => ({ ...state, loadPoolListLoading: false, poolList: payload }),
        [loadPoolList.FAIL]: (state, { payload }) => ({ ...state, loadPoolListLoading: false, error: payload }),
        [putPool.REQUEST]: (state, { payload }) => ({ ...state, }),
        [putPool.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [putPool.FAIL]: (state, { payload }) => ({ ...state, }),
        [syncPool.REQUEST]: (state, { payload }) => ({ ...state, }),
        [syncPool.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [syncPool.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadPoolSelect.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadPoolSelect.SUCCESS]: (state, { payload }) => ({ ...state, poolSelect: payload }),
        [loadPoolSelect.FAIL]: (state, { payload }) => ({ ...state, }),


        [loadBgList.REQUEST]: (state, { payload }) => ({ ...state, loadBgListLoading: true }),
        [loadBgList.SUCCESS]: (state, { payload }) => ({ ...state, loadBgListLoading: false, bgList: payload }),
        [loadBgList.FAIL]: (state, { payload }) => ({ ...state, loadBgListLoading: false, error: payload }),
        [addBg.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addBg.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addBg.FAIL]: (state, { payload }) => ({ ...state, }),
        [putBg.REQUEST]: (state, { payload }) => ({ ...state, }),
        [putBg.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [putBg.FAIL]: (state, { payload }) => ({ ...state, }),
        [delBg.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delBg.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delBg.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadBgSelect.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadBgSelect.SUCCESS]: (state, { payload }) => ({ ...state, bgSelect: payload }),
        [loadBgSelect.FAIL]: (state, { payload }) => ({ ...state, }),


        [loadAwardList.REQUEST]: (state, { payload }) => ({ ...state, loadAwardListLoading: true }),
        [loadAwardList.SUCCESS]: (state, { payload }) => ({ ...state, loadAwardListLoading: false, awardList: payload }),
        [loadAwardList.FAIL]: (state, { payload }) => ({ ...state, loadAwardListLoading: false, error: payload }),
        [addAward.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addAward.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addAward.FAIL]: (state, { payload }) => ({ ...state, }),
        [putAward.REQUEST]: (state, { payload }) => ({ ...state, }),
        [putAward.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [putAward.FAIL]: (state, { payload }) => ({ ...state, }),
        [delAward.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delAward.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delAward.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadAwardSelect.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadAwardSelect.SUCCESS]: (state, { payload }) => ({ ...state, awardSelect: payload }),
        [loadAwardSelect.FAIL]: (state, { payload }) => ({ ...state, }),


        [loadOrderList.REQUEST]: (state, { payload }) => ({ ...state, loadOrderListLoading: true }),
        [loadOrderList.SUCCESS]: (state, { payload }) => ({ ...state, loadOrderListLoading: false, orderList: payload }),
        [loadOrderList.FAIL]: (state, { payload }) => ({ ...state, loadOrderListLoading: false, error: payload }),
        [loadOrderById.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadOrderById.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [loadOrderById.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadOrderByNo.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadOrderByNo.SUCCESS]: (state, { payload }) => ({ ...state, orderByNo: payload }),
        [loadOrderByNo.FAIL]: (state, { payload }) => ({ ...state, }),
        [addOrder.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addOrder.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addOrder.FAIL]: (state, { payload }) => ({ ...state, }),
        [putOrder.REQUEST]: (state, { payload }) => ({ ...state, }),
        [putOrder.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [putOrder.FAIL]: (state, { payload }) => ({ ...state, }),
        [delOrder.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delOrder.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delOrder.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadOrderSelect.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadOrderSelect.SUCCESS]: (state, { payload }) => ({ ...state, orderSelect: payload }),
        [loadOrderSelect.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadOrderTasteSelect.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadOrderTasteSelect.SUCCESS]: (state, { payload }) => ({ ...state, orderTasteSelect: payload?.content || [] }),
        [loadOrderTasteSelect.FAIL]: (state, { payload }) => ({ ...state, }),




        [loadActiveList.REQUEST]: (state, { payload }) => ({ ...state, loadActiveListLoading: true }),
        [loadActiveList.SUCCESS]: (state, { payload }) => ({ ...state, loadActiveListLoading: false, activeList: payload }),
        [loadActiveList.FAIL]: (state, { payload }) => ({ ...state, loadActiveListLoading: false, error: payload }),
        [loadActiveById.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadActiveById.SUCCESS]: (state, { payload }) => ({ ...state, activeById: payload }),
        [loadActiveById.FAIL]: (state, { payload }) => ({ ...state, }),
        [addActive.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addActive.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addActive.FAIL]: (state, { payload }) => ({ ...state, }),
        [editActive.REQUEST]: (state, { payload }) => ({ ...state, }),
        [editActive.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [editActive.FAIL]: (state, { payload }) => ({ ...state, }),
        [delActive.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delActive.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delActive.FAIL]: (state, { payload }) => ({ ...state, }),
        [publishActive.REQUEST]: (state, { payload }) => ({ ...state, publishActiveLoading: true, }),
        [publishActive.SUCCESS]: (state, { payload }) => ({ ...state, publishActiveLoading: false, }),
        [publishActive.FAIL]: (state, { payload }) => ({ ...state, publishActiveLoading: false, }),
        [disabledOrEnableActive.REQUEST]: (state, { payload }) => ({ ...state, }),
        [disabledOrEnableActive.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [disabledOrEnableActive.FAIL]: (state, { payload }) => ({ ...state, }),
        [confirmActive.REQUEST]: (state, { payload }) => ({ ...state, }),
        [confirmActive.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [confirmActive.FAIL]: (state, { payload }) => ({ ...state, }),


        [loadActiveHistoriesById.REQUEST]: (state, { payload }) => ({ ...state, loadActiveHistoriesByIdLoading: true }),
        [loadActiveHistoriesById.SUCCESS]: (state, { payload }) => ({ ...state, loadActiveHistoriesByIdLoading: false, activeHistories: payload }),
        [loadActiveHistoriesById.FAIL]: (state, { payload }) => ({ ...state, loadActiveHistoriesByIdLoading: false, error: payload }),
        [getActiveHistory.REQUEST]: (state, { payload }) => ({ ...state, historyLoading: true, }),
        [getActiveHistory.SUCCESS]: (state, { payload }) => {
            const newState = { ...state, historyLoading: false };
            newState[`historyStep${payload.step - 1}`] = payload.res;
            return newState;
        },
        [getActiveHistory.FAIL]: (state, { payload }) => ({ ...state, historyLoading: false, error: payload }),

        [getActiveHistoryWin.REQUEST]: (state, { payload }) => ({ ...state, getActiveHistoryWinLoading: true }),
        [getActiveHistoryWin.SUCCESS]: (state, { payload }) => ({ ...state, getActiveHistoryWinLoading: false, lotteryWinHistory: payload }),
        [getActiveHistoryWin.FAIL]: (state, { payload }) => ({ ...state, getActiveHistoryWinLoading: false, error: payload }),


        [initActiveById.REQUEST]: (state, { payload }) => ({ ...state, activeById: {}, historyStep0: '', historyStep1: '', historyStep2: '', }),


        [loadParticipantList.REQUEST]: (state, { payload }) => ({ ...state, loadParticipantListLoading: true }),
        [loadParticipantList.SUCCESS]: (state, { payload }) => ({ ...state, loadParticipantListLoading: false, participantList: payload }),
        [loadParticipantList.FAIL]: (state, { payload }) => ({ ...state, loadParticipantListLoading: false, error: payload }),
        [loadParticipantById.REQUEST]: (state, { payload }) => ({ ...state }),
        [loadParticipantById.SUCCESS]: (state, { payload }) => ({ ...state }),
        [loadParticipantById.FAIL]: (state, { payload }) => ({ ...state }),

        [addParticipant.REQUEST]: (state, { payload }) => ({ ...state, }),
        [addParticipant.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [addParticipant.FAIL]: (state, { payload }) => ({ ...state, }),
        [editParticipant.REQUEST]: (state, { payload }) => ({ ...state, }),
        [editParticipant.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [editParticipant.FAIL]: (state, { payload }) => ({ ...state, }),
        [delParticipant.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delParticipant.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delParticipant.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadParticipantSelect.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadParticipantSelect.SUCCESS]: (state, { payload }) => ({ ...state, participantSelect: payload }),
        [loadParticipantSelect.FAIL]: (state, { payload }) => ({ ...state, }),

        [emptyParticipant.REQUEST]: (state, { payload }) => ({ ...state, }),
        [emptyParticipant.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [emptyParticipant.FAIL]: (state, { payload }) => ({ ...state, }),
        [sureParticipant.REQUEST]: (state, { payload }) => ({ ...state, }),
        [sureParticipant.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [sureParticipant.FAIL]: (state, { payload }) => ({ ...state, }),
        [importParticipant.REQUEST]: (state, { payload }) => ({ ...state, }),
        [importParticipant.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [importParticipant.FAIL]: (state, { payload }) => ({ ...state, }),


        [loadInAwardList.REQUEST]: (state, { payload }) => ({ ...state, loadInAwardListLoading: true }),
        [loadInAwardList.SUCCESS]: (state, { payload }) => ({ ...state, loadInAwardListLoading: false, inAwardList: payload }),
        [loadInAwardList.FAIL]: (state, { payload }) => ({ ...state, loadInAwardListLoading: false, error: payload }),
        [addInAward.REQUEST]: (state, { payload }) => ({ ...state, loadAddInAward: true }),
        [addInAward.SUCCESS]: (state, { payload }) => ({ ...state, loadAddInAward: false }),
        [addInAward.FAIL]: (state, { payload }) => ({ ...state, loadAddInAward: false }),
        [editInAward.REQUEST]: (state, { payload }) => ({ ...state, loadEditInAward: true }),
        [editInAward.SUCCESS]: (state, { payload }) => ({ ...state, loadEditInAward: false }),
        [editInAward.FAIL]: (state, { payload }) => ({ ...state, loadEditInAward: false }),
        [delInAward.REQUEST]: (state, { payload }) => ({ ...state, }),
        [delInAward.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [delInAward.FAIL]: (state, { payload }) => ({ ...state, }),
        [disabledOrEnableInActive.REQUEST]: (state, { payload }) => ({ ...state, }),
        [disabledOrEnableInActive.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [disabledOrEnableInActive.FAIL]: (state, { payload }) => ({ ...state, }),
        [appointInAward.REQUEST]: (state, { payload }) => ({ ...state, }),
        [appointInAward.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [appointInAward.FAIL]: (state, { payload }) => ({ ...state, }),


        [loadLotteryById.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadLotteryById.SUCCESS]: (state, { payload }) => ({ ...state, lotteryById: payload }),
        [loadLotteryById.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadLotteryParticipant.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadLotteryParticipant.SUCCESS]: (state, { payload }) => ({ ...state, lotteryParticipant: payload }),
        [loadLotteryParticipant.FAIL]: (state, { payload }) => ({ ...state, }),

        [loadLotteryWin.REQUEST]: (state, { payload }) => ({ ...state, loadLotteryWinLoading: true, }),
        [loadLotteryWin.SUCCESS]: (state, { payload }) => ({ ...state, loadLotteryWinLoading: false, lotteryWin: payload }),
        [loadLotteryWin.FAIL]: (state, { payload }) => ({ ...state, loadLotteryWinLoading: false, }),



        [simulateLottery.REQUEST]: (state, { payload }) => ({ ...state, }),
        [simulateLottery.SUCCESS]: (state, { payload }) => ({ ...state, lotterydata: payload }),
        [simulateLottery.FAIL]: (state, { payload }) => ({ ...state, }),
        [trueLottery.REQUEST]: (state, { payload }) => ({ ...state, }),
        [trueLottery.SUCCESS]: (state, { payload }) => ({ ...state, lotterydata: payload }),
        [trueLottery.FAIL]: (state, { payload }) => ({ ...state, }),
        [loadLotteryByRound.REQUEST]: (state, { payload }) => ({ ...state, }),
        [loadLotteryByRound.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [loadLotteryByRound.FAIL]: (state, { payload }) => ({ ...state, }),


    },
    initialPoolState
)
export default reducer;