import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './promotions';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { trims } from '@/utils/commonUtils.js';

// Epics
export const loadByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadById.REQUEST.toString()),
  mergeMap(action => {
    const { activeType = 'promotions' } = action.payload;
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion[activeType].promotions}/${action.payload.promotionId ? action.payload.promotionId : action.payload}${action.payload.eseId ? '?eseId=' + action.payload.eseId : ''}`
    }).pipe(
      map(res => {
        action.payload.callback && action.payload.callback(res.response);
        return actions.loadById.SUCCESS(res.response)
      }
      ),
      catchError(error => of(actions.loadById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const loadTimeAllEpic = actions$ => actions$.pipe(
  ofType(actions.loadTimeAll.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.promotions}/toBeReleasedIds`
    }).pipe(
      map(res => {
        // action.payload.callback && action.payload.callback(res.response);
        return actions.loadTimeAll.SUCCESS(res.response.content)
      }
      ),
      catchError(error => of(actions.loadTimeAll.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);



export const deletePromotionById = (actions$, store) => actions$.pipe(
  ofType(actions.deletePromotionById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'DELETE',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].promotions}/${action.payload.id}`
    }).pipe(
      mergeMap(res => [actions.deletePromotionById.SUCCESS(res.response), actions.loadList.REQUEST(store.value.promotions.loadPromotionsParams), actions.draftCount.REQUEST(store.value.promotions.loadPromotionsParams), actions.draftCountTime.REQUEST(store.value.promotions.loadPromotionsParams)]),
      catchError(error => of(actions.deletePromotionById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);



export const createTagPromotionEpic = actions$ => actions$.pipe(
  ofType(actions.createTagPromotion.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { sendData, activeType = 'promotions', callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion[activeType].tag}`,
      method: 'POST',
      body: trims(sendData)
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.createTagPromotion.SUCCESS(res.response)
      }),
      // mergeMap(res => [() => {
      //   const search = location.search;
      //   console.log(search);
      //   const s = search.substring(1, search.length);
      //   const parsed = queryString.parse(s);
      //   parsed.step = 1;
      //   parsed.promotionId = res.response.id;
      //   const path = location.pathname;
      //   let pushUrl;
      //   if (isGoNext) {
      //     pushUrl = `${path}?${queryString.stringify(parsed)}`;
      //     history.replace(pushUrl);
      //   } else {
      //     pushUrl = '/promotiondevelop';
      //     history.replace(pushUrl);
      //   }
      //   return actions.createTagPromotion.SUCCESS(res.response)
      // }, actions.loadById.REQUEST(res.response.id)]),
      catchError(error => of(actions.createTagPromotion.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const editTagPromotionEpic = actions$ => actions$.pipe(
  ofType(actions.editTagPromotion.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { sendData, activeType = 'promotions', callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion[activeType].tag}`,
      method: 'PUT',
      body: trims(sendData)
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.editTagPromotion.SUCCESS(res.response)
      }),
      // mergeMap(res => [() => {
      //   const search = location.search;
      //   const s = search.substring(1, search.length);
      //   const parsed = queryString.parse(s);
      //   parsed.step = 1;
      //   parsed.promotionId = res.response.id;
      //   const path = location.pathname;
      //   let pushUrl;
      //   if (isGoNext) {
      //     pushUrl = `${path}?${queryString.stringify(parsed)}`;
      //     history.replace(pushUrl);
      //   } else {
      //     pushUrl = '/promotiondevelop';
      //     history.replace(pushUrl);
      //   }
      //   return actions.editTagPromotion.SUCCESS(res.response)
      // }, actions.loadById.REQUEST(res.response.id)]),
      catchError(error => of(actions.editTagPromotion.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const createOtherPromotionEpic = actions$ => actions$.pipe(
  ofType(actions.createOtherPromotion.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { sendData, activeType = 'promotions', callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion[activeType].other}`,
      method: 'POST',
      body: trims(sendData)
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.createOtherPromotion.SUCCESS(res.response)
      }),
      // const search = location.search;
      // const s = search.substring(1, search.length);
      // const parsed = queryString.parse(s);
      // parsed.step = 1;
      // parsed.promotionId = res.response.id;
      // const path = location.pathname;
      // let pushUrl;
      // if (isGoNext) {
      //   pushUrl = `${path}?${queryString.stringify(parsed)}`;
      //   history.replace(pushUrl);
      // } else {
      //   pushUrl = '/promotiondevelop';
      //   history.replace(pushUrl);
      // }


      // }, actions.loadById.REQUEST(res.response.id)]),
      catchError(error => of(actions.createOtherPromotion.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const editOtherPromotionEpic = actions$ => actions$.pipe(
  ofType(actions.editOtherPromotion.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { sendData, activeType = 'promotions', callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion[activeType].other}`,
      method: 'PUT',
      body: trims(sendData)
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.editOtherPromotion.SUCCESS(res.response)
      }),
      // mergeMap(res => [() => {
      //   const search = location.search;
      //   const s = search.substring(1, search.length);
      //   const parsed = queryString.parse(s);
      //   parsed.step = 1;
      //   parsed.promotionId = res.response.id;
      //   const path = location.pathname;
      //   let pushUrl;
      //   if (isGoNext) {
      //     pushUrl = `${path}?${queryString.stringify(parsed)}`;
      //     history.replace(pushUrl);
      //   } else {
      //     pushUrl = '/promotiondevelop';
      //     history.replace(pushUrl);
      //   }
      //   return actions.editOtherPromotion.SUCCESS(res.response)
      // }, actions.loadById.REQUEST(res.response.id)]),
      catchError(error => of(actions.editOtherPromotion.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);

export const loadListByTypeEpic = actions$ => actions$.pipe(
  ofType(actions.loadList.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { isVaild = true, toBeReleased = true, promotionCategory, keywords = '', promotionType = '', promotionPublishStatus = '', page = 0, size = 20, sort = '', activeType = 'promotions', promotionSource } = action.payload;
    const url = !toBeReleased ? urls.promotion[activeType].toBeReleased : isVaild ? urls.promotion[activeType].valid : urls.promotion[activeType].promotions
    return noecAjax({
      ...ajaxBaseConfig,
      url: `${url}?promotionCategory=${promotionCategory}&keywords=${keywords ? encodeURIComponent(keywords) : ''}&promotionType=${promotionType}&promotionPublishStatus=${promotionPublishStatus}&page=${page}&size=${size}&sort=${sort}${!toBeReleased ? '&promotionStatus=TO_BE_RELEASED' : ''}${toBeReleased && isVaild && activeType == 'promotions' && promotionSource ? '&promotionSource=C' : ''}`
    }).pipe(
      map(res => actions.loadList.SUCCESS(res.response)),
      catchError(error => of(actions.loadList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const promotionPauseEpic = (actions$, store) => actions$.pipe(
  ofType(actions.promotionPause.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].pause(action.payload.id)}`
    }).pipe(
      mergeMap(res => [actions.promotionPause.SUCCESS(res.response), actions.loadList.REQUEST(store.value.promotions.loadPromotionsParams)]),
      catchError(error => of(actions.promotionPause.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const promotionResumeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.promotionResume.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].resume(action.payload.id)}`
    }).pipe(
      mergeMap(res => [actions.promotionResume.SUCCESS(res.response), actions.loadList.REQUEST(store.value.promotions.loadPromotionsParams)]),
      catchError(error => of(actions.promotionResume.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const promotionRegulateEpic = (actions$, store) => actions$.pipe(
  ofType(actions.promotionRegulate.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].promotions}/${action.payload.id}/regulate`
    }).pipe(
      mergeMap(res => [actions.promotionRegulate.SUCCESS(res.response), actions.loadList.REQUEST(store.value.promotions.loadPromotionsParams)]),
      catchError(error => of(actions.promotionRegulate.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



export const loadPromotionHistoriesByIdEpic = (actions$, store) => actions$.pipe(
  ofType(actions.loadPromotionHistoriesById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { keywords, promotionId, page = 0, size = 10, sort } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotionHistories.promotionHistories}?keywords=${keywords}&promotionId=${promotionId}&page=${page}&size=${size}&sort=${sort}`
    }).pipe(
      map(res => actions.loadPromotionHistoriesById.SUCCESS(res.response)),
      catchError(error => of(actions.loadPromotionHistoriesById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const publishPromotionEpic = (actions$, store) => actions$.pipe(
  ofType(actions.publishPromotion.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].publish(action.payload.id)}?&authType=${action.payload.authType}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.publishPromotion.SUCCESS(res.response), uiActions.notification('操作成功', 'success'), actions.loadList.REQUEST(store.value.promotions.loadPromotionsParams), uiActions.updatePublishDialogStatus(false), actions.draftCount.REQUEST(store.value.promotions.loadPromotionsParams), actions.draftCountTime.REQUEST(store.value.promotions.loadPromotionsParams)]),
      catchError(error => of(actions.publishPromotion.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const publishPromotionTimeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.publishPromotionTime.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].toBeReleased}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.publishPromotionTime.SUCCESS(res.response), uiActions.notification('操作成功', 'success'), actions.loadList.REQUEST(store.value.promotions.loadPromotionsParams), uiActions.updatePublishDialogStatus(false), actions.draftCount.REQUEST(store.value.promotions.loadPromotionsParams), actions.draftCountTime.REQUEST(store.value.promotions.loadPromotionsParams)]),
      catchError(error => of(actions.publishPromotionTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const publishPromotionInStep3Epic = (actions$) => actions$.pipe(
  ofType(actions.publishPromotionInStep3.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].publish(action.payload.id)}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.publishPromotionInStep3.SUCCESS(res.response), uiActions.updatePublishDialogInStepStatus(false), uiActions.notification('操作成功', 'success')]),
      catchError(error => of(actions.publishPromotionInStep3.FAIL(error.xhr.response), uiActions.updatePublishDialogInStepStatus(false), uiActions.notification(error.xhr.response)))
    )
  })
);

export const publishPromotionTimeInStep3Epic = (actions$) => actions$.pipe(
  ofType(actions.publishPromotionTimeInStep3.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].toBeReleased}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.publishPromotionTimeInStep3.SUCCESS(res.response), uiActions.updatePublishDialogInStepStatus(false), uiActions.notification('操作成功', 'success')]),
      catchError(error => of(actions.publishPromotionTimeInStep3.FAIL(error.xhr.response), uiActions.updatePublishDialogInStepStatus(false), uiActions.notification(error.xhr.response)))
    )
  })
);



export const deletePublishTimeEpic = (actions$, store) => actions$.pipe(
  ofType(actions.deletePublishTime.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion[action.payload.activeType ? action.payload.activeType : 'promotions'].promotions}/${action.payload.id}/unPublish`
    }).pipe(
      mergeMap(res => [actions.deletePublishTime.SUCCESS(res.response), actions.loadList.REQUEST(store.value.promotions.loadPromotionsParams), actions.draftCount.REQUEST(store.value.promotions.loadPromotionsParams), actions.draftCountTime.REQUEST(store.value.promotions.loadPromotionsParams)]),
      catchError(error => of(actions.deletePublishTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);


export const loadPrioritiesEpic = actions$ => actions$.pipe(
  ofType(actions.loadPriorities.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionCategory, keywords, statues, page = 0, size = 20, sort = '', promotionType = '', activeType = 'promotions' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion[activeType].priorities(promotionCategory)}?keywords=${keywords}&statues=${statues}&page=${page}&size=${size}&sort=${sort}&promotionType=${promotionType}`
    }).pipe(
      map(res => actions.loadPriorities.SUCCESS(res.response)),
      catchError(error => of(actions.loadPriorities.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const updatePrioritiesEpic = (actions$, store) => actions$.pipe(
  ofType(actions.updatePriorities.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { publishId, promotionPriority, params = { activeType: 'promotions' } } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      method: 'PATCH',
      url: `${urls.promotion[params.activeType].priority(publishId, promotionPriority)}`
    }).pipe(
      mergeMap(res => [actions.updatePriorities.SUCCESS(res.response), actions.loadPriorities.REQUEST(store.value.promotions.loadPrioritiesParams), uiActions.updatePriorityDialogStatus(false)]),
      catchError(error => of(actions.updatePriorities.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const loadTagConfigsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadTagConfigsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.tagConfigs.tagConfigs}/${action.payload}`
    }).pipe(
      map(res => {
        let response = res.response;
        if (response) {
          response.hitStatus = response.hitStatus === 'DISABLE' ? false : true;
          response.lotteryStatus = response.lotteryStatus === 'DISABLE' ? false : true;
          response.userConfig.hitStatus = !response.userConfig || response.userConfig.hitStatus === 'DISABLE' ? false : true;
          response.userConfig.lotteryStatus = !response.userConfig || response.userConfig.lotteryStatus === 'DISABLE' ? false : true;
          if (response.userConfig) {
            if (response.userConfig.lotteryGlobalAmount) {
              response.userConfig.lotteryGlobalAmount = response.userConfig.lotteryGlobalAmount / 100;
            }
            if (response.userConfig.hitGlobalAmount) {
              response.userConfig.hitGlobalAmount = response.userConfig.hitGlobalAmount / 100;
            }
            // if (response.userConfig.shopHitNum) {
            //   response.userConfig.shopHitNum = response.userConfig.shopHitNum / 100;
            // }
            // if (response.userConfig.shopLotteryNum) {
            //   response.userConfig.shopLotteryNum = response.userConfig.shopLotteryNum / 100;
            // }

            // response.userConfig.userVerify='COORDINATE'
            if (response.userConfig.userVerify) {
              response.userConfig.userVerify = response.userConfig.userVerify.split(',');
            }
            else {
              response.userConfig.userVerify = []
            }
          }
          if (response.allowUserRepeatLottery === undefined) {
            response.allowUserRepeatLottery = false;
          }

          return actions.loadTagConfigsById.SUCCESS(response)

        }

        return actions.loadTagConfigsById.SUCCESS({ allowUserRepeatLottery: false });

      }),
      catchError(error => of(actions.loadTagConfigsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const addTagConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.addTagConfigs.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.promotion.tagConfigs.tagConfigs}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.addTagConfigs.SUCCESS(res.response), actions.loadTagConfigsById.REQUEST(res.response.promotionId)]),
      catchError(error => of(actions.addTagConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const updateTagConfigsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.updateTagConfigsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.promotion.tagConfigs.tagConfigs}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.updateTagConfigsById.SUCCESS(res.response), actions.loadTagConfigsById.REQUEST(res.response.promotionId)]),
      catchError(error => of(actions.updateTagConfigsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const loadUserConfigsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadUserConfigsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.userConfigs.userConfigs}/${action.payload}`
    }).pipe(
      map(res => {
        if (res.response) {
          const response = res.response;
          if (response.lotteryGlobalAmount) {
            response.lotteryGlobalAmount = response.lotteryGlobalAmount / 100;
          }
          if (response.hitGlobalAmount) {
            response.hitGlobalAmount = response.hitGlobalAmount / 100;
          }
          if (response.participateLotteryGlobalAmount) {
            response.participateLotteryGlobalAmount = response.participateLotteryGlobalAmount / 100;
          }
          if (response.participateHitGlobalAmount) {
            response.participateHitGlobalAmount = response.participateHitGlobalAmount / 100;
          }
          // if (response.shopHitNum) {
          //   response.shopHitNum = response.shopHitNum / 100;
          // }
          // if (response.shopLotteryNum) {
          //   response.shopLotteryNum = response.shopLotteryNum / 100;
          // }

          if (response.userVerify) {
            response.userVerify = response.userVerify.split(',');
          }
          else {
            response.userVerify = []
          }

          response.hitStatus = response.hitStatus === 'DISABLE' ? false : true;
          response.lotteryStatus = response.lotteryStatus === 'DISABLE' ? false : true;
          response.sponsorStatus = response.sponsorStatus === 'DISABLE' ? false : true;
          response.participateStatus = response.participateStatus === 'DISABLE' ? false : true;
          response.participateLotteryStatus = response.participateLotteryStatus === 'DISABLE' ? false : true;
          response.participateHitStatus = response.participateHitStatus === 'DISABLE' ? false : true;
          return actions.loadUserConfigsById.SUCCESS(res.response)

        }


        return actions.loadUserConfigsById.SUCCESS({});
      }),
      catchError(error => of(actions.loadUserConfigsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const addUserConfigsEpic = actions$ => actions$.pipe(
  ofType(actions.addUserConfigs.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'POST',
      url: `${urls.promotion.userConfigs.userConfigs}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.addUserConfigs.SUCCESS(res.response), actions.loadUserConfigsById.REQUEST(res.response.promotionId)]),
      catchError(error => of(actions.addUserConfigs.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const updateUserConfigsByIdEpic = actions$ => actions$.pipe(
  ofType(actions.updateUserConfigsById.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      method: 'PUT',
      url: `${urls.promotion.userConfigs.userConfigs}`,
      body: action.payload
    }).pipe(
      mergeMap(res => [actions.updateUserConfigsById.SUCCESS(res.response), actions.loadUserConfigsById.REQUEST(res.response.promotionId)]),
      catchError((error, res) => {
        return of(actions.updateUserConfigsById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  })
);

export const checkMaxEpic = actions$ => actions$.pipe(
  ofType(actions.checkMax.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.max}?promotionId=${action.payload}&warnAmount=5000`
    }).pipe(
      map(res => actions.checkMax.SUCCESS(res.response)),
      catchError(error => of(actions.checkMax.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const checkLimitEpic = actions$ => actions$.pipe(
  ofType(actions.checkLimit.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.limit}?promotionId=${action.payload}&warnAmount=5000`
    }).pipe(
      map(res => actions.checkLimit.SUCCESS(res.response)),
      catchError(error => of(actions.checkLimit.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const checkTencentBspEpic = actions$ => actions$.pipe(
  ofType(actions.checkTencentBsp.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.tencentBsp}?promotionId=${action.payload}`
    }).pipe(
      map(res => actions.checkTencentBsp.SUCCESS(res.response)),
      catchError(error => of(actions.checkTencentBsp.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
export const checkTencentUserWeightEpic = actions$ => actions$.pipe(
  ofType(actions.checkTencentUserWeight.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.tencentUserWeight}?promotionId=${action.payload}`
    }).pipe(
      map(res => actions.checkTencentUserWeight.SUCCESS(res.response)),
      catchError(error => of(actions.checkTencentUserWeight.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
export const checkTencentAppidEpic = actions$ => actions$.pipe(
  ofType(actions.checkTencentAppid.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.checkAppId}?promotionId=${action.payload}`
    }).pipe(
      map(res => actions.checkTencentAppid.SUCCESS(res.response)),
      catchError(error => of(actions.checkTencentAppid.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
export const checkTencentIntelligentEpic = actions$ => actions$.pipe(
  ofType(actions.checkTencentIntelligent.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.plans.tencentIntelligent}?promotionId=${action.payload}`
    }).pipe(
      map(res => actions.checkTencentIntelligent.SUCCESS(res.response)),
      catchError(error => of(actions.checkTencentIntelligent.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);




export const checkPaymentEpic = actions$ => actions$.pipe(
  ofType(actions.checkPayment.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionId, appId } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      // switch
      url: `${urls.promotion.plans.switch}?promotionId=${promotionId}`
    }).pipe(
      map(res => actions.checkPayment.SUCCESS(res.response)),
      catchError(error => of(actions.checkPayment.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const productValid = actions$ => actions$.pipe(
  ofType(actions.productValid.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionId, productId } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.promotion.promotions.productValid(promotionId, productId)
    }).pipe(
      map(res => actions.productValid.SUCCESS(res.response)),
      catchError(error => of(actions.productValid.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


export const getHistoryEpic = actions$ => actions$.pipe(
  ofType(actions.getHistory.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionId, historyId, step } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.promotion.promotionHistories.step(historyId, promotionId, step)
    }).pipe(
      map(res => {
        return actions.getHistory.SUCCESS({ res: res.response, step: action.payload.step })
      }),
      catchError(error => of(actions.getHistory.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const draftCountEpic = actions$ => actions$.pipe(
  ofType(actions.draftCount.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { activeType = 'promotions' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.promotion[activeType].draftCount,
    }).pipe(
      map(res => actions.draftCount.SUCCESS(res.response)),
      catchError(error => of(actions.draftCount.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

export const draftCountTimeEpic = actions$ => actions$.pipe(
  ofType(actions.draftCountTime.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { activeType = 'promotions' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.promotion[activeType].draftCountTime + '?promotionStatus=TO_BE_RELEASED',
    }).pipe(
      map(res => actions.draftCountTime.SUCCESS(res.response)),
      catchError(error => of(actions.draftCountTime.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);


// 绑定活动code
export const bindCodeEpic = actions$ => actions$.pipe(
  ofType(actions.bindCode.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { publishId, promotionCode } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: urls.promotion.promotions.bindCode(publishId, promotionCode),
      method: 'PATCH',
    }).pipe(
      map(res => actions.bindCode.SUCCESS(res.status)),
      catchError(error => of(actions.bindCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 活动下拉列表
export const getPromotionSelectionEpic = actions$ => actions$.pipe(
  ofType(actions.getPromotionSelection.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const promotionCategory = action.payload ? action.payload.promotionCategory : '';
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.promotions}/selections?promotionCategory=${promotionCategory}`,
    }).pipe(
      map(res => actions.getPromotionSelection.SUCCESS(res.response.content || [])),
      catchError(error => of(actions.getPromotionSelection.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);

// 会员标签被活动使用列表
export const getTagCodeUsedPromotionsEpic = actions$ => actions$.pipe(
  ofType(actions.getTagCodeUsedPromotions.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { tagCodes, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.tagCode}?tagCodes=${tagCodes}`,
    }).pipe(
      map(res => {
        callback && callback(res.response.content || []);
        return actions.getTagCodeUsedPromotions.SUCCESS(res.response.content);
      }),
      catchError(error => of(actions.getTagCodeUsedPromotions.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);
// 活动下拉列表
export const getPromotionSelectionByeseIdEpic = actions$ => actions$.pipe(
  ofType(actions.getPromotionSelectionByeseId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const eseId = action.payload ? action.payload.eseId : '';
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.promotions}/all?eseId=${eseId}`,
    }).pipe(
      map(res => actions.getPromotionSelectionByeseId.SUCCESS(res.response.content || [])),
      catchError(error => of(actions.getPromotionSelectionByeseId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);



export const changeWarnEpic = actions$ => actions$.pipe(
  ofType(actions.changeWarn.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.promotions}/updatePromotionRemind`,
      method: 'PATCH',
      body: data,
    }).pipe(
      map(res => {
        callback && callback(res.response);
        return actions.changeWarn.SUCCESS(res.response);
      }),
      catchError(error => of(actions.changeWarn.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);





//list
export const loadMoreActiveListEpic = actions$ => actions$.pipe(
  ofType(actions.loadMoreActiveList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'id,desc', promotionCategory = '', promotionSource = '' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.promotions.moreActive}?word=${keywords}&page=${page}&size=${size}&sort=${sort}&promotionCategory=${promotionCategory}&promotionSource=${promotionSource}`,
      }).pipe(
        map(res => actions.loadMoreActiveList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadMoreActiveList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createMoreActiveEpic = actions$ => actions$.pipe(
  ofType(actions.createMoreActive.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.promotion.promotions.moreActive}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createMoreActive.SUCCESS(res)
        }),
        catchError(error => of(actions.createMoreActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//edit
export const editMoreActiveEpic = actions$ => actions$.pipe(
  ofType(actions.editMoreActive.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PUT',
        url: `${urls.promotion.promotions.moreActive}`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.editMoreActive.SUCCESS(res)
        }),
        catchError(error => of(actions.editMoreActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//able

export const ableMoreActiveEpic = actions$ => actions$.pipe(
  ofType(actions.ableMoreActive.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'PATCH',
        url: `${urls.promotion.promotions.moreActive}/${data.id}/status?status=${data.status}`,
        //  body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.ableMoreActive.SUCCESS(res)
        }),
        catchError(error => of(actions.ableMoreActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

//通信录查询by id
export const loadMoreActiveIdEpic = actions$ => actions$.pipe(
  ofType(actions.loadMoreActiveId.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (
      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.promotions.moreActive}/${data.id}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.loadMoreActiveId.SUCCESS(res.response)

        }),
        catchError(error => of(actions.loadMoreActiveId.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


export const deleteMoreActiveEpic = actions$ => actions$.pipe(
  ofType(actions.deleteMoreActive.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'DELETE',
        url: `${urls.promotion.promotions.moreActive}/${id}`,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.deleteMoreActive.SUCCESS(res)
        }),
        catchError(error => of(actions.deleteMoreActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

// 活动下拉列表
export const getPromotionSelectionForMoreEpic = actions$ => actions$.pipe(
  ofType(actions.getPromotionSelectionForMore.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { promotionCategory, promotionSource, callback } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.promotion.promotions.promotions}/all?promotionCategory=${promotionCategory}&promotionSource=${promotionSource}`,
    }).pipe(
      map(res => actions.getPromotionSelectionForMore.SUCCESS(res.response.content || [])),
      catchError(error => of(actions.getPromotionSelectionForMore.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  })
);





//list
export const loadMateActiveListEpic = actions$ => actions$.pipe(
  ofType(actions.loadMateActiveList.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { keywords = '', page = 0, size = 20, sort = 'id,desc', type = '' } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.promotions.mateActive}?word=${keywords}&page=${page}&size=${size}&sort=${sort}&type=${type}`,
      }).pipe(
        map(res => actions.loadMateActiveList.SUCCESS(res.response || [])),
        catchError(error => of(actions.loadMateActiveList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);
//create
export const createMateActiveEpic = actions$ => actions$.pipe(
  ofType(actions.createMateActive.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { data, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.promotion.promotions.mateActive}/create`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.createMateActive.SUCCESS(res)
        }),
        catchError(error => of(actions.createMateActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);

export const deleteMateActiveEpic = actions$ => actions$.pipe(
  ofType(actions.deleteMateActive.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { id, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        method: 'POST',
        url: `${urls.promotion.promotions.mateActive}/delete`,
        body: action.payload.data,
      }).pipe(
        map(res => {
          callback && callback()
          return actions.deleteMateActive.SUCCESS(res)
        }),
        catchError(error => of(actions.deleteMateActive.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);


//list
export const mateCheckEpic = actions$ => actions$.pipe(
  ofType(actions.mateCheck.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { promotionId = '', page = 0, callback } = action.payload;
    return (

      ajax({
        ...ajaxBaseConfig,
        url: `${urls.promotion.plans.plans}/conditions?promotionId=${promotionId}&page=${page}`,
      }).pipe(
        map(res => {
          callback && callback(res.response)
          return actions.mateCheck.SUCCESS(res.response)
        }),
        catchError(error => of(actions.mateCheck.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
      )
    )
  })
);