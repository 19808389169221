import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'billInfomation';

/**
 * TODO: 
 */

// 主数据字典管理
export const changeBillPaging = createAction(`${moduleName}/changeBillPaging`);
export const changeBillDataPaging = createAction(`${moduleName}/changeBillDataPaging`);
export const billTypeList = fasterActions(moduleName, 'billTypeList');
export const billTypeInfo = fasterActions(moduleName, 'billTypeInfo');
export const createBillType = fasterActions(moduleName, 'createBillType');
export const enableOrDisabledBillType = fasterActions(moduleName, 'enableOrDisabledBillType');
export const billDataList = fasterActions(moduleName, 'billDataList');
export const billDataInfo = fasterActions(moduleName, 'billDataInfo');
export const loadbillDataSelections = fasterActions(moduleName, 'loadbillDataSelections');
export const loadbillDataData = fasterActions(moduleName, 'loadbillDataData');
// 单据条件查询
export const getbillRecord = fasterActions(moduleName, 'getbillRecord');

const initialState = {
    billPaging: {
        page: 0,
        size: 20,
    },
    billDataPaging: {
        page: 0,
        size: 20
    },
    billTypeData: {},
    billTypeInfo: {},
    billDataData: [],
}

const reducer = handleActions(
    {
        [changeBillPaging]: (state, { payload }) => {
            return { ...state, billPaging: { ...payload, page: 0 } }
        },

        [changeBillDataPaging]: (state, { payload }) => {
            return { ...state, billDataPaging: { ...payload, page: 0 } }
        },

        [billTypeList.REQUEST]: (state, { payload }) => ({ ...state, billPaging: payload, loading: true }),
        [billTypeList.SUCCESS]: (state, { payload }) => ({ ...state, billTypeData: payload, loading: false }),
        [billTypeList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [createBillType.REQUEST]: (state, { payload }) => ({ ...state, payload, createStatus: false }),
        [createBillType.SUCCESS]: (state, { payload }) => ({ ...state, createStatus: payload }),
        [createBillType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [enableOrDisabledBillType.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [enableOrDisabledBillType.SUCCESS]: (state, { payload }) => ({ ...state }),
        [enableOrDisabledBillType.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

        [billDataList.REQUEST]: (state, { payload }) => ({ ...state, billDataPaging: payload, loading: true }),
        [billDataList.SUCCESS]: (state, { payload }) => ({ ...state, billTypeData: payload, loading: false }),
        [billDataList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [billTypeInfo.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
        [billTypeInfo.SUCCESS]: (state, { payload }) => ({ ...state, billTypeInfo: payload, loading: false }),
        [billTypeInfo.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

        [billDataInfo.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [billDataInfo.SUCCESS]: (state, { payload }) => ({ ...state, }),
        [billDataInfo.FAIL]: (state, { payload }) => ({ ...state, }),

        // 下拉框
        [loadbillDataSelections.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [loadbillDataSelections.SUCCESS]: (state, { payload }) => ({ ...state, billDataSelections: payload }),
        [loadbillDataSelections.FAIL]: (state, { payload }) => ({ ...state, }),

        [loadbillDataData.REQUEST]: (state, { payload }) => ({ ...state, payload }),
        [loadbillDataData.SUCCESS]: (state, { payload }) => ({ ...state, billDataData: payload }),
        [loadbillDataData.FAIL]: (state, { payload }) => ({ ...state, }),

        [getbillRecord.REQUEST]: (state, { payload }) => {
            return({ ...state, payload, billRecord: [] })
        },
        [getbillRecord.SUCCESS]: (state, { payload }) => {
            let billRecord = [];
            if (payload?.id) {
                payload.text = payload.name;
                payload.value = payload.code;
                delete payload.code;
                billRecord = [payload];
            }

            return ({ ...state, billRecord });
        },
        [getbillRecord.FAIL]: (state, { payload }) => ({ ...state }),

    },
    initialState
);

export default reducer;
