import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeStatistics';
import { ajaxBaseConfig, actionApi ,apiBaseConfig} from '../../utils';

export const loadcodeStatisticsEpic = actions$ => actions$.pipe(
  ofType(actions.loadcodeStatistics.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { name = '', page = 0, size = 20, sort = 'id,desc' } = action.payload;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.statistics.codeStatisticsList}?search=${name}&page=${page}&size=${size}`
    }).pipe(
      map(res => actions.loadcodeStatistics.SUCCESS(res.response)),
      catchError(error => of(actions.loadcodeStatistics.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);
export const loadcodeStatisticsExcelEpic = (actions$, state$) => actions$.pipe(
  ofType(actions.loadcodeStatisticsExcel.REQUEST.toString()),

  mergeMap(action => {
    actionApi()
    const { productPageParam = {}, productSearchKey = '' } = state$.value.products;
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.data.statistics.codeStatisticsExcel}?search=${name}`
    }).pipe(
      concatMap(res => [actions.loadcodeStatisticsExcel.SUCCESS(res.response), uiActions.notification('文件导出成功，请注意邮件查收', 'success')]),
      catchError(error => of(actions.loadcodeStatisticsExcel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      )
    )
  }
  )
);
// export const loadcodeStatisticsExcelEpic = actions$ => actions$.pipe(
//   ofType(actions.loadcodeStatisticsExcel.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi()
//     debugger
//     const { name = '' } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       url: `${urls.data.statistics.codeStatisticsExcel}?search=${name}`
//     }).pipe(
//       map(res => actions.loadcodeStatisticsExcel.SUCCESS(res.response)),
//       catchError(error => of(actions.loadcodeStatisticsExcel.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
//     )
//   }
//   )
// );
// export const editCodedbEpic = actions$ => actions$.pipe(
//   ofType(actions.editCodedb.REQUEST.toString()),
//   mergeMap(action => {
//     actionApi()
//     const { data, callback } = action.payload;
//     return ajax({
//       ...ajaxBaseConfig,
//       method: 'PUT',
//       body: data,
//       url: `${urls.data.codedb.codedb}`
//     }).pipe(
//       map(res => {
//         // let array = [
//         //   res.response,
//         //   ...state$.value.plan.planAllList,

//         // ];
//         callback && callback()
//         return actions.editCodedb.SUCCESS(res)
//       }),
//       catchError(error => of(actions.editCodedb.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
//       )
//     )
//   }
//   )
// );


