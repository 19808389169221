import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax as noecAjax } from 'rxjs/ajax';
import { ajax } from '../../ajax';
import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './operationTools';
import { ajaxBaseConfig, actionApi } from '../../utils';

// Epics
export const getLeaveInfoListEpic = actions$ => actions$.pipe(
    ofType(actions.getLeaveInfoList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { name = '', product = '', type = '', status = '', page = 0, size = 20, sort = '', dataRange: { from = '', to = '' } = {}, provinceCode = '', cityCode = '', areaCode = '' } = action.payload;

        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveInfo}/find?page=${page}&size=${size}&sort=${sort}&product=${product}&type=${type}&titles=${name ? encodeURIComponent(name) : ''}&startTime=${from}&endTime=${to}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaCode=${areaCode}&status=${status}`
        }).pipe(
            map(res => actions.getLeaveInfoList.SUCCESS(res.response)),
            catchError(error => of(actions.getLeaveInfoList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const putLeaveInfoEpic = actions$ => actions$.pipe(
    ofType(actions.putLeaveInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: data.ids,
            url: `${urls.queryTools.leaveInfo.leaveInfo}/modify-status?comment=${data.comment}&status=${data.status}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.putLeaveInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putLeaveInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const exportLeaveInfoEpic = actions$ => actions$.pipe(
    ofType(actions.exportLeaveInfo.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { name = '', product = '', type = '', status = '', page = 0, size = 20, sort = '', dataRange: { from = '', to = '' } = {}, provinceCode = '', cityCode = '', areaCode = '', callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveInfo}/export?page=${page}&size=${size}&sort=${sort}&product=${product}&type=${type}&titles=${name ? encodeURIComponent(name) : ''}&startTime=${from}&endTime=${to}&provinceCode=${provinceCode}&cityCode=${cityCode}&areaCode=${areaCode}&status=${status}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.exportLeaveInfo.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportLeaveInfo.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




export const getLeaveFieldListEpic = actions$ => actions$.pipe(
    ofType(actions.getLeaveFieldList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { page = 0, size = 20, sort = '' } = action.payload;
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveField}/page?page=${page}&size=${size}&sort=${sort}`
        }).pipe(
            map(res => actions.getLeaveFieldList.SUCCESS(res.response)),
            catchError(error => of(actions.getLeaveFieldList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const putLeaveFieldEpic = actions$ => actions$.pipe(
    ofType(actions.putLeaveField.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url:data.id?`${urls.queryTools.leaveInfo.leaveField}/update`: `${urls.queryTools.leaveInfo.leaveField}/create`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.putLeaveField.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putLeaveField.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const statusLeaveFieldEpic = actions$ => actions$.pipe(
    ofType(actions.statusLeaveField.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: data.id,
            url: `${urls.queryTools.leaveInfo.leaveField}/${data.status}/${data.id}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.statusLeaveField.SUCCESS(res.response)
            }),
            catchError(error => of(actions.statusLeaveField.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getLeaveFieldSelectEpic = actions$ => actions$.pipe(
    ofType(actions.getLeaveFieldSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveField}/all`
        }).pipe(
            map(res => actions.getLeaveFieldSelect.SUCCESS(res.response)),
            catchError(error => of(actions.getLeaveFieldSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



export const getLeaveTypeListEpic = actions$ => actions$.pipe(
    ofType(actions.getLeaveTypeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { title = '', page = 0, size = 20, sort = '', withdrawlTime = { from: '', to: '' }, status = [] } = action.payload;
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveType}/find/false?page=${page}&size=${size}&sort=${sort}&title=${title ? encodeURIComponent(title) : ''}&withdrawlTime=${withdrawlTime.to ? withdrawlTime.from + ',' + withdrawlTime.to : ''}`
        }).pipe(
            map(res => actions.getLeaveTypeList.SUCCESS(res.response)),
            catchError(error => of(actions.getLeaveTypeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const getLeaveTypeByIdEpic = actions$ => actions$.pipe(
    ofType(actions.getLeaveTypeById.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveType}/find-select-options?id=${id}`
        }).pipe(
            map(res => {
                callback && callback(res.response);
                return actions.getLeaveTypeById.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getLeaveTypeById.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



export const putLeaveTypeEpic = actions$ => actions$.pipe(
    ofType(actions.putLeaveType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.queryTools.leaveInfo.leaveType}/save`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.putLeaveType.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putLeaveType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const statusLeaveTypeEpic = actions$ => actions$.pipe(
    ofType(actions.statusLeaveType.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            body: data.ids,
            url: `${urls.queryTools.leaveInfo.leaveType}/modify-status?status=${data.status}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.statusLeaveType.SUCCESS(res.response)
            }),
            catchError(error => of(actions.statusLeaveType.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const getLeaveTypeSelectEpic = actions$ => actions$.pipe(
    ofType(actions.getLeaveTypeSelect.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { isPage = false, status, callback } = action.payload;
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveType}/allots?isPage=${isPage}&status=${status}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.getLeaveTypeSelect.SUCCESS(res.response)
            }),
            catchError(error => of(actions.getLeaveTypeSelect.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



export const getLeaveTypecriteriaEpic = actions$ => actions$.pipe(
    ofType(actions.getLeaveTypecriteria.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.leaveInfo.leaveType}/criteria`
        }).pipe(
            map(res => actions.getLeaveTypecriteria.SUCCESS(res.response)),
            catchError(error => of(actions.getLeaveTypecriteria.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)




//活动码
export const getPromotionCodeListEpic = actions$ => actions$.pipe(
    ofType(actions.getPromotionCodeList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { promotionTagNameOrId = '', promotionType = '', promotionIdList = [], page = 0, size = 20, sort = '', } = action.payload;

        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.promotionCode.promotionCode}?page=${page}&size=${size}&sort=${sort}&promotionTagNameOrId=${promotionTagNameOrId ? encodeURIComponent(promotionTagNameOrId) : ''}&promotionType=${promotionType}&promotionIdList=${promotionIdList}`
        }).pipe(
            map(res => actions.getPromotionCodeList.SUCCESS(res.response)),
            catchError(error => of(actions.getPromotionCodeList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const createPromotionCodeEpic = actions$ => actions$.pipe(
    ofType(actions.createPromotionCode.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.queryTools.promotionCode.promotionCode}/addCode`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.createPromotionCode.SUCCESS(res.response)
            }),
            catchError(error => of(actions.createPromotionCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const putPromotionCodeEpic = actions$ => actions$.pipe(
    ofType(actions.putPromotionCode.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.queryTools.promotionCode.promotionCode}/update`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.putPromotionCode.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putPromotionCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const exportPromotionCodeEpic = actions$ => actions$.pipe(
    ofType(actions.exportPromotionCode.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.promotionCode.promotionCode}/download_detail/${id}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.exportPromotionCode.SUCCESS(res.response)
            }),
            catchError(error => of(actions.exportPromotionCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const downPromotionCodeEpic = actions$ => actions$.pipe(
    ofType(actions.downPromotionCode.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id = '', callback } = action.payload;

        return ajax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.promotionCode.promotionCode}/download/${id}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.downPromotionCode.SUCCESS(res.response)
            }),
            catchError(error => of(actions.downPromotionCode.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)



//产品手册
export const getProductManualListEpic = actions$ => actions$.pipe(
    ofType(actions.getProductManualList.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { promotionTagNameOrId = '', promotionType = '', promotionIdList = [], page = 0, size = 20, sort = '', } = action.payload;

        return noecAjax({
            ...ajaxBaseConfig,
            url: `${urls.queryTools.productManual.productManual}?page=${page}&size=${size}&sort=${sort}&promotionTagNameOrId=${promotionTagNameOrId}&promotionType=${promotionType}&promotionIdList=${promotionIdList}`
        }).pipe(
            map(res => actions.getProductManualList.SUCCESS(res.response)),
            catchError(error => of(actions.getProductManualList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)


export const createProductManualEpic = actions$ => actions$.pipe(
    ofType(actions.createProductManual.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'POST',
            body: data,
            url: `${urls.queryTools.productManual.productManual}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.createProductManual.SUCCESS(res.response)
            }),
            catchError(error => of(actions.createProductManual.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)

export const putProductManualEpic = actions$ => actions$.pipe(
    ofType(actions.putProductManual.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PUT',
            body: data,
            url: `${urls.queryTools.productManual.productManual}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.putProductManual.SUCCESS(res.response)
            }),
            catchError(error => of(actions.putProductManual.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const delProductManualEpic = actions$ => actions$.pipe(
    ofType(actions.delProductManual.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { id, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'DELETE',
            url: `${urls.queryTools.productManual.productManual}/${id}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.delProductManual.SUCCESS(res.response)
            }),
            catchError(error => of(actions.delProductManual.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
export const disabledOrEnableProductManualEpic = actions$ => actions$.pipe(
    ofType(actions.disabledOrEnableProductManual.REQUEST.toString()),
    mergeMap(action => {
        actionApi()
        const { data, callback } = action.payload;
        return ajax({
            ...ajaxBaseConfig,
            method: 'PATCH',
            url: `${urls.queryTools.productManual.productManual}/${data.id}/${data.status}`
        }).pipe(
            map(res => {
                callback && callback(res);
                return actions.disabledOrEnableProductManual.SUCCESS(res.response)
            }),
            catchError(error => of(actions.disabledOrEnableProductManual.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
        )
    })
)
