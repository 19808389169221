import { createAction, handleActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'factory';

export const loadBrandSelections = fasterActions(moduleName, 'loadBrandSelections');
export const loadBrandPagerList = fasterActions(moduleName, 'loadBrandPagerList');
export const loadBrandById = fasterActions(moduleName, 'loadBrandById');
export const createBrand = fasterActions(moduleName, 'createBrand');
export const editBrand = fasterActions(moduleName, 'editBrand');
export const disabledOrEnabledBrand = fasterActions(moduleName, 'disabledOrEnabledBrand');


export const updataBrandSearchKey = createAction(`${moduleName}/updataBrandSearchKey`);
export const resetBrandSelections = createAction(`${moduleName}/resetBrandSelections`);


const initialState = {
  brandSelecList: [],
  brandPageList: [],
  brandPageListLoading: false,
  brandPageParam: { page: 0, size: 20, sort:'id,desc' },
  brandDetail: {},
  brandSearchKey: ''

}

const reducer = handleActions(
  {
    // select
    [loadBrandSelections.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadBrandSelections.SUCCESS]: (state, { payload }) => {
      let selection = [];
      for (let elem of payload.content) {
        selection.push({ value: elem.id, text: elem.name });
      }
      return { ...state, brandSelecList: selection }
    },
    [loadBrandSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 分页查询
    [loadBrandPagerList.REQUEST]: (state, { payload }) => ({ ...state, ...payload, brandPageListLoading: true}),
    [loadBrandPagerList.SUCCESS]: (state, { payload }) => ({ ...state, brandPageList: payload.content, brandPageListLoading: false, brandPageParam: {...state.brandPageParam, totalElements: payload.totalElements} }),
    [loadBrandPagerList.FAIL]: (state, { payload }) => ({ ...state, error: payload, brandPageListLoading: false }),
    
    // id查詢
    [loadBrandById.REQUEST]: (state, { payload }) => ({ ...state}),
    [loadBrandById.SUCCESS]: (state, { payload }) => ({ ...state, brandDetail: payload  }),
    [loadBrandById.FAIL]: (state, { payload }) => ({ ...state, error: payload  }),
  
    // 创建品牌
    [createBrand.REQUEST]: (state, { payload }) => ({ ...state}),
    [createBrand.SUCCESS]: (state, { payload }) => ({ ...state}),
    [createBrand.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
    
    // 编辑品牌
    [editBrand.REQUEST]: (state, { payload }) => ({ ...state}),
    [editBrand.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editBrand.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 禁用，启用品牌
    [disabledOrEnabledBrand.REQUEST]: (state, { payload }) => ({ ...state}),
    [disabledOrEnabledBrand.SUCCESS]: (state, { payload }) => ({ ...state }),
    [disabledOrEnabledBrand.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [updataBrandSearchKey]: (state, {payload}) => ({...state, ...payload}),
    [resetBrandSelections]: (state, {payload}) => ({...state, brandSelecList: []}),
    

  },
  initialState
)

export default reducer;