import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './labelBatchQuery';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

// 标签批量查询-分页列表
export const getLabelBatchQueryListEpic = actions$ => actions$.pipe(
  ofType(actions.getLabelBatchQueryList.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { page, size } = action.payload;
    let url = `${urls.labelBatchQuery.getLabelBatchQueryList}?page=${page}&size=${size}`;

    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.getLabelBatchQueryList.SUCCESS(res.response)),
      catchError(error => {
        return of(actions.getLabelBatchQueryList.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);

// 标签码数据批量下载-下载文件
export const handleTagBatchDownloadEpic = actions$ => actions$.pipe(
  ofType(actions.handleTagBatchDownload.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { id } = action.payload;
    let url = `${urls.labelBatchQuery.handleTagBatchDownload}/${id}/export`;

    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.handleTagBatchDownload.SUCCESS(res.response)),
      catchError(error => {
        return of(actions.handleTagBatchDownload.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);

// 标签码数据批量下载-标签批量下载任务创建
export const handleCreateLabelBatchQueryEpic = actions$ => actions$.pipe(
  ofType(actions.handleCreateLabelBatchQuery.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { paramsObj = {}, callback } = action.payload;
    let url = `${urls.labelBatchQuery.createLabelBatchQuery}`;

    return ajax({
      ...ajaxBaseConfig,
      url,
      method: 'POST',
      body: paramsObj
    }).pipe(
      map(res => {
        callback && callback(res);
        return actions.handleCreateLabelBatchQuery.SUCCESS(res.response);
      }),
      catchError(error => {
        return of(actions.handleCreateLabelBatchQuery.FAIL(error.xhr.response), uiActions.notification(error.xhr.response))
      })
    )
  }
  )
);