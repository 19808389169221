import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'products';

const initialState = {
  listLoaded: false,
  // products: []
  productPageList: [],
  productDetail: {},
  productPageLoad: false,
  productSearchKey: '',
  productPageParam: {
    page: 0,
    size: 20,
    sort: 'id,desc',
    brandId: ''
  }
}

// Actions
export const loadList = fasterActions(moduleName, 'loadList');
export const products = fasterActions(moduleName, 'products');
export const loadProductPageList = fasterActions(moduleName, 'products');
export const enabledOrDisabledProducts = fasterActions(moduleName, 'enabledOrDisabledProducts');
export const createProducts = fasterActions(moduleName, 'createProducts');
export const editProducts = fasterActions(moduleName, 'editProducts');
export const loadProductById = fasterActions(moduleName, 'loadProductById');
export const exportProduct = fasterActions(moduleName, 'exportProduct');


export const updataProductSearchKey = createAction(`${moduleName}/updataProductSearchKey`);


export const editProductPack = fasterActions(moduleName, 'editProductPack');



// Reducer
const reducer = handleActions(
  {
    // select
    [loadList.REQUEST]: (state, { payload }) => ({ ...state, ...payload, listLoaded: true }) ,
    [loadList.SUCCESS]: (state, { payload }) => {
      const selector = [];
      const enableSelector = [];
      const selectorAll = []
      enableSelector.push({ value: '', text: '暂无' });
      if (payload.type == 'all') {
        for (let elem of payload.content.values()) {
          const obj = { ...elem, value: elem.id, text: elem.name }
          selectorAll.push(obj);
        }
      }
      else {
        for (let elem of payload.content.values()) {
          
          const obj = { ...elem, value: elem.id, text: elem.name }
          if (elem.code) {
            obj.text += '_' + elem.code;
          }
          selector.push(obj);
          if (elem.status == 'ENABLE') {
            enableSelector.push(obj);
          }
        }
      }
      if (payload.type == 'all') {
        return { ...state, listLoaded: true, selectorAll }

      }
      else {
        return { ...state, listLoaded: true, selector, enableSelector, list: payload.content, }

      }
    },
    [loadList.FAIL]: (state, { payload }) => ({ ...state, listLoaded: false }),

    // [products.REQUEST]: (state, { payload }) => ({ ...state }),
    // [products.SUCCESS]: (state, { payload }) => {
    //   if (payload.content) {
    //     payload.content.forEach(val => { val.text = val.name; val.value = val.id; });
    //   } else {
    //     payload.content = [];
    //   }
    //   payload.content.splice(0, 0, { text: '请选择产品', value: '' });
    //   return ({ ...state, productSelector: payload.content })
    // },
    // [products.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 分页查询
    [loadProductPageList.REQUEST]: (state, { payload }) => ({ ...state, ...payload, productPageLoad: true }),
    [loadProductPageList.SUCCESS]: (state, { payload }) => ({ ...state, productPageLoad: false, productPageList: payload.content, productPageParam: { ...state.productPageParam, totalElements: payload.totalElements } }),
    [loadProductPageList.FAIL]: (state, { payload }) => ({ ...state, error: payload, productPageLoad: false }),

    // 启用，禁用产品
    [enabledOrDisabledProducts.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [enabledOrDisabledProducts.SUCCESS]: (state, { payload }) => ({ ...state }),
    [enabledOrDisabledProducts.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 创建产品
    [createProducts.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [createProducts.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createProducts.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 编辑产品
    [editProducts.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [editProducts.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editProducts.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 根据id查询产品
    [loadProductById.REQUEST]: (state, { payload }) => ({ ...state, ...payload, loadProductByIdLoad: true }),
    [loadProductById.SUCCESS]: (state, { payload }) => {
      return ({ ...state, loadProductByIdLoad: false, productDetail: payload })
    },
    [loadProductById.FAIL]: (state, { payload }) => ({ ...state, error: payload, loadProductByIdLoad: false }),

    // 导出产品
    [exportProduct.REQUEST]: (state, { payload }) => ({ ...state, }),
    [exportProduct.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [exportProduct.FAIL]: (state, { payload }) => ({ ...state, error: payload, }),

    [updataProductSearchKey]: (state, { payload }) => ({ ...state, ...payload }),




    [editProductPack.REQUEST]: (state, { payload }) => ({ ...state, ...payload }),
    [editProductPack.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editProductPack.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    
  },
  initialState
);
export default reducer;