import { handleActions, createAction } from 'redux-actions';
import { fasterActions } from '../../utils';

const moduleName = 'dealers';

export const loadDealerList = fasterActions(moduleName, 'loadDealerList');
export const dealerById = fasterActions(moduleName, 'dealerById');
export const enableOrDisableDealer = fasterActions(moduleName, 'enableOrDisableDealer');
export const postDealer = fasterActions(moduleName, 'postDealer');
export const putDealer = fasterActions(moduleName, 'putDealer');
export const loadDealerSelection = fasterActions(moduleName, 'loadDealerSelection');

export const setDatadealerFormdata = createAction(`${moduleName}/setDatadealerFormdata`);

const initialState = {
    paging: {
        page: 0,
        size: 20,
        totalElements: 0,
        sort:'id,desc'
    },
    dealerList: []
};



const reducer = handleActions({
    [setDatadealerFormdata]: (state, { payload }) => ({ ...state, datadealerFormdata: payload }),
    // 分页查询经销商
    [loadDealerList.REQUEST]: (state, { payload }) => ({ ...state, paging: { ...payload }, dealerListloading: true }),
    [loadDealerList.SUCCESS]: (state, { payload }) => {
        const { content = [], number: size = state.paging.size, totalElements } = payload;
        return { ...state, dealerListloading: false, dealerList: content, paging: { ...state.paging, size, totalElements } };
    },
    [loadDealerList.FAIL]: (state, { payload }) => ({ ...state, dealerListloading: false, error: payload }),

    // 查询经销商详情
    [dealerById.REQUEST]: (state, { payload }) => ({ ...state, dealerByIdloading: true }),
    [dealerById.SUCCESS]: (state, { payload }) => {
        return { ...state, dealerByIdloading: false, datadealerFormdata: payload };
    },
    [dealerById.FAIL]: (state, { payload }) => ({ ...state, dealerByIdloading: false, error: payload, datadealerFormdata: null }),

    // 禁用启用经销商
    [enableOrDisableDealer.REQUEST]: (state, { payload }) => ({ ...state, enableOrDisableDealerloading: true }),
    [enableOrDisableDealer.SUCCESS]: (state, { payload }) => {
        return { ...state, enableOrDisableDealerloading: false };
    },
    [enableOrDisableDealer.FAIL]: (state, { payload }) => ({ ...state, enableOrDisableDealerloading: false, error: payload }),

    // 新增经销商
    [postDealer.REQUEST]: (state, { payload }) => ({ ...state, postDealerloading: true }),
    [postDealer.SUCCESS]: (state, { payload }) => {
        return { ...state, postDealerloading: false };
    },
    [postDealer.FAIL]: (state, { payload }) => ({ ...state, postDealerloading: false, error: payload }),

    // 修改经销商
    [putDealer.REQUEST]: (state, { payload }) => ({ ...state, putDealerloading: true }),
    [putDealer.SUCCESS]: (state, { payload }) => {
        return { ...state, putDealerloading: false };
    },
    [putDealer.FAIL]: (state, { payload }) => ({ ...state, putDealerloading: false, error: payload }),

    // 下拉框
    [loadDealerSelection.REQUEST]: (state, { payload }) => ({ ...state, loadDealerSelectionloading: true }),
    [loadDealerSelection.SUCCESS]: (state, { payload }) => {
        let dealerSelection = [];
        for (let elem of payload.content) {
          const obj = { ...elem, value: elem.id, text: elem.name }
          if (elem.code) {
            obj.text += '_' + elem.code;
          }
          dealerSelection.push(obj);
        }
        return { ...state, loadDealerSelectionloading: false, dealerSelection };
    },
    [loadDealerSelection.FAIL]: (state, { payload }) => ({ ...state, loadDealerSelectionloading: false, error: payload }),

}, initialState);

export default reducer;


