import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
import { ajax } from '../../ajax';
import urls from '../../../api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './warnEvents';
import { ajaxBaseConfig, apiBaseConfig, actionApi } from '../../utils';

/**
 * TODO: 微信管理
 */

// Epics
// 分页查询预警事件接口
export const warnEventsEpic = actions$ => actions$.pipe(
  ofType(actions.warnEvents.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    const { eventName = '', page, size } = action.payload;
    let url = `${urls.warn.events.events}?page=${page}&size=${size}&sort=id,desc`;
    if (eventName) {
      url += `&eventName=${eventName}`;
    }
    return ajax({
      ...apiBaseConfig,
      url,
    }).pipe(
      map(res => actions.warnEvents.SUCCESS(res.response)),
      catchError(error => of(actions.warnEvents.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 创建/修改 预警事件
export const warnEventsModifyEpic = actions$ => actions$.pipe(
  ofType(actions.warnEventsModify.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.events.events}`,
      body: action.payload,
      method: action.payload.id ? 'PATCH' : 'post',
    }).pipe(
      mergeMap(res => [actions.warnEventsModify.SUCCESS(res.status), uiActions.notification(action.payload.id ? '修改成功' : '创建成功', 'success')]),
      catchError(error => of(actions.warnEventsModify.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);


// 启用/禁用 预警事件
export const warnEventsStatusEpic = actions$ => actions$.pipe(
  ofType(actions.warnEventsStatus.REQUEST.toString()),
  mergeMap(action => {
    actionApi();
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.warn.events.eventsStatus}`,
      body: action.payload,
      method: 'PATCH',
    }).pipe(
      map(res => actions.warnEventsStatus.SUCCESS(res.response)),
      catchError(error => of(actions.warnEventsStatus.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);